var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "vx-row" },
        [
          _c("select-type-cancel-contract", {
            staticClass: "vx-col w-full",
            on: { change: _vm.typeCancelChanged },
            model: {
              value: _vm.typeCancel,
              callback: function ($$v) {
                _vm.typeCancel = $$v
              },
              expression: "typeCancel",
            },
          }),
        ],
        1
      ),
      _vm.typeCancel !== "no_refund"
        ? _c(
            "div",
            { staticClass: "vx-row" },
            [
              _c("select-type-payment", {
                staticClass: "vx-col md:w-1/2",
                model: {
                  value: _vm.typePayment,
                  callback: function ($$v) {
                    _vm.typePayment = $$v
                  },
                  expression: "typePayment",
                },
              }),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/2 w-full mt-0" },
                [
                  _c("vs-input", {
                    staticClass: "w-full mt-0",
                    attrs: { type: "date", label: "Vencimento" },
                    model: {
                      value: _vm.expiration,
                      callback: function ($$v) {
                        _vm.expiration = $$v
                      },
                      expression: "expiration",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showFormPayment
        ? _c(
            "div",
            { staticClass: "vx-row" },
            [
              _c(_vm.paymentComponent, {
                ref: "payment",
                tag: "component",
                staticClass: "vx-col w-full",
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-full w-full mt-0" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v("Motivo do cancelamento"),
            ]),
            _c("vs-textarea", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate.initial",
                  value: "required",
                  expression: "'required'",
                  modifiers: { initial: true },
                },
              ],
              staticClass: "mb-0",
              attrs: {
                "data-vv-as": "Motivo do cancelamento",
                "data-vv-scope": "contractCancel",
                name: "reasonCancellation",
              },
              model: {
                value: _vm.reasonCancellation,
                callback: function ($$v) {
                  _vm.reasonCancellation = $$v
                },
                expression: "reasonCancellation",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("contractCancel.reasonCancellation"),
                    expression:
                      "errors.has('contractCancel.reasonCancellation')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [
                _vm._v(
                  _vm._s(_vm.errors.first("contractCancel.reasonCancellation"))
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("vs-divider"),
      _c(
        "div",
        { staticClass: "flex flex-row-reverse bg-grey-lighter" },
        [_c("vs-button", { on: { click: _vm.save } }, [_vm._v("Salvar")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }