<template>
  <div v-if="params.data" class="flex items-center">
    <div v-if="status === 'Não Concluído'" class="h-4 w-4 inline-block rounded-full mr-2 border-2 border-solid border-secondary"></div>
    <div v-else-if="status === 'Concluído'" class="h-4 w-4 inline-block rounded-full mr-2 bg-success"></div>
    <div v-else-if="status === 'Apto'" class="h-4 w-4 inline-block rounded-full mr-2 bg-success"></div>
    <feather-icon v-else-if="status === 'Inapto'" class="text-danger mr-1" icon="SlashIcon" svgClasses="w-5 h-5" />
    <div v-else-if="status === 'Inapto Temporário'" class="h-4 w-4 inline-block rounded-full mr-2 bg-warning"></div>
    <div v-else-if="status === 'Pendente'" class="h-4 w-4 inline-block rounded-full mr-2 border-2 border-solid border-secondary"></div>
    <div v-else-if="status === 'Apto Com Restrições'" class="h-4 w-4 inline-block rounded-full mr-2 border-2 border-solid border-secondary"></div>
    <div v-else-if="status === 'Aguardando Teste'" class="h-4 w-4 inline-block rounded-full mr-2 border-2 border-solid border-secondary"></div>

    <span class="cursor-default ml-1 mr-2" :class="`text-${textColor}`">
      {{ status | scheduleStatus }}
    </span>

    <vx-tooltip text="Alterar Situação" position="top" class="flex items-center cursor-pointer hover:text-primary">
      <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
        <feather-icon icon="EditIcon" svgClasses="h-5 w-5 mr-1" />
        <vs-dropdown-menu class="w-60">

          <vs-dropdown-item v-if="type === 'psychotechnical' || type === 'medical_exam' || type === 'practical_exam' || type === 'theoretical_exam'" @click="updateStatus('Apto')">
            <span class="flex items-center">
              <div class="h-4 w-4 inline-block rounded-full mr-3 bg-success"></div>
              <span>Apto</span>
            </span>
          </vs-dropdown-item>

          <vs-dropdown-item v-if="type === 'psychotechnical' || type === 'medical_exam' || type === 'practical_exam'  || type === 'theoretical_exam'" @click="updateStatus('Inapto')">
            <span class="flex items-center">
              <feather-icon class="mr-2 text-danger" icon="SlashIcon" svgClasses="w-5 h-5" />
              <span class="text-danger hover:text-primary">Inapto</span>
            </span>
          </vs-dropdown-item>

          <vs-dropdown-item v-if="type === 'practical_exam' || type === 'theoretical_exam'" @click="updateStatus('Aguardando Teste')">
            <span class="flex items-center">
              <div class="h-4 w-4 inline-block rounded-full mr-3 border-2 border-solid border-secondary"></div>
              <span>Aguardando Teste</span>
            </span>
          </vs-dropdown-item>

          <vs-dropdown-item v-if="type === 'medical_exam'" @click="updateStatus('Apto Com Restrições')">
            <span class="flex items-center">
              <div class="h-4 w-4 inline-block rounded-full mr-3 border-2 border-solid border-secondary"></div>
              <span>Apto Com Restrições</span>
            </span>
          </vs-dropdown-item>

          <vs-dropdown-item v-if="type === 'psychotechnical'" @click="updateStatus('Inapto Temporário')">
            <span class="flex items-center">
              <div class="h-4 w-4 inline-block rounded-full mr-2 bg-warning"></div>
              <span>Inapto Temporário</span>
            </span>
          </vs-dropdown-item>

          <vs-dropdown-item v-if="type === 'psychotechnical' || type === 'medical_exam'" @click="updateStatus('Pendente')">
            <span class="flex items-center">
              <div class="h-4 w-4 inline-block rounded-full mr-3 border-2 border-solid border-secondary"></div>
              <span>Pendente</span>
            </span>
          </vs-dropdown-item>

        </vs-dropdown-menu>
      </vs-dropdown>
    </vx-tooltip>
    <!-- <span class="cursor-default" :class="`text-${status === 'Presente' ? 'success' : status === 'Ausente' ? 'danger' : status === 'Programada' ? 'secondary' : ''}`">
     {{ status | scheduleStatus }}
    </span> -->
  </div>
</template>

<script>
export default {
  name: 'CellRendererSituation',
  computed: {
    status () {
      return this.params.value
    },
    textColor () {
      let color = ''
      if (this.status === 'Não Concluído')  color = 'secondary'
      else if (this.status === 'Concluído') color = 'success'
      else if (this.status === 'Apto')      color = 'success'
      else if (this.status === 'Inapto')    color = 'danger'
      else if (this.status === 'Pendente')  color = 'secondary'
      else if (this.status === 'Inapto Temporário')   color = 'warning'
      else if (this.status === 'Apto Com Restrições') color = 'secondary'
      else if (this.status === 'Aguardando Teste')    color = 'secondary'

      return color

    },
    type () {
      return this.params.data.type
    }
  },
  methods: {
    updateStatus (status) {
      this.params.updateStatus(this.params, status)// assim chamo a função de fora
    }
  }
}
</script>
