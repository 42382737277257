<template>
  <div id="page-student-edit">
    <vs-alert color="danger" title="Aluno não encontrado!" :active.sync="student_not_found">
      <span>O aluno com código: {{ $route.params.studentId }} não foi encontrado. </span>
      <span>
        <span>Verifique </span>
        <router-link :to="{name:'student-list'}" class="text-inherit underline">a lista de alunos</router-link>
        <span> ou faça uma busca no campo do topo da página.</span>
      </span>
    </vs-alert>

    <vx-card v-if="ready">
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Dados Gerais" icon-pack="feather" icon="icon-user">
            <div class="tab-text">
              <student-edit-tab-general-data class="mt-4" :states="states" ref="student_edit_tab_general_data" />
            </div>
          </vs-tab>
          <vs-tab label="Dados Extras/CNH" icon-pack="feather" icon="icon-info">
            <div class="tab-text">
              <student-edit-tab-additional-information class="mt-4" :states="states" ref="student_edit_tab_additional_information" />
            </div>
          </vs-tab>
          <vs-tab label="Contrato" icon-pack="feather" icon="icon-file-text">
            <div class="tab-text">
              <student-edit-tab-contract class="mt-4" :states="states" />
            </div>
          </vs-tab>
          <vs-tab label="Financeiro" icon-pack="feather" icon="icon-dollar-sign">
            <div class="tab-text">
              <student-edit-tab-financial class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Taxas" icon-pack="feather" icon="icon-dollar-sign">
            <div class="tab-text">
              <student-edit-tab-tax class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Agenda Aula" icon-pack="feather" icon="icon-calendar">
            <div class="tab-text">
              <student-edit-tab-class-schedule class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Agenda Exame" icon-pack="feather" icon="icon-calendar">
            <div class="tab-text">
              <student-edit-tab-exam-schedule class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Documentos" icon-pack="feather" icon="icon-file-text">
            <div class="tab-text">
              <student-edit-tab-documents class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Observação" icon-pack="feather" icon="icon-file-text">
            <div class="tab-text">
              <student-edit-tab-observation class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Protocolo" icon-pack="feather" icon="icon-calendar">
            <div class="tab-text">
              <student-edit-tab-protocol class="mt-4" />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import StudentEditTabGeneralData from './StudentEditTabGeneralData.vue'
import StudentEditTabAdditionalInformation from './StudentEditTabAdditionalInformation.vue'
import StudentEditTabContract from './StudentEditTabContract.vue'
import StudentEditTabFinancial from './StudentEditTabFinancial.vue'
import StudentEditTabTax from './StudentEditTabTax.vue'
import StudentEditTabClassSchedule from './StudentEditTabClassSchedule.vue'
import StudentEditTabExamSchedule from './StudentEditTabExamSchedule.vue'
import StudentEditTabHistoric from './StudentEditTabHistoric.vue'
import StudentEditTabDocuments from './StudentEditTabDocuments.vue'
import StudentEditTabObservation from './StudentEditTabObservation.vue'
import StudentEditTabProtocol from './StudentEditTabProtocol.vue'
import StudentDocumentForm from '@/components/register/student/document/StudentDocumentForm.vue'

// Store Module
import moduleStudentManagement from '@/store/student-management/moduleStudentManagement.js'
import moduleStates from '@/store/state/moduleStates.js'
import moduleGroups from '@/store/groups/moduleGroups.js'
import moduleServicePackage from '@/store/service-package/moduleServicePackage.js'

export default {
  components: {
    StudentEditTabGeneralData,
    StudentEditTabAdditionalInformation,
    StudentEditTabContract,
    StudentEditTabFinancial,
    StudentEditTabTax,
    StudentEditTabClassSchedule,
    StudentEditTabExamSchedule,
    StudentEditTabHistoric,
    StudentEditTabDocuments,
    StudentEditTabObservation,
    StudentEditTabProtocol,
    StudentDocumentForm
  },
  data () {
    return {
      ready: false,
      student_not_found: false,
      activeTab: 0
    }
  },

  watch:{
    // necessário quando estou editando um aluno e altero para outro via search superior
    '$route.params.studentId' (studentId) {
      this.fetch_student_data(studentId)
    },

    //Preciso verificar quando altera a TAB para avisar sobre salvar dados não salvos
    activeTab (newVal, oldVal) {
      if (this.companyConfigurations.general.studentSave && ((newVal === 0 || newVal === 1) && (oldVal === 0 || oldVal === 1))) { //se venho ou vou entre abas 0 e 1
        let data = {}
        let changed = false
        let changedCnh = false
        let changedTenant = false
        if (newVal === 1 && this.$refs.student_edit_tab_general_data.changed) { //estou indo da 0 para a 1
          data = this.$refs.student_edit_tab_general_data.data_local //devo salvar dados da 0
          changed = this.$refs.student_edit_tab_general_data.changed
        } else if (newVal === 0) { // estou indo da 1 para a 0
          changed = this.$refs.student_edit_tab_additional_information.changed
          changedCnh = this.$refs.student_edit_tab_additional_information.changedCnh
          changedTenant = this.$refs.student_edit_tab_additional_information.changedTenant
          data = this.$refs.student_edit_tab_additional_information.data_local //devo salvar dados da 1
        }
        //Salvando dados de registro, CNH e Avalista)
        if ((newVal === 1 && changed) || (newVal === 0 && changed) || (newVal === 0 && changedCnh) || (newVal === 0 && changedTenant)) {
          const yes = () => {
            if ((newVal === 0 || newVal === 1) && changed) {
              this.saveStudentData(data)
            }
            if (newVal === 0 && changedCnh) {
              this.$vs.loading()
              let showAlert = false
              data.cnhs.forEach(cnh => {
                this.updateCnh(cnh)
                showAlert = true
              })
              this.$nextTick(() => { this.$vs.loading.close() })
              if (showAlert) {
                this.$vs.notify({
                  time: 5000,
                  title: 'SUCESSO',
                  text: 'Dados Salvos.',
                  color: 'success',
                  iconPack: 'feather',
                  icon: 'icon-check'
                })
              }
            }
            if (newVal === 0 && changedTenant) {
              this.saveTenant(data)
            }
          }
          this.$vs.dialog({
            type: 'confirm',
            color: 'primary',
            title: 'Confirmação',
            text: 'Você não salvou as alterações de registro do aluno! Deseja salvar agora?',
            accept: yes,
            acceptText: 'SIM',
            cancelText: 'NÃO'
          })
        }
      }
    }
  },
  methods: {
    // Preciso desse método em função do salvamento após mudar de tab no caso de avisos por dados não salvos
    saveStudentData (data) {
      this.$store.dispatch('studentManagement/updateStudent', data)
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Salvos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(error => {
          this.$vs.loading.close()
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        })
    },
    updateCnh (cnh) {
      this.$store.dispatch('cnh/updateCnh', cnh)
        .then((resp) => {
          this.$store.commit('studentManagement/SET_STUDENT_CNH', resp.data)
        })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },
    saveTenant (data) {
      Object.assign(data.tenant, {student_id: data.id})
      this.$vs.loading()
      this.$store.dispatch('tenant/saveTenant', data.tenant)
        .then((resp) => {
          this.$store.commit('studentManagement/SET_STUDENT', {tenant: resp.data})
          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Salvos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },

    fetch_student_data (studentId) {
      this.$vs.loading()
      this.$store.dispatch('studentManagement/fetchStudent', studentId)
        .then((resp) => {
          if (!resp.data.id) {
            this.student_not_found = true
          } else {
            this.student_not_found = false
            if (this.ready && this.activeTab === 0) {
              this.$refs.student_edit_tab_general_data.resetLocalData()
            }
            this.ready = true
            this.activeTab = 0
            if (resp.data.defaulting) {
              this.$vs.notify({
                time: 5000,
                title: 'AVISO',
                text: 'Há parcelas atrasadas deste aluno!',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'warning',
                position:'top-center'
              })
            }
          }
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          if (err.response && err.response.status === 404) {
            this.student_not_found = true
          }
          this.$vs.loading.close()
        })
    }
  },
  computed: {
    companyConfigurations () {
      return this.$store.getters.companyConfigurations
    },
    states () {
      return this.$store.getters['states/getStates']
    }
  },

  created () {
    // Register Module StudentManagement Module
    if (!moduleStudentManagement.isRegistered) {
      this.$store.registerModule('studentManagement', moduleStudentManagement)
      moduleStudentManagement.isRegistered = true
    }
    this.fetch_student_data(this.$route.params.studentId)

    if (!moduleStates.isRegistered) {
      this.$store.registerModule('states', moduleStates)
      moduleStates.isRegistered = true
    }
    this.$store.dispatch('states/fetchStates')

    if (!moduleServicePackage.isRegistered) {
      // this.$store.registerModule('servicePackage', moduleServicePackage)
      moduleServicePackage.isRegistered = true
    }
    this.$store.dispatch('servicePackage/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleGroups.isRegistered) {
      this.$store.registerModule('groups', moduleGroups)
      moduleGroups.isRegistered = true
    }
    this.$store.dispatch('groups/fetchGroups')
  },

  //CONTROLO AS ALTERAÇÕES NAS ABAS E AVISO CASO SAIA DAPÁGINA SEM SALVAR CASO TENHA ALTERADO
  beforeRouteLeave (to, from, next) {

    //verifica configurações
    if (this.companyConfigurations.general.studentSave) {
      const no = () => { next() }

      if (this.activeTab === 0 && this.$refs.student_edit_tab_general_data && this.$refs.student_edit_tab_general_data.changed) {
        const yes = () => {
          this.$refs.student_edit_tab_general_data.saveChanges()
          next()
        }
        this.$vs.dialog({
          type: 'confirm',
          color: 'primary',
          title: 'Confirmação',
          text: 'Você não salvou as alterações! Deseja salvar agora?',
          accept: yes,
          cancel: no,
          acceptText: 'SIM',
          cancelText: 'NÃO'
        })
      } else if (this.activeTab === 1 && (this.$refs.student_edit_tab_additional_information.changed || this.$refs.student_edit_tab_additional_information.changedCnh || this.$refs.student_edit_tab_additional_information.changedTenant)) {
        const yes = () => {
          if (this.$refs.student_edit_tab_additional_information.changed) this.$refs.student_edit_tab_additional_information.saveChanges()
          if (this.$refs.student_edit_tab_additional_information.changedTenant) this.$refs.student_edit_tab_additional_information.saveTenant()

          if (this.$refs.student_edit_tab_additional_information.changedCnh) {
            const data = this.$refs.student_edit_tab_additional_information.data_local //devo salvar dados da 1
            this.$vs.loading()
            let showAlert = false
            data.cnhs.forEach(cnh => {
              this.updateCnh(cnh)
              showAlert = true
            })
            this.$nextTick(() => { this.$vs.loading.close() })
            if (showAlert) {
              this.$vs.notify({
                time: 5000,
                title: 'SUCESSO',
                text: 'Dados Salvos.',
                color: 'success',
                iconPack: 'feather',
                icon: 'icon-check'
              })
            }
          }
          next()
        }
        this.$vs.dialog({
          type: 'confirm',
          color: 'primary',
          title: 'Confirmação',
          text: 'Você não salvou as alterações! Deseja salvar agora?',
          accept: yes,
          cancel: no,
          acceptText: 'SIM',
          cancelText: 'NÃO'
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }
}
</script>
<style lang="scss">
#page-student-edit {
  .vs-tabs--content {
    padding: 0 10px 23px !important;
  }
}
</style>
