var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "sig-prompt",
        {
          attrs: {
            active: _vm.formStudentDocument,
            title: "Formulário de protocolo",
          },
          on: {
            "update:active": function ($event) {
              _vm.formStudentDocument = $event
            },
            accept: _vm.save,
          },
        },
        [
          _c("student-document-form", {
            ref: "form",
            model: {
              value: _vm.studentDocument,
              callback: function ($$v) {
                _vm.studentDocument = $$v
              },
              expression: "studentDocument",
            },
          }),
        ],
        1
      ),
      _c("sig-data-list", {
        ref: "list",
        attrs: {
          model: _vm.model,
          fetch: _vm.params,
          "allow-search": _vm.allowSearch,
        },
        on: {
          "search-end": function ($event) {
            return _vm.$emit("search-end")
          },
          "grid-ready": function ($event) {
            return _vm.$emit("grid-ready")
          },
          saved: function ($event) {
            return _vm.search(false)
          },
          register: _vm.register,
          editing: _vm.register,
        },
        scopedSlots: _vm._u([
          {
            key: "form",
            fn: function () {
              return [
                _c(
                  "sig-popup",
                  {
                    ref: "popup",
                    model: {
                      value: _vm.popup,
                      callback: function ($$v) {
                        _vm.popup = $$v
                      },
                      expression: "popup",
                    },
                  },
                  [_c("div", [_vm._v("\n      TESTE\n    ")])]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }