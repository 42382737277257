<template>
  <div>
    <div class="vx-row">
      <select-type-cancel-contract v-model="typeCancel" class="vx-col w-full" @change="typeCancelChanged"/>
    </div>
    <div class="vx-row" v-if="typeCancel !== 'no_refund'">
      <select-type-payment v-model="typePayment" class="vx-col md:w-1/2" />
      <div class="vx-col md:w-1/2 w-full mt-0">
        <vs-input type="date" class="w-full mt-0" label="Vencimento" v-model="expiration" />
      </div>
    </div>
    <div class="vx-row" v-if="showFormPayment">
     <component :is="paymentComponent" class="vx-col w-full" ref="payment" />
    </div>
     <div class="vx-row">
        <div class="vx-col md:w-full w-full mt-0">
          <label class="vs-input--label">Motivo do cancelamento</label>
          <vs-textarea class="mb-0" v-model="reasonCancellation"
            data-vv-as="Motivo do cancelamento" data-vv-scope="contractCancel" v-validate.initial="'required'" name="reasonCancellation"/>
          <span class="text-danger text-sm" v-show="errors.has('contractCancel.reasonCancellation')">{{ errors.first('contractCancel.reasonCancellation') }}</span>
        </div>
      </div>
       <vs-divider></vs-divider>
    <div class="flex flex-row-reverse bg-grey-lighter">
      <vs-button @click="save"
          >Salvar</vs-button>
      </div>
  </div>
</template>

<script>
import SelectTypeCancelContract from './SelectTypeCancelContract'
import SelectTypePayment from '@/components/financial/typePayment/SelectTypePayment'
import FormBankPayment from '@/components/financial/payment/bank-payment/FormBankPayment'

export default {
  components: {
    SelectTypeCancelContract,
    SelectTypePayment,
    FormBankPayment
  },
  props: {
    contractId: {}
  },
  data () {
    return {
      typeCancel: 'no_refund',
      reasonCancellation: null,
      typePayment: null,
      expiration: this.mixinToday()
    }
  },
  computed: {
    paymentComponent () {
      return 'FormBankPayment'
    },
    showFormPayment () {
      if (this.typePayment && this.typePayment.type_payment === 'TRANSFERÊNCIA') return true
    }
  },
  methods: {
    save () {
      if (!this.validate()) return
      this.$vs.loading()
      this.$http.post(`${process.env.VUE_APP_API_URL}/contract/cancel`, this.getPayload())
        .then((e) => {
          this.$emit('input', e.data)
          this.$emit('saved')
        })
        .catch(this.mixinCatch)
        .finally(this.mixinFinally)
    },
    getPayload () {
      return {
        contractId: this.contractId,
        typeCancel: this.typeCancel,
        reasonCancellation: this.reasonCancellation,
        typePayment: this.typePayment,
        expiration: this.expiration,
        payment: this.getPayloadPayment()
      }
    },
    getPayloadPayment () {
      if (this.showFormPayment && this.$refs.payment.getPayload) return this.$refs.payment.getPayload()
    },
    validate () {
      const rules = [
        () => {
          if (this.reasonCancellation) return true
          this.mixinNotify('Informe o motivo do cancelamento', 'danger')
        },
        () => {
          if (this.typeCancel !== 'no_refund' && !this.typePayment) {
            this.mixinNotify('Selecione o tipo de pagamento', 'danger')
            return false
          }
          return true

        },
        () => {
          if (this.showFormPayment && this.$refs.payment.validate) return this.$refs.payment.validate()
          return true
        }
      ]
      for (const isValid of rules) {
        if (!isValid()) return
      }
      return true
    },
    typeCancelChanged (val) {
      if (val === 'no_refund') this.typePayment = null
    }
  }
}
</script>
