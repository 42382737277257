var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { direction: _vm.$vs.rtl ? "rtl" : "ltr" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: {
            fullscreen: false,
            title: "Contrato",
            active: _vm.popupSeeContract,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupSeeContract = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-2/5 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Serviços"),
                ]),
                _c("v-select", {
                  attrs: {
                    disabled: true,
                    options: _vm.servicePackages,
                    multiple: "",
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.contract.servicePackages,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "servicePackages", $$v)
                    },
                    expression: "contract.servicePackages",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-0" },
              [
                _c("vs-input", {
                  ref: "subTotal",
                  staticClass: "w-full",
                  attrs: { disabled: true, label: "Valor" },
                  model: {
                    value: _vm.contract.sub_total,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "sub_total", $$v)
                    },
                    expression: "contract.sub_total",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Desconto " + _vm._s(_vm.contract.discount_type)),
                ]),
                _c(
                  "vx-input-group",
                  {
                    attrs: {
                      prependClasses:
                        "border border-solid border-grey border-r-0",
                    },
                  },
                  [
                    _c("template", { slot: "append" }, [
                      _c(
                        "div",
                        { staticClass: "append-text btn-addon" },
                        [
                          _c("vs-button", { attrs: { disabled: true } }, [
                            _vm._v(_vm._s(_vm.contract.discount_type)),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _vm.contract.discount_type === "%"
                      ? _c("vs-input", {
                          key: "1__",
                          staticClass: "w-full",
                          attrs: { disabled: true, type: "number" },
                          model: {
                            value: _vm.contract.discount,
                            callback: function ($$v) {
                              _vm.$set(_vm.contract, "discount", $$v)
                            },
                            expression: "contract.discount",
                          },
                        })
                      : _c("vs-input", {
                          key: "2___",
                          ref: "discount",
                          staticClass: "w-full",
                          attrs: { disabled: true },
                          model: {
                            value: _vm.contract.discount,
                            callback: function ($$v) {
                              _vm.$set(_vm.contract, "discount", $$v)
                            },
                            expression: "contract.discount",
                          },
                        }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-0" }, [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v("Total"),
              ]),
              _c("h1", { staticClass: "flex flex-wrap justify-center" }, [
                _c("sup", { staticClass: "text-lg" }, [_vm._v("R$")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.contract.value_total
                        .toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                        .replace("R$", "")
                    )
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Nº de Parcelas"),
                ]),
                _c("vs-input-number", {
                  staticClass: "mt-2",
                  attrs: { disabled: true, min: "1" },
                  model: {
                    value: _vm.contract.parcelsNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "parcelsNumber", $$v)
                    },
                    expression: "contract.parcelsNumber",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("vs-input", {
                  ref: "firstParcelValue",
                  staticClass: "w-full mt-0",
                  attrs: { label: "Valor 1ª Parcela", disabled: true },
                  model: {
                    value: _vm.contract.firstParcelValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "firstParcelValue", $$v)
                    },
                    expression: "contract.firstParcelValue",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    disabled: true,
                    type: "date",
                    label: "1º Vencimento",
                  },
                  model: {
                    value: _vm.contract.expirationDateFirstParcel,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "expirationDateFirstParcel", $$v)
                    },
                    expression: "contract.expirationDateFirstParcel",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Periodicidade"),
                ]),
                _c("v-select", {
                  attrs: {
                    disabled: true,
                    reduce: (option) => option.value,
                    clearable: false,
                    options: [
                      { label: "Mensal", value: "monthly" },
                      { label: "Quinzenal", value: "biweekly" },
                      { label: "Semanal", value: "weekly" },
                    ],
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  model: {
                    value: _vm.contract.frequency,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "frequency", $$v)
                    },
                    expression: "contract.frequency",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-2" }, [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v("Aplicar Juros"),
              ]),
              _c(
                "div",
                { staticClass: "flex flex-wrap items-center justify-center" },
                [
                  _c(
                    "vs-switch",
                    {
                      staticClass: "w-1/2 mt-2",
                      attrs: {
                        disabled: true,
                        "icon-pack": "feather",
                        "vs-icon-on": "icon-check-circle",
                        "vs-icon-off": "icon-slash",
                      },
                      model: {
                        value: _vm.contract.applyInterest,
                        callback: function ($$v) {
                          _vm.$set(_vm.contract, "applyInterest", $$v)
                        },
                        expression: "contract.applyInterest",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                        _vm._v("SIM"),
                      ]),
                      _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                        _vm._v("NÃO"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Tipo de Pagamento"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_180",
                  attrs: {
                    disabled: true,
                    reduce: (option) => option.value,
                    clearable: false,
                    options: _vm.typePayments,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.contract.type_payment,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "type_payment", $$v)
                    },
                    expression: "contract.type_payment",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Conta"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_180",
                  attrs: {
                    disabled: true,
                    reduce: (option) => option.value,
                    clearable: false,
                    options: _vm.accounts,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.contract.account,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "account", $$v)
                    },
                    expression: "contract.account",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Plano de contas"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_180",
                  attrs: {
                    disabled: true,
                    reduce: (option) => option.value,
                    options: _vm.accountPlans,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.contract.account_plan,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "account_plan", $$v)
                    },
                    expression: "contract.account_plan",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-2/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Vendedor"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_180",
                  attrs: {
                    disabled: true,
                    reduce: (option) => option.value,
                    options: _vm.employees,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.contract.employee_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "employee_id", $$v)
                    },
                    expression: "contract.employee_id",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Categoria Atual"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_115",
                  attrs: {
                    disabled: true,
                    reduce: (option) => option.value,
                    options: _vm.cnhOptions,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.contract.current_category_cnh,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "current_category_cnh", $$v)
                    },
                    expression: "contract.current_category_cnh",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Categoria Pretendida"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_115",
                  attrs: {
                    disabled: true,
                    reduce: (option) => option.value,
                    options: _vm.cnhOptions,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.contract.intended_category_cnh,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "intended_category_cnh", $$v)
                    },
                    expression: "contract.intended_category_cnh",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-2" }, [
              _c("label", { staticClass: "vs-input--label" }, [_vm._v("EAR")]),
              _c(
                "div",
                { staticClass: "flex flex-wrap items-center justify-center" },
                [
                  _c(
                    "vs-switch",
                    {
                      staticClass: "w-1/2 mt-2",
                      attrs: {
                        disabled: true,
                        "icon-pack": "feather",
                        "vs-icon-on": "icon-check-circle",
                        "vs-icon-off": "icon-slash",
                      },
                      model: {
                        value: _vm.contract.gainful_activity,
                        callback: function ($$v) {
                          _vm.$set(_vm.contract, "gainful_activity", $$v)
                        },
                        expression: "contract.gainful_activity",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                        _vm._v("SIM"),
                      ]),
                      _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                        _vm._v("NÃO"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "vx-col md:w-2/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Cursos"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_115",
                  attrs: {
                    disabled: true,
                    reduce: (option) => option.value,
                    options: _vm.specializedCoursesCNHOptions,
                    multiple: "",
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.contract.specialized_courses,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "specialized_courses", $$v)
                    },
                    expression: "contract.specialized_courses",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-4/5 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { disabled: true, label: "Observações" },
                  model: {
                    value: _vm.contract.observation,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "observation", $$v)
                    },
                    expression: "contract.observation",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-2" }, [
              _c(
                "div",
                { staticClass: "mt-5 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "w-full",
                      attrs: {
                        disabled: true,
                        type: "border",
                        color: "warning",
                        "icon-pack": "feather",
                        icon: "icon-dollar-sign",
                      },
                    },
                    [_vm._v("Gerar Parcelas")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "vs-divider",
            { attrs: { position: "left" } },
            [
              _c("feather-icon", {
                staticClass: "mr-0",
                attrs: { icon: "DollarSignIcon", svgClasses: "w-4 h-4" },
              }),
              _c("span", { staticClass: "mb-4" }, [_vm._v("Parcelas")]),
            ],
            1
          ),
          _vm.contract.transactions.length
            ? _c(
                "vs-table",
                {
                  staticStyle: { overflow: "-webkit-paged-y" },
                  attrs: { data: _vm.contract.transactions, hoverFlat: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ data }) {
                          return [
                            _vm._l(data, function (parcel, i) {
                              return _c(
                                "vs-tr",
                                {
                                  key: i,
                                  staticStyle: { opacity: "1 !important" },
                                },
                                [
                                  _c("vs-td", [
                                    _vm._v(" " + _vm._s(i + 1) + " "),
                                  ]),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("currency-input", {
                                        staticClass:
                                          "w-32 vs-inputx vs-input--input normal hasValue",
                                        attrs: {
                                          disabled: true,
                                          currency: { prefix: "R$ " },
                                          valueAsInteger: false,
                                          distractionFree: false,
                                          precision: 2,
                                          autoDecimalMode: true,
                                          valueRange: { min: 0 },
                                          allowNegative: false,
                                        },
                                        model: {
                                          value: parcel.value_parcel,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              parcel,
                                              "value_parcel",
                                              $$v
                                            )
                                          },
                                          expression: "parcel.value_parcel",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("vs-input", {
                                        staticClass: "w-48",
                                        attrs: { disabled: true, type: "date" },
                                        model: {
                                          value: parcel.expiration_date,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              parcel,
                                              "expiration_date",
                                              $$v
                                            )
                                          },
                                          expression: "parcel.expiration_date",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("v-select", {
                                        staticClass:
                                          "vue_select_drop_size_100 w-48",
                                        attrs: {
                                          disabled: true,
                                          clearable: false,
                                          reduce: (option) => option.value,
                                          options: _vm.typePayments,
                                          placeholder: "Selecione",
                                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "no-options",
                                              fn: function ({}) {
                                                return [
                                                  _vm._v(
                                                    "\n                Nenhum resultado encontrado.\n              "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: parcel.type_payment_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              parcel,
                                              "type_payment_id",
                                              $$v
                                            )
                                          },
                                          expression: "parcel.type_payment_id",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("v-select", {
                                        staticClass:
                                          "vue_select_drop_size_100 w-48",
                                        attrs: {
                                          disabled: true,
                                          clearable: false,
                                          reduce: (option) => option.value,
                                          options: _vm.accounts,
                                          placeholder: "Selecione",
                                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "no-options",
                                              fn: function ({}) {
                                                return [
                                                  _vm._v(
                                                    "\n                Nenhum resultado encontrado.\n              "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: parcel.account_id,
                                          callback: function ($$v) {
                                            _vm.$set(parcel, "account_id", $$v)
                                          },
                                          expression: "parcel.account_id",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("vs-td", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex flex-wrap items-center justify-center",
                                      },
                                      [
                                        _c(
                                          "vs-switch",
                                          {
                                            staticClass: "w-28 mt-2",
                                            attrs: {
                                              disabled: true,
                                              "icon-pack": "feather",
                                              "vs-icon-on": "icon-check-circle",
                                              "vs-icon-off": "icon-slash",
                                            },
                                            model: {
                                              value: parcel.situation,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  parcel,
                                                  "situation",
                                                  $$v
                                                )
                                              },
                                              expression: "parcel.situation",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                attrs: { slot: "off" },
                                                slot: "off",
                                              },
                                              [_vm._v("ABERTA")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                attrs: { slot: "on" },
                                                slot: "on",
                                              },
                                              [_vm._v("QUITADA")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("vs-input", {
                                        staticClass: "w-48",
                                        attrs: { disabled: true, type: "date" },
                                        model: {
                                          value: parcel.payday,
                                          callback: function ($$v) {
                                            _vm.$set(parcel, "payday", $$v)
                                          },
                                          expression: "parcel.payday",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            _c(
                              "vs-tr",
                              { staticStyle: { opacity: "1 !important" } },
                              [
                                _c(
                                  "vs-td",
                                  { attrs: { colspan: "8" } },
                                  [
                                    _c("vs-divider"),
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mt-2 flex flex-wrap items-center",
                                          },
                                          [
                                            _c(
                                              "vs-button",
                                              {
                                                staticClass: "mr-4",
                                                attrs: { color: "secondary" },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.popupSeeContract = false
                                                  },
                                                },
                                              },
                                              [_vm._v("Cancelar")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    821204119
                  ),
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", [_vm._v("#")]),
                      _c("vs-th", [_vm._v("Valor")]),
                      _c("vs-th", [_vm._v("Vencimento")]),
                      _c("vs-th", [_vm._v("Forma Pagamento")]),
                      _c("vs-th", [_vm._v("Conta")]),
                      _c("vs-th", [_vm._v("Situação")]),
                      _c("vs-th", [_vm._v("Pago Em")]),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: {
            fullscreen: false,
            title: "Boleto(s)",
            active: _vm.popupShowBoleto,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowBoleto = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "vs-con-loading__container",
              attrs: { id: "boleto-loading" },
            },
            [
              !Array.isArray(_vm.boletoUrl)
                ? _c("embed", {
                    attrs: {
                      src: _vm.boletoUrl,
                      alt: "Boleto",
                      width: "100%",
                      height: "600",
                      type: "application/pdf",
                      pluginspage:
                        "http://www.adobe.com/products/acrobat/readstep2.html",
                    },
                  })
                : _vm._e(),
              Array.isArray(_vm.boletoUrl)
                ? _c(
                    "div",
                    _vm._l(_vm.boletoUrl, function (bUrl, index) {
                      return _c("embed", {
                        key: index,
                        attrs: {
                          src: bUrl,
                          alt: "Boleto",
                          width: "100%",
                          height: "600",
                          type: "application/pdf",
                          pluginspage:
                            "http://www.adobe.com/products/acrobat/readstep2.html",
                        },
                      })
                    }),
                    0
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "vx-row" },
                [
                  _c("vs-divider"),
                  _c("div", { staticClass: "vx-col w-full mt-0" }, [
                    _c(
                      "div",
                      {
                        staticClass: "mt-2 flex flex-wrap items-center justify",
                      },
                      [
                        !Array.isArray(_vm.remessaUrl)
                          ? _c(
                              "vs-button",
                              {
                                staticClass: "w-full",
                                attrs: { href: _vm.remessaUrl },
                              },
                              [
                                _vm._v(
                                  "!! Clique aqui para baixar o arquivo de remessa !!"
                                ),
                              ]
                            )
                          : _vm._e(),
                        Array.isArray(_vm.remessaUrl)
                          ? _c(
                              "div",
                              _vm._l(_vm.remessaUrl, function (rUrl, index) {
                                return _c(
                                  "vs-button",
                                  {
                                    key: index,
                                    staticClass: "w-full",
                                    attrs: { href: rUrl },
                                  },
                                  [
                                    _vm._v(
                                      "!! Clique aqui para baixar o arquivo " +
                                        _vm._s(index + 1) +
                                        " de remessa !!"
                                    ),
                                  ]
                                )
                              }),
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "noOverflow",
          attrs: {
            fullscreen: false,
            title: _vm.showPdfTitle,
            active: _vm.popupShowPdf,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowPdf = $event
            },
          },
        },
        [
          _c("object", {
            staticStyle: { height: "600px" },
            attrs: { data: _vm.srcPdf, width: "100%" },
          }),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup50",
          attrs: {
            fullscreen: false,
            title: "Impressão de Contrato",
            active: _vm.popupChooseContractModel,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupChooseContractModel = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "FileTextIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Escolha o modelo de contrato desejado"),
              ]),
            ],
            1
          ),
          _c("hr", { staticClass: "mb-4" }),
          _vm.params.data
            ? _c(
                "div",
                { staticClass: "vx-row" },
                [
                  _c("div", { staticClass: "vx-col w-full" }, [
                    _c(
                      "span",
                      {
                        staticClass: "mx-2 cursor-pointer hover:text-primary",
                        on: {
                          click: function ($event) {
                            return _vm.printTemplateContractSIGCFC(
                              _vm.params.data.id
                            )
                          },
                        },
                      },
                      [_vm._v("Contrato Modelo SIGCFC")]
                    ),
                  ]),
                  _vm._l(_vm.contractModels, function (contractModel, i) {
                    return _c("div", { key: i, staticClass: "vx-col w-full" }, [
                      _c(
                        "span",
                        {
                          staticClass: "mx-2 cursor-pointer hover:text-primary",
                          on: {
                            click: function ($event) {
                              return _vm.printContract(
                                _vm.params.data.id,
                                contractModel.id
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(contractModel.title))]
                      ),
                    ])
                  }),
                ],
                2
              )
            : _vm._e(),
          _c(
            "vs-alert",
            {
              staticClass: "mt-4 mb-4",
              staticStyle: { height: "auto" },
              attrs: {
                active: !_vm.contractModels.length,
                color: "warning",
                "icon-pack": "feather",
                icon: "icon-info",
              },
            },
            [
              _c("span", [
                _vm._v(
                  "Nenhum modelo de contrato encontrado. Cadastre um modelo de contrato para poder imprimir aqui."
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "flex justify-between" }, [
        _c(
          "div",
          { staticClass: "flex items-center" },
          [
            _c(
              "vx-tooltip",
              {
                staticClass: "h-5 w-5 mr-2",
                attrs: { text: "Ver", position: "top" },
              },
              [
                _c("feather-icon", {
                  attrs: {
                    icon: "EyeIcon",
                    svgClasses:
                      "h-5 w-5 mr-4 cursor-pointer hover:text-primary",
                  },
                  on: { click: _vm.seeContract },
                }),
              ],
              1
            ),
            _c(
              "vx-tooltip",
              {
                staticClass: "h-5 w-5",
                attrs: { text: "Impressões", position: "top" },
              },
              [
                _c(
                  "vs-dropdown",
                  {
                    staticClass: "cursor-pointer hover:text-primary",
                    attrs: { "vs-trigger-click": "" },
                  },
                  [
                    _c("feather-icon", {
                      attrs: {
                        icon: "PrinterIcon",
                        svgClasses:
                          "h-5 w-5 mr-4 cursor-pointer hover:text-primary",
                      },
                    }),
                    _c(
                      "vs-dropdown-menu",
                      { staticClass: "w-54" },
                      [
                        _c("vs-dropdown-item", [
                          _c(
                            "div",
                            {
                              staticClass: "flex items-center",
                              on: { click: _vm.handlePrintContract },
                            },
                            [
                              _c("feather-icon", {
                                staticClass: "inline-block mr-2",
                                attrs: {
                                  icon: "PrinterIcon",
                                  svgClasses: "w-4 h-4",
                                },
                              }),
                              _c("span", [_vm._v("Contrato")]),
                            ],
                            1
                          ),
                        ]),
                        _c("vs-dropdown-item", [
                          _c(
                            "div",
                            {
                              staticClass: "flex items-center",
                              on: {
                                click: function ($event) {
                                  return _vm.printPromissoria(
                                    _vm.params.data.id
                                  )
                                },
                              },
                            },
                            [
                              _c("feather-icon", {
                                staticClass: "inline-block mr-2",
                                attrs: {
                                  icon: "PrinterIcon",
                                  svgClasses: "w-4 h-4",
                                },
                              }),
                              _c("span", [_vm._v("Promissória")]),
                            ],
                            1
                          ),
                        ]),
                        _c("vs-dropdown-item", [
                          _c(
                            "div",
                            {
                              staticClass: "flex items-center",
                              on: {
                                click: function ($event) {
                                  return _vm.printCarnet(_vm.params.data.id)
                                },
                              },
                            },
                            [
                              _c("feather-icon", {
                                staticClass: "inline-block mr-2",
                                attrs: {
                                  icon: "PrinterIcon",
                                  svgClasses: "w-4 h-4",
                                },
                              }),
                              _c("span", [_vm._v("Carnê")]),
                            ],
                            1
                          ),
                        ]),
                        _vm.params.data &&
                        _vm.params.data.situation_contract === "CANCELED"
                          ? _c("vs-dropdown-item", [
                              _c(
                                "div",
                                {
                                  staticClass: "flex items-center",
                                  on: {
                                    click: function ($event) {
                                      return _vm.printWithdrawal(
                                        _vm.params.data.id
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "inline-block mr-2",
                                    attrs: {
                                      icon: "PrinterIcon",
                                      svgClasses: "w-4 h-4",
                                    },
                                  }),
                                  _c("span", [_vm._v("Desistência")]),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.params.data && _vm.params.data.remessas
              ? _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.generate2viaBoletos(_vm.params.data)
                      },
                    },
                  },
                  [
                    _c(
                      "vx-tooltip",
                      {
                        staticClass: "h-5 w-5 ml-2",
                        attrs: { text: "2ª Via de boletos", position: "top" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass:
                              "h-6 w-6 mr-4 cursor-pointer svg-hover",
                            staticStyle: {
                              "-ms-transform": "rotate(360deg)",
                              "-webkit-transform": "rotate(360deg)",
                              transform: "rotate(360deg)",
                            },
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              "xmlns:xlink": "http://www.w3.org/1999/xlink",
                              "aria-hidden": "true",
                              focusable: "false",
                              preserveAspectRatio: "xMidYMid meet",
                              viewBox: "0 0 24 24",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M2 6h2v12H2V6m3 0h1v12H5V6m2 0h3v12H7V6m4 0h1v12h-1V6m3 0h2v12h-2V6m3 0h3v12h-3V6m4 0h1v12h-1V6z",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.mixinAllow("attendance:student:contract:destroy")
              ? _c(
                  "vx-tooltip",
                  {
                    staticClass: "h-5 w-5 mr-2",
                    attrs: { text: "Excluir", position: "top" },
                  },
                  [
                    _c("feather-icon", {
                      attrs: {
                        icon: "Trash2Icon",
                        svgClasses:
                          "h-5 w-5 ml-4 cursor-pointer hover:text-primary",
                      },
                      on: { click: _vm.askToDelete },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }