<template>
  <div id="user-edit-tab-contract">
    <cancel-contract-form-popup :contract-id="contractId" :active.sync="popupCancelContract" @saved="refreshData" />
    <vs-popup class="popup90" :fullscreen="false" title="Boleto(s)" :active.sync="popupShowBoleto">
      <div id="boleto-loading" class="vs-con-loading__container">
        <embed v-if="!Array.isArray(boletoUrl)" :src="boletoUrl" alt="Boleto" width="100%" height="600" type="application/pdf" pluginspage="http://www.adobe.com/products/acrobat/readstep2.html">
        <div v-if="Array.isArray(boletoUrl)">
          <embed v-for="(bUrl, index) in boletoUrl" :src="bUrl" :key="index" alt="Boleto" width="100%" height="600" type="application/pdf" pluginspage="http://www.adobe.com/products/acrobat/readstep2.html">
        </div>

        <div class="vx-row">
          <vs-divider></vs-divider>
          <div class="vx-col w-full mt-0">
            <div class="mt-2 flex flex-wrap items-center justify">
              <vs-button v-if="!Array.isArray(remessaUrl)" :href="remessaUrl" class="w-full">!! Clique aqui para baixar o arquivo de remessa !!</vs-button>
              <div v-if="Array.isArray(remessaUrl)">
                <vs-button v-for="(rUrl, index) in remessaUrl" :href="rUrl" :key="index" class="w-full">!! Clique aqui para baixar o arquivo {{ index+1 }} de remessa !!</vs-button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </vs-popup>

    <!-- CONTRATO -->
    <vs-popup class="popup90" :fullscreen="false" :title="contractPopupTitle" :active.sync="popupContract">

      <dialog-sig style="z-index: 9999999999;" :show="popupServiceQty" title="QUANTIDADE" icon="warning"
        @action="changeServiceQty"
        @cancel="closeChangeServiceQty"
        actionButtonText="Continuar" actionButtonColor="warning">
          <div class="vx-row">
            <div class="vx-col w-full mt-0">
              <vs-input type="number" min="1" class="w-full" label="Quantidade" v-model="serviceQty" />
            </div>
          </div>
      </dialog-sig>

      <permission-password style="z-index: 9999999999;" :show="popupPermissionPassword" title="ALERTA DE BAIXA RETROATIVA" icon="warning"
        actionButtonText="Prosseguir"
        @action="storeContract(true)"
        @cancel="popupPermissionPassword = false">
      </permission-password>

      <div class="vx-row">
        <!-- Pacotes agora chamamos de serviços e serviços do pacote são ítens -->
        <div class="vx-col md:w-2/5 w-full mt-0">
          <label class="vs-input--label">Serviços</label>
          <v-select v-model="contract.servicePackages" @input="changeContractSubTotal" @option:deselected="serviceDeselected" @option:selected="newServiceSelected"
            data-vv-as="Serviços" data-vv-scope="contract" v-validate.initial="'required'" name="services"
            :options="servicePackages" multiple placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('contract.services')">{{ errors.first('contract.services') }}</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-0">
          <vs-input class="w-full" label="Valor" ref="subTotal" v-model="contract.sub_total" v-currency="currencyConfig" />
        </div>
        <div class="vx-col md:w-1/5 w-full mt-0">
          <label class="vs-input--label">Desconto {{ contract.discount_type }}</label>
          <vx-input-group prependClasses="border border-solid border-grey border-r-0">
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button @click="toggleDiscountType">{{ contract.discount_type }}</vs-button>
              </div>
            </template>
            <vs-input key="1__" v-if="contract.discount_type === '%'" class="w-full" type="number" v-model="contract.discount" />
            <vs-input key="2___" v-else class="w-full" ref="discount" v-model="contract.discount" v-currency="currencyConfig" />
          </vx-input-group>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-0">
          <label class="vs-input--label">Total</label>
          <h1 class="flex flex-wrap justify-center">
            <sup class="text-lg">R$</sup>
            <span>{{contract.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')}}</span>
          </h1>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Nº de Parcelas</label>
          <vs-input-number min="1" class="mt-2" v-model="contract.parcelsNumber" @input="changeParcelsNumber"
            data-vv-as="Parcelas" data-vv-scope="contract" v-validate="'required|min_value:1'" name="parcelsNumber" />
          <span class="text-danger text-sm" v-show="errors.has('contract.parcelsNumber')">{{ errors.first('contract.parcelsNumber') }}</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <vs-input class="w-full mt-0" label="Valor 1ª Parcela" ref="firstParcelValue"
            :disabled="contract.parcelsNumber < 2"
            v-model="contract.firstParcelValue" v-currency="currencyConfig" />
          <span class="text-danger text-sm" v-if="errorfirstParcelValue">Este valor é maior que o total.</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <vs-input type="date" class="w-full" label="1º Vencimento" v-model="contract.expirationDateFirstParcel"
            data-vv-as="1º Vencimento" data-vv-scope="contract" v-validate.initial="'required'" name="expirationDateFirstParcel" />
          <span class="text-danger text-sm" v-show="errors.has('contract.expirationDateFirstParcel')">Este campo é obrigatório.</span>
          <span class="text-danger text-sm" v-if="!isSameOrAfterToday(contract.expirationDateFirstParcel)">A primeira parcela já está vencida.</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Periodicidade</label>
          <v-select v-model="contract.frequency" :reduce="option => option.value" :clearable="false" :options="[{label: 'Mensal', value: 'monthly'}, {label: 'Quinzenal', value: 'biweekly'}, {label: 'Semanal', value: 'weekly'}]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"></v-select>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Aplicar Juros</label>
          <div class="flex flex-wrap items-center justify-center">
          <vs-switch class="w-1/2 mt-2" icon-pack="feather" vs-icon-on="icon-check-circle" vs-icon-off="icon-slash" v-model="contract.applyInterest">
            <span slot="on">SIM</span> <span slot="off">NÃO</span>
          </vs-switch>
          </div>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Tipo de Pagamento</label>
          <v-select class="vue_select_drop_size_180" @input="filterAccounts" v-model="contract.type_payment" :reduce="option => option.value" :clearable="false"
            data-vv-as="Tipo de Pagamento" data-vv-scope="contract" v-validate.initial="'required'" name="type_payment"
            :options="typePayments" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('contract.type_payment')">Este campo é obrigatório.</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Conta</label>
          <v-select class="vue_select_drop_size_180" :disabled="!contract.type_payment" v-model="contract.account" :reduce="option => option.value" :clearable="false"
            data-vv-as="Conta" data-vv-scope="contract" v-validate.initial="'required'" name="account"
            :options="accountsFilteredOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('contract.account')">Este campo é obrigatório.</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Plano de contas</label>
          <v-select class="vue_select_drop_size_180" v-model="contract.account_plan" :reduce="option => option.value" :options="accountPlans" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Vendedor</label>
          <v-select class="vue_select_drop_size_180" v-model="contract.employee_id" :reduce="option => option.value" :options="employees"
            @input="changeSeller" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>

        <div class="vx-col md:w-1/5 w-full mt-0">
          <label class="vs-input--label">Comissão {{ contract.commission_type }}</label>
          <vx-input-group prependClasses="border border-solid border-grey border-r-0">
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button @click="toggleCommissionType">{{ contract.commission_type }}</vs-button>
              </div>
            </template>
            <vs-input key="1__" v-if="contract.commission_type === '%'" class="w-full" type="number" v-model="contract.commission" />
            <vs-input key="2___" v-else class="w-full" ref="commission" v-model="contract.commission" v-currency="currencyConfig" />
          </vx-input-group>
        </div>

      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Categoria Atual</label>
          <v-select class="vue_select_drop_size_115" v-model="contract.current_category_cnh" :reduce="option => option.value" :options="cnhOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Categoria Pretendida</label>
          <v-select class="vue_select_drop_size_115" v-model="contract.intended_category_cnh" :reduce="option => option.value" :options="cnhOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">EAR</label>
          <div class="flex flex-wrap items-center justify-center">
            <vs-switch class="w-1/2 mt-2" icon-pack="feather" vs-icon-on="icon-check-circle" vs-icon-off="icon-slash" v-model="contract.gainful_activity">
              <span slot="on">SIM</span> <span slot="off">NÃO</span>
            </vs-switch>
          </div>
        </div>
        <div class="vx-col md:w-2/5 w-full mt-2">
          <label class="vs-input--label">Cursos</label>
          <v-select class="vue_select_drop_size_115" v-model="contract.specialized_courses" :reduce="option => option.value" :options="specializedCoursesCNHOptions" multiple placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-4/5 w-full mt-2">
          <vs-input class="w-full" label="Observações" v-model="contract.observation" />
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <div class="mt-5 flex flex-wrap items-center justify-end">
            <vs-button @click="generateContractParcels" :disabled="disableGenerateParcels" class="w-full" type="border" color="warning" icon-pack="feather" icon="icon-dollar-sign">Gerar Parcelas</vs-button>
          </div>
        </div>
      </div>

      <vs-divider position="left">
          <feather-icon icon="DollarSignIcon" class="mr-0" svgClasses="w-4 h-4" /><span class="mb-4">Parcelas</span>
      </vs-divider>

      <!-- PARCELAS -->
      <vs-table v-if="contract.parcels.length" :data="contract.parcels" hoverFlat style="overflow: -webkit-paged-y">
        <template slot="thead">
          <vs-th>#</vs-th>
          <vs-th>Valor</vs-th>
          <vs-th>Vencimento</vs-th>
          <vs-th>Forma Pagamento</vs-th>
          <vs-th>Conta</vs-th>
          <vs-th>Situação</vs-th>
          <vs-th>Pago Em</vs-th>
          <!-- <vs-th>Plano de Contas</vs-th> -->
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="i" v-for="(parcel, i) in data" style="opacity: 1 !important">
            <vs-td> {{ i + 1 }} </vs-td>
            <vs-td>
                <currency-input class="w-32 vs-inputx vs-input--input normal hasValue"
                  @input="changeValueParcel(parcel, i)"
                  v-model="parcel.value_parcel"
                  :currency="{prefix: 'R$ '}"
                  :valueAsInteger="false"
                  :distractionFree="false"
                  :precision="2"
                  :autoDecimalMode="true"
                  :valueRange="{ min: 0 }"
                  :allowNegative="false" />
            </vs-td>
            <vs-td>
              <vs-input type="date" class="w-48" v-model="parcel.expiration_date"
                data-vv-as="Vencimento" data-vv-scope="contract" v-validate.initial="{ required: true }" :name="'expiration_date'+i" />
              <span class="text-danger text-sm" v-show="errors.has('contract.expiration_date'+i)">Campo obrigatório</span>
            </vs-td>
            <vs-td>
              <v-select class="vue_select_drop_size_100 w-48" :clearable="false" @input="filterAccountsParcels(i)" v-model="parcel.type_payment" :reduce="option => option.value" :options="typePayments" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </vs-td>
            <vs-td>
              <v-select class="vue_select_drop_size_100 w-48" :clearable="false" :disabled="!parcel.type_payment" v-model="parcel.account"
                :reduce="option => option.value" :options="parcel.accountsFilteredOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                data-vv-as="Conta" data-vv-scope="contract" v-validate.initial="{ required: !parcel.account }" :name="'account'+i">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
              <span class="text-danger text-sm" v-show="errors.has('contract.account'+i)">Campo obrigatório</span>
            </vs-td>
            <vs-td>
              <div class="flex flex-wrap items-center justify-center">
                <vs-switch class="w-28 mt-2" icon-pack="feather" vs-icon-on="icon-check-circle" vs-icon-off="icon-slash"
                  @input="parcel.situation ? parcel.value_pay = parcel.value_parcel : parcel.value_pay = 0;
                  parcel.situation ? parcel.payday = today : parcel.payday = ''
                  parcel.situation ? '' : parcel.futureDate = false;
                  checkPayday(parcel)" v-model="parcel.situation">
                  <span slot="off">ABERTA</span> <span slot="on">QUITADA</span>
                </vs-switch>
              </div>
            </vs-td>
            <vs-td>
              <vs-input type="date" class="w-48" :disabled="!parcel.situation" v-model="parcel.payday" @change="checkPayday(parcel)"
              data-vv-as="Pago em" data-vv-scope="contract" v-validate.initial="{ required: parcel.situation }" :name="'payday'+i" />
              <span class="text-danger text-sm" v-show="errors.has('contract.payday'+i)">Campo obrigatório</span>
              <span class="text-danger text-sm" v-show="parcel.futureDate">Data futura não permitida</span>
            </vs-td>
            <!-- <vs-td>
              <v-select class="vue_select_drop_size_100 w-48" :clearable="false" v-model="parcel.account_plan" :reduce="option => option.value" :options="accountPlans" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </vs-td> -->
          </vs-tr>

          <vs-tr style="opacity: 1 !important">
            <vs-td colspan="8">
              <vs-divider></vs-divider>
              <div class="vx-col w-full">
                <div class="mt-2 flex flex-wrap items-center">
                  <vs-button class="mr-4" color="secondary" @click="popupContract = false">Cancelar</vs-button>
                  <vs-button class="mr-auto" @click="storeContract()" :disabled="!validateContract || disableGenerateParcels || disableSave">Salvar Contrato</vs-button>
                </div>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-alert v-if="!contract.parcels.length" class="mt-4 mb-4" :active="true" color="warning" icon-pack="feather" icon="icon-info" style="height: auto">
        <span>Por favor preencha o formulário e gere as parcelas para poder cadastrar o contrato!</span>
      </vs-alert>
    </vs-popup>

    <!-- CANCELAR CONTRATO - REMOVER POIS NAO UTILIZA MAIS -->
    <vs-popup class="popup50" :fullscreen="false" title="Cancelar contrato">

      <div class="vx-row">
        <div class="vx-col md:w-full w-full mt-0">
          <label class="vs-input--label">Ressarcimento</label>
          <v-select v-model="contractRefund" :reduce="option => option.value" :clearable="false"
            :options="[
              {label: 'Sem ressarcimento', value: 'no_refund'},
              {label: 'Devolver valor ao aluno', value: 'refund_student'},
              {label: 'Receber valor do aluno', value: 'refund_company'}
            ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"></v-select>
        </div>
      </div>

      <div class="vx-row" v-if="contractRefund !== 'no_refund'">
        <div class="vx-col md:w-1/2 w-full mt-0">
          <label class="vs-input--label">Banco</label>
          <v-select class="vue_select_drop_size_150"
            v-model="contract.return_bank_id" :reduce="option => option.value"
            :options="banks" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <vs-input class="w-full mt-0" label="Agência" v-model="contract.return_agency" />
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <vs-input class="w-full mt-0" label="Conta" v-model="contract.return_account" />
        </div>
      </div>

      <div class="vx-row" v-if="contractRefund === 'refund_company'">
        <div class="vx-col md:w-1/2 w-full mt-0">
          <label class="vs-input--label">Valor</label>
          <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
            key="contractRefund1"
            v-model="contract.amount_receivable"
            :currency="{prefix: 'R$ '}"
            :valueAsInteger="false"
            :distractionFree="false"
            :precision="2"
            :autoDecimalMode="true"
            :valueRange="{ min: 0 }"
            :allowNegative="false" />
        </div>
        <div class="vx-col md:w-1/2 w-full mt-0">
          <vs-input type="date" class="w-full mt-0" label="Vencimento" v-model="contract.date_receivable" />
        </div>
      </div>
      <div class="vx-row" v-if="contractRefund === 'refund_student'">
        <div class="vx-col md:w-1/2 w-full mt-0">
          <label class="vs-input--label">Valor</label>
          <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
            key="contractRefund1"
            v-model="contract.amount_to_pay"
            :currency="{prefix: 'R$ '}"
            :valueAsInteger="false"
            :distractionFree="false"
            :precision="2"
            :autoDecimalMode="true"
            :valueRange="{ min: 0 }"
            :allowNegative="false" />
        </div>
        <div class="vx-col md:w-1/2 w-full mt-0">
          <vs-input type="date" class="w-full mt-0" label="Vencimento" v-model="contract.date_amount_to_pay" />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-full w-full mt-0">
          <label class="vs-input--label">Motivo do cancelamento</label>
          <vs-textarea class="mb-0" v-model="contract.reason_cancellation"
            data-vv-as="Motivo do cancelamento" data-vv-scope="contractCancel" v-validate.initial="'required'" name="reason_cancellation"/>
          <span class="text-danger text-sm" v-show="errors.has('contractCancel.reason_cancellation')">{{ errors.first('contractCancel.reason_cancellation') }}</span>
        </div>
      </div>

      <vs-alert class="mt-4 mb-4" active color="danger" icon-pack="feather" icon="icon-info" style="height: auto">
        <span>
          ATENÇÃO. O cancelamento de um contrato é irreversível.
          As parcelas pagas serão mantidas e as parcelas em aberto serão excluídas.
          Outros ajustes devem ser feitos manualmente direto no financeiro.
        </span>
      </vs-alert>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col md:w-full w-full">
          <div class="mt-2 flex flex-wrap items-center">
            <vs-button class="ml-auto" color="secondary" @click="popupCancelContract = false">Fechar</vs-button>
            <vs-button :disabled="!contract.reason_cancellation" class="ml-2 mr-0" color="danger" @click="cancelContract">Cancelar Contrato</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>


    <!-- ALTERAR SITUAÇÃO DO CONTRATO -->
    <vs-popup class="popup50" :fullscreen="false" title="Alterar situação do contrato" :active.sync="popupChangeContractSituation">
      <vs-alert class="mt-4 mb-4" :active="constractToChangeSituation.situation_contract == 'CANCELED'" color="warning" icon-pack="feather" icon="icon-info" style="height: auto">
        <span>Este contrato está cancelado e por tanto não pode ter sua situação alterada.</span>
      </vs-alert>

      <div class="vx-row">
        <div class="vx-col w-1/4">
          <div class="mt-2 flex flex-wrap items-center">
            <vs-button class="mr-auto" color="secondary" @click="popupChangeContractSituation = false">Fechar</vs-button>
          </div>
        </div>
        <div class="vx-col w-3/4">
          <div class="mt-2 flex flex-wrap items-center">
            <vs-button v-if="constractToChangeSituation.situation_contract === 'FINISHED'" class="ml-auto" @click="changeContractSituation('IN_PROGRESS')">Reabrir Contrato</vs-button>
            <vs-button v-if="constractToChangeSituation.situation_contract === 'IN_PROGRESS'" class="ml-auto" @click="changeContractSituation('FINISHED')">Finalizar Contrato</vs-button>
            <vs-button v-if="constractToChangeSituation.situation_contract !== 'CANCELED'" class="ml-2 mr-0" color="danger" @click="handleCancelContract">Cancelar Contrato</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>

    <!-- MODELO DE CONTRATO -->
    <!-- <vs-popup :fullscreen="true" :title="newContractPopupTitleModel" :active.sync="popupContractModel">
      <div class="vx-row">
        <div class="vx-col md:w-3/4 w-full mt-0">
          <vs-input class="w-full mt-0" label="Título" v-model="contractModel.title" data-vv-as="Título" data-vv-scope="contractModel" v-validate.initial="'required'" name="title" />
          <span class="text-danger text-sm" v-show="errors.has('contractModel.title')">{{ errors.first('contractModel.title') }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Cabeçalho</label>
          <v-select v-model="contractModel.print_header" :reduce="option => option.value" :clearable="false" :options="[{label: 'SIM', value: true}, {label: 'NÃO', value: false}]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"></v-select>
        </div>
      </div>

      <div class="vx-row mt-4">
        <div class="vx-col md:w-3/4 w-full mt-0">
          <div>
            <ckeditor :editor="editor" v-model="contractModel.body" :config="editorConfig" @ready="onReady"></ckeditor>
            <div class="vx-row">
              <div class="vx-col w-full">
                <div class="mt-0 flex flex-wrap items-center justify-end">
                  <vs-button class="ml-auto mt-4" color="secondary" @click="popupContractModel = false">Cancelar</vs-button>
                  <vs-button class="ml-4 mt-4" @click="addContractModel" :disabled="!validateContractFormModel || disableContractModelSave">Salvar Modelo De Contrato</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <vs-divider position="left"><strong class="text-sm">Variáveis do Aluno</strong></vs-divider>
          <p v-for="(variable, i) in studentVariables" :key="`${i}_studentVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
              <vx-tooltip :text="variable.variable" position="left">
              <span class="under mb-1">{{variable.label}}</span>
              </vx-tooltip>
          </p>
          <vs-divider position="left"><strong class="text-sm">Endereço</strong></vs-divider>
          <p v-for="(variable, i) in addressVariables" :key="`${i}_addressVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
              <vx-tooltip :text="variable.variable" position="left">
              <span class="under mb-1">{{variable.label}}</span>
              </vx-tooltip>
          </p>
          <vs-divider position="left"><strong class="text-sm">Avalista</strong></vs-divider>
          <p v-for="(variable, i) in tenantVariables" :key="`${i}_tenantVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
              <vx-tooltip :text="variable.variable" position="left">
              <span class="under mb-1">{{variable.label}}</span>
              </vx-tooltip>
          </p>
          <vs-divider position="left"><strong class="text-sm">Contrato</strong></vs-divider>
          <p v-for="(variable, i) in contractVariables" :key="`${i}_contractVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
            {{variable.label}}
          </p>
        </div>
      </div>
    </vs-popup> -->

    <!-- AVALISTA -->
    <vs-popup class="popup80" :fullscreen="false" title="Salvar Avalista" :active.sync="popupShowGuarantor">

      <dialog-sig style="z-index: 999999999999999;" :show="popupGuarantorDeleteConfirm" title="ALERTA" @action="dispatchDeleteGuarantor"
        @cancel="popupGuarantorDeleteConfirm = false">
          Tem certeza que deseja excluir o avalista? <br>
          Esta ação é irreversível
      </dialog-sig>

      <div class="vx-row">
        <div class="vx-col md:w-4/5 w-full mt-0">
          <vs-input class="w-full mt-0" label="Nome Avalista" v-model="data_local.guarantor.name_guarantor" data-vv-as="Nome Avalista" data-vv-scope="guarantor" v-validate.initial="'required|alpha_spaces'" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('guarantor.name')">{{ errors.first('guarantor.name') }}</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-0">
          <label class="vs-input--label">Tipo de Pessoa</label>
          <v-select v-model="data_local.guarantor.type" :reduce="option => option.value" :clearable="false" :options="typeOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full mt-2">
          <vs-input class="w-full" label="CPF/CNPJ" @keyup="searchGuarantor" v-model="data_local.guarantor.cpf_guarantor" v-mask="['###.###.###-##','##.###.###/####-##']" data-vv-as="CPF" data-vv-scope="guarantor" v-validate="'cpf'" name="cpf" />
          <span class="text-danger text-sm" v-show="errors.has('guarantor.cpf')">O CPF/CNPJ não é válido</span>
        </div>

        <div class="vx-col md:w-1/5 w-full mt-2">
          <vs-input class="w-full" label="RG/IE" v-model="data_local.guarantor.rg_guarantor" />
        </div>

        <div class="vx-col md:w-1/5 w-full mt-2">
          <vs-input class="w-full" label="Órgão Emissor RG" v-model="data_local.guarantor.orgao_emissor_rg_guarantor" />
        </div>

        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Estado RG</label>
          <v-select :reduce="option => option.value" v-model="data_local.guarantor.orgao_emissor_rg_uf_id_guarantor" :clearable="true" :options="states" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <vs-input type="date" class="w-full" label="Data de Nascimento" v-model="data_local.guarantor.date_of_birth_guarantor" />
        </div>

      </div>

      <div class="flex items-end mt-2">
        <feather-icon icon="MapPinIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Endereço</span>
      </div>
      <hr>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full mt-2">
          <vs-input class="w-full" label="CEP" placeholder="CEP" v-model="data_local.guarantor.address.zip_code" v-mask="['#####-###']" @keyup="searchZipCode" />
        </div>

        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Logradouro</label>
          <v-select v-model="data_local.guarantor.address.logradouro" :reduce="option => option.value" clearable :options="logradouroOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>

        <div class="vx-col md:w-2/5 w-full mt-2">
          <vs-input class="w-full" label="Endereço" v-model="data_local.guarantor.address.street" />
        </div>

        <div class="vx-col md:w-1/5 w-full mt-2">
          <vs-input class="w-full" label="Número" v-model="data_local.guarantor.address.number" type="number" placeholder="S/N" />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full">
          <vs-input class="w-full mt-2" label="Complemento" v-model="data_local.guarantor.address.complement" />
        </div>
        <div class="vx-col md:w-1/5 w-full">
          <vs-input class="w-full mt-2" label="Bairro" v-model="data_local.guarantor.address.neighborhood" />
        </div>
        <div class="vx-col md:w-1/4_ w-full mt-2">
          <label class="vs-input--label">Cidade</label>
          <v-select class="vue_select_drop_size_240" :reduce="option => option.value" v-model="data_local.guarantor.address.city_id"
            :placeholder="cityGuarantorOptions.length ? 'Selecione' : 'Selecione um estado'" :options="cityGuarantorOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-2">
          <label class="vs-input--label">Estado</label>
          <v-select class="vue_select_drop_size_240" :reduce="option => option.value"
            v-model="data_local.guarantor.address.state_id"
            @input="fillCity(data_local.guarantor.address.state_id, null, 'cityGuarantorOptions', 'guarantor'), data_local.guarantor.address.city_id = null"
            :options="states" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-2">
          <label class="vs-input--label">País</label>
          <v-select v-model="data_local.guarantor.address.country" :reduce="option => option.value" :clearable="false" :options="countryOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <div class="flex items-end mt-2">
        <feather-icon icon="PhoneIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Contato</span>
      </div>
      <hr>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full" v-for="(phone,i) in data_local.guarantor.phones" :key="i">
          <vs-input class="w-full mt-2" v-mask="['(##)#####-####','(##)####-####']" :label="phone.type === 'phone' ? 'Telefone' : phone.type === 'mobile' ? 'Celular' : phone.type === 'other' ? 'Outro' : ''" v-model="phone.phone" />
        </div>
        <div class="vx-col md:w-2/5 w-full">
          <vs-input class="w-full mt-2" label="E-mail" data-vv-as="E-mail" data-vv-scope="guarantor" v-model="data_local.guarantor.email" type="email" v-validate="'email'" name="email" />
          <span class="text-danger text-sm" v-show="errors.has('guarantor.email')">{{ errors.first('guarantor.email') }}</span>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full">
          <vs-input class="w-full mt-2" label="Local de Trabalho" v-model="data_local.guarantor.workplace_guarantor" />
        </div>
        <div class="vx-col md:w-1/5 w-full">
          <vs-input class="w-full mt-2" label="Cônjuge" v-model="data_local.guarantor.spouse_name_guarantor" />
        </div>
        <div class="vx-col md:w-3/5 w-full">
          <vs-input class="w-full mt-2" label="Observações" v-model="data_local.guarantor.observation_guarantor" />
        </div>
      </div>

      <vs-divider></vs-divider>

      <!-- Save & Reset Button -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-0 flex flex-wrap items-center justify-end">
            <vs-button v-if="data_local.guarantor.id" class="ml-auto mt-2" color="danger" @click="deleteGuarantor">Deletar Avalista</vs-button>
            <vs-button class="ml-4 mt-2" @click="saveGuarantor" :disabled="!validateGuarantorForm || disableGuarantorSave">Salvar Avalista</vs-button>
          </div>
        </div>
      </div>

    </vs-popup>

    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <div class="mt-2 mb-4 flex flex-wrap items-center">
          <vs-button class="w-1/2" @click="newContract">Novo Contrato</vs-button>
        </div>
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <div class="mt-2 mb-4 flex flex-wrap items-center justify-end">
          <vs-button class="w-1/2" @click="newContractModel">Novo Modelo de Contrato</vs-button>
        </div>
      </div>
    </div>


    <div v-if="accordionDocumentTemplate" class="vx-row mt-0">
      <div class="vx-col md:w-full w-full mt-0">
        <div class="vx-row">
          <div class="vx-col md:w-3/4 w-full mt-0">
            <vs-input class="w-full mt-0" label="Título" v-model="contractModel.title" data-vv-as="Título" data-vv-scope="contractModel" v-validate.initial="'required'" name="title" />
            <span class="text-danger text-sm" v-show="errors.has('contractModel.title')">{{ errors.first('contractModel.title') }}</span>
          </div>
          <div class="vx-col md:w-1/4 w-full mt-0">
            <label class="vs-input--label">Cabeçalho</label>
            <v-select v-model="contractModel.print_header" :reduce="option => option.value" :clearable="false" :options="[{label: 'SIM', value: true}, {label: 'NÃO', value: false}]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"></v-select>
          </div>
        </div>

        <div class="vx-row mt-4">
          <div class="vx-col md:w-3/4 w-full mt-0">
            <div>
              <ckeditor :editor="editor" v-model="contractModel.body" :config="editorConfig" @ready="onReady"></ckeditor>
              <div class="vx-row">
                <div class="vx-col w-full">
                  <div class="mt-0 flex flex-wrap items-center justify-end">
                    <vs-button class="ml-auto mt-4" color="secondary" @click="accordionDocumentTemplate = false">Cancelar</vs-button>
                    <vs-button class="ml-4 mt-4" @click="addContractModel" :disabled="!validateContractFormModel || disableContractModelSave">Salvar Modelo De Contrato</vs-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mt-0">
            <vs-divider position="left"><strong class="text-sm">Variáveis do Aluno</strong></vs-divider>
            <p v-for="(variable, i) in studentVariables" :key="`${i}_studentVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
                <vx-tooltip :text="variable.variable" position="left">
                <span class="under mb-1">{{variable.label}}</span>
                </vx-tooltip>
            </p>
            <vs-divider position="left"><strong class="text-sm">Endereço</strong></vs-divider>
            <p v-for="(variable, i) in addressVariables" :key="`${i}_addressVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
                <vx-tooltip :text="variable.variable" position="left">
                <span class="under mb-1">{{variable.label}}</span>
                </vx-tooltip>
            </p>
            <vs-divider position="left"><strong class="text-sm">Avalista</strong></vs-divider>
            <p v-for="(variable, i) in tenantVariables" :key="`${i}_tenantVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
                <vx-tooltip :text="variable.variable" position="left">
                <span class="under mb-1">{{variable.label}}</span>
                </vx-tooltip>
            </p>
            <vs-divider position="left"><strong class="text-sm">Contrato</strong></vs-divider>
            <p v-for="(variable, i) in contractVariables" :key="`${i}_contractVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
              {{variable.label}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex items-end mt-2">
      <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
      <span class="leading-none font-medium">Avalista</span>
    </div>
    <hr>

    <div class="vx-row flex flex-wrap items-center">
      <div class="vx-col md:w-1/4 w-full mt-0">
        <span>{{this.$store.state.studentManagement.student.guarantor ? this.$store.state.studentManagement.student.guarantor.name_guarantor : ''}}</span>
      </div>
      <div class="vx-col md:w-1/4 w-full mt-0">
        <span>{{this.$store.state.studentManagement.student.guarantor && this.$store.state.studentManagement.student.guarantor.phones && this.$store.state.studentManagement.student.guarantor.phones.find(phone => phone.type === 'mobile') ? this.$store.state.studentManagement.student.guarantor.phones.find(phone => phone.type === 'mobile').phone : ''}}</span>
      </div>
      <div class="vx-col md:w-1/2 w-full mt-0">
        <vs-button @click="handleGuarantor" type="line" :icon="data_local.guarantor.id ? 'create' : 'add_circle_outline'" class="ml-auto sizedIcon">{{data_local.guarantor.id ? 'Alterar' : 'Cadastrar'}} Avalista</vs-button>
      </div>
    </div>

    <div class="flex items-end mt-2">
      <feather-icon icon="FileTextIcon" class="mr-2" svgClasses="w-5 h-5" />
      <span class="leading-none font-medium">Contratos</span>
    </div>
    <hr class="mb-8">

    <div class="flex flex-wrap items-center">

      <!-- ITEMS PER PAGE -->
      <div class="flex-grow">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ contractStudentsData.total - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : contractStudentsData.total }} de {{ contractStudentsData.total }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="paginationSetPageSize(10)">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="paginationSetPageSize(15)">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="paginationSetPageSize(20)">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <!-- <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Busca rápida por nome..." /> -->
        <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->

        <!-- ACTION - DROPDOWN -->
        <!-- <vs-dropdown vs-trigger-click class="cursor-pointer">

          <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
            <span class="mr-2 leading-none">Ações</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>

          <vs-dropdown-menu>

            <vs-dropdown-item>
              <span class="flex items-center">
                <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                <span>Deletar</span>
              </span>
            </vs-dropdown-item>

            <vs-dropdown-item>
              <span class="flex items-center">
                <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                <span>Arquivo</span>
              </span>
            </vs-dropdown-item>

            <vs-dropdown-item>
              <span class="flex items-center">
                <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                <span>Imprimir</span>
              </span>
            </vs-dropdown-item>

            <vs-dropdown-item>
              <span class="flex items-center">
                <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                <span>CSV</span>
              </span>
            </vs-dropdown-item>

          </vs-dropdown-menu>
        </vs-dropdown> -->
    </div>

    <!-- AgGrid Table -->
    <ag-grid-vue
      ref="agGridTableContract"
      :components="components"
      :gridOptions="gridOptions"
      @grid-ready="onGridReady"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :overlayLoadingTemplate="overlayLoadingTemplate"
      :overlayNoRowsTemplate="noRowsTemplate"
      :localeText="localeText"
      rowSelection="multiple"
      colResizeDefault="shift"
      :animateRows="true"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      :cacheBlockSize="40"
      :suppressPaginationPanel="true"
      :enableRtl="$vs.rtl">
    </ag-grid-vue>

    <vs-pagination :total="totalPages" :max="10" v-model="currentPage" />

  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CancelContractFormPopup from '@/components/attendance/contract/cancel-contract/CancelContractFormPopup'

import _ from 'lodash'
import { localePt_Br } from '../../../../assets/agGridTranslate/locale.pt_Br'
import axios from '@/axios.js'
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
const dialogSig = () => import(/* webpackChunkName: "dialogSig" */ '@/components/dialog/dialogSig.vue')
const permissionPassword = () => import(/* webpackChunkName: "permissionPassword" */ '@/components/permission-password/permissionPassword.vue')

import CKEditor from '@ckeditor/ckeditor5-vue2'

// ⚠️ NOTE: We don't use @ckeditor/ckeditor5-build-classic any more!
// Since we're building CKEditor from source, we use the source version of ClassicEditor.
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import ClassicEditor from '@/util/ckeditor5/src/ckeditor'
import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br'

import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat.js'
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote.js'
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js'
import Code from '@ckeditor/ckeditor5-basic-styles/src/code.js'
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock.js'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js'
import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport.js'
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js'
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed.js'
import Image from '@ckeditor/ckeditor5-image/src/image.js'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption.js'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle.js'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar.js'
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload.js'
import Indent from '@ckeditor/ckeditor5-indent/src/indent.js'
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js'
import Link from '@ckeditor/ckeditor5-link/src/link.js'
import List from '@ckeditor/ckeditor5-list/src/list.js'
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed.js'
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js'
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice.js'
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting.js'
import Table from '@ckeditor/ckeditor5-table/src/table.js'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar.js'
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation.js'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment.js'
import AutoImage from '@ckeditor/ckeditor5-image/src/autoimage.js'
import CKFinderUploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter.js'
import CloudServices from '@ckeditor/ckeditor5-cloud-services/src/cloudservices.js'
import FindAndReplace from '@ckeditor/ckeditor5-find-and-replace/src/findandreplace.js'
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js'
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor.js'
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily.js'
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize.js'
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight.js'
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize.js'
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock.js'
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat.js'
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters.js'
import SpecialCharactersArrows from '@ckeditor/ckeditor5-special-characters/src/specialcharactersarrows.js'
import SpecialCharactersCurrency from '@ckeditor/ckeditor5-special-characters/src/specialcharacterscurrency.js'
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials.js'
import SpecialCharactersLatin from '@ckeditor/ckeditor5-special-characters/src/specialcharacterslatin.js'
import SpecialCharactersMathematical from '@ckeditor/ckeditor5-special-characters/src/specialcharactersmathematical.js'
import SpecialCharactersText from '@ckeditor/ckeditor5-special-characters/src/specialcharacterstext.js'
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption.js'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline.js'
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount.js'

import { setValue, getValue, CurrencyDirective, CurrencyInput } from 'vue-currency-input'
import moment from 'moment'

// Cell Renderer
import CellRendererDateTime from './contract-cell-renderer/CellRendererDateTime.vue'
import CellRendererServices from './contract-cell-renderer/CellRendererServices.vue'
import CellRendererActions from './contract-cell-renderer/CellRendererActions.vue'
import CellRendererSituation from './contract-cell-renderer/CellRendererSituation.vue'

//STORE
import moduleStudentManagement from '@/store/student-management/moduleStudentManagement.js'
import moduleContractStudent from '@/store/contract-student/moduleContractStudent.js'
import moduleGuarantor from '@/store/guarantor/moduleGuarantor.js'
import moduleContractModel from '@/store/contract-model/moduleContractModel.js'
import moduleTypePayment from '@/store/type-payment/moduleTypePayment.js'
import moduleAccount from '@/store/account/moduleAccount.js'
import moduleAccountPlan from '@/store/account-plan/moduleAccountPlan.js'
import moduleEmployee from '@/store/employee/moduleEmployee.js'
import moduleBank from '@/store/bank/moduleBank.js'

import PaginationMixin from '@/mixins/pagination'

//AJUSTE DE VENCIMENTOS 30 DIAS EM MESES COM MENOS DE 31 DIAS
Date.isLeapYear = function (year) {
  return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0))
}
Date.getDaysInMonth = function (year, month) {
  return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month]
}
Date.prototype.isLeapYear = function () {
  return Date.isLeapYear(this.getFullYear())
}
Date.prototype.getDaysInMonth = function () {
  return Date.getDaysInMonth(this.getFullYear(), this.getMonth())
}
Date.prototype.addMonths = function (value) {
  const n = this.getDate()
  this.setDate(1)
  this.setMonth(this.getMonth() + value)
  this.setDate(Math.min(n, this.getDaysInMonth()))
  // return `${this.getFullYear()}-0${this.getMonth() + 1}-${this.getDate()}`
  return this
}

export default {
  mixins: [PaginationMixin],
  components: {
    vSelect,
    dialogSig,
    permissionPassword,
    ckeditor: CKEditor.component,
    CurrencyInput,
    AgGridVue,
    // Cell Renderer
    CellRendererDateTime,
    CellRendererServices,
    CellRendererActions,
    CellRendererSituation,
    CancelContractFormPopup
  },
  props: {
    states: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      popupServiceQty: false,
      serviceQty: 1,
      contractId: null,
      errorfirstParcelValue: false,
      popupPermissionPassword: false,

      data_local: {},
      accountsFilteredOptions: [],
      currencyConfig:  {
        currency: {prefix: 'R$ '},
        valueAsInteger: false,
        distractionFree: false,
        precision: 2,
        autoDecimalMode: true,
        valueRange: { min: 0 },
        allowNegative: false
      },

      ckEd: null,
      editor: ClassicEditor,
      editorConfig: {
        language: 'pt-br',
        plugins: [
          Autoformat,
          BlockQuote,
          Bold,
          Code,
          CodeBlock,
          Essentials,
          GeneralHtmlSupport,
          Heading,
          HtmlEmbed,
          Image,
          ImageCaption,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          Indent,
          Italic,
          Link,
          List,
          MediaEmbed,
          Paragraph,
          PasteFromOffice,
          SourceEditing,
          Table,
          TableToolbar,
          TextTransformation,
          Alignment,
          AutoImage,
          CKFinderUploadAdapter,
          CloudServices,
          FindAndReplace,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          FontSize,
          Highlight,
          ImageResize,
          IndentBlock,
          RemoveFormat,
          SpecialCharacters,
          SpecialCharactersArrows,
          SpecialCharactersCurrency,
          SpecialCharactersEssentials,
          SpecialCharactersLatin,
          SpecialCharactersMathematical,
          SpecialCharactersText,
          TableCaption,
          TableCellProperties,
          TableProperties,
          Underline,
          WordCount
        ],
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            // 'link',
            // 'bulletedList',
            // 'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            // 'imageUpload',
            'blockQuote',
            'insertTable',
            // 'mediaEmbed',
            'undo',
            'redo',
            'alignment',
            'code',
            'findAndReplace',
            // 'fontBackgroundColor',
            // 'fontColor',
            'fontFamily',
            'fontSize',
            'highlight',
            'htmlEmbed',
            'removeFormat',
            'sourceEditing'
            // 'specialCharacters'
          ]
        },
        image: {
          toolbar: [
            'imageTextAlternative',
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side'
          ]
        },
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells'
            // 'tableCellProperties',
            // 'tableProperties'
          ]
        },
        htmlEmbed: {
          showPreviews: true
          // sanitizeHtml: (inputHtml) => {
          //   // Strip unsafe elements and attributes, e.g.:
          //   // the `<script>` elements and `on*` attributes.
          //   const outputHtml = sanitize(inputHtml)

          //   return {
          //     html: outputHtml,
          //     // true or false depending on whether the sanitizer stripped anything.
          //     hasChanged: true
          //   }
          // }
        },
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Paragraph',
              class: 'ck-heading_paragraph'
            },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Heading 1',
              class: 'ck-heading_heading1'
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Heading 2',
              class: 'ck-heading_heading2'
            }
          ]
        }
      },

      // Variáveis do aluno
      studentVariables: [
        {variable: '[logo_empresa]', label: 'Logo da empresa'},
        {variable: '[dia]', label: 'Dia'},
        {variable: '[mes]', label: 'Mês'},
        {variable: '[ano]', label: 'Ano'},
        {variable: '[nome_aluno]', label: 'Nome'},
        {variable: '[nome_social]', label: 'Nome social'},
        {variable: '[CODIGO_ALUNO]', label: 'Código'},
        {variable: '[data_cadastro]', label: 'Data de cadastro'},
        {variable: '[renach]', label: 'Nº do formulário RENACH'},
        {variable: '[cat_atual]', label: 'Categoria atual'},
        {variable: '[cat_pret]', label: 'Categoria pretendida'},
        {variable: '[data_nascimento]', label: 'Data de nascimento'},
        {variable: '[idade]', label: 'Idade'},
        {variable: '[nome_mae]', label: 'Nome da mãe'},
        {variable: '[nome_pai]', label: 'Nome do pai'},
        {variable: '[uf_nascimento]', label: 'Estado de nascimento'},
        {variable: '[cidade_nascimento]', label: 'Cidade de nascimento'},
        {variable: '[cpf_aluno]', label: 'CPF do aluno'},
        {variable: '[rg_aluno]', label: 'RG do aluno'},
        {variable: '[orgao_emissor]', label: 'Órgão emissor RG'},
        {variable: '[uf_rg]', label: 'Estado do RG'},
        {variable: '[nacionalidade_aluno]', label: 'Nacionalidade'},
        {variable: '[genero_aluno]', label: 'Gênero do aluno'},
        {variable: '[grau_instrucao]', label: 'Grau de instrução'},
        {variable: '[telefone_fixo]', label: 'Telefone fixo'},
        {variable: '[celular]', label: 'Celular'},
        {variable: '[email_aluno]', label: 'E-mail'},
        {variable: '[profissao_aluno]', label: 'Profissão'},
        {variable: '[estado_civil_aluno]', label: 'Estado civil'},
        {variable: '[extra_field]', label: 'Campo extra'}
      ],

      // ENDEREÇO:
      addressVariables: [
        {variable: '[logo_empresa]', label: 'Logo da empresa'},
        {variable: '[estado_aluno]', label: 'Estado'},
        {variable: '[cidade_aluno]', label: 'Cidade'},
        {variable: '[rua_aluno]', label: 'Rua'},
        {variable: '[numero_endereco]', label: 'Número'},
        {variable: '[bairro_aluno]', label: 'Bairro'},
        {variable: '[cep_aluno]', label: 'CEP'},
        {variable: '[complemento_endereco]', label: 'Complemento'}
      ],

      // AVALISTA:
      tenantVariables: [
        {variable: '[nome_avalista]', label: 'Nome avalista'},
        {variable: '[cpf_avalista]', label: 'CPF avalista'},
        {variable: '[rg_avalista]', label: 'RG avalista'},
        {variable: '[orgao_emissor_rg_avalista]', label: 'Órg. emissor RG avalista'},
        {variable: '[uf_rg_avalista]', label: 'UF RG avalista'},
        {variable: '[local_trabalho_avalista]', label: 'Local trabalho avalista'},
        {variable: '[conjuge_avalista]', label: 'Cônjuge avalista'},
        {variable: '[estado_avalista]', label: 'Estado'},
        {variable: '[cidade_avalista]', label: 'Cidade'},
        {variable: '[rua_avalista]', label: 'Rua'},
        {variable: '[numero_endereco_avalista]', label: 'Número end.'},
        {variable: '[bairro_avalista]', label: 'Bairro'},
        {variable: '[cep_avalista]', label: 'CEP'},
        {variable: '[complemento_endereco_avalista]', label: 'Complemento end.'}
      ],

      // CONTRATO:
      contractVariables: [
        {variable: '[CODIGO_CONTRATO]', label: 'Código'},
        {variable: '[valor_total]', label: 'Valor total do contrato'},
        {variable: '[valor_das_parcelas]', label: 'Valor de cada parcela'},
        {variable: '[valor_da_primeira_parcela]', label: 'Valor da primeira parcela/entrada'},
        {variable: '[numero_total_de_parcelas]', label: 'Número de parcelas'},
        {variable: '[vencimento_de_parcelas]', label: 'Vencimento de parcelas'},
        {variable: '[nome_do_servico]', label: 'Nome do serviço'},
        {variable: '[pacote_detalhado]', label: 'Pacote detalhado'},
        {variable: '[multa_do_contrato]', label: 'Multa do contrato'},
        {variable: '[juros_do_contrato]', label: 'Juros do contrato'},

        {variable: '[DATA_VENCIMENTO_1PARCELA]', label: 'Vencimento 1ª Parcela'},
        {variable: '[DATA_VENCIMENTO_2PARCELA]', label: 'Vencimento 2ª Parcela'},
        {variable: '[DATA_VENCIMENTO_3PARCELA]', label: 'Vencimento 3ª Parcela'},
        {variable: '[DATA_VENCIMENTO_4PARCELA]', label: 'Vencimento 4ª Parcela'},
        {variable: '[DATA_VENCIMENTO_5PARCELA]', label: 'Vencimento 5ª Parcela'},
        {variable: '[DATA_VENCIMENTO_6PARCELA]', label: 'Vencimento 6ª Parcela'},
        {variable: '[DATA_VENCIMENTO_7PARCELA]', label: 'Vencimento 7ª Parcela'},
        {variable: '[DATA_VENCIMENTO_8PARCELA]', label: 'Vencimento 8ª Parcela'},
        {variable: '[DATA_VENCIMENTO_9PARCELA]', label: 'Vencimento 9ª Parcela'},
        {variable: '[DATA_VENCIMENTO_10PARCELA]', label: 'Vencimento 10ª Parcela'},

        {variable: '[VALOR_1PARCELA]', label: 'Valor 1ª Parcela'},
        {variable: '[VALOR_2PARCELA]', label: 'Valor 2ª Parcela'},
        {variable: '[VALOR_3PARCELA]', label: 'Valor 3ª Parcela'},
        {variable: '[VALOR_4PARCELA]', label: 'Valor 4ª Parcela'},
        {variable: '[VALOR_5PARCELA]', label: 'Valor 5ª Parcela'},
        {variable: '[VALOR_6PARCELA]', label: 'Valor 6ª Parcela'},
        {variable: '[VALOR_7PARCELA]', label: 'Valor 7ª Parcela'},
        {variable: '[VALOR_8PARCELA]', label: 'Valor 8ª Parcela'},
        {variable: '[VALOR_9PARCELA]', label: 'Valor 9ª Parcela'},
        {variable: '[VALOR_10PARCELA]', label: 'Valor 10ª Parcela'},

        {variable: '[TIPO_PAGAMENTO_1PARCELA]', label: 'Tipo Pagamento 1ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_2PARCELA]', label: 'Tipo Pagamento 2ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_3PARCELA]', label: 'Tipo Pagamento 3ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_4PARCELA]', label: 'Tipo Pagamento 4ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_5PARCELA]', label: 'Tipo Pagamento 5ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_6PARCELA]', label: 'Tipo Pagamento 6ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_7PARCELA]', label: 'Tipo Pagamento 7ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_8PARCELA]', label: 'Tipo Pagamento 8ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_9PARCELA]', label: 'Tipo Pagamento 9ª Parcela'},
        {variable: '[TIPO_PAGAMENTO_10PARCELA]', label: 'Tipo Pagamento 10ª Parcela'}
      ],

      localeText: null,
      searchQuery: '',

      // AgGrid
      gridApi: null,
      dataSource: {},
      gridOptions: {
        rowModelType: 'infinite'
      },
      overlayLoadingTemplate: 'Consultando dados...',
      noRowsTemplate: 'Nenhum dado foi encontrado',
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'Ações',
          field: 'contracts',
          width: 130,
          sortable: false,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams : {
            refreshData: this.refreshData
          }
        },
        {
          headerName: 'Código',
          field: 'id',
          width: 150,
          sortable: false
        },
        {
          headerName: 'Data',
          field: 'created_at',
          width: 228,
          floatingFilter:true,
          filter: 'agDateColumnFilter',
          filterParams: {
            suppressAndOrCondition: true,
            filterOptions: ['equals', 'inRange'],
            defaultOption: 'equals',
            debounceMs: 800,
            // comparator: (filterLocalDateAtMidnight, cellValue) => {
            //   const dateAsString = cellValue
            //   if (dateAsString === null) return -1
            //   const dateParts = dateAsString.split('/')
            //   const cellDate = new Date(
            //     Number(dateParts[2]),
            //     Number(dateParts[1]) - 1,
            //     Number(dateParts[0])
            //   )

            //   if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            //     return 0
            //   }

            //   if (cellDate < filterLocalDateAtMidnight) {
            //     return -1
            //   }

            //   if (cellDate > filterLocalDateAtMidnight) {
            //     return 1
            //   }
            // },
            browserDatePicker: true,
            minValidYear: 2000
          },
          cellRendererFramework: 'CellRendererDateTime'
        },
        {
          headerName: 'Serviço(s)',
          field: 'service',
          width: 300,
          // floatingFilter:true,
          // filter: 'agTextColumnFilter',
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   debounceMs: 800
          // },
          sortable: false,
          cellRendererFramework: 'CellRendererServices'
        },
        {
          headerName: 'Valor',
          field: 'value_total',
          valueFormatter: params => { return params.value ? params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '' },
          width: 145
        },
        {
          headerName: 'Responsável',
          field: 'salesman.name',
          width: 180,
          // floatingFilter:true,
          // filter: true,
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   debounceMs: 800
          // },
          sortable: false
        },
        {
          headerName: 'Situação',
          field: 'situation_contract',
          width: 180,
          // floatingFilter: true,
          // filter: true,
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   debounceMs: 800
          // },
          cellRendererFramework: 'CellRendererSituation',
          sortable: false,
          onCellClicked: (event) => { this.handleChangeContractSituation(event.data) }
        }
      ],

      components: {
        CellRendererDateTime,
        CellRendererServices,
        CellRendererActions,
        CellRendererSituation
      },

      payload: {
        offset: 0,
        limit: 40,
        searchQuery: null,
        params: null,
        student_id: this.$store.state.studentManagement.student.id,
        consultApi: true
      },

      popupShowGuarantor: false,
      popupGuarantorDeleteConfirm: false,

      contractPopupTitle: '',
      popupContract: false,

      disableGenerateParcels: false,
      disableSave: false,
      contract: {
        account: null,
        type_payment: null,
        account_plan: null,
        servicePackages: [],
        situation_contract: 'IN_PROGRESS', //IN_PROGRESS - FINISHED - CANCELED

        reason_cancellation: null,
        return_bank_id: null, //Banco para devolução do valor do contrato em caso de cancelamento
        return_agency: null, //Agência bancária para devolução do valor do contrato em caso de cancelamento
        return_account: null, //Conta bancária para devolução do valor do contrato em caso de cancelamento
        return_operation: null, //Operação bancária para devolução do valor do contrato em caso de cancelamento
        amount_receivable: 0, //valor a receber no cancelamento
        amount_to_pay: 0, //valor a devolver no cancelamento
        date_receivable: '', //data a receber no cancelamento
        date_amount_to_pay: '', //data valor a devolver no cancelamento

        parcelsNumber: 1,
        discount_type: '%',
        discount: 0,
        total: 0,
        sub_total: 0,
        firstParcelValue: 0,
        expirationDateFirstParcel: '',
        frequency: 'monthly',
        applyInterest: true,
        commission: 0,
        commission_type:'%',
        employee_id: null,
        current_category_cnh: null,
        intended_category_cnh: null,
        gainful_activity: false,
        specialized_courses: null,
        observation: null,
        parcels: []
      },

      popupChangeContractSituation: false,
      constractToChangeSituation: {},

      popupCancelContract: false,
      contractRefund: 'no_refund',

      popupShowBoleto: false,
      boletoUrl:'',
      remessaUrl:'',

      cnhOptions: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'AB', value: 'AB' },
        { label: 'C', value: 'C' },
        { label: 'D', value: 'D' },
        { label: 'E', value: 'E' },
        { label: 'AC', value: 'AC' },
        { label: 'AD', value: 'AD' },
        { label: 'AE', value: 'AE' },
        { label: 'ACC', value: 'ACC' }
      ],

      specializedCoursesCNHOptions: [
        { label: 'Produtos perigosos', value: 11 },
        { label: 'Escolar', value: 12 },
        { label: 'Coletivo', value: 13 },
        { label: 'Emergência', value: 14 }
        // { label: 'EAR', value: 15 }
      ],

      disableContractModelSave: false,
      newContractPopupTitleModel: '',
      // popupContractModel: false,
      accordionDocumentTemplate: false,

      contractModel: {
        title: null,
        print_header: false,
        body: ''
      },

      typeOptions: [
        { label: 'Física',  value: 'FISICA' },
        { label: 'Jurídica',  value: 'JURIDICA' }
      ],

      cityGuarantorOptions:[],

      logradouroOptions: [
        { label: 'Rua', value: 'Rua' },
        { label: 'Avenida', value: 'Avenida' },
        { label: 'Rodovia', value: 'Rodovia' },
        { label: 'Estrada', value: 'Estrada' },
        { label: 'Lote', value: 'Lote' },
        { label: 'Servidão', value: 'Servidão' },
        { label: 'Quadra', value: 'Quadra' },
        { label: 'Travessa', value: 'Travessa' },
        { label: 'Linha', value: 'Linha' }
      ],
      countryOptions: [{ label: 'Brasil', value: 1 }],

      changed: false,
      disableGuarantorSave: false
    }
  },

  directives: { currency: CurrencyDirective },

  computed: {
    today () {
      const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
      return `${YYYY}-${MM}-${DD}`
    },
    firstContractValue () { //controlo no watch para não permitir valor maior que o total
      return this.contract.firstParcelValue
    },
    discount () {
      return this.contract.discount
    },
    subTotal () {
      return this.contract.sub_total
    },
    firstParcelValue () {
      return this.contract.parcels[0] && this.contract.parcels[0].value_parcel
    },
    validateGuarantorForm () {
      return !this.errors.any('guarantor')
    },
    validateContract () {
      return !this.errors.any('contract')
    },
    validateContractFormModel () {
      return !this.errors.any('contractModel')
    },
    servicePackages () {
      return JSON.parse(JSON.stringify(this.$store.getters['servicePackage/forSelect']))
    },
    typePayments () {
      return this.$store.getters['typePayment/forSelect']
    },
    accounts () {
      return this.$store.getters['account/forSelect']
    },
    accountPlans () {
      return this.$store.getters['accountPlan/forSelectFilterReceipt']
    },
    banks () {
      return this.$store.getters['bank/forSelect']
    },
    employees () {
      return this.$store.getters['employee/forSelect']
    },
    contractStudentsData () {
      const contracts = this.$store.state.contractStudent.contracts
      if (contracts.data && contracts.data.length) {  // DEFININDO O ÚLTIMO CONTRATO PARA SINALIZAR COM ESTRELA NA LISTA
        contracts.data.map(a => {
          if (a.id === contracts.lastContract.id) {
            a.isLast = true
          } else {
            a.isLast = false
          }
        })
      }
      return contracts
    }
  },
  methods: {
    changeValueParcel (parcel, idxParcel) {
      parcel.situation ? parcel.value_pay = parcel.value_parcel : parcel.value_pay = 0
      this.calculateNextParcels(idxParcel)
    },
    round (value) {
      return parseFloat(parseFloat(value).toFixed(2))
    },
    calculateNextParcels (idxParcel) {
      if (!idxParcel) return
      let idx = 0
      let partialValue =  this.contract.total
      let quantity = this.contract.parcels.length - idxParcel - 1
      while (idx <= idxParcel) {
        partialValue -= this.round(this.contract.parcels[idx].value_parcel)
        idx++
      }
      partialValue = this.round(partialValue / quantity)

      const difference = this.contract.total - (partialValue * quantity)

      while (idx < this.contract.parcels.length) {
        this.contract.parcels[idx].value_parcel = partialValue
        idx++
      }
      quantity = this.contract.parcels.length - 1
      partialValue = 0
      for (let i = 0; i < quantity; i++) {
        partialValue += this.contract.parcels[i].value_parcel
      }

      this.contract.parcels[quantity].value_parcel = this.round(this.contract.total - partialValue)
    },
    openLoading () {
      this.$vs.loading({
        container: '#boleto-loading',
        scale: 1
      })
    },
    toggleCommissionType () {
      this.contract.commission = 0
      this.contract.commission_type = this.contract.commission_type === '%' ? '$' : '%'
    },
    changeSeller () {
      const idx = this.employees.findIndex((e) => e.data.id === this.contract.employee_id)
      if (idx >= 0) {

        this.contract.commission_type = this.employees[idx].data.commission_type
        if (this.contract.commission_type === '$') {
          const self = this
          this.$nextTick(function () {
            setValue(self.$refs.commission, self.employees[idx].data.commission.toString())
          })
        } else {
          this.contract.commission = this.employees[idx].data.commission
        }
      } else {
        this.contract.commission = 0
        this.contract.commission_type = '%'
      }
    },

    //EVITA BAIXA COM DATA FUTURA
    checkPayday (parcel) {
      if (moment(parcel.payday).year() > 1000) {
        const difDays = moment(parcel.payday).diff(moment(this.today), 'days')
        if (difDays > 0) {
          parcel.futureDate = true
          this.disableSave = true
        } else {
          parcel.futureDate = false
          //checa se há alguma parcela com pagamento futuro
          const index = this.contract.parcels.findIndex(e => e.futureDate)
          if (index === -1) this.disableSave = false
        }
      } else {
        parcel.futureDate = false
        //checa se há alguma parcela com pagamento futuro
        const index = this.contract.parcels.findIndex(e => e.futureDate)
        if (index === -1) this.disableSave = false
      }
    },
    // FILTRA AS CONTAS CONFORME O TIPO DE PAGAMENTO
    filterAccounts () {
      this.contract.account = null
      const myArrayFiltered = this.accounts.filter((account) => {
        return account.type_payments.some(e => e.id === this.contract.type_payment)
      })
      this.accountsFilteredOptions = myArrayFiltered
      if (myArrayFiltered.length) this.contract.account = myArrayFiltered[0].value
    },

    // FILTRA AS CONTAS NO FOR DAS PARCELAS CONFORME O TIPO DE PAGAMENTO
    filterAccountsParcels (i) {
      const parcel = this.contract.parcels[i]
      parcel.account = null
      const myArrayFiltered = this.accounts.filter((account) => {
        return account.type_payments.some(e => e.id === parcel.type_payment)
      })
      parcel.accountsFilteredOptions = myArrayFiltered
      if (myArrayFiltered.length) {
        parcel.account = myArrayFiltered[0].value
        if (parcel.accountsFilteredOptions.find(o => o.value === parcel.account) && parcel.accountsFilteredOptions.find(o => o.value === parcel.account).card_account) {
          parcel.situation = true
          parcel.payday = this.today
        } else {
          parcel.situation = false
          parcel.payday = ''
        }
      } else {
        parcel.situation = false
        parcel.payday = ''
      }
    },

    isSameOrAfterToday (e) {
      if (moment(e).isValid()) return moment(e).isSameOrAfter(moment().format('YYYY-MM-DD'))
      return true
    },

    getSearchResult (searchVal) {
      this.payload.searchQuery = searchVal
      this.gridApi.setDatasource(this.dataSource)
    },
    updateSearchQuery (val) {
      this.debouncedGetSearchResult(val)
      // this.gridApi.setQuickFilter(val)
    },
    onGridReady () {
      const self = this
      this.dataSource = {
        rowCount: null,
        async getRows (params) {
          self.payload.offset = params.startRow
          self.payload.params = params
          self.gridApi.showLoadingOverlay()

          self.gridApi.showLoadingOverlay()

          if (self.payload.consultApi) await self.getAllContracts()
          self.payload.consultApi = true

          self.gridApi.hideOverlay()
          if (!self.contractStudentsData.total) {
            self.gridApi.showNoRowsOverlay()
          }

          let lastRow = -1
          if (self.contractStudentsData.total <= params.endRow) {
            lastRow = self.contractStudentsData.total
          }
          params.successCallback(self.contractStudentsData.data, lastRow)
        }
      }
      this.gridApi.setDatasource(this.dataSource)
    },

    refreshGrid () {
      this.gridApi.setDatasource(this.dataSource)
    },
    refreshData () {
      this.gridApi.refreshInfiniteCache()
    },

    async getAllContracts () {
      await this.$store.dispatch('contractStudent/fetchContracts', this.payload).catch(err => { console.error(err) })
    },

    handleChangeContractSituation (data) {
      this.popupChangeContractSituation = true
      this.constractToChangeSituation = data
      this.contractId = data.id
    },
    async changeContractSituation (newSituation) {
      try {
        await this.$store.dispatch('contractStudent/updateContract', { id: this.constractToChangeSituation.id, situation_contract: newSituation })
        this.payload.consultApi = false
        this.gridApi.setDatasource(this.dataSource)
        this.popupChangeContractSituation = false
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Situação Alterada.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.notify({
          time: 5000,
          title: 'Erro',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    },
    handleCancelContract () {
      this.popupChangeContractSituation = false
      this.popupCancelContract = true
    },
    async cancelContract () {
      try {
        await this.$store.dispatch('contractStudent/contractCancel', this.constractToChangeSituation)
        this.payload.consultApi = false
        this.gridApi.setDatasource(this.dataSource)
        this.popupCancelContract = false
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Contrato Cancelado.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.notify({
          time: 5000,
          title: 'Erro',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    },
    resetGuarantor () {
      this.data_local.guarantor = JSON.parse(JSON.stringify(this.$store.getters['studentManagement/getStudent'].guarantor))
    },
    handleGuarantor () {
      this.resetGuarantor()
      if (!this.data_local.guarantor.id) {
        //ESTADO DEFAULT SC
        this.data_local.guarantor.address.state_id = 24
        this.fillCity(this.data_local.guarantor.address.state_id, null, 'cityGuarantorOptions', 'guarantor')
      }

      this.popupShowGuarantor = true
    },
    saveGuarantor () {
      if (this.data_local.guarantor.id) {
        this.updateGuarantor()
      } else {
        this.storeGuarantor()
      }
    },
    storeGuarantor () {
      // Loading
      this.$vs.loading()
      this.disableGuarantorSave = true
      setTimeout(() => { this.disableGuarantorSave = false }, 2000) //TRAVA O BOTÃO SALVAR POR 3 SEGUNDOS

      Object.assign(this.data_local.guarantor, {student_id: this.data_local.id})
      this.$store.dispatch('guarantor/store', this.data_local.guarantor)
        .then((resp) => {
          this.$store.commit('studentManagement/SET_STUDENT', {guarantor: resp.data})
          this.resetGuarantor()
          this.$vs.loading.close()
          this.popupShowGuarantor = false
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Salvos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },
    updateGuarantor () {
      // Loading
      this.$vs.loading()
      this.disableGuarantorSave = true
      setTimeout(() => { this.disableGuarantorSave = false }, 2000) //TRAVA O BOTÃO SALVAR POR 3 SEGUNDOS

      Object.assign(this.data_local.guarantor, {student_id: this.data_local.id})
      this.$store.dispatch('guarantor/update', this.data_local.guarantor)
        .then((resp) => {
          this.$store.commit('studentManagement/SET_STUDENT', {guarantor: resp.data})
          this.resetGuarantor()
          this.$vs.loading.close()
          this.popupShowGuarantor = false
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Salvos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },
    deleteGuarantor () {
      this.popupGuarantorDeleteConfirm = true
    },
    dispatchDeleteGuarantor () {
      this.$store.dispatch('guarantor/delete', this.data_local.guarantor.id)
        .then(() => {
          this.$store.commit('studentManagement/SET_STUDENT', {guarantor: null})
          this.resetGuarantor()
          this.popupGuarantorDeleteConfirm = false
          this.popupShowGuarantor = false
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Excluídos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },
    searchGuarantor () {
      const identification = this.data_local.guarantor.cpf_guarantor
      if ((identification.length === 14 || identification.length === 18) && !this.errors.has('guarantor.cpf')) {
        const cpf_cnpj = identification.replace(/\D/g, '')
        this.$store.dispatch('guarantor/getByCpfCnpj', cpf_cnpj)
          .then((resp) => {
            if (resp.data.name_guarantor) {
              resp.data.date_of_birth_guarantor = resp.data.date_of_birth_guarantor ? resp.data.date_of_birth_guarantor.slice(0, 10) : ''
              this.data_local.guarantor = resp.data
              this.data_local.tenant.address.country = 1

              const sortPhonesBy = ['mobile', 'phone', 'other']

              if (!this.data_local.guarantor.phones) {
                this.data_local.guarantor.phones = [
                  {type: 'mobile', phone: null},
                  {type: 'phone', phone: null},
                  {type: 'other', phone: null}
                ]
              } else {
                // AJUSTANDO OS TELEFONES VAZIOS
                if (!this.data_local.guarantor.phones.filter(phone => phone.type === 'mobile').length) {
                  this.data_local.guarantor.phones.unshift({id: null, phone: null, type: 'mobile' })
                }
                if (!this.data_local.guarantor.phones.filter(phone => phone.type === 'phone').length) {
                  this.data_local.guarantor.phones.push({id: null, phone: null, type: 'phone' })
                }
                if (!this.data_local.guarantor.phones.filter(phone => phone.type === 'other').length) {
                  this.data_local.guarantor.phones.push({id: null, phone: null, type: 'other' })
                }
                this.data_local.guarantor.phones.sort((a, b) => sortPhonesBy.indexOf(a.type) - sortPhonesBy.indexOf(b.type))
              }
              this.errors.remove('guarantor.name')
            }
          })
          .catch(error => {
            this.$vs.loading.close()
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })


      //   try {
      //     const response = await axios.get("/responsible/getByIdentification/"+cpf_cnpj)
      //     if (_.isEmpty(response.data)) {

      //       console.log('no responsible')

      //     } else {
      //       this.patient.responsible = {
      //         id: response.data.id,
      //         name: response.data.name,
      //         identification1: response.data.identification1,
      //         identification2: response.data.identification2,
      //         orgao_emissor_rg: response.data.orgao_emissor_rg,
      //         orgao_emissor_rg_uf_id: response.data.orgao_emissor_rg_uf_id,
      //         emails: response.data.emails,
      //         birthday: response.data.birthday ? response.data.birthday.substring(0,10) : '',
      //         gender: response.data.gender, //'male', 'feminine', 'undefined'
      //         kinship: response.data.kinship, //'male', 'feminine', 'undefined'
      //         phones: response.data.phones,
      //         address: response.data.addresses[0],
      //       }
      //       this.$refs.responsibleAddress.address = this.patient.responsible.address
      //     }
      //   } catch (error) {
      //     console.log(error)
      //     if (error.response.status === 401 || error.response.status === 419) {
      //       this.$toast.warning( "Sua sessão expirou. Por favor atualize a página.", "AVISO", this.optionsToast )
      //     } else {
      //       this.$toast.error( error.response.data.message, "Erro", this.optionsToast )
      //     }
      //   }
      // } else {
      //   this.patient.responsible.id = null
      //   this.patient.responsible.name = null
      //   this.patient.responsible.identification2 = null
      //   this.patient.responsible.orgao_emissor_rg = null
      //   this.patient.responsible.orgao_emissor_rg_uf_id = null
      //   this.patient.responsible.emails = [{ email: null, type_email: 'principal' }] // principal - secondary (se eu quiser mais campos de email incluo mais um objeto - repetindo esta linha)
      //   this.patient.responsible.birthday = ''
      //   this.patient.responsible.gender = 'undefined' //'male', 'feminine', 'undefined'
      //   this.patient.responsible.kinship = null
      //   this.patient.responsible.phones = [{type: 'mobile', number:''}, {type: 'phone', number:''}, {type: 'other', number:''}] // principal - secondary (se eu quiser mais campos de email incluo mais um objeto - repetindo esta linha)
      //   this.patient.responsible.address = {}

      //   this.$refs.responsibleAddress.resetAddress()
      }
    },

    newContract () {
      this.resetContractModal()
      this.contractPopupTitle = 'Novo Contrato'
      this.popupContract = true
    },

    storeContract (pass = false) {
      /** IMPORTANTE:
       *  VERIFICAR SE TODAS AS PARCELAS QUE GERAM BOLETO USAM A MESMA CONTA
       */
      const typePaymentBoleto = this.typePayments.find(el => {
        return el.label.toLowerCase() === 'boleto'
      })
      if (typePaymentBoleto) {
        const parcelsBoleto = this.contract.parcels.filter(e => e.type_payment === typePaymentBoleto.value)
        const index = parcelsBoleto.findIndex(o => o.account !== parcelsBoleto[0].account)
        if (index !== -1) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'TODAS as parcelas que emitem boleto devem utilizar a mesma conta!',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }
      }
      /**
       * IMPORTANTE:
       * VERIFICAR SE TODAS AS PARCELAS QUE GERAM BOLETO USAM A MESMA CONTA
       */

      /** CHECAR PAGAMENTO RETROATIVO */
      if (!pass) {
        const retroactiveParcels = this.contract.parcels.filter(e => {
          if (moment(e.payday).diff(moment(this.today), 'days') < 0) return true
        })

        if (retroactiveParcels.length) {
          this.popupPermissionPassword = true
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'Há parcelas com baixa retroativa',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }
      }

      // Loading
      this.$vs.loading()
      this.disableGenerateParcels = true
      setTimeout(() => { this.disableGenerateParcels = false }, 2000) //TRAVA O BOTÃO SALVAR POR 3 SEGUNDOS
      Object.assign(this.contract, {student_id: this.data_local.id})

      // É PRECISO TRATAR PARA QUE O CONTRATO RECEBA CORRETAMENTE O CAMPO servicePackages
      const contract =  JSON.parse(JSON.stringify(this.contract))
      contract.servicePackages = contract.servicePackages.map(item => {
        item.data.services.map((s) => { delete s.pivot })
        return item.data
      })
      contract.sub_total = getValue(this.$refs.subTotal)
      if (contract.discount_type === '$') contract.discount = getValue(this.$refs.discount)
      if (contract.commission_type === '$') contract.commission = getValue(this.$refs.commission)

      this.$store.dispatch('contractStudent/store', contract)
        .then((resp) => {

          this.refreshGrid()
          this.popupContract = false

          //VERIFICAR SE RETORNA URL DE BOLETO PARA MOSTRAR
          if (resp.data.boletoUrl) {
            this.boletoUrl = resp.data.boletoUrl
            this.remessaUrl = resp.data.remessaUrl
            this.popupShowBoleto = true
            this.openLoading()
            const self = this
            setTimeout(() => { self.$vs.loading.close('#boleto-loading > .con-vs-loading') }, 2000)
          }

          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Salvos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(error => {
          this.$vs.loading.close()
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 6000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        })
    },

    toggleDiscountType () {
      this.contract.discount = 0
      this.contract.discount_type = this.contract.discount_type === '%' ? '$' : '%'
    },

    newServiceSelected (val) {
      //LAST IN ARRAY IS THE SELECTED
      const lastServiceSelected = val[this.contract.servicePackages.length - 1]
      if (lastServiceSelected.data.type_service && lastServiceSelected.data.type_service.toUpperCase() === 'AULA AVULSA') {
        this.serviceQty = 1
        lastServiceSelected.data.quantity = this.serviceQty
        this.popupServiceQty = true
      }
    },

    serviceDeselected (val) {
      if (val.data.type_service && val.data.type_service.toUpperCase() === 'AULA AVULSA') {
        this.serviceQty = 1
        val.label = `${val.data.name} x ${this.serviceQty}`
        val.data.quantity = this.serviceQty
      }
    },

    changeServiceQty () {
      const lastServiceSelected = this.contract.servicePackages[this.contract.servicePackages.length - 1]
      lastServiceSelected.data.quantity = this.serviceQty
      lastServiceSelected.label = `${lastServiceSelected.data.name} x ${this.serviceQty}`
      this.changeContractSubTotal()
      this.popupServiceQty = false
    },

    closeChangeServiceQty () {
      this.serviceQty = 1
      this.popupServiceQty = false
    },

    changeContractSubTotal () {
      if (this.contract.servicePackages.length) {
        this.contract.account_plan = this.contract.servicePackages[0].data.account_plan_id
      } else {
        this.contract.account_plan = null
      }
      const value = this.contract.servicePackages.reduce((a, v) => a + ((v.currencyValue * v.data.quantity) || 0), 0).toString()
      setValue(this.$refs.subTotal, value)
    },

    changeParcelsNumber () {
      this.contract.parcels = []
      if (parseInt(this.contract.parcelsNumber) === 1) {
        setValue(this.$refs.firstParcelValue, this.contract.total)
      } else {
        setValue(this.$refs.firstParcelValue, 0)
      }
    },

    recalculateTotal () {
      this.contract.parcels = [] //evita alterar valores e esquecer de gerar novamente as parcelas
      let discount = this.contract.discount_type === '%' ? this.contract.discount : (this.$refs.discount ? getValue(this.$refs.discount) : 0)
      const subTotal = getValue(this.$refs.subTotal)
      if (this.contract.discount_type === '%') {
        discount = ((discount / 100) * subTotal).toFixed(2)
      }
      if (subTotal >= 0) {
        this.contract.total = subTotal - discount
      }

      if (parseInt(this.contract.parcelsNumber) === 1) {
        setValue(this.$refs.firstParcelValue, this.contract.total)
      } else {
        setValue(this.$refs.firstParcelValue, 0)
      }

    },

    generateContractParcels () {
      this.disableGenerateParcels = true
      setTimeout(() => { this.disableGenerateParcels = false }, 2000) //TRAVA O BOTÃO POR 3 SEGUNDOS
      if (!this.validateContract) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Por favor confira o preenchimento do formulário.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      this.contract.parcels = []

      const valueParcel = parseFloat((this.contract.total / parseInt(this.contract.parcelsNumber)).toFixed(2))
      let expirationDate = new Date(`${this.contract.expirationDateFirstParcel}T00:00:00`)

      for (let parcel = 1; parcel <= this.contract.parcelsNumber; parcel++) {
        if (parcel > 1) {
          if (this.contract.frequency === 'monthly') {
            expirationDate = new Date(`${this.contract.expirationDateFirstParcel}T00:00:00`).addMonths(parcel - 1)
          } else if (this.contract.frequency === 'biweekly') expirationDate.setDate(expirationDate.getDate() + 15)
          else if (this.contract.frequency === 'weekly') expirationDate.setDate(expirationDate.getDate() + 7)
        }
        this.contract.parcels.push({
          futureDate: false,
          // eslint-disable-next-line
          expiration_date: `${expirationDate.getFullYear()}-${('0' + (expirationDate.getMonth()+1)).slice(-2)}-${('0' + (expirationDate.getDate())).slice(-2)}`,
          payday: !(this.accountsFilteredOptions.find(o => o.card_account)) ? '' : this.today,
          value_pay: !(this.accountsFilteredOptions.find(o => o.card_account)) ? 0 : valueParcel,
          parcel,
          value_parcel: valueParcel,
          situation: !!(this.accountsFilteredOptions.find(o => o.card_account)), // false = PENDING | true = PAID
          value_total: this.contract.total,
          observation: this.contract.observation,
          account_plan: this.contract.account_plan,
          account: this.contract.account,
          type_payment: this.contract.type_payment,
          accountsFilteredOptions: this.accountsFilteredOptions
        })
      }

      //ESTE BLOCO SERVE APENAS PARA CASOS EM QUE A PRIMEIRA PARCELA É DEFINIDA ANTES DE GERAR AS PARCELAS
      //CASO NÃO HAJA UM VALOR INICIAL DE PRIMEIRA PARCELA COMENTAR O BLOCO É O SUFICIENTE
      const v = getValue(this.$refs.firstParcelValue)
      if (this.contract.parcelsNumber > 1 && v > 0) {
        this.contract.parcels[0].value_parcel = v
        this.recalculateParcelsValue(v)
      }

      // vou ajustar o valor da ultima parcela, colocando a diferenca
      this.$nextTick(() => {
        let differenceValue = 0
        this.contract.parcels.forEach(e => {
          differenceValue += e.value_parcel
        })

        this.contract.parcels[this.contract.parcels.length - 1].value_parcel = parseFloat((this.contract.parcels[this.contract.parcels.length - 1].value_parcel + (this.contract.total - differenceValue)).toFixed(2))
      })
    },

    recalculateParcelsValue (firstParcelValue) {
      if (this.contract.parcels.length) { //evita erro
        if (firstParcelValue > this.contract.total) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'O valor digitado é maior que o valor total do contrato.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })

          this.contract.parcels[0].value_parcel = 0
          return
        }
        const valueParcels = (this.contract.total - firstParcelValue) / (parseInt(this.contract.parcelsNumber) - 1)
        for (let parcel = 1; parcel < (this.contract.parcelsNumber); parcel++) {
          this.contract.parcels[parcel].value_parcel = valueParcels
          if (this.contract.parcels[parcel].situation) {
            this.contract.parcels[parcel].value_pay = this.contract.parcels[parcel].value_parcel
          } else {
            this.contract.parcels[parcel].value_pay = 0
          }
        }
      }
    },

    newContractModel () {
      this.newContractPopupTitleModel = 'Novo Modelo de Contrato'
      this.contractModel = { title: null, print_header: false, body: '' }
      // this.popupContractModel = true
      this.accordionDocumentTemplate = true
    },
    onReady (editor)  {
      this.ckEd = editor
      // console.log(editor.ui.componentFactory.names())
    },
    copyVariable (variable) {
      //INSERE A VARIÁVEL ONDE O CURSOR ESTIVER
      this.ckEd.model.change(writer => {
        const insertPosition = this.ckEd.model.document.selection.getFirstPosition()
        writer.insertText(variable, {}, insertPosition)
      })

      //COPIA A VARIÁVEL PARA A MEMÓRIA (CTRL C + CTRL V)
      const thisIns = this
      this.$copyText(variable).then(function () {
        thisIns.$vs.notify({
          time: 700,
          title: 'Success',
          text: 'Variável copiada.',
          color: 'success',
          iconPack: 'feather',
          position: 'top-center',
          icon: 'icon-check-circle'
        })
      }, function () {
        thisIns.$vs.notify({
          title: 'Failed',
          text: 'Oops. Algo inesperado aconteceu. Por favor avise o suporte',
          color: 'danger',
          iconPack: 'feather',
          position: 'top-center',
          icon: 'icon-alert-circle'
        })
      })
    },
    addContractModel () {
      // Loading
      this.$vs.loading()
      this.disableContractModelSave = true
      setTimeout(() => { this.disableContractModelSave = false }, 2000) //TRAVA O BOTÃO SALVAR POR 3 SEGUNDOS
      this.$store.dispatch('contractModel/store', this.contractModel)
        .then(() => {
          this.$vs.loading.close()
          // this.popupContractModel = false
          this.accordionDocumentTemplate = false
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Salvos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },

    async searchZipCode ($event) {
      const zip_code = this.data_local.guarantor.address.zip_code.trim().replace(/[^0-9]/g, '')

      if (zip_code.length === 8 && ($event.keyCode < 37 || $event.keyCode > 40)) { // $event.keyCode evita chamadas de API ao teclar setas

        axios._noHeaders = true //enviando um get sem Autorization no header
        const resp = await axios.get(`https://viacep.com.br/ws/${zip_code}/json`)
        if (resp.data.erro) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: 'Este CEP não existe ou não foi encontrado.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }

        this.data_local.guarantor.address.neighborhood = resp.data.bairro
        this.data_local.guarantor.address.complement = resp.data.complemento

        //abaixo testo a primeira palavra da rua (logradouro), se existir removo da rua e seleciono o logradouro
        const rua = /Rua /i
        const avenida = /Avenida /i
        const rodovia = /Rodovia /i
        const estrada = /Estrada /i
        const lote = /Lote /i
        const servidao = /Servidão /i
        const quadra = /Quadra /i
        const travessa = /Travessa /i
        const linha = /Linha /i

        if (rua.test(resp.data.logradouro)) {
          this.data_local.guarantor.address.logradouro = 'Rua'
          const regEx = new RegExp('Rua ', 'ig')
          this.data_local.guarantor.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (avenida.test(resp.data.logradouro)) {
          this.data_local.guarantor.address.logradouro = 'Avenida'
          const regEx = new RegExp('Avenida ', 'ig')
          this.data_local.guarantor.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (rodovia.test(resp.data.logradouro)) {
          this.data_local.guarantor.address.logradouro = 'Rodovia'
          const regEx = new RegExp('Rodovia ', 'ig')
          this.data_local.guarantor.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (estrada.test(resp.data.logradouro)) {
          this.data_local.guarantor.address.logradouro = 'Estrada'
          const regEx = new RegExp('Estrada ', 'ig')
          this.data_local.guarantor.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (lote.test(resp.data.logradouro)) {
          this.data_local.guarantor.address.logradouro = 'Lote'
          const regEx = new RegExp('Lote ', 'ig')
          this.data_local.guarantor.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (servidao.test(resp.data.logradouro)) {
          this.data_local.guarantor.address.logradouro = 'Servidão'
          const regEx = new RegExp('Servidão ', 'ig')
          this.data_local.guarantor.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (quadra.test(resp.data.logradouro)) {
          this.data_local.guarantor.address.logradouro = 'Quadra'
          const regEx = new RegExp('Quadra ', 'ig')
          this.data_local.guarantor.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (travessa.test(resp.data.logradouro)) {
          this.data_local.guarantor.address.logradouro = 'Travessa'
          const regEx = new RegExp('Travessa ', 'ig')
          this.data_local.guarantor.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (linha.test(resp.data.logradouro)) {
          this.data_local.guarantor.address.logradouro = 'Linha'
          const regEx = new RegExp('Linha ', 'ig')
          this.data_local.guarantor.address.street = resp.data.logradouro.replace(regEx, '')
        } else {
          this.data_local.guarantor.address.street = resp.data.logradouro
        }

        const state = this.states.find(o => o.initials === resp.data.uf)
        this.data_local.guarantor.address.state_id = state.value
        await this.fillCity(state.value, resp.data.localidade, 'cityGuarantorOptions', 'guarantor')
      }
    },

    async fillCity (stateId, city = null, prop = 'cityOptions', prop2 = 'main') {
      this[prop] = []
      try {
        if (stateId) {
          const resp = await axios.get(`${process.env.VUE_APP_API_URL}/cities/${stateId}`)
          for (const k in resp.data) {
            this[prop].push({ value: resp.data[k].id, label: resp.data[k].city })
          }
          if (city) {
            //procura pelo id, se não encontrar peocura pelo nome (motivo: da api recebo ID e do CEP recebo nome)
            let ct = this[prop].find(x => x.value === city)
            if (!ct) {
              ct = this[prop].find(x => x.label === city)
            }
            if (prop2 === 'main') {
              this.data_local.guarantor.address.city_id = ct.value
            } else if (prop2 === 'guarantor') {
              this.data_local.guarantor.address.city_id = ct.value
            }
          }
        }
      } catch (error) { console.error(error) }
    },

    resetLocalData (change = false) {
      this.data_local = JSON.parse(JSON.stringify(this.$store.getters['studentManagement/getStudent']))
      this.changed = change
    },

    resetContractModal () {
      this.contract.account = null
      this.contract.type_payment = null
      this.contract.account_plan = null

      // Já vem preenchido conforme serviços desejados
      const servicePreSelected = this.servicePackages.find(sp => sp.value === this.data_local.intended_services)
      this.contract.servicePackages = servicePreSelected ? [servicePreSelected] : []

      this.contract.situation_contract = 'IN_PROGRESS' //IN_PROGRESS - FINISHED - CANCELED
      this.contract.reason_cancellation = null
      this.contract.return_bank_id = null //Banco para devolução do valor do contrato em caso de cancelamento
      this.contract.return_agency = null //Agência bancária para devolução do valor do contrato em caso de cancelamento
      this.contract.return_account = null //Conta bancária para devolução do valor do contrato em caso de cancelamento
      this.contract.return_operation = null //Operação bancária para devolução do valor do contrato em caso de cancelamento
      this.contract.amount_receivable = 0 //valor a receber no cancelamento
      this.contract.amount_to_pay = 0 //valor a devolver no cancelamento
      this.contract.date_receivable = '' //data a receber no cancelamento
      this.contract.date_amount_to_pay = '' //data valor a devolver no cancelamento

      this.contract.parcelsNumber = 1
      this.contract.discount_type = '%'
      this.contract.discount = 0
      this.contract.total = 0
      setValue(this.$refs.subTotal, 0)
      setValue(this.$refs.firstParcelValue, 0)
      this.contract.expirationDateFirstParcel = ''
      this.contract.frequency = 'monthly'
      this.contract.applyInterest = true

      //VINCULA O USUÁRIO A AGENDA MOSTRADA NO INÍCIO
      const idx = this.employees.findIndex((e) => e.data.user_id === this.$store.state.AppActiveUser.id || e.data.email === this.$store.state.AppActiveUser.email)
      if (idx >= 0) {
        this.contract.employee_id = this.employees[idx].value
        this.contract.commission = this.employees[idx].data.commission
        this.contract.commission_type = this.employees[idx].data.commission_type
      } else {
        this.contract.employee_id = null
        this.contract.commission = 0
        this.contract.commission_type = '%'
      }

      // Preenchimento conforme a última CNH
      this.contract.current_category_cnh = this.data_local.cnhs && this.data_local.cnhs.length ? this.data_local.cnhs[0].current_category_cnh : null
      this.contract.intended_category_cnh = servicePreSelected ? servicePreSelected.data.category_cnh : null
      this.contract.gainful_activity = this.data_local.cnhs && this.data_local.cnhs.length ? this.data_local.cnhs[0].gainful_activity : false
      this.contract.specialized_courses = this.data_local.cnhs && this.data_local.cnhs.length ? this.data_local.cnhs[0].specialized_courses : null

      this.contract.observation = null
      this.contract.parcels = []

      if (servicePreSelected) {
        this.changeContractSubTotal()
        this.recalculateTotal()
      }
    }
  },

  watch:{
    discount () {
      this.recalculateTotal()
    },
    subTotal () {
      this.recalculateTotal()
    },
    firstParcelValue (newValue) {
      this.recalculateParcelsValue(newValue)
    },
    firstContractValue () {
      const value = getValue(this.$refs.firstParcelValue)
      this.contract.parcels = []
      if (value > this.contract.total) {
        this.errorfirstParcelValue = true
      } else {
        this.errorfirstParcelValue = false
      }
    }

    // data_local: {
    //   deep: true,
    //   handler (data_local) {
    //     //OBSERVO DATA_LOCAL PARA AVISAR CASO HAJA UMA ALTERAÇÃO E SE TENTE SAIR DA PÁGINA SEM SALVAR
    //     if (JSON.stringify(this.data) === JSON.stringify(data_local)) {
    //       this.changed = false
    //     } else {
    //       this.changed = true
    //     }
    //   }
    // }
    // data: {
    //   deep: true,
    //   handler () {
    //     //OBSERVO DATA PARA ALTERAR OS DADOS NO MOMENTO EM QUE A PÁGINA É TROCADA
    //     this.data_local = JSON.parse(JSON.stringify(this.data))
    //     this.resetLocalData()
    //   }
    // }
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    this.localeText = localePt_Br
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTableContract.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }

    if (this.data_local.guarantor.address.state_id) {
      if (this.data_local.guarantor.address.city_id) {
        this.fillCity(this.data_local.guarantor.address.state_id, this.data_local.guarantor.address.city_id, 'cityGuarantorOptions', 'guarantor')
      } else {
        this.fillCity(this.data_local.guarantor.address.state_id, null, 'cityGuarantorOptions', 'guarantor')
      }
    }

  },
  created () {
    this.setGrid('contractStudentsData')

    // Register VUEX Modules
    if (!moduleContractStudent.isRegistered) {
      this.$store.registerModule('contractStudent', moduleContractStudent)
      moduleContractStudent.isRegistered = true
    }
    if (!moduleStudentManagement.isRegistered) {
      this.$store.registerModule('studentManagement', moduleStudentManagement)
      moduleStudentManagement.isRegistered = true
    }
    if (!moduleGuarantor.isRegistered) {
      this.$store.registerModule('guarantor', moduleGuarantor)
      moduleGuarantor.isRegistered = true
    }
    if (!moduleContractModel.isRegistered) {
      this.$store.registerModule('contractModel', moduleContractModel)
      moduleContractModel.isRegistered = true
    }
    this.$store.dispatch('contractModel/fetchAll', this.payload).catch(err => { console.error(err) })

    if (!moduleTypePayment.isRegistered) {
      this.$store.registerModule('typePayment', moduleTypePayment)
      moduleTypePayment.isRegistered = true
    }
    this.$store.dispatch('typePayment/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleAccount.isRegistered) {
      // this.$store.registerModule('account', moduleAccount)
      moduleAccount.isRegistered = true
    }
    this.$store.dispatch('account/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleAccountPlan.isRegistered) {
      // this.$store.registerModule('accountPlan', moduleAccountPlan)
      moduleAccountPlan.isRegistered = true
    }
    this.$store.dispatch('accountPlan/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleEmployee.isRegistered) {
      this.$store.registerModule('employee', moduleEmployee)
      moduleEmployee.isRegistered = true
    }
    this.$store.dispatch('employee/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleBank.isRegistered) {
      this.$store.registerModule('bank', moduleBank)
      moduleBank.isRegistered = true
    }
    this.$store.dispatch('bank/fetchAll').catch(err => { console.error(err) })

    this.debouncedGetSearchResult = _.debounce(this.getSearchResult, 600)
    this.resetLocalData()
  }
}
</script>
<style lang="scss">
  .ck-editor__editable {
    min-height: 650px;
  }
.popup50 .vs-popup {
  width: 50% !important;
}
.popup80 .vs-popup {
  width: 80% !important;
}
.popup90 .vs-popup {
  width: 90% !important;
}
.vue_select_drop_size_240 .vs__dropdown-menu {
  max-height: 240px;
}
.vue_select_drop_size_180 .vs__dropdown-menu {
  max-height: 180px;
}
.vue_select_drop_size_115 .vs__dropdown-menu {
  max-height: 115px;
}
.vue_select_drop_size_100 .vs__dropdown-menu {
  max-height: 100px;
}
.vs-tooltip {
  z-index: 99999;
}
.under:hover {
  text-decoration: underline;
}
.noOverflow .vs-popup--content {
  overflow: hidden;
}
.ag-floating-filter-button {
  margin-left: 0px !important;
}
</style>
