<template>
  <div class="w-full">
    <div class="vx-col w-full">
      <student-document-list
        :student-id="$route.params.studentId"
        ref="document"
      />
    </div>
  </div>
</template>

<script>
import StudentDocumentList from '@/components/register/student/document/StudentDocumentList'

export default {
  components: {
    StudentDocumentList
  },

  data () {
    return {
      popupObservation: {
        title: 'Observação'
      },
      dados: {
        value: ''
      },
      description: {
        value: ''
      },
      user: {
        value: ''
      },
      list: [
        {
          date: '',
          description: '',
          user: ''
        }
      ]
    }
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
.ck-editor__editable {
  min-height: 650px;
}
.con-vs-popup .vs-popup {
  width: 100%;
}
.noOverflow .vs-popup--content {
  overflow: hidden;
}
.popup80 .vs-popup {
  width: 80% !important;
}
.popup50 .vs-popup {
  width: 50% !important;
}
.vue_select_drop_size_90 .vs__dropdown-menu {
  max-height: 90px;
}
#user-edit-tab-documents th .vs-table-text {
  cursor: default;
}
#user-edit-tab-documents .vs-con-table .vs-con-tbody {
  border: 2px solid black;
}
.svg-hover:hover {
  fill: #158482;
}
.svg-hover {
  fill: #626262;
}
#user-edit-tab-documents td {
  border: 1px solid #c0c0c0;
  line-height: 1;
}
#user-edit-tab-documents td:hover {
  border-bottom: 1px solid #353434;
}
#user-edit-tab-documents td:first-child:hover {
  border-bottom: 1px solid #c0c0c0;
}
#user-edit-tab-documents .vs-con-table td:last-child {
  border-right: 1px solid transparent;
}
#user-edit-tab-documents .vs-table--thead {
  border: 1px solid transparent;
}
#user-edit-tab-documents
  .vs-con-table
  .vs-con-tbody
  .vs-table--tbody-table
  .tr-values
  .vs-table--td {
  padding: 6px;
}
#user-edit-tab-documents .ps__scrollbar-y-rail {
  z-index: 99999;
}
.vs-tooltip {
  z-index: 99999;
}
.under:hover {
  text-decoration: underline;
}
</style>

