import state from './moduleServiceHistoryState.js'
import mutations from './moduleServiceHistoryMutations.js'
import actions from './moduleServiceHistoryActions.js'
import getters from './moduleServiceHistoryGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

