<template>
  <div>
    <vs-popup class="popup80 noOverflow" :fullscreen="false" :title="showPdfTitle" :active.sync="popupShowPdf">
      <object :data="srcPdf" width="100%" style="height: 600px" />
    </vs-popup>

    <div class="flex flex-wrap items-center mt-8">
      <!-- ITEMS PER PAGE -->
      <div class="flex flex-grow">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ allData.total - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : allData.total || 0 }} de {{ allData.total || 0 }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="paginationSetPageSize(10)">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="paginationSetPageSize(20)">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="paginationSetPageSize(30)">
              <span>30</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="paginationSetPageSize(50)">
              <span>50</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <vx-tooltip text="Imprimir" position="top" class="ml-4 flex items-center cursor-pointer hover:text-primary">
          <feather-icon icon="PrinterIcon" @click="printExamScheduleReport" svg-classes="h-8 w-8"></feather-icon>
        </vx-tooltip>
      </div>

      <vx-tooltip text="Alterar Situação" position="top" class="flex items-center cursor-pointer hover:text-primary">
        <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
          <!-- <feather-icon icon="MenuIcon" svgClasses="h-5 w-5 mr-1" /> -->
          <vs-button icon-pack="feather" icon="icon-calendar" class="shadow-md mb-4 md:mb-0">Novo Agendamento</vs-button>
          <vs-dropdown-menu class="w-60">
            <vs-dropdown-item @click="$emit('openMedicalExam')">
              <span class="flex items-center">
                <div class="h-4 w-4 inline-block rounded-full mr-3 border-2 border-solid border-secondary"></div>
                <span>Exame Médico</span>
              </span>
            </vs-dropdown-item>

            <vs-dropdown-item @click="$emit('openPracticalExam')">
              <span class="flex items-center">
                <div class="h-4 w-4 inline-block rounded-full mr-3 border-2 border-solid border-secondary"></div>
                <span>Exame Prático</span>
              </span>
            </vs-dropdown-item>

            <vs-dropdown-item @click="$emit('openPsychotechnicalExam')">
              <span class="flex items-center">
                <div class="h-4 w-4 inline-block rounded-full mr-3 border-2 border-solid border-secondary"></div>
                <span>Exame Psicotécnico</span>
              </span>
            </vs-dropdown-item>

            <vs-dropdown-item @click="$emit('openTheoreticalExam')">
              <span class="flex items-center">
                <div class="h-4 w-4 inline-block rounded-full mr-3 border-2 border-solid border-secondary"></div>
                <span>Exame Teórico</span>
              </span>
            </vs-dropdown-item>

          </vs-dropdown-menu>
        </vs-dropdown>
      </vx-tooltip>

    </div>

    <!-- AgGrid Table -->
    <ag-grid-vue
      ref="agGridTable"
      :components="components"
      :gridOptions="gridOptions"
      @grid-ready="onGridReady"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :overlayLoadingTemplate="overlayLoadingTemplate"
      :overlayNoRowsTemplate="noRowsTemplate"
      :localeText="localeText"
      rowSelection="multiple"
      colResizeDefault="shift"
      :animateRows="true"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      :cacheBlockSize="payload.limit"
      :suppressPaginationPanel="true"
      :enableRtl="$vs.rtl">
    </ag-grid-vue>

    <vs-pagination :total="totalPages" :max="10" v-model="currentPage" />

  </div>
</template>

<script>

import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { AgGridVue }        from 'ag-grid-vue'
import { localePt_Br }      from '../../../../../assets/agGridTranslate/locale.pt_Br'
// Cell Renderer
import CellRendererDateTime from './cell-renderer/CellRendererDateTime.vue'
import CellRendererSituation from './cell-renderer/CellRendererSituation.vue'
// import CellRendererActions  from './cell-renderer-theoretical-class-schedule/CellRendererActions.vue'
//STORE
import moduleScheduleTheoreticalClass from '@/store/schedule-theoretical-class/moduleScheduleTheoreticalClass.js'

import PaginationMixin from '@/mixins/pagination'

export default {
  mixins: [PaginationMixin],
  components: {
    AgGridVue,
    CellRendererDateTime,
    CellRendererSituation
    // CellRendererActions

  },
  props: {
    student: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showPdfTitle: 'PDF',
      srcPdf: '',
      popupShowPdf: false,

      payload: {
        offset: 0,
        limit: 40,
        student_id: null,
        type: 'EXAM', // FILTRA TODOS OS AGENDAMENTOS DE EXAMES
        params: null,
        consultApi: true
      },
      // AgGrid
      gridApi: null,
      localeText: null,
      dataSource: {},
      gridOptions: {
        rowModelType: 'infinite'
      },
      overlayLoadingTemplate: 'Consultando dados...',
      noRowsTemplate: 'Nenhum dado foi encontrado',
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      components: {
        CellRendererDateTime,
        CellRendererSituation
        // CellRendererActions
      },

      columnDefs: [
        {
          headerName: 'Tipo',
          field: 'type',
          valueFormatter: params => params.value && (params.value === 'psychotechnical' ? 'Psicotécnico' : params.value === 'medical_exam' ? 'Médico' : params.value === 'practical_exam' ? 'Prático' : params.value === 'theoretical_exam' ? 'Teórico' : params.value),
          width: 150
        },
        {
          headerName: 'Data',
          field: 'start_date',
          width: 110,
          cellRendererFramework: 'CellRendererDateTime'
        },
        {
          headerName: 'Início',
          field: 'start_time',
          width: 90,
          valueFormatter: params => params.value && params.value.slice(0, 5)
        },
        {
          headerName: 'Fim',
          field: 'end_time',
          width: 90,
          valueFormatter: params => params.value && params.value.slice(0, 5)
        },
        {
          headerName: 'Médico',
          field: 'doctor.name',
          width: 200
        },
        {
          headerName: 'Categ.',
          field: 'category',
          width: 100,
          valueFormatter: params => params.value || '--'
        },
        {
          headerName: 'Nota',
          field: 'note',
          width: 100,
          valueFormatter: params => params.value || '--'
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 250,
          cellRendererFramework: 'CellRendererSituation',
          // onCellClicked: (event) => { this.updateStatus(event) }
          cellRendererParams: {
            updateStatus: this.updateStatus // usado para passar parametros para dentro o componente renderizado no ag grid
          }

        }
        // {
        //   headerName: 'Ações',
        //   field: 'transactions',
        //   width: 95,
        //   sortable: false,
        //   cellRendererFramework: 'CellRendererActions'
        // }
      ]
    }
  },
  computed: {
    allData () {
      const data = this.$store.state.studentManagement.studentListSchedules
      return data
    }
  },
  methods: {
    async printExamScheduleReport () {
      this.$vs.loading()
      this.showPdfTitle = 'Relatório de Agendamentos de Exames'
      const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/schedule/examSchedule/report`, {
        firstPeriod: null,
        lastPeriod: null,
        orderBy: 'date',
        format: 'PDF',
        payload: {
          students: [this.student.id],
          doctors: [],
          cacs: [],
          status: null,
          type: 'ALL'
        }
      }, { responseType: this.type === 'EXCEL' ? 'arraybuffer' : null })

      // if (this.type === 'PDF') {
      this.srcPdf = `data:application/pdf;base64,${resp.data}`
      this.popupShowPdf = true
      // } else if (this.type === 'EXCEL') {
      //   const blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      //   this.makeBlobDoc(blob, 'Relatório de Agendamentos de Aulas Práticas')
      // }
      this.$nextTick(() => { this.$vs.loading.close() })
    },
    refreshGrid (consultApi) {
      this.payload.consultApi = consultApi
      this.gridApi.refreshInfiniteCache()
    },

    async updateStatus (event, status) {
      const schedule = JSON.parse(JSON.stringify(event.data))
      schedule.status = status
      schedule.duration = '00:50'

      try {
        this.$vs.loading()

        // POSSO UTILIZAR O updateMany DE QQR UM DOS MÓDULOS POIS TODOS ACESSAM O MESMO CONTROLLER/TOTA NA API schedule/updateMany
        await this.$store.dispatch('scheduleTheoreticalClass/updateMany', [schedule])

        this.$store.commit('studentManagement/UPDATE_STUDENT_LIST_SCHEDULES', schedule)
        this.refreshGrid(false) // ATUALIZA O DATAGRID

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Alterados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) { console.error(error) }
    },

    onGridReady (params) {
      this.gridApi = params.api
      const self = this
      this.dataSource = {
        rowCount: null,
        async getRows (params) {
          self.payload.offset = params.startRow
          self.payload.params = params

          if (self.payload.consultApi) await self.getAllData()
          self.payload.consultApi = true //esta linha faz com que a próxima chamada sempre seja feita para a API

          let lastRow = -1
          if (self.allData.total <= params.endRow) {
            lastRow = self.allData.total
          }
          params.successCallback(self.allData.data, lastRow)
        }
      }
      this.gridApi.setDatasource(this.dataSource)
    },
    async getAllData () {
      this.gridApi.showLoadingOverlay()
      const data = await this.$store.dispatch('studentManagement/fetchStudentExamSchedules', this.payload)
      this.gridApi.hideOverlay()
      if (!data.data.total) this.gridApi.showNoRowsOverlay()
      return data
    }

  },
  mounted () {
    this.localeText = localePt_Br
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created () {
    this.setGrid('allData')
    this.payload.student_id = this.student.id
    if (!moduleScheduleTheoreticalClass.isRegistered) {
      this.$store.registerModule('scheduleTheoreticalClass', moduleScheduleTheoreticalClass)
      moduleScheduleTheoreticalClass.isRegistered = true
    }
  }
}
</script>
<style>
.popup80 .vs-popup {
  width: 80% !important;
}
</style>
