var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup400",
          attrs: { title: "Formulário de taxa", active: _vm.popupForm },
          on: {
            "update:active": function ($event) {
              _vm.popupForm = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "min-height": "150px" } },
            [
              _c("form-debit-student", {
                attrs: { "student-id": _vm.$route.params.studentId },
                on: { saved: _vm.afterEditRegister },
                model: {
                  value: _vm.debit,
                  callback: function ($$v) {
                    _vm.debit = $$v
                  },
                  expression: "debit",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "vs-table",
        {
          staticStyle: { overflow: "-webkit-paged-y" },
          attrs: {
            data: _vm.services,
            hoverFlat: "",
            noDataText: "Nenhum dado encontrado",
            stripe: "",
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ data }) {
                return _vm._l(data, function (service, i) {
                  return _c(
                    "vs-tr",
                    { key: i, staticStyle: { opacity: "1 !important" } },
                    [
                      _c(
                        "vs-td",
                        [
                          _c(
                            "vs-tooltip",
                            { attrs: { text: "Tooltip Default" } },
                            [
                              _c("vs-button", {
                                attrs: { type: "flat", icon: "edit" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editRecord(service)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("vs-td", [
                        _vm._v(
                          "\n        " + _vm._s(service.name) + "\n      "
                        ),
                      ]),
                      _c("vs-td", [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.mixinCurrency(service.value)) +
                            "\n      "
                        ),
                      ]),
                      _c("vs-td", [
                        _vm._v(
                          "\n        " +
                            _vm._s(service.situation_formated) +
                            "\n      "
                        ),
                      ]),
                      _c("vs-td", [
                        _vm._v(
                          "\n        " +
                            _vm._s(service.observation) +
                            "\n      "
                        ),
                      ]),
                    ],
                    1
                  )
                })
              },
            },
          ]),
        },
        [
          _c("template", { slot: "header" }, [
            _c("h3", [_vm._v("\n        Taxas\n      ")]),
          ]),
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [_vm._v("Ações")]),
              _c("vs-th", [_vm._v("Descrição")]),
              _c("vs-th", [_vm._v("Valor")]),
              _c("vs-th", [_vm._v("Situação")]),
              _c("vs-th", [_vm._v("Observação")]),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }