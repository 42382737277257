var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          attrs: { active: _vm.localActive, title: "Cancelamento de contrato" },
          on: {
            "update:active": function ($event) {
              _vm.localActive = $event
            },
          },
        },
        [
          _c("cancel-contract-form", {
            attrs: { contractId: _vm.contractId },
            on: { saved: this.saved },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }