<template>
  <div id="user-edit-tab-info">
    <vs-popup class="popup80 noOverflow" :fullscreen="false" :title="showPdfTitle" :active.sync="popupShowPdf">
      <object :data="srcPdf" width="100%" style="height: 600px" />
    </vs-popup>

    <dialog-sig :show="popupCnhDeleteConfirm" title="ALERTA" icon="warning"
      @action="dispatchDeleteCnh(cnhDeleteId)"
      @cancel="popupCnhDeleteConfirm = false">
        Tem certeza que deseja excluir esta CNH? <br>
        Esta ação é irreversível
    </dialog-sig>

    <vs-popup class="popup80" :fullscreen="false" title="Cadastrar nova CNH" :active.sync="popupStoreCnh">
        <div class="vx-row">
          <div class="vx-col md:w-1/5 w-full mt-2">
            <vs-input class="w-full" label="Registro" v-model="cnh.registro_cnh" />
          </div>
          <div class="vx-col md:w-1/5 w-full mt-2">
            <vs-input class="w-full" label="Cód. Segurança" v-model="cnh.security_code" />
          </div>
          <div class="vx-col md:w-1/5 mt-2">
            <vs-input type="date" class="w-full" label="Emissão" @change="autoSetValidityCnh" v-model="cnh.date_last_cnh" data-vv-scope="newCNH" v-validate="'date_format:yyyy-MM-dd'" data-vv-as="Emissão" name="date_last_cnh" />
            <span class="text-danger text-sm" v-show="errors.has('newCNH.date_last_cnh')">Formato incorreto</span>
          </div>
          <div class="vx-col md:w-1/5 mt-2">
            <vs-input type="date" class="w-full" label="Vencimento" v-model="cnh.validity_cnh" data-vv-scope="newCNH" v-validate="'date_format:yyyy-MM-dd'" data-vv-as="Emissão" name="validity_cnh" />
            <span class="text-danger text-sm" v-show="errors.has('newCNH.validity_cnh')">Formato incorreto</span>
          </div>
          <div class="vx-col md:w-1/5 w-full mt-2">
            <label class="vs-input--label">Categoria</label>
            <v-select v-model="cnh.current_category_cnh" :options="categoryCNHOptions" :reduce="option => option.value" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-1/5 w-full mt-2">
            <label class="vs-input--label">Tipo</label>
            <v-select v-model="cnh.type_cnh" :options="typeCNHOptions" :reduce="option => option.value" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
          <div class="vx-col md:w-1/5 w-full mt-2">
            <label class="vs-input--label">Cursos</label>
            <v-select v-model="cnh.specialized_courses" :options="specializedCoursesCNHOptions" :reduce="option => option.value" placeholder="Selecione" multiple :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
          <div class="vx-col md:w-1/5 mt-2">
            <vs-input type="date" class="w-full" label="Toxicológico" @change="autoSetValidityToxicological" v-model="cnh.toxicological" data-vv-scope="newCNH" v-validate="'date_format:yyyy-MM-dd'" data-vv-as="Toxicológico" name="toxicological" />
            <span class="text-danger text-sm" v-show="errors.has('newCNH.toxicological')">Formato incorreto</span>
          </div>
          <div class="vx-col md:w-1/5 mt-2">
            <vs-input type="date" class="w-full" label="Venc. Toxicológico" v-model="cnh.validity_toxicological" data-vv-scope="newCNH" v-validate="'date_format:yyyy-MM-dd'" data-vv-as="Toxicológico" name="validity_toxicological" />
            <span class="text-danger text-sm" v-show="errors.has('newCNH.validity_toxicological')">Formato incorreto</span>
          </div>
          <div class="vx-col md:w-1/5 w-full mt-2">
            <div>
              <label class="vs-input--label">EAR</label>
              <v-select v-model="cnh.gainful_activity" :clearable="false" :reduce="option => option.value" :options="[{value:0, label: 'NÃO'}, {value:1, label: 'SIM'}]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>

              <!-- <label class="text-sm">EAR</label>
              <div class="mt-2">
                <vs-checkbox v-model="cnh.gainful_activity" :vs-value="1" class="mr-4 mb-2">{{ !!cnh.gainful_activity ? 'SIM' : 'NÃO'}}</vs-checkbox>
              </div> -->
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-full mt-2">
            <vs-input class="w-full" label="Observação" v-model="cnh.observation" />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-2/5 w-full mt-2">
            <label class="vs-input--label">Cidade</label>
            <v-select class="vue_select_drop_size_city_cnh_id" v-model="cnh.city_cnh_id" :reduce="option => option.value" :placeholder="cityCnhOptions.length ? 'Selecione' : 'Selecione um estado'" :options="cityCnhOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
          <div class="vx-col md:w-1/5 w-full mt-2">
            <label class="vs-input--label">Estado</label>
            <v-select class="vue_select_drop_size_city_cnh_id" v-model="cnh.state_cnh_id" :reduce="option => option.value" :options="states" @input="fillCity(cnh.state_cnh_id, null, 'cityCnhOptions', 'cnh'), delete cnh.city_cnh_id" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
          <div class="vx-col md:w-1/5 w-full mt-2">
            <label class="vs-input--label">Situação</label>
            <v-select v-model="cnh.situation" :options="typeSituationOptions" :reduce="option => option.value" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
          <div class="vx-col md:w-1/5 w-full mt-2">
            <vs-input type="date" class="w-full" label="Data Entrega" v-model="cnh.delivery_date" data-vv-scope="newCNH" v-validate="'date_format:yyyy-MM-dd'" data-vv-as="Data Entrega" name="delivery_date" />
            <span class="text-danger text-sm" v-show="errors.has('newCNH.delivery_date')">Formato incorreto</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-3/5 w-full mt-2">
            <vs-input class="w-full" label="Responsável Pela Retirada" v-model="cnh.responsible_withdrawal" />
          </div>
          <div class="vx-col md:w-1/5 w-full">
            <vs-input class="w-full mt-2" v-model="cnh.responsible_withdrawal_phone" v-mask="['(##)#####-####','(##)####-####']" label="Telefone" />
          </div>
          <div class="vx-col md:w-1/5 mt-2 mb-16">
            <div class="mt-5 flex flex-wrap items-center justify-end">
              <vs-button class="mt-0 w-full" @click="storeCnh()" :disabled="!validateFormNewCNH || disableCnhSave">Cadastrar CNH</vs-button>
            </div>
          </div>
        </div>
    </vs-popup>

    <vs-popup id="popupShowTenant" class="popup80" :fullscreen="false" :title="showTenantTitle" :active.sync="popupShowTenant">

      <dialog-sig :show="popupTenantDeleteConfirm" title="ALERTA" icon="warning"
        @action="dispatchDeleteTenant"
        @cancel="popupTenantDeleteConfirm = false">
          Tem certeza que deseja excluir o locatário? <br>
          Esta ação é irreversível
      </dialog-sig>

      <div class="vx-row">
        <div class="vx-col md:w-full w-full mt-0">
          <vs-input class="w-full mt-0" label="Nome Locatário" v-model="data_local.tenant.name" data-vv-as="Nome Locatário" data-vv-scope="tenant" v-validate.initial="'required|alpha_spaces'" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('tenant.name')">{{ errors.first('tenant.name') }}</span>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full mt-2">
          <vs-input class="w-full" label="CPF/CNPJ" @keyup="searchTenant" v-model="data_local.tenant.cpf" v-mask="['###.###.###-##','##.###.###/####-##']" data-vv-as="CPF" data-vv-scope="tenant" v-validate="'cpf'" name="cpf" />
          <span class="text-danger text-sm" v-show="errors.has('tenant.cpf')">{{ errors.first('tenant.cpf') }}</span>
        </div>

        <div class="vx-col md:w-1/4 w-full mt-2">
          <vs-input class="w-full" label="RG/IE" v-model="data_local.tenant.rg" />
        </div>

        <div class="vx-col md:w-1/4 w-full mt-0">
          <vs-input class="w-full" label="Órgão Emissor RG" v-model="data_local.tenant.orgao_emissor_rg" />
        </div>

        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Estado RG</label>
          <v-select class="vue_select_drop_size_orgao_emissor_rg_uf_id" :reduce="option => option.value" v-model="data_local.tenant.orgao_emissor_rg_uf_id" :clearable="true" :options="states" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <div class="flex items-end mt-2">
        <feather-icon icon="MapPinIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Endereço</span>
      </div>
      <hr>

      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full mt-2">
          <vs-input class="w-full" label="CEP" placeholder="CEP" v-model="data_local.tenant.address.zip_code" v-mask="['#####-###']" @keyup="searchZipCode" />
        </div>

        <div class="vx-col md:w-1/4 w-full mt-2">
          <label class="vs-input--label">Logradouro</label>
          <v-select v-model="data_local.tenant.address.logradouro" :reduce="option => option.value" clearable :options="logradouroOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>

        <div class="vx-col md:w-1/4 w-full mt-2">
          <vs-input class="w-full" label="Endereço" v-model="data_local.tenant.address.street" />
        </div>

        <div class="vx-col md:w-1/4 w-full mt-2">
          <vs-input class="w-full" label="Número" v-model="data_local.tenant.address.number" type="number" placeholder="S/N" />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full">
          <vs-input class="w-full mt-2" label="Complemento" v-model="data_local.tenant.address.complement" />
        </div>
        <div class="vx-col md:w-1/5 w-full">
          <vs-input class="w-full mt-2" label="Bairro" v-model="data_local.tenant.address.neighborhood" />
        </div>
        <div class="vx-col md:w-1/4_ w-full mt-2">
          <label class="vs-input--label">Cidade</label>

          <v-select class="vue_select_drop_size_tenant_city_id" :reduce="option => option.value" v-model="data_local.tenant.address.city_id"
            :placeholder="cityTenantOptions.length ? 'Selecione' : 'Selecione um estado'" :options="cityTenantOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-2">
          <label class="vs-input--label">Estado</label>
          <v-select class="vue_select_drop_size_tenant_state_id" :reduce="option => option.value"
            v-model="data_local.tenant.address.state_id"
            @input="fillCity(data_local.tenant.address.state_id, null, 'cityTenantOptions', 'tenant'), data_local.tenant.address.city_id = null"
            :options="states" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-2">
          <label class="vs-input--label">País</label>
          <v-select v-model="data_local.tenant.address.country" :reduce="option => option.value" :clearable="false" :options="countryOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <div class="flex items-end mt-2">
        <feather-icon icon="PhoneIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Contato</span>
      </div>
      <hr>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full" v-for="(phone,i) in data_local.tenant.phones" :key="i">
          <vs-input class="w-full mt-2" v-mask="['(##)#####-####','(##)####-####']" :label="phone.type === 'phone' ? 'Telefone' : phone.type === 'mobile' ? 'Celular' : phone.type === 'other' ? 'Outro' : ''" v-model="phone.phone" />
        </div>
        <div class="vx-col md:w-2/5 w-full">
          <vs-input class="w-full mt-2" label="E-mail" data-vv-as="E-mail" data-vv-scope="tenant" v-model="data_local.tenant.email" type="email" v-validate="'email'" name="email" />
          <span class="text-danger text-sm" v-show="errors.has('tenant.email')">{{ errors.first('tenant.email') }}</span>
        </div>
      </div>

      <!-- Save & Reset Button -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button v-if="data_local.tenant.id" class="ml-auto mt-2" color="danger" @click="deleteTenant">Deletar Locatário</vs-button>
            <vs-button class="ml-4 mt-2" @click="saveTenant" :disabled="!validateTenantForm || disableTenantSave">Salvar Locatário</vs-button>
          </div>
        </div>
      </div>

    </vs-popup>

    <div class="vx-row">
      <div class="vx-col md:w-1/5 w-full mt-0">
        <label class="vs-input--label">Estado de Nascimento</label>
        <v-select v-model="data_local.state_nasc_id" :reduce="option => option.value" @input="fillCity(data_local.state_nasc_id), data_local.city_nasc_id = null" :options="states" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
          <template #no-options="{}">
            Desculpe, nenhum resultado encontrado.
          </template>
        </v-select>
      </div>
      <div class="vx-col md:w-1/5 w-full mt-0">
        <label class="vs-input--label">Cidade de Nascimento</label>
        <v-select v-model="data_local.city_nasc_id" :reduce="option => option.value" :placeholder="cityOptions.length ? 'Selecione' : 'Selecione um estado'" :options="cityOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
          <template #no-options="{}">
            Desculpe, nenhum resultado encontrado.
          </template>
        </v-select>
      </div>

      <div class="vx-col md:w-1/5 w-full mt-0">
        <label class="vs-input--label">Nacionalidade</label>
        <v-select v-model="data_local.nationality" :reduce="option => option.value" :clearable="true" :options="nationalityOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
          <template #no-options="{}">
            Desculpe, nenhum resultado encontrado.
          </template>
        </v-select>
      </div>

      <div class="vx-col md:w-1/5 w-full mt-0">
        <vs-input class="w-full" label="Órgão Emissor RG" v-model="data_local.orgao_emissor_rg" />
      </div>

      <div class="vx-col md:w-1/5 w-full mt-0">
        <label class="vs-input--label">Estado RG</label>
        <v-select v-model="data_local.orgao_emissor_rg_uf_id" :reduce="option => option.value" :options="states" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
          <template #no-options="{}">
            Desculpe, nenhum resultado encontrado.
          </template>
        </v-select>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-1/4 w-full mt-2">
        <label class="vs-input--label">Escolaridade</label>
        <v-select v-model="data_local.education" :reduce="option => option.value" :options="educationOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
          <template #no-options="{}">
            Desculpe, nenhum resultado encontrado.
          </template>
        </v-select>
      </div>

      <div class="vx-col md:w-1/4 w-full mt-2">
        <vs-input class="w-full" label="Profissão" v-model="data_local.occupation" />
      </div>

      <div class="vx-col md:w-1/4 w-full mt-2">
        <vs-input class="w-full" label="Local de Trabalho" v-model="data_local.workplace" />
      </div>

      <div class="vx-col md:w-1/4 w-full mt-2">
        <label class="vs-input--label">Estado Civil</label>
        <v-select appendToBody v-model="data_local.marital_status" :reduce="option => option.value" :options="maritalStatusOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
          <template #no-options="{}">
            Desculpe, nenhum resultado encontrado.
          </template>
        </v-select>
      </div>
    </div>

    <div class="flex items-end mt-2">
      <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
      <span class="leading-none font-medium">Filiação</span>
    </div>
    <hr>

    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full mt-2">
        <vs-input class="w-full" label="Nome Mãe" v-model="data_local.mothers_name" />
      </div>
      <div class="vx-col md:w-1/2 w-full mt-2">
        <vs-input class="w-full" label="Nome Pai" v-model="data_local.fathers_name" />
      </div>
    </div>

    <div class="flex items-end mt-2">
      <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
      <span class="leading-none font-medium">Locatário</span>
    </div>
    <hr>

    <div class="vx-row flex flex-wrap items-center">
      <div class="vx-col md:w-1/4 w-full mt-0">
        <span>{{this.$store.state.studentManagement.student.tenant ? this.$store.state.studentManagement.student.tenant.name : ''}}</span>
      </div>
      <div class="vx-col md:w-1/4 w-full mt-0">
        <span>{{this.$store.state.studentManagement.student.tenant && this.$store.state.studentManagement.student.tenant.phones && this.$store.state.studentManagement.student.tenant.phones.find(phone => phone.type === 'mobile') ? this.$store.state.studentManagement.student.tenant.phones.find(phone => phone.type === 'mobile').phone : ''}}</span>
      </div>
      <div class="vx-col md:w-1/2 w-full mt-0">
        <vs-button @click="handleTenant" type="line" :icon="data_local.tenant.id ? 'create' : 'add_circle_outline'" class="ml-auto sizedIcon">{{data_local.tenant.id ? 'Alterar' : 'Cadastrar'}} Locatário</vs-button>
      </div>
    </div>

    <div class="flex items-end mt-2">
      <feather-icon icon="FolderIcon" class="mr-2" svgClasses="w-5 h-5" />
      <span class="leading-none font-medium">Cadastro CNH</span>
    </div>
    <hr>

    <div class="vx-row">
      <div class="vx-col w-full mt-2">
        <vs-button @click="handleCnh" type="line" icon="add_circle_outline" class="ml-auto sizedIcon">Cadastrar Nova CNH</vs-button> <!-- create | add_circle_outline -->
      </div>
    </div>

    <vs-collapse accordion type="margin">
      <vs-collapse-item v-for="(cnh, i) in data_local.cnhs" :key="`${i}_cnh`" :open="i === 0">
        <div slot="header">CNH Registro: {{ cnh.registro_cnh }} {{ cnh.date_last_cnh ? `| Emitida em: ${formatDateTime(cnh.date_last_cnh, 'date')}` : '' }}</div>
          <div class="vx-row">
            <div class="vx-col md:w-1/5 w-full mt-2">
              <vs-input class="w-full" label="Registro" v-model="cnh.registro_cnh" />
            </div>
            <div class="vx-col md:w-1/5 w-full mt-2">
              <vs-input class="w-full" label="Cód. Segurança" v-model="cnh.security_code" />
            </div>
            <div class="vx-col md:w-1/5 mt-2">
              <vs-input type="date" class="w-full" label="Emissão" @change="autoSetValidityCnhArray(i)" v-model="cnh.date_last_cnh" />
            </div>
            <div class="vx-col md:w-1/5 mt-2">
              <vs-input type="date" class="w-full" label="Vencimento" v-model="cnh.validity_cnh" />
            </div>
            <div class="vx-col md:w-1/5 w-full mt-2">
              <label class="vs-input--label">Categoria</label>
              <v-select appendToBody v-model="cnh.current_category_cnh" :options="categoryCNHOptions" :reduce="option => option.value" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-1/5 w-full mt-2">
              <label class="vs-input--label">Tipo</label>
              <v-select appendToBody v-model="cnh.type_cnh" :options="typeCNHOptions" :reduce="option => option.value" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
            <div class="vx-col md:w-1/5 w-full mt-2">
              <label class="vs-input--label">Cursos</label>
              <v-select appendToBody v-model="cnh.specialized_courses" :options="specializedCoursesCNHOptions" :reduce="option => option.value" placeholder="Selecione" multiple :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
              <div class="vx-col md:w-1/5 mt-2">
              <vs-input type="date" class="w-full" label="Toxicológico" @change="autoSetValidityToxicologicalArray(i)" v-model="cnh.toxicological" />
            </div>
            <div class="vx-col md:w-1/5 mt-2">
              <vs-input type="date" class="w-full" label="Venc. Toxicológico" v-model="cnh.validity_toxicological" />
            </div>
            <div class="vx-col md:w-1/5 w-full mt-2">
              <div>
                <label class="vs-input--label">EAR</label>
                <v-select appendToBody v-model="cnh.gainful_activity" :clearable="false" :reduce="option => option.value" :options="[{value:0, label: 'NÃO'}, {value:1, label: 'SIM'}]" @input="fillCityCnh(cnh.state_cnh_id, null, i), cnh.city_cnh_id = null" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                  <template #no-options="{}">
                    Desculpe, nenhum resultado encontrado.
                  </template>
                </v-select>

                <!-- <label class="text-sm">EAR</label>
                <div class="mt-2">
                  <vs-checkbox v-model="cnh.gainful_activity" :vs-value="1" class="mr-4 mb-2">{{ !!cnh.gainful_activity ? 'SIM' : 'NÃO'}}</vs-checkbox>
                </div> -->
              </div>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-full mt-2">
              <vs-input class="w-full" label="Observação" v-model="cnh.observation" />
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-2/5 w-full mt-2">
              <label class="vs-input--label">Cidade</label>
              <v-select appendToBody v-model="cnh.city_cnh_id" :reduce="option => option.value" :placeholder="cnh.cityCnhOptions ? 'Selecione' : 'Selecione um estado'" :options="cnh.cityCnhOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
            <div class="vx-col md:w-1/5 w-full mt-2">
              <label class="vs-input--label">Estado</label>
              <v-select appendToBody v-model="cnh.state_cnh_id" :reduce="option => option.value" :options="states" @input="fillCityCnh(cnh.state_cnh_id, null, i), cnh.city_cnh_id = null" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
            <div class="vx-col md:w-1/5 w-full mt-2">
              <label class="vs-input--label">Situação</label>
              <v-select appendToBody v-model="cnh.situation" :options="typeSituationOptions" :reduce="option => option.value" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
            <div class="vx-col md:w-1/5 w-full mt-2">
              <vs-input type="date" class="w-full" label="Data Entrega" v-model="cnh.delivery_date" />
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-2/5 w-full mt-2">
              <vs-input class="w-full" label="Responsável Pela Retirada" v-model="cnh.responsible_withdrawal" />
            </div>
            <div class="vx-col md:w-1/5 w-full">
              <vs-input class="w-full mt-2" v-model="cnh.responsible_withdrawal_phone" v-mask="['(##)#####-####','(##)####-####']" label="Telefone" />
            </div>
            <div class="vx-col md:w-1/5 mt-2 mb-16">
              <div class="mt-5 flex flex-wrap items-center justify-end">
                <vs-button class="mt-0" @click="updateCnh(cnh)" :disabled="disableCnhSave">Alterar Esta CNH</vs-button>
              </div>
            </div>
            <div class="vx-col md:w-1/5 mt-2 mb-16">
              <div class="mt-5 flex flex-wrap items-center justify-end">
                <vs-button color="danger" class=" mt-0" @click="deleteCnh(cnh)" :disabled="!validateForm">Excluir Esta CNH</vs-button>
              </div>
            </div>
        </div>
      </vs-collapse-item>
    </vs-collapse>

      <!-- VERIFICAR ESTE CAMPO


      <div class="vx-col md:w-1/6 mt-2">
        <vs-input type="date" class="w-full" label="Cadastro" v-model="data_local.registration_date" data-vv-as="Data de cadastro" v-validate="'required'" name="registration_date" />
        <span class="text-danger text-sm" v-show="errors.has('registration_date')">{{ errors.first('registration_date') }}</span>
      </div>


      -->


    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="saveChanges" :disabled="!validateForm || disableSave">Salvar Alterações Gerais</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="warning" @click="resetLocalData">Resetar</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import axios from '@/axios.js'
import dialogSig from '@/components/dialog/dialogSig.vue'
import formatDateTime from '@/util/formatDateTime.js'
import moment from 'moment'
// import _ from 'lodash'
//STORE
import moduleStudentManagement from '@/store/student-management/moduleStudentManagement.js'
import moduleTenant from '@/store/tenant/moduleTenant.js'
import moduleCnh from '@/store/cnh/moduleCnh.js'

export default {
  components: { vSelect, dialogSig },
  props: {
    states: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      showPdfTitle: 'PDF',
      srcPdf: '',
      popupShowPdf: false,

      data_local: {},

      showTenantTitle: '',
      popupShowTenant: false,
      disableTenantSave: false,
      popupTenantDeleteConfirm: false,

      cnh: {
        registro_cnh: '',
        security_code: '',
        date_last_cnh: '',
        validity_cnh: '',
        current_category_cnh: null,
        type_cnh: null,
        specialized_courses: [],
        toxicological: '',
        validity_toxicological: '',
        observation: '',
        city_cnh_id: null,
        state_cnh_id: null,
        gainful_activity: false,
        situation: null,
        delivery_date: '',
        responsible_withdrawal: '',
        responsible_withdrawal_phone: null
      },
      disableCnhSave: false,
      popupCnhDeleteConfirm: false,
      cnhDeleteId: null,
      popupStoreCnh: false,

      changed: false,
      changedCnh: false,
      changedTenant: false,
      disableSave: false,

      cityOptions:[],
      cityTenantOptions:[],
      cityCnhOptions: [],

      nationalityOptions: [
        { label: 'Brasileira',  value: '1' },
        { label: 'Naturalizado',  value: '2' },
        { label: 'Estrangeiro',  value: '3' }
      ],

      maritalStatusOptions: [
        { label: 'Solteiro', value: 'single' },
        { label: 'Casado', value: 'married' },
        { label: 'Divorciado', value: 'separated' },
        { label: 'Convivente', value: 'cohabitant' },
        { label: 'Viúvo', value: 'widower' }
      ],

      educationOptions: [
        { label: '1º Grau Incompl.', value: '1' },
        { label: '1º Grau Compl.',   value: '2' },
        { label: '2º Grau Incompl.', value: '3' },
        { label: '2º Grau Compl.',   value: '4' },
        { label: '3º Grau Incompl.', value: '5' },
        { label: '3º Grau Compl.',   value: '6' }
      ],

      categoryCNHOptions: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'AB', value: 'AB' },
        { label: 'C', value: 'C' },
        { label: 'D', value: 'D' },
        { label: 'E', value: 'E' },
        { label: 'AC', value: 'AC' },
        { label: 'AD', value: 'AD' },
        { label: 'AE', value: 'AE' },
        { label: 'ACC', value: 'ACC' }
      ],

      specializedCoursesCNHOptions: [
        { label: 'Produtos perigosos', value: 11 },
        { label: 'Escolar', value: 12 },
        { label: 'Coletivo', value: 13 },
        { label: 'Emergência', value: 14 }
        // { label: 'EAR', value: 15 }
      ],

      typeCNHOptions: [
        { label: 'DEFINITIVA', value: 'DEFINITIVE' },
        { label: 'PERMISSÃO', value: 'PERMISSION' }
      ],

      typeSituationOptions: [
        { label: 'AGUARDA RETIRADA', value: 'WAITING_WITHDRAWAL' },
        { label: 'ENTREGUE', value: 'DELIVERED' },
        { label: 'NÃO SOLICITADA', value: 'NOT_REQUESTED' },
        { label: 'SOLICITADA', value: 'REQUESTED' }
      ],

      logradouroOptions: [
        { label: 'Rua', value: 'Rua' },
        { label: 'Avenida', value: 'Avenida' },
        { label: 'Rodovia', value: 'Rodovia' },
        { label: 'Estrada', value: 'Estrada' },
        { label: 'Lote', value: 'Lote' },
        { label: 'Servidão', value: 'Servidão' },
        { label: 'Quadra', value: 'Quadra' },
        { label: 'Travessa', value: 'Travessa' },
        { label: 'Linha', value: 'Linha' }
      ],
      countryOptions: [{ label: 'Brasil', value: 1 }]

    }
  },
  computed: {
    validateForm () {
      return !this.errors.any('main')
    },
    validateTenantForm () {
      return !this.errors.any('tenant')
    },
    validateFormNewCNH () {
      return !this.errors.any('newCNH')
    },
    intendedCategory () {
      const intended_service = this.$store.state.servicePackage.servicePackages.find(e => e.id === this.data_local.intended_services)
      return intended_service ? intended_service.category_cnh : null
    },
    user () {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    formatDateTime (dateTimeString, type = null) {
      return formatDateTime(dateTimeString, type)
    },
    autoSetValidityCnh () {
      if (moment(this.cnh.date_last_cnh).year() > 1000) {
        const validityCnh = moment(this.cnh.date_last_cnh).add(10, 'years').format('YYYY-MM-DD')
        Object.assign(this.cnh, {validity_cnh: validityCnh })
      }
    },
    autoSetValidityCnhArray (i) {
      if (moment(this.data_local.cnhs[i].date_last_cnh).year() > 1000) {
        const validityCnh = moment(this.data_local.cnhs[i].date_last_cnh).add(10, 'years').format('YYYY-MM-DD')
        this.data_local.cnhs[i].validity_cnh = validityCnh
      }
    },
    autoSetValidityToxicological () {
      if (moment(this.cnh.toxicological).year() > 1000) {
        const validity = moment(this.cnh.toxicological).add(29, 'month').format('YYYY-MM-DD')
        Object.assign(this.cnh, {validity_toxicological: validity })
      }
    },
    autoSetValidityToxicologicalArray (i) {
      if (moment(this.data_local.cnhs[i].toxicological).year() > 1000) {
        const validity = moment(this.data_local.cnhs[i].toxicological).add(29, 'month').format('YYYY-MM-DD')
        this.data_local.cnhs[i].validity_toxicological = validity
      }
    },
    async searchTenant () {
      const identification = this.data_local.tenant.cpf
      if ((identification.length === 14 || identification.length === 18) && !this.errors.has('tenant.cpf')) {
        const cpf_cnpj = identification.replace(/\D/g, '')
        try {
          const resp = await this.$http.get(`${process.env.VUE_APP_API_URL}/tenant/getByCpfCnpj/${cpf_cnpj}`)
          if (resp.data.name) {
            this.data_local.tenant = resp.data
            this.data_local.tenant.address.country = 1

            const sortPhonesBy = ['mobile', 'phone', 'other']

            if (!this.data_local.tenant.phones) {
              this.data_local.tenant.phones = [
                {type: 'mobile', phone: null},
                {type: 'phone', phone: null},
                {type: 'other', phone: null}
              ]
            } else {
              // AJUSTANDO OS TELEFONES VAZIOS
              if (!this.data_local.tenant.phones.filter(phone => phone.type === 'mobile').length) {
                this.data_local.tenant.phones.unshift({id: null, phone: null, type: 'mobile' })
              }
              if (!this.data_local.tenant.phones.filter(phone => phone.type === 'phone').length) {
                this.data_local.tenant.phones.push({id: null, phone: null, type: 'phone' })
              }
              if (!this.data_local.tenant.phones.filter(phone => phone.type === 'other').length) {
                this.data_local.tenant.phones.push({id: null, phone: null, type: 'other' })
              }
              this.data_local.tenant.phones.sort((a, b) => sortPhonesBy.indexOf(a.type) - sortPhonesBy.indexOf(b.type))
            }
            this.errors.remove('tenant.name')
          }
        } catch (error) {
          console.log(error)
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    handleTenant () {
      this.showTenantTitle = 'Salvar Locatário'
      this.resetTenant()
      if (!this.data_local.tenant.id) {
        //ESTADO DEFAULT SC
        this.data_local.tenant.address.state_id = 24
        this.fillCity(this.data_local.tenant.address.state_id, null, 'cityTenantOptions', 'tenant')
      }
      this.popupShowTenant = true
    },
    saveChanges () {
      if (!this.validateForm) return

      // Loading
      this.$vs.loading()
      this.disableSave = true
      setTimeout(() => { this.disableSave = false }, 3000) //TRAVA O BOTÃO SALVAR POR 3 SEGUNDOS

      this.changed = false //salvou por isso não preciso alertar ao trocar de página

      this.$store.dispatch('studentManagement/updateStudent', this.data_local)
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Salvos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(error => {
          this.$vs.loading.close()
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        })
    },

    saveTenant () {
      Object.assign(this.data_local.tenant, {student_id: this.data_local.id})
      // Loading
      this.$vs.loading()
      this.disableTenantSave = true
      setTimeout(() => { this.disableTenantSave = false }, 3000) //TRAVA O BOTÃO SALVAR POR 3 SEGUNDOS

      this.$store.dispatch('tenant/saveTenant', this.data_local.tenant)
        .then((resp) => {
          this.$store.commit('studentManagement/SET_STUDENT', {tenant: resp.data})
          this.resetTenant()
          this.$vs.loading.close()
          this.popupShowTenant = false
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Salvos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },

    deleteTenant () {
      this.popupTenantDeleteConfirm = true
    },
    dispatchDeleteTenant () {
      this.$store.dispatch('tenant/delete', this.data_local.tenant.id)
        .then(() => {
          this.$store.commit('studentManagement/SET_STUDENT', {tenant: null})
          this.resetTenant()
          this.popupTenantDeleteConfirm = false
          this.popupShowTenant = false
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Excluídos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },

    handleCnh () {
      this.cnh = {
        registro_cnh: '',
        security_code: '',
        date_last_cnh: '',
        validity_cnh: '',
        current_category_cnh: null,
        type_cnh: null,
        specialized_courses: [],
        toxicological: '',
        validity_toxicological: '',
        observation: '',
        city_cnh_id: null,
        state_cnh_id: null,
        gainful_activity: false,
        situation: null,
        delivery_date: '',
        responsible_withdrawal: '',
        responsible_withdrawal_phone: null
      }
      this.cnh.current_category_cnh = this.intendedCategory
      //ESTADO DEFAULT SC
      this.cnh.state_cnh_id = 24
      this.fillCity(this.cnh.state_cnh_id, null, 'cityCnhOptions', 'cnh')
      this.popupStoreCnh = true
    },
    async printTermoDeEntregaCnh () {
      this.showPdfTitle = 'Termo de Entrega CNH'
      const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/document/pdf/entregacnh`, { student_id: [this.data_local.id] })
      this.srcPdf = `data:application/pdf;base64,${resp.data}`
      this.popupShowPdf = true
    },
    storeCnh () {
      // Loading
      this.$vs.loading()
      this.disableCnhSave = true
      setTimeout(() => { this.disableCnhSave = false }, 3000) //TRAVA O BOTÃO SALVAR POR 3 SEGUNDOS
      Object.assign(this.cnh, {student_id: this.data_local.id})
      this.$store.dispatch('cnh/storeCnh', this.cnh)
        .then(async (resp) => {
          this.$store.commit('studentManagement/ADD_STUDENT_CNH', resp.data)
          if (this.cnh.delivery_date) await this.printTermoDeEntregaCnh()
          this.resetLocalData()
          this.$vs.loading.close()
          this.popupStoreCnh = false
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Salvos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(error => {
          this.$vs.loading.close()
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: 'Algo saiu errado. Por favor chame o suporte.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        })
    },

    updateCnh (cnh) {
      // Loading
      this.$vs.loading()
      this.disableCnhSave = true
      setTimeout(() => { this.disableCnhSave = false }, 3000) //TRAVA O BOTÃO SALVAR POR 3 SEGUNDOS
      this.$store.dispatch('cnh/updateCnh', cnh)
        .then(async (resp) => {
          this.$store.commit('studentManagement/SET_STUDENT_CNH', resp.data)
          if (resp.data.delivery_date) await this.printTermoDeEntregaCnh()
          this.resetLocalData()
          this.$vs.loading.close()
          this.popupShowTenant = false
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Salvos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(error => {
          this.$vs.loading.close()
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: 'Algo saiu errado. Por favor chame o suporte.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        })
    },

    deleteCnh (cnh) {
      this.cnhDeleteId = cnh.id //temporário para obter o valor na janela de confirmação
      this.popupCnhDeleteConfirm = true
    },

    dispatchDeleteCnh () {
      this.$store.dispatch('cnh/delete', this.cnhDeleteId)
        .then(() => {
          this.$store.commit('studentManagement/DELETE_STUDENT_CNH', this.cnhDeleteId)
          this.resetLocalData()
          this.cnhDeleteId = null //evita exclusões por engano
          this.popupCnhDeleteConfirm = false
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Excluídos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },


    async searchZipCode ($event) {
      const zip_code = this.data_local.tenant.address.zip_code.trim().replace(/[^0-9]/g, '')

      if (zip_code.length === 8 && ($event.keyCode < 37 || $event.keyCode > 40)) { // $event.keyCode evita chamadas de API ao teclar setas

        axios._noHeaders = true //enviando um get sem Autorization no header
        const resp = await axios.get(`https://viacep.com.br/ws/${zip_code}/json`)
        if (resp.data.erro) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: 'Este CEP não existe ou não foi encontrado.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }

        this.data_local.tenant.address.neighborhood = resp.data.bairro
        this.data_local.tenant.address.complement = resp.data.complemento

        //abaixo testo a primeira palavra da rua (logradouro), se existir removo da rua e seleciono o logradouro
        const rua = /Rua /i
        const avenida = /Avenida /i
        const rodovia = /Rodovia /i
        const estrada = /Estrada /i
        const lote = /Lote /i
        const servidao = /Servidão /i
        const quadra = /Quadra /i
        const travessa = /Travessa /i
        const linha = /Linha /i

        if (rua.test(resp.data.logradouro)) {
          this.data_local.tenant.address.logradouro = 'Rua'
          const regEx = new RegExp('Rua ', 'ig')
          this.data_local.tenant.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (avenida.test(resp.data.logradouro)) {
          this.data_local.tenant.address.logradouro = 'Avenida'
          const regEx = new RegExp('Avenida ', 'ig')
          this.data_local.tenant.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (rodovia.test(resp.data.logradouro)) {
          this.data_local.tenant.address.logradouro = 'Rodovia'
          const regEx = new RegExp('Rodovia ', 'ig')
          this.data_local.tenant.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (estrada.test(resp.data.logradouro)) {
          this.data_local.tenant.address.logradouro = 'Estrada'
          const regEx = new RegExp('Estrada ', 'ig')
          this.data_local.tenant.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (lote.test(resp.data.logradouro)) {
          this.data_local.tenant.address.logradouro = 'Lote'
          const regEx = new RegExp('Lote ', 'ig')
          this.data_local.tenant.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (servidao.test(resp.data.logradouro)) {
          this.data_local.tenant.address.logradouro = 'Servidão'
          const regEx = new RegExp('Servidão ', 'ig')
          this.data_local.tenant.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (quadra.test(resp.data.logradouro)) {
          this.data_local.tenant.address.logradouro = 'Quadra'
          const regEx = new RegExp('Quadra ', 'ig')
          this.data_local.tenant.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (travessa.test(resp.data.logradouro)) {
          this.data_local.tenant.address.logradouro = 'Travessa'
          const regEx = new RegExp('Travessa ', 'ig')
          this.data_local.tenant.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (linha.test(resp.data.logradouro)) {
          this.data_local.tenant.address.logradouro = 'Linha'
          const regEx = new RegExp('Linha ', 'ig')
          this.data_local.tenant.address.street = resp.data.logradouro.replace(regEx, '')
        } else {
          this.data_local.tenant.address.street = resp.data.logradouro
        }

        const state = this.states.find(o => o.initials === resp.data.uf)
        this.data_local.tenant.address.state_id = state.value
        await this.fillCity(state.value, resp.data.localidade, 'cityTenantOptions', 'tenant')
      }
    },

    async fillCity (stateId, city = null, prop = 'cityOptions', prop2 = 'main') {
      this[prop] = []
      try {
        if (stateId) {
          const resp = await axios.get(`${process.env.VUE_APP_API_URL}/cities/${stateId}`)
          for (const k in resp.data) {
            this[prop].push({ value: resp.data[k].id, label: resp.data[k].city })
          }
          if (city) {
            //procura pelo id, se não encontrar peocura pelo nome (motivo: da api recebo ID e do CEP recebo nome)
            let ct = this[prop].find(x => x.value === city)
            if (!ct) {
              ct = this[prop].find(x => x.label === city)
            }
            if (prop2 === 'main') {
              this.data_local.city_nasc_id = ct.value
            } else if (prop2 === 'tenant') {
              this.data_local.tenant.address.city_id = ct.value
            }
          }
        }
      } catch (error) { console.error(error) }
    },

    async fillCityCnh (state_id, city_id = null, i) {
      this.data_local.cnhs[i].cityCnhOptions = []
      try {
        if (state_id) {
          const resp = await axios.get(`${process.env.VUE_APP_API_URL}/cities/${state_id}`)
          for (const k in resp.data) {
            this.data_local.cnhs[i].cityCnhOptions.push({ value: resp.data[k].id, label: resp.data[k].city })
          }
          if (city_id) {
            //procura pelo id, se não encontrar peocura pelo nome (motivo: da api recebo ID e do CEP recebo nome)
            let ct = this.data_local.cnhs[i].cityCnhOptions.find(x => x.value === city_id)
            if (!ct) {
              ct = this.data_local.cnhs[i].cityCnhOptions.find(x => x.label === city_id)
            }
            this.data_local.cnhs[i].city_cnh_id = ct.value
          }
        }
      } catch (error) { console.error(error) }
    },
    resetLocalData (change = false) {
      this.data_local = JSON.parse(JSON.stringify(this.$store.getters['studentManagement/getStudent']))
      this.changed = change
    },
    resetTenant () {
      this.data_local.tenant = JSON.parse(JSON.stringify(this.$store.getters['studentManagement/getStudent'].tenant))
    },
    upper (obj) {
      for (const prop in obj) {
        if (typeof obj[prop] === 'string') {
          //LISTAR OS CAMPOS DO OBJETO QUE DEVEM SER ALTERADOS
          if (prop === 'occupation' || prop === 'workplace' || prop === 'mothers_name' || prop === 'fathers_name' || prop === 'responsible_withdrawal'
              || prop === 'observation' || prop === 'name' || prop === 'street' || prop === 'complement' || prop === 'neighborhood') {
            obj[prop] = obj[prop].toUpperCase()
          }
        }
        if (typeof obj[prop] === 'object' && prop !== 'phones' && prop !== 'email' && prop !== 'emails') {
          this.upper(obj[prop])
        }
      }
      return obj
    }
  },

  watch:{
    cnh: {
      deep: true,
      handler (data) {
        if (this.user.company.configuration) {
          if (this.user.company.configuration.general && this.user.company.configuration.general.uppercase_forms) {
            // SE CONFIGURADO PARA USAR MAIÚSUCAS NOS FORMS
            // MÉTODO VARRE TODO O OBJETO COLOCANDO MAIÚSCULOS OS CAMPOS STRING DO IF DENTRO DO MÉTODO upper
            this.upper(data)
          }
        }
      }
    },
    data_local: {
      deep: true,
      handler (data) {
        if (this.user.company.configuration) {
          if (this.user.company.configuration.general && this.user.company.configuration.general.uppercase_forms) {
            // SE CONFIGURADO PARA USAR MAIÚSUCAS NOS FORMS
            // MÉTODO VARRE TODO O OBJETO COLOCANDO MAIÚSCULOS OS CAMPOS STRING DO IF DENTRO DO MÉTODO upper
            this.upper(data)
          }
        }
        //OBSERVO DATA_LOCAL comparando com os dados do GETTER PARA AVISAR CASO HAJA UMA ALTERAÇÃO E SE TENTE SAIR DA PÁGINA SEM SALVAR
        const data_local = JSON.parse(JSON.stringify(data))
        const local_getter = JSON.parse(JSON.stringify(this.$store.getters['studentManagement/getStudent']))

        const cnhs_local = data_local.cnhs
        if (cnhs_local.length) {
          cnhs_local.forEach((cnh) => {
            cnh.cityCnhOptions = []
          })
        }
        const tenant_local = data_local.tenant
        delete data_local.cnhs
        delete data_local.tenant
        const registration_local = data_local

        const cnhs_local_getter = local_getter.cnhs
        const tenant_local_getter = local_getter.tenant
        delete local_getter.cnhs
        delete local_getter.tenant
        const registration_local_getter = local_getter

        // Aqui controlo SOMENTE alterações de CNH
        if (JSON.stringify(cnhs_local) === JSON.stringify(cnhs_local_getter)) {
          this.changedCnh = false
        } else {
          this.changedCnh = true
        }

        // Aqui controlo SOMENTE alterações de LOCATÁRIO
        if (JSON.stringify(tenant_local) === JSON.stringify(tenant_local_getter)) {
          this.changedTenant = false
        } else {
          this.changedTenant = true
        }

        // Aqui controlo alterações de dados gerais (não de cnhs e nem de locatário)
        const loc = JSON.parse(JSON.stringify(registration_local))
        delete loc.transactions
        const getter = JSON.parse(JSON.stringify(registration_local_getter))
        delete getter.transactions
        if (JSON.stringify(loc) === JSON.stringify(getter)) {
          this.changed = false
        } else {
          this.changed = true
        }

        // APENAS UM CÓDIGO UTILIZADO PARA AUXILIAR NA COMPARAÇÃO ENTRE OBJETOS (DESCOMENTAR LODASH)

        // function difference (origObj, newObj) {
        //   function changes (newObj, origObj) {
        //     let arrayIndexCounter = 0
        //     return _.transform(newObj, function (result, value, key) {
        //       if (!_.isEqual(value, origObj[key])) {
        //         const resultKey = _.isArray(origObj) ? arrayIndexCounter++ : key
        //         result[resultKey] = (_.isObject(value) && _.isObject(origObj[key])) ? _.isDate(value) ? value : changes(value, origObj[key]) : value
        //       }
        //     })
        //   }
        //   return changes(newObj, origObj)
        // }
        // console.log(difference(loc, getter))

        // console.log(registration_local.transactions)
        // console.log(registration_local_getter.transactions)


      }
    }
  },
  mounted () {
    if (this.data_local.state_nasc_id) {
      if (this.data_local.city_nasc_id) {
        this.fillCity(this.data_local.state_nasc_id, this.data_local.city_nasc_id)
      } else {
        this.fillCity(this.data_local.state_nasc_id)
      }
    }

    if (this.data_local.tenant.address.state_id) {
      if (this.data_local.tenant.address.city_id) {
        this.fillCity(this.data_local.tenant.address.state_id, this.data_local.tenant.address.city_id, 'cityTenantOptions', 'tenant')
      } else {
        this.fillCity(this.data_local.tenant.address.state_id, null, 'cityTenantOptions', 'tenant')
      }
    }

    if (this.data_local.cnhs.length) {
      this.data_local.cnhs.forEach((cnh, i) => {
        this.fillCityCnh(cnh.state_cnh_id, cnh.city_cnh_id, i)
      })
    }
  },
  created () {
    // Register VUEX Modules
    if (!moduleStudentManagement.isRegistered) {
      this.$store.registerModule('studentManagement', moduleStudentManagement)
      moduleStudentManagement.isRegistered = true
    }
    if (!moduleTenant.isRegistered) {
      this.$store.registerModule('tenant', moduleTenant)
      moduleTenant.isRegistered = true
    }
    if (!moduleCnh.isRegistered) {
      this.$store.registerModule('cnh', moduleCnh)
      moduleCnh.isRegistered = true
    }
    this.resetLocalData()
  }

}
</script>
<style>
.sizedIcon .vs-icon {
    color: inherit;
    text-align: center;
    font-size: 24px;
}
.noOverflow .vs-popup--content {
  overflow: hidden;
}
.popup80 .vs-popup {
  width: 80% !important;
}
.vue_select_drop_size_orgao_emissor_rg_uf_id .vs__dropdown-menu {
  max-height: 340px;
}
.vue_select_drop_size_tenant_state_id .vs__dropdown-menu {
  max-height: 180px;
}
.vue_select_drop_size_tenant_city_id .vs__dropdown-menu {
  max-height: 180px;
}
.vue_select_drop_size_city_cnh_id .vs__dropdown-menu {
  max-height: 130px;
}

</style>
