<template>
  <div>
    <div class="flex flex-wrap items-center mb-4">
      <div class="flex flex-wrap items-center">
        <div class="flex-2">
          <vs-button @click="popupForm = true" icon-pack="feather" class="shadow-md mb-4 md:mb-0"
            >Cadastrar</vs-button
          >
        </div>
      </div>
       <vs-popup class="popup400" title="Formulário de taxa" :active.sync="popupForm">
        <div style="min-height: 150px">
          <form-debit-student :student-id="$route.params.studentId" @saved="afterSaved"/>
        </div>
      </vs-popup>
    </div>
    <div class="vx-card p-6">
      <div class="vx-col w-full">
          <list-debit-student :student-id="$route.params.studentId" ref="listDebit" />
      </div>
    </div>
  </div>
</template>

<script>
import SigPopup from '@/components/sig/SigPopup'
import FormDebitStudent from '@/components/register/student/debit/FormDebitStudent'
import ListDebitStudent from '@/components/register/student/debit/ListDebitStudent'

export default {
  components: {
    SigPopup,
    FormDebitStudent,
    ListDebitStudent
  },

  data () {
    return {
      popupForm: false,
      popup: false,
      situation: 'PAYED_STUDENT',
      optionsSituation: [
        {
          label: 'Pago pelo aluno',
          value: 'PAYED_STUDENT'
        },
        {
          label: 'Pendente',
          value: 'PENDING'
        },
        {
          label: 'Pago pelo CFC',
          value: 'PAYED_CFC'
        }
      ]
    }
  },
  computed: {},
  methods: {
    changeSituation (value) {
      this.popup = false
      this.$vs.dialog({
        type: 'confirm',
        title: 'DESPESA - TAXA',
        text: 'Não existe despesa cadastrada. Deseja gerar?',
        acceptText: 'Sim',
        cancelText: 'Não'
      })
    },
    afterSaved () {
      this.popupForm = false
      this.$refs.listDebit.fetchDebitStudent()
    }
  }
}
</script>

<style lang="scss">
.ck-editor__editable {
  min-height: 650px;
}
.con-vs-popup .vs-popup {
  width: 50%;
}
.noOverflow .vs-popup--content {
  overflow: hidden;
}
.popup80 .vs-popup {
  width: 80% !important;
}
.popup50 .vs-popup {
  width: 50% !important;
}

.popup400 .vs-popup {
  max-width: 400px !important;
}
.vue_select_drop_size_90 .vs__dropdown-menu {
  max-height: 90px;
}
#user-edit-tab-documents th .vs-table-text {
  cursor: default;
}
#user-edit-tab-documents .vs-con-table .vs-con-tbody {
  border: 2px solid black;
}
.svg-hover:hover {
  fill: #158482;
}
.svg-hover {
  fill: #626262;
}
#user-edit-tab-documents td {
  border: 1px solid #c0c0c0;
  line-height: 1;
}
#user-edit-tab-documents td:hover {
  border-bottom: 1px solid #353434;
}
#user-edit-tab-documents td:first-child:hover {
  border-bottom: 1px solid #c0c0c0;
}
#user-edit-tab-documents .vs-con-table td:last-child {
  border-right: 1px solid transparent;
}
#user-edit-tab-documents .vs-table--thead {
  border: 1px solid transparent;
}
#user-edit-tab-documents
  .vs-con-table
  .vs-con-tbody
  .vs-table--tbody-table
  .tr-values
  .vs-table--td {
  padding: 6px;
}
#user-edit-tab-documents .ps__scrollbar-y-rail {
  z-index: 99999;
}
.vs-tooltip {
  z-index: 99999;
}
.under:hover {
  text-decoration: underline;
}
</style>

