var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "schedule-exam" } },
    [
      _c("vx-card", [
        _c(
          "div",
          {
            staticClass: "tabs-container px-6 pt-6",
            attrs: { slot: "no-body" },
            slot: "no-body",
          },
          [
            _c("grid", {
              ref: "gridTheoretical",
              attrs: { student: this.student },
              on: {
                openMedicalExam: _vm.openMedicalExamViewSideBar,
                openPracticalExam: _vm.openPracticalExamViewSideBar,
                openPsychotechnicalExam: _vm.openPsychotechnicalExamViewSideBar,
                openTheoreticalExam: _vm.openTheoreticalExamViewSideBar,
              },
            }),
          ],
          1
        ),
      ]),
      _c("medical-exam-schedule", {
        attrs: { isSidebarActive: _vm.isMedicalExamSidebarActive },
        on: {
          refreshData: function ($event) {
            return _vm.refreshGrid(false)
          },
          closeSidebar: _vm.closeMedicalExamViewSideBar,
        },
      }),
      _c("practical-exam-schedule", {
        attrs: { isSidebarActive: _vm.isPracticalExamSidebarActive },
        on: {
          refreshData: function ($event) {
            return _vm.refreshGrid(false)
          },
          closeSidebar: _vm.closePracticalExamViewSideBar,
        },
      }),
      _c("psychotechnical-exam-schedule", {
        attrs: { isSidebarActive: _vm.isPsychotechnicalExamSidebarActive },
        on: {
          refreshData: function ($event) {
            return _vm.refreshGrid(false)
          },
          closeSidebar: _vm.closePsychotechnicalExamViewSideBar,
        },
      }),
      _c("theoretical-exam-schedule", {
        attrs: { isSidebarActive: _vm.isTheoreticalExamSidebarActive },
        on: {
          refreshData: function ($event) {
            return _vm.refreshGrid(false)
          },
          closeSidebar: _vm.closeTheoreticalExamViewSideBar,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }