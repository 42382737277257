var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "vx-row w-full items-end" }, [
      _c(
        "div",
        { staticClass: "vx-col md:w-4/5" },
        [
          _c("sig-input", {
            attrs: { label: "Observação" },
            model: {
              value: _vm.observation,
              callback: function ($$v) {
                _vm.observation = $$v
              },
              expression: "observation",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/5" },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "filled" },
              on: { click: _vm.save },
            },
            [_vm._v("Salvar")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }