var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _c("vs-input", {
        staticClass: "w-full",
        attrs: {
          label: _vm.label,
          "data-vv-as": _vm.label,
          "v-validate": _vm.validate,
          disabled: _vm.disabled,
          name: "single",
        },
        model: {
          value: _vm.localValue,
          callback: function ($$v) {
            _vm.localValue = $$v
          },
          expression: "localValue",
        },
      }),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.errors.has("single"),
              expression: "errors.has('single')",
            },
          ],
          staticClass: "text-danger text-sm",
        },
        [_vm._v(_vm._s(_vm.errors.first("single")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }