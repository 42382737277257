var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.params.data
    ? _c(
        "div",
        { staticClass: "flex items-center" },
        [
          _vm.status === "Não Concluído"
            ? _c("div", {
                staticClass:
                  "h-4 w-4 inline-block rounded-full mr-2 border-2 border-solid border-secondary",
              })
            : _vm.status === "Concluído"
            ? _c("div", {
                staticClass:
                  "h-4 w-4 inline-block rounded-full mr-2 bg-success",
              })
            : _vm.status === "Apto"
            ? _c("div", {
                staticClass:
                  "h-4 w-4 inline-block rounded-full mr-2 bg-success",
              })
            : _vm.status === "Inapto"
            ? _c("feather-icon", {
                staticClass: "text-danger mr-1",
                attrs: { icon: "SlashIcon", svgClasses: "w-5 h-5" },
              })
            : _vm.status === "Inapto Temporário"
            ? _c("div", {
                staticClass:
                  "h-4 w-4 inline-block rounded-full mr-2 bg-warning",
              })
            : _vm.status === "Pendente"
            ? _c("div", {
                staticClass:
                  "h-4 w-4 inline-block rounded-full mr-2 border-2 border-solid border-secondary",
              })
            : _vm.status === "Apto Com Restrições"
            ? _c("div", {
                staticClass:
                  "h-4 w-4 inline-block rounded-full mr-2 border-2 border-solid border-secondary",
              })
            : _vm.status === "Aguardando Teste"
            ? _c("div", {
                staticClass:
                  "h-4 w-4 inline-block rounded-full mr-2 border-2 border-solid border-secondary",
              })
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "cursor-default ml-1 mr-2",
              class: `text-${_vm.textColor}`,
            },
            [
              _vm._v(
                "\n    " + _vm._s(_vm._f("scheduleStatus")(_vm.status)) + "\n  "
              ),
            ]
          ),
          _c(
            "vx-tooltip",
            {
              staticClass:
                "flex items-center cursor-pointer hover:text-primary",
              attrs: { text: "Alterar Situação", position: "top" },
            },
            [
              _c(
                "vs-dropdown",
                {
                  staticClass: "cursor-pointer",
                  attrs: { "vs-custom-content": "", "vs-trigger-click": "" },
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: "EditIcon", svgClasses: "h-5 w-5 mr-1" },
                  }),
                  _c(
                    "vs-dropdown-menu",
                    { staticClass: "w-60" },
                    [
                      _vm.type === "psychotechnical" ||
                      _vm.type === "medical_exam" ||
                      _vm.type === "practical_exam" ||
                      _vm.type === "theoretical_exam"
                        ? _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.updateStatus("Apto")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "flex items-center" }, [
                                _c("div", {
                                  staticClass:
                                    "h-4 w-4 inline-block rounded-full mr-3 bg-success",
                                }),
                                _c("span", [_vm._v("Apto")]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.type === "psychotechnical" ||
                      _vm.type === "medical_exam" ||
                      _vm.type === "practical_exam" ||
                      _vm.type === "theoretical_exam"
                        ? _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.updateStatus("Inapto")
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "flex items-center" },
                                [
                                  _c("feather-icon", {
                                    staticClass: "mr-2 text-danger",
                                    attrs: {
                                      icon: "SlashIcon",
                                      svgClasses: "w-5 h-5",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-danger hover:text-primary",
                                    },
                                    [_vm._v("Inapto")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.type === "practical_exam" ||
                      _vm.type === "theoretical_exam"
                        ? _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.updateStatus("Aguardando Teste")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "flex items-center" }, [
                                _c("div", {
                                  staticClass:
                                    "h-4 w-4 inline-block rounded-full mr-3 border-2 border-solid border-secondary",
                                }),
                                _c("span", [_vm._v("Aguardando Teste")]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.type === "medical_exam"
                        ? _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.updateStatus("Apto Com Restrições")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "flex items-center" }, [
                                _c("div", {
                                  staticClass:
                                    "h-4 w-4 inline-block rounded-full mr-3 border-2 border-solid border-secondary",
                                }),
                                _c("span", [_vm._v("Apto Com Restrições")]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.type === "psychotechnical"
                        ? _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.updateStatus("Inapto Temporário")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "flex items-center" }, [
                                _c("div", {
                                  staticClass:
                                    "h-4 w-4 inline-block rounded-full mr-2 bg-warning",
                                }),
                                _c("span", [_vm._v("Inapto Temporário")]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.type === "psychotechnical" ||
                      _vm.type === "medical_exam"
                        ? _c(
                            "vs-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.updateStatus("Pendente")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "flex items-center" }, [
                                _c("div", {
                                  staticClass:
                                    "h-4 w-4 inline-block rounded-full mr-3 border-2 border-solid border-secondary",
                                }),
                                _c("span", [_vm._v("Pendente")]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }