var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "flex flex-wrap items-center mb-4" },
      [
        _c("div", { staticClass: "flex flex-wrap items-center" }, [
          _c(
            "div",
            { staticClass: "flex-2" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "shadow-md mb-4 md:mb-0",
                  attrs: { "icon-pack": "feather" },
                  on: {
                    click: function ($event) {
                      _vm.popupForm = true
                    },
                  },
                },
                [_vm._v("Cadastrar")]
              ),
            ],
            1
          ),
        ]),
        _c(
          "vs-popup",
          {
            staticClass: "popup400",
            attrs: { title: "Formulário de taxa", active: _vm.popupForm },
            on: {
              "update:active": function ($event) {
                _vm.popupForm = $event
              },
            },
          },
          [
            _c(
              "div",
              { staticStyle: { "min-height": "150px" } },
              [
                _c("form-debit-student", {
                  attrs: { "student-id": _vm.$route.params.studentId },
                  on: { saved: _vm.afterSaved },
                }),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "vx-card p-6" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full" },
        [
          _c("list-debit-student", {
            ref: "listDebit",
            attrs: { "student-id": _vm.$route.params.studentId },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }