<template>
  <div class="vx-row">
    <select-bank v-model="bank" class="vx-col md:w-1/3" />
    <div class="vx-col md:w-1/3 w-full mt-0">
      <vs-input class="w-full mt-0" label="Agência" v-model="branch" />
    </div>
    <div class="vx-col md:w-1/3 w-full mt-0">
      <vs-input class="w-full mt-0" label="Conta" v-model="account" />
    </div>
  </div>
</template>

<script>
import SelectBank from '@/components/bank/SelectBank'

export default {
  components: {
    SelectBank
  },
  data () {
    return {
      bank: null,
      branch: null,
      account: null
    }
  },
  methods: {
    getPayload () {
      return {
        bank: this.bank,
        branch: this.branch,
        account: this.account
      }
    },
    validate () {
      const rules = [
        () => {
          if (this.bank) return true
          this.mixinNotify('Selecione o banco', 'danger')
        },
        () => {
          if (this.branch) return true
          this.mixinNotify('Informe a agência', 'danger')
        },
        () => {
          if (this.account) return true
          this.mixinNotify('Informe a conta', 'danger')
        }
      ]
      for (const isValid of rules) {
        if (!isValid()) return
      }
      return true
    }
  }
}
</script>
