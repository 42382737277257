<template>
  <div id="student-edit-tab-general-data">
    <vs-popup
      class="noOverflow"
      :fullscreen="false"
      :title="showPdfTitle"
      :active.sync="popupShowPdf"
    >
      <object :data="srcPdf" width="100%" style="height: 600px" />
    </vs-popup>

    <vs-popup
      :fullscreen="false"
      title="Entrega de Documentos"
      :active.sync="popupDeliveryOfDocuments"
    >
      <div class="flex items-end mt-2">
        <feather-icon icon="FileTextIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Motivos(s)</span>
      </div>
      <hr class="mb-4" />

      <div
        class="vx-row"
        v-for="(reason, i) in deliveryOfDocumentReasonList"
        :key="`${i}_reason`"
      >
        <div class="vx-col md:w-1/3 w-full" v-if="reason.value1">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="deliveryOfDocument.motivo"
                :vs-value="reason.value1"
                class="mr-4 mb-2"
                >{{ reason.text1 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/3 w-full" v-if="reason.value2">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="deliveryOfDocument.motivo"
                :vs-value="reason.value2"
                class="mr-4 mb-2"
                >{{ reason.text2 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/3 w-full" v-if="reason.value3">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="deliveryOfDocument.motivo"
                :vs-value="reason.value3"
                class="mr-4 mb-2"
                >{{ reason.text3 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-end mt-2">
        <feather-icon icon="FileTextIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Documento(s)</span>
      </div>
      <hr class="mb-4" />

      <div
        class="vx-row"
        v-for="(document, i) in deliveryOfDocumentList"
        :key="i"
      >
        <div class="vx-col md:w-1/3 w-full" v-if="document.value1">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="deliveryOfDocument.document"
                :vs-value="document.value1"
                class="mr-4 mb-2"
                >{{ document.text1 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/3 w-full" v-if="document.value2">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="deliveryOfDocument.document"
                :vs-value="document.value2"
                class="mr-4 mb-2"
                >{{ document.text2 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/3 w-full" v-if="document.value3">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="deliveryOfDocument.document"
                :vs-value="document.value3"
                class="mr-4 mb-2"
                >{{ document.text3 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mt-2">
          <vs-input
            v-model="deliveryOfDocument.other"
            class="w-full"
            label="Outros (Especificar)"
          />
        </div>
      </div>

      <!-- Generate & Cancel Button -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-2 flex flex-wrap items-center justify-end">
            <vs-button class="ml-auto mt-2" @click="generateDeliveryOfDocument"
              >Gerar</vs-button
            >
            <vs-button
              class="ml-4 mt-2"
              type="border"
              color="warning"
              @click="popupDeliveryOfDocuments = false"
              >Cancelar</vs-button
            >
          </div>
        </div>
      </div>
    </vs-popup>

    <!-- Popup documentos faltantes -->
    <vs-popup
      :fullscreen="false"
      title="Documentos Faltantes"
      :active.sync="popupPendingDocuments"
    >
      <div class="flex items-end mt-2">
        <feather-icon icon="FileTextIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Motivos(s)</span>
      </div>
      <hr class="mb-4" />

      <div
        class="vx-row"
        v-for="(reason, i) in deliveryOfDocumentReasonList"
        :key="`${i}_reason`"
      >
        <div class="vx-col md:w-1/3 w-full" v-if="reason.value1">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="deliveryOfDocument.motivo"
                :vs-value="reason.value1"
                class="mr-4 mb-2"
                >{{ reason.text1 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/3 w-full" v-if="reason.value2">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="deliveryOfDocument.motivo"
                :vs-value="reason.value2"
                class="mr-4 mb-2"
                >{{ reason.text2 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/3 w-full" v-if="reason.value3">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="deliveryOfDocument.motivo"
                :vs-value="reason.value3"
                class="mr-4 mb-2"
                >{{ reason.text3 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-end mt-2">
        <feather-icon icon="FileTextIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Documento(s)</span>
      </div>
      <hr class="mb-4" />

      <div
        class="vx-row"
        v-for="(document, i) in deliveryOfDocumentList"
        :key="i"
      >
        <div class="vx-col md:w-1/3 w-full" v-if="document.value1">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="deliveryOfDocument.document"
                :vs-value="document.value1"
                class="mr-4 mb-2"
                >{{ document.text1 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/3 w-full" v-if="document.value2">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="deliveryOfDocument.document"
                :vs-value="document.value2"
                class="mr-4 mb-2"
                >{{ document.text2 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/3 w-full" v-if="document.value3">
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="deliveryOfDocument.document"
                :vs-value="document.value3"
                class="mr-4 mb-2"
                >{{ document.text3 }}</vs-checkbox
              >
            </div>
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mt-2">
          <vs-input
            v-model="deliveryOfDocument.other"
            class="w-full"
            label="Outros (Especificar)"
          />
        </div>
      </div>

      <!-- Generate & Cancel Button -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-2 flex flex-wrap items-center justify-end">
            <vs-button class="ml-auto mt-2" @click="generatePendingDocument"
              >Gerar</vs-button
            >
            <vs-button
              class="ml-4 mt-2"
              type="border"
              color="warning"
              @click="popuPendingDocuments = false"
              >Cancelar</vs-button
            >
          </div>
        </div>
      </div>
    </vs-popup>
    <!-- Fim popup documentos faltantes -->

    <vs-popup
      id="popupResultsOfDETRANTests"
      :fullscreen="false"
      title="Resultado de Provas DETRAN"
      :active.sync="popupResultsOfDETRANTests"
    >
      <div id="loadResultsOfDETRANTests" class="vs-con-loading__container">
        <div class="vx-row">
          <div class="vx-col md:w-2/5 w-full mt-2">Aluno</div>
          <div class="vx-col md:w-1/5 w-full mt-2">Data</div>
          <div class="vx-col md:w-1/5 w-full mt-2">Nota</div>
          <div class="vx-col md:w-1/5 w-full mt-2">Situação</div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-2/5 w-full mt-2">{{ data_local.name }}</div>
          <div class="vx-col md:w-1/5 w-full mt-2">{{ data_prova }}</div>
          <div class="vx-col md:w-1/5 w-full mt-2">
            {{ nota ? nota : "Sem nota" }}
          </div>
          <div class="vx-col md:w-1/5 w-full mt-2">
            {{ nota ? (nota >= 7 ? "APTO" : "INAPTO") : "AGUARDANDO TESTE" }}
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="mt-2 flex flex-wrap items-center justify-end">
              <vs-button class="ml-auto mt-2" @click="updateStudentNote"
                >Atualizar Nota do Aluno</vs-button
              >
              <vs-button
                class="ml-4 mt-2"
                type="border"
                color="warning"
                @click="popupResultsOfDETRANTests = false"
                >Cancelar</vs-button
              >
            </div>
          </div>
        </div>
      </div>
    </vs-popup>

    <!-- WEBCAM -->
    <webcam-photo
      ref="webcam"
      :person="data_local"
      @photoUrl="onValueChanged"
    />

    <!-- Avatar Row -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="flex items-start flex-col lg:flex-row">
          <div
            @mouseover="mouseOver = true"
            @mouseleave="mouseOver = false"
            @click="loadCamera"
            id="photo-loading"
            class="cursor-pointer p-2 vs-con-loading__container mt-6"
            style="position: absolute; width: 200px; height: 200px"
          >
            <feather-icon
              v-if="mouseOver"
              :svgClasses="['h-16 w-16 stroke-current', textColor]"
              icon="CameraIcon"
              :style="
                data_local.photo && data_local.photo.url
                  ? 'margin-left: 67px; margin-top: 61px'
                  : 'margin-left: 55px; margin-top: 54px'
              "
            />
          </div>
          <img
            :src="
              data_local.photo && data_local.photo.url
                ? data_local.photo.url
                : noUserPhoto
            "
            class="mt-6 mr-8 rounded h-56 w-56"
            @load="closeLoading"
          />

          <div class="w-full">
            <div class="vx-row">
              <div class="vx-col md:w-1/5">
                <vs-input
                  class="w-full mt-0"
                  label="Código"
                  v-model="data_local.code"
                  data-vv-as="Código"
                  disabled
                />
              </div>
              <div class="vx-col md:w-3/5">
                <div class="vx-row">
                  <div class="vx-col md:w-1/2">
                    <vs-input
                      class="w-full mt-0"
                      label="Nome"
                      v-model="data_local.name"
                      data-vv-as="Nome"
                      v-validate="'required|alpha_spaces'"
                      name="name"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('name')"
                      >{{ errors.first("name") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/2">
                    <vs-input
                      class="w-full mt-0"
                      label="Nome social"
                      v-model="data_local.social_name"
                      data-vv-as="Nome social"
                      v-validate="'alpha_spaces'"
                      name="socialName"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('socialName')"
                      >{{ errors.first("socialName") }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="vx-col md:w-1/5">
               <label class="vs-input--label">Tipo de Pessoa</label>
                <v-select
                  v-model="data_local.type"
                  :reduce="(option) => option.value"
                  :clearable="false"
                  :options="typeOptions"
                  placeholder="Selecione"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                >
                  <template #no-options="{}">
                    Desculpe, nenhum resultado encontrado.
                  </template>
                </v-select>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col md:w-1/5 w-full mt-0">
                <vs-input
                  class="w-full"
                  label="CPF"
                  v-model="data_local.cpf"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                  data-vv-as="CPF"
                  v-validate="'cpf'"
                  name="cpf"
                   v-on:keyup.ctrl.c="copyCpf"
                />
                <span class="text-danger text-sm" v-show="errors.has('cpf')">{{
                  errors.first("cpf")
                }}</span>
              </div>
              <div class="vx-col md:w-1/5 w-full mt-0">
                <vs-input
                  class="w-full"
                  label="RG/IE"
                  v-model="data_local.rg"
                />
              </div>
                <div class="vx-col md:w-1/5 w-full mt-2">
                <vs-input
                  type="date"
                  class="w-full"
                  label="Data de Nascimento"
                  v-model="data_local.date_of_birth"
                  v-validate="'date_format:yyyy-MM-dd'"
                  data-vv-as="Data de Nascimento"
                  name="date_of_birth"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('date_of_birth')"
                  >Formato incorreto</span
                >
              </div>
               <div class="vx-col md:w-1/5 w-full mt-2">
                <div>
                  <label class="text-sm">Sexo</label>
                  <div class="mt-2">
                    <vs-radio
                      v-model="data_local.gender"
                      vs-value="male"
                      class="mr-4"
                      >Masc.</vs-radio
                    >
                    <vs-radio
                      v-model="data_local.gender"
                      vs-value="feminine"
                      class="mr-4"
                      >Fem.</vs-radio
                    >
                  </div>
                </div>
              </div>
              <div class="vx-col md:w-1/5 w-full mt-0">
                <label class="vs-input--label">Situação</label>
                <v-select
                  v-model="data_local.status"
                  :reduce="(option) => option.value"
                  :clearable="false"
                  :options="statusOptions"
                  placeholder="Selecione"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                >
                  <template #no-options="{}">
                    Desculpe, nenhum resultado encontrado.
                  </template>
                </v-select>
              </div>
            </div>
            <div class="flex items-end mt-2">
              <feather-icon
                icon="MapPinIcon"
                class="mr-2"
                svgClasses="w-5 h-5"
              />
              <span class="leading-none font-medium">Endereço</span>
            </div>
            <hr />

            <div class="vx-row">
              <div class="vx-col md:w-1/4 w-full mt-2">
                <vs-input
                  class="w-full"
                  label="CEP"
                  placeholder="CEP"
                  v-model="data_local.address.zip_code"
                  v-mask="['#####-###']"
                  @keyup="searchZipCode"
                />
              </div>

              <div class="vx-col md:w-1/4 w-full mt-2">
                <label class="vs-input--label">Logradouro</label>
                <v-select
                  v-model="data_local.address.logradouro"
                  :reduce="(option) => option.value"
                  clearable
                  :options="logradouroOptions"
                  placeholder="Selecione"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                >
                  <template #no-options="{}">
                    Desculpe, nenhum resultado encontrado.
                  </template>
                </v-select>
              </div>

              <div class="vx-col md:w-1/4 w-full mt-2">
                <vs-input
                  class="w-full"
                  label="Endereço"
                  v-model="data_local.address.street"
                />
              </div>

              <div class="vx-col md:w-1/4 w-full mt-2">
                <vs-input
                  class="w-full"
                  label="Número"
                  v-model="data_local.address.number"
                  type="number"
                  placeholder="S/N"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col md:w-1/5 w-full">
        <vs-input
          class="w-full mt-2"
          label="Complemento"
          v-model="data_local.address.complement"
        />
      </div>
      <div class="vx-col md:w-1/5 w-full">
        <vs-input
          class="w-full mt-2"
          label="Bairro"
          v-model="data_local.address.neighborhood"
        />
      </div>
      <div class="vx-col md:w-1/4_ w-full mt-2">
        <label class="vs-input--label">Cidade</label>
        <v-select
          v-model="data_local.address.city_id"
          :reduce="(option) => option.value"
          :placeholder="
            cityOptions.length ? 'Selecione' : 'Selecione um estado'
          "
          :options="cityOptions"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        >
          <template #no-options="{}">
            Desculpe, nenhum resultado encontrado.
          </template>
        </v-select>
      </div>
      <div class="vx-col md:w-1/6 w-full mt-2">
        <label class="vs-input--label">Estado</label>
        <v-select
          v-model="data_local.address.state_id"
          :reduce="(option) => option.value"
          @input="
            fillCity(data_local.address.state_id),
              (data_local.address.city_id = null)
          "
          :options="states"
          placeholder="Selecione"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        >
          <template #no-options="{}">
            Desculpe, nenhum resultado encontrado.
          </template>
        </v-select>
      </div>
      <div class="vx-col md:w-1/6 w-full mt-2">
        <label class="vs-input--label">País</label>
        <v-select
          v-model="data_local.address.country"
          :reduce="(option) => option.value"
          :clearable="false"
          :options="countryOptions"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        >
          <template #no-options="{}">
            Desculpe, nenhum resultado encontrado.
          </template>
        </v-select>
      </div>
    </div>

    <div class="flex items-end mt-2">
      <feather-icon icon="PhoneIcon" class="mr-2" svgClasses="w-5 h-5" />
      <span class="leading-none font-medium">Contato</span>
    </div>
    <hr />
    <div class="vx-row">
      <div class="vx-col md:w-1/5 w-full">
        <div class="w-full mt-1">
          <label for="" class="vs-input--label">Celular</label>
          <vue-tel-input
            v-model="cellphone"
            v-bind="vuetelbind"
          ></vue-tel-input>
        </div>
      </div>
      <div class="vx-col md:w-1/5 w-full">
        <vs-input
          class="w-full mt-1"
          v-mask="['(##)#####-####', '(##)####-####']"
          label="Telefone"
          v-model="data_local.phones[1].phone"
        />
      </div>
      <div class="vx-col md:w-1/5 w-full">
        <vs-input
          class="w-full mt-1"
          v-mask="['(##)#####-####', '(##)####-####']"
          label="Outro"
          v-model="data_local.phones[2].phone"
        />
      </div>
      <div class="vx-col md:w-2/5 w-full">
        <vs-input
          class="w-full mt-2"
          label="E-mail"
          data-vv-as="E-mail"
          v-model="data_local.email"
          type="email"
          v-validate="'email'"
          name="email"
        />
        <span class="text-danger text-sm" v-show="errors.has('email')">{{
          errors.first("email")
        }}</span>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-1/3 w-full">
        <div class="mt-2">
          <label class="text-sm">Aceita Comunicados</label>
          <div class="mt-2">
            <div class="flex flex-wrap mt-1">
              <vs-checkbox
                v-model="data_local.type_of_communication"
                vs-value="email"
                class="mr-4 mb-2"
                >E-mail</vs-checkbox
              >
              <vs-checkbox
                v-model="data_local.type_of_communication"
                vs-value="sms"
                class="mr-4 mb-2"
                >SMS</vs-checkbox
              >
              <vs-checkbox
                v-model="data_local.type_of_communication"
                vs-value="whatsapp"
                class="mb-2"
                >Whatsapp</vs-checkbox
              >
            </div>
          </div>
        </div>
      </div>
      <div class="vx-col md:w-1/3_ w-full">
        <vs-input
          class="w-full mt-2"
          label="Facebook"
          icon-pack="feather"
          icon="icon-facebook"
          icon-no-border
          v-model="data_local.facebook"
        />
      </div>
      <div class="vx-col md:w-1/3_ w-full">
        <vs-input
          class="w-full mt-2"
          label="Instagram"
          icon-pack="feather"
          icon="icon-instagram"
          icon-no-border
          v-model="data_local.instagram"
        />
      </div>
      <div class="vx-col md:w-1/3_ w-full">
        <vs-input
          class="w-full mt-2"
          label="Outra Mídia Social"
          icon-pack="feather"
          icon="icon-share-2"
          icon-no-border
          v-model="data_local.other_social_media"
        />
      </div>
    </div>

    <div class="flex items-end mt-2">
      <feather-icon icon="InfoIcon" class="mr-2" svgClasses="w-5 h-5" />
      <span class="leading-none font-medium">Outros</span>
    </div>
    <hr />

    <div class="vx-row">
      <div class="vx-col md:w-1/5 w-full mt-2">
        <label class="vs-input--label">Último Serviço</label>
        <v-select
          appendToBody
          v-model="data_local.intended_services"
          clearable
          :reduce="(option) => option.value"
          :options="servicePackages"
          @input="changeLastService"
          placeholder="Selecione"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        >
          <template #no-options="{}">
            Desculpe, nenhum resultado encontrado.
          </template>
        </v-select>
      </div>
      <div class="vx-col md:w-1/5 w-full mt-2">
        <label class="vs-input--label">Cat. Atual</label>
        <v-select
          appendToBody
          v-model="data_local.current_category_cnh"
          :options="categoryCNHOptions"
          :reduce="(option) => option.value"
          placeholder="Selecione"
          :clearable="true"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        >
          <template #no-options="{}">
            Desculpe, nenhum resultado encontrado.
          </template>
        </v-select>
      </div>
      <div class="vx-col md:w-1/5 w-full mt-2">
        <label class="vs-input--label">Cat. Desejada</label>
        <v-select
          appendToBody
          v-model="data_local.intended_category_cnh"
          :options="categoryCNHOptions"
          :reduce="(option) => option.value"
          placeholder="Selecione"
          :clearable="true"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        >
          <template #no-options="{}">
            Desculpe, nenhum resultado encontrado.
          </template>
        </v-select>
      </div>

      <div class="vx-col md:w-1/5 w-full mt-2">
        <label class="vs-input--label">Histórico de Serviços</label>
        <!-- DROPDOWN -->
        <div class="w-full flex flex-wrap items-center justify-center">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <vs-button
              icon-pack="feather"
              icon="icon-chevrons-right"
              icon-after
              class="shadow-md w-full lg:mt-0 mt-4"
              >Ver Histórico</vs-button
            >
            <vs-dropdown-menu class="w-1/3">
              <vs-dropdown-item
                v-for="(serviceHistory, i) in serviceHistories"
                :key="i"
              >
                <div
                  v-if="serviceHistory.service_package"
                  class="flex items-center"
                >
                  <span
                    >{{
                      formatDateTime(serviceHistory.created, "dateTimeNoSecond")
                    }}
                    - {{ serviceHistory.service_package.name }}
                    {{ serviceHistory.service_package.category_cnh }}</span
                  >
                  <feather-icon
                    @click="confirmDeleteServiceHistory(serviceHistory)"
                    icon="Trash2Icon"
                    class="inline-block ml-4"
                    svgClasses="w-5 h-5 hover:text-danger"
                  />
                </div>
              </vs-dropdown-item>

              <vs-dropdown-item v-if="!serviceHistories.length">
                <div class="flex items-center">
                  <feather-icon
                    icon="AlertCircleIcon"
                    class="inline-block mr-4"
                    svgClasses="w-5 h-5"
                  />
                  <span>NENHUM SERVIÇO NO HISTÓRICO</span>
                </div>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </div>
      <div class="vx-col md:w-1/5 w-full mt-2">
        <vs-input
          type="date"
          class="w-full"
          label="Emissão de LADV"
          v-model="data_local.ladv_emission"
          v-validate="'date_format:yyyy-MM-dd'"
          data-vv-as="Emissão de LADV"
          name="ladv_emission"
        />
        <span class="text-danger text-sm" v-show="errors.has('ladv_emission')"
          >Formato incorreto</span
        >
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-1/5 w-full mt-2">
        <label class="vs-input--label">CNH Impressa|Digital</label>
        <v-select
          appendToBody
          v-model="data_local.cnh_printed_digital"
          :clearable="true"
          :reduce="(option) => option.value"
          :options="typeCNHOptions"
          placeholder="Selecione"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        >
          <template #no-options="{}">
            Desculpe, nenhum resultado encontrado.
          </template>
        </v-select>
      </div>
      <div class="vx-col md:w-1/5 w-full mt-2">
        <vs-input
          class="w-full"
          label="RENACH"
          v-model="data_local.renach_form_number"
        />
      </div>
      <div class="vx-col md:w-1/5 w-full mt-2">
        <vs-input
          type="date"
          class="w-full"
          label="Abertura RENACH"
          v-model="data_local.opening_date_renach_form_number"
          v-validate="'date_format:yyyy-MM-dd'"
          data-vv-as="Data Abertura RENACH"
          name="opening_date_renach_form_number"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('opening_date_renach_form_number')"
          >Formato incorreto</span
        >
      </div>
      <div class="vx-col md:w-2/5 w-full">
        <vs-input
          class="w-full mt-2"
          label="Campo Extra"
          v-model="data_local.extra_field"
        />
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full mt-2">
        <label class="vs-input--label">Grupos</label>
        <v-select
          appendToBody
          v-model="data_local.groups"
          :reduce="(status) => status.value"
          clearable
          multiple
          :options="groups"
          placeholder="Selecione"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        >
          <template #no-options="{}">
            Desculpe, nenhum resultado encontrado.
          </template>
        </v-select>
      </div>

      <div
        class="
          vx-col
          md:w-1/2
          w-full
          mt-6
          flex flex-wrap
          items-center
          justify-end
        "
      >
        <!-- DROPDOWN -->
        <vs-dropdown vs-trigger-click class="ml-auto cursor-pointer">
          <vs-button radius icon="icon-eye" icon-pack="feather" />
          <vs-dropdown-menu class="w-71">
            <vs-dropdown-item>
              <div @click="consultDETRANProcess" class="flex items-center">
                <feather-icon
                  icon="SearchIcon"
                  class="inline-block mr-2"
                  svgClasses="w-4 h-4"
                />
                <span>Consultar Processo DETRAN</span>
              </div>
            </vs-dropdown-item>
            <vs-dropdown-item>
              <div @click="issueOfDETRANGuides" class="flex items-center">
                <feather-icon
                  icon="SearchIcon"
                  class="inline-block mr-2"
                  svgClasses="w-4 h-4"
                />
                <span>Emissão de Guias DETRAN</span>
              </div>
            </vs-dropdown-item>
            <vs-dropdown-item>
              <div @click="resultsOfDETRANTests" class="flex items-center">
                <feather-icon
                  icon="SearchIcon"
                  class="inline-block mr-2"
                  svgClasses="w-4 h-4"
                />
                <span>Resultado de Prova DETRAN</span>
              </div>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>

        <vs-dropdown vs-trigger-click class="ml-auto cursor-pointer">
          <vs-button radius icon="icon-printer" icon-pack="feather" />
          <vs-dropdown-menu class="w-71">
            <vs-dropdown-item>
              <div
                @click="
                  popupDeliveryOfDocuments = true;
                  deliveryOfDocument = {
                    motivo: [],
                    document: [],
                    other: null,
                    student_id: null,
                  };
                "
                class="flex items-center"
              >
                <feather-icon
                  icon="PrinterIcon"
                  class="inline-block mr-2"
                  svgClasses="w-4 h-4"
                />
                <span>Entrega de Documentos</span>
              </div>
            </vs-dropdown-item>
            <vs-dropdown-item>
              <div
                @click="
                  popupPendingDocuments = true;
                  deliveryOfDocument = {
                    motivo: [],
                    document: [],
                    other: null,
                    student_id: null,
                  };
                "
                class="flex items-center"
              >
                <feather-icon
                  icon="PrinterIcon"
                  class="inline-block mr-2"
                  svgClasses="w-4 h-4"
                />
                <span>Documentos Faltantes</span>
              </div>
            </vs-dropdown-item>
            <vs-dropdown-item>
              <div
                @click="generatePrintRegistrationForm"
                class="flex items-center"
              >
                <feather-icon
                  icon="PrinterIcon"
                  class="inline-block mr-2"
                  svgClasses="w-4 h-4"
                />
                <span>Imprimir Ficha de Matrícula</span>
              </div>
            </vs-dropdown-item>
            <vs-dropdown-item>
              <div
                @click="generatePrintMedicalRecord"
                class="flex items-center"
              >
                <feather-icon
                  icon="PrinterIcon"
                  class="inline-block mr-2"
                  svgClasses="w-4 h-4"
                />
                <span>Imprimir Ficha Médica</span>
              </div>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <vs-button
          class="ml-auto mt-2"
          @click="saveChanges"
          :disabled="!validateForm || disableSave"
          >Salvar Alterações</vs-button
        >
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="warning"
          @click="resetLocalData"
          >Resetar</vs-button
        >
      </div>
    </div>

    <form
      name="formDETRANProcess"
      id="DETRANProcess"
      method="post"
      target="_blank"
      action="https://consultas.detrannet.sc.gov.br/habilitacao/ConsultaProcesso.asp"
    >
      <input type="hidden" name="modo" value="C" />
      <input
        type="hidden"
        class="var_NumeroRENACH"
        name="NumeroRENACH"
        :value="data_local.renach_form_number"
      />
      <input
        type="hidden"
        class="var_txtDocPrincipal"
        name="txtDocPrincipal"
        :value="cleanStr(data_local.cpf)"
      />
      <input type="hidden" name="oculto" value="AvancarC" />
    </form>
    <form
      name="formDETRANGuias"
      id="DETRANGuides"
      method="post"
      target="_blank"
      action="http://consultas.detrannet.sc.gov.br/habilitacao/ConsultaCpf.asp"
    >
      <input type="hidden" name="oculto" value="C" />
      <input
        type="hidden"
        name="txtDocPrincipal"
        :value="cleanStr(data_local.cpf)"
      />
      <input type="hidden" name="NumeroRenach" id="NumeroRenach" />
      <input type="hidden" name="txtDocCNH" id="txtDocCNH" />
    </form>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import WebcamPhoto from '@/components/webcam-photo/WebcamPhoto'
import axios from '@/axios.js'
import moment from 'moment'
import formatDateTime from '@/util/formatDateTime.js'
// import _ from 'lodash'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
// STORE MODULES
import moduleStudentManagement from '@/store/student-management/moduleStudentManagement.js'
import moduleServiceHistory from '@/store/service-history/moduleServiceHistory.js'

export default {
  components: { vSelect, WebcamPhoto, VueTelInput },

  props: {
    // data: {
    //   type: Object,
    //   required: true
    // },
    states: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      cellphone: '',
      vuetelbind: {
        defaultCountry: 'BR',
        inputOptions: {
          placeholder: '',
          styleClasses: 'vs-inputx vs-input--input normal hasValue'
        }
      },
      mouseOver: null,
      mouseLeave: null,
      changed: false,
      disableSave: false,
      data_local: {},
      noUserPhoto: require('@/assets/images/user/material-design-user-icon-29.jpg'),

      serviceHistory: {},

      showPdfTitle: 'PDF',
      srcPdf: '',
      popupShowPdf: false,

      popupDeliveryOfDocuments: false,
      popupPendingDocuments: false,
      deliveryOfDocument: {
        motivo: [],
        document: [],
        other: null,
        student_id: null
      },

      deliveryOfDocumentReasonList: [
        {
          value1: 'Obter a permissão para dirigir',
          text1: 'Obter a permissão para dirigir',
          value2: 'Mudança de categoria',
          text2: 'Mudança de categoria',
          value3: 'Autorização para conduzir ciclomotor',
          text3: 'Autorização para conduzir ciclomotor'
        },
        {
          value1: 'Adição de categoria',
          text1: 'Adição de categoria',
          value2: 'CNH Definitiva',
          text2: 'CNH Definitiva',
          value3: 'Habilitação de estrangeiro',
          text3: 'Habilitação de estrangeiro'
        },
        {
          value1: 'Renovação de CNH',
          text1: 'Renovação de CNH',
          value2: 'Alteração dos dados',
          text2: 'Alteração dos dados',
          value3: 'Segunda via de CNH',
          text3: 'Segunda via de CNH'
        }
      ],

      deliveryOfDocumentList: [
        {
          value1: 'Carteira de Identidade',
          text1: 'Carteira de Identidade',
          value2:
            'Carteira de Trabalho da Previdência Social (CTPS) e/ou Passaporte',
          text2:
            'Carteira de Trabalho da Previdência Social (CTPS) e/ou Passaporte',
          value3: 'Carteira de Estrangeiro com Tradução',
          text3: 'Carteira de Estrangeiro com Tradução'
        },
        {
          value1:
            'Carteira dos Cursos Especializados ou Certificados correspondentes',
          text1:
            'Carteira dos Cursos Especializados ou Certificados correspondentes',
          value2: 'Carteira Nacional de Habilitação - Xerox Legível',
          text2: 'Carteira Nacional de Habilitação - Xerox Legível',
          value3: 'Certificado Prático do CFC',
          text3: 'Certificado Prático do CFC'
        },
        {
          value1: 'Certificado Teórico do CFC',
          text1: 'Certificado Teórico do CFC',
          value2:
            'Certidão de Nascimento/Casamento/União Estável - Xerox Conferindo com o Original',
          text2:
            'Certidão de Nascimento/Casamento/União Estável - Xerox Conferindo com o Original',
          value3: 'Comprovante de Residência - Xerox Conferindo com o Original',
          text3: 'Comprovante de Residência - Xerox Conferindo com o Original'
        },
        {
          value1: 'CPF - Xerox conferindo com o original',
          text1: 'CPF - Xerox conferindo com o original',
          value2: 'Declaração de Atividade Remunerada',
          text2: 'Declaração de Atividade Remunerada',
          value3: 'Exame de Legislação (PROVA ELETRÔNICA)',
          text3: 'Exame de Legislação (PROVA ELETRÔNICA)'
        },
        {
          value1: 'Exame Prático de Direção Veicular',
          text1: 'Exame Prático de Direção Veicular',
          value2: 'Exame de Aptidão Física e Mental',
          text2: 'Exame de Aptidão Física e Mental',
          value3: 'Avaliação Psicológica',
          text3: 'Avaliação Psicológica'
        },
        {
          value1: 'Foto',
          text1: 'Foto',
          value2: 'Laudo Médico - Junta Especial',
          text2: 'Laudo Médico - Junta Especial',
          value3: 'Licença de Aprendizagem de Direção Veicular (LADV)',
          text3: 'Licença de Aprendizagem de Direção Veicular (LADV)'
        },
        {
          value1: 'Planilha de Aulas Práticas',
          text1: 'Planilha de Aulas Práticas',
          value2: 'Tela Base Estadual',
          text2: 'Tela Base Estadual',
          value3: 'Tela da Transferência',
          text3: 'Tela da Transferência'
        },
        {
          value1: 'Curso de Atualização',
          text1: 'Curso de Atualização',
          value2: 'Requerimento Aproveitamento Curso',
          text2: 'Requerimento Aproveitamento Curso',
          value3: 'Boletim de Ocorrência',
          text3: 'Boletim de Ocorrência'
        },
        {
          value1: 'Declaração',
          text1: 'Declaração',
          value2: 'Residência',
          text2: 'Residência',
          value3: 'Perda da CNH',
          text3: 'Perda da CNH'
        },
        {
          value1: 'Isenção de Taxa',
          text1: 'Isenção de Taxa',
          value2: 'Exame Toxicológico',
          text2: 'Exame Toxicológico',
          value3: 'Termo de Ciência ( )CAC ( )NIP',
          text3: 'Termo de Ciência ( )CAC ( )NIP'
        },
        {
          value1: 'Notificação Pontuação NIP',
          text1: 'Notificação Pontuação NIP',
          value2: false,
          text2: false,
          value3: false,
          text3: false
        }
      ],

      popupResultsOfDETRANTests: false,
      nota: null,
      data_prova: null,

      cityOptions: [],

      statusOptions: [
        { label: 'Ativo', value: 1 },
        { label: 'Inativo', value: 0 }
      ],

      typeOptions: [
        { label: 'Física', value: 'FISICA' },
        { label: 'Jurídica', value: 'JURIDICA' }
      ],

      countryOptions: [{ label: 'Brasil', value: 1 }],

      typeCNHOptions: [
        { label: 'Impressa', value: 1 },
        { label: 'Digital', value: 2 },
        { label: 'Imp. e Digital', value: 3 }
      ],

      categoryCNHOptions: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'AB', value: 'AB' },
        { label: 'C', value: 'C' },
        { label: 'D', value: 'D' },
        { label: 'E', value: 'E' },
        { label: 'AC', value: 'AC' },
        { label: 'AD', value: 'AD' },
        { label: 'AE', value: 'AE' },
        { label: 'ACC', value: 'ACC' }
      ],

      logradouroOptions: [
        { label: 'Rua', value: 'Rua' },
        { label: 'Avenida', value: 'Avenida' },
        { label: 'Rodovia', value: 'Rodovia' },
        { label: 'Estrada', value: 'Estrada' },
        { label: 'Lote', value: 'Lote' },
        { label: 'Servidão', value: 'Servidão' },
        { label: 'Quadra', value: 'Quadra' },
        { label: 'Travessa', value: 'Travessa' },
        { label: 'Linha', value: 'Linha' },
        { label: 'Assentamento', value: 'Assentamento' },
        { label: 'Vila', value: 'Vila' }
      ]
    }
  },
  computed: {
    servicePackages () {
      return this.$store.getters['servicePackage/forSelect']
    },
    serviceHistories () {
      return this.$store.state.serviceHistory.serviceHistories
    },
    groups () {
      return this.$store.getters['groups/getGroups']
    },
    textColor () {
      return 'text-success'
      // return this.$store.state.themePrimaryColor === '#28C76F' ? 'text-success' : 'text-white'
    },
    validateForm () {
      return !this.errors.any()
    },
    user () {
      return this.$store.state.AppActiveUser
    }
  },

  methods: {
    copyCpf () {
      window.apt = this
      if (this.data_local.cpf) this.$copyText(this.data_local.cpf.replace(/\D/g, ''))
    },
    formatDateTime (dateTimeString, type = null) {
      return formatDateTime(dateTimeString, type)
    },
    confirmDeleteServiceHistory (serviceHistory) {
      this.$store.commit('serviceHistory/SET', serviceHistory)
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: `Tem certeza que deseja excluir este serviço do histórico? \n
          Esta ação é irreversível e pode afetar relatórios, estatísticas e gráficos.`,
        accept: this.deleteServiceHistory,
        acceptText: 'Deletar'
      })
    },
    changeLastService () {
      const index = this.servicePackages.findIndex(
        (e) => e.value === this.data_local.intended_services
      )
      if (index >= 0 && this.servicePackages[index].data) this.data_local.intended_category_cnh =
          this.servicePackages[index].data.category_cnh
    },
    deleteServiceHistory () {
      // Loading
      this.$vs.loading()
      this.$store
        .dispatch(
          'serviceHistory/delete',
          this.$store.state.serviceHistory.serviceHistory
        )
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Excluídos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch((error) => {
          this.$vs.loading.close()
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        })
    },
    async generateDeliveryOfDocument () {
      this.$vs.loading()
      try {
        this.showPdfTitle = 'Entrega de Documentos'
        this.deliveryOfDocument.student_id = this.data_local.id
        const resp = await axios.post(
          `${process.env.VUE_APP_API_URL}/document/pdf/deliveryOfDocuments`,
          this.deliveryOfDocument
        )
        this.$vs.loading.close()
        this.srcPdf = `data:application/pdf;base64,${resp.data}`
        this.popupDeliveryOfDocuments = false
        this.popupShowPdf = true
      } catch (error) {
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Desculpe, algo deu errado. Por favor avise o suporte.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    async generatePendingDocument () {
      this.$vs.loading()
      try {
        this.showPdfTitle = 'Entrega de Documentos'
        this.deliveryOfDocument.student_id = this.data_local.id
        const resp = await axios.post(
          `${process.env.VUE_APP_API_URL}/document/pdf/pendingDocuments`,
          this.deliveryOfDocument
        )
        this.$vs.loading.close()
        this.srcPdf = `data:application/pdf;base64,${resp.data}`
        this.popupPendingDocuments = false
        this.popupShowPdf = true
      } catch (error) {
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Desculpe, algo deu errado. Por favor avise o suporte.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },

    async generatePrintRegistrationForm () {
      this.$vs.loading()
      try {
        this.showPdfTitle = 'Ficha de Matrícula'
        const resp = await axios.post(
          `${process.env.VUE_APP_API_URL}/document/pdf/printRegistrationForm`,
          { student_id: [this.data_local.id] }
        )
        this.$vs.loading.close()
        this.srcPdf = `data:application/pdf;base64,${resp.data}`
        this.popupDeliveryOfDocuments = false
        this.popupShowPdf = true
      } catch (error) {
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Desculpe, algo deu errado. Por favor avise o suporte.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },

    async generatePrintMedicalRecord () {
      this.$vs.loading()
      try {
        this.showPdfTitle = 'Ficha Médica'
        const resp = await axios.post(
          `${process.env.VUE_APP_API_URL}/document/pdf/printMedicalRecord`,
          { student_id: [this.data_local.id] }
        )
        this.$vs.loading.close()
        this.srcPdf = `data:application/pdf;base64,${resp.data}`
        this.popupDeliveryOfDocuments = false
        this.popupShowPdf = true
      } catch (error) {
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Desculpe, algo deu errado. Por favor avise o suporte.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },

    async consultDETRANProcess () {
      document.getElementById('DETRANProcess').submit()
    },

    async issueOfDETRANGuides () {
      document.getElementById('DETRANGuides').submit()
    },

    async resultsOfDETRANTests () {
      this.popupResultsOfDETRANTests = true

      this.$vs.loading({
        container: '#loadResultsOfDETRANTests',
        scale: 0.6
      })

      try {
        const resp = await axios.get(
          `${process.env.VUE_APP_API_URL}/student/getTestResultDetran/${this.data_local.cpf}`
        )
        this.nota = resp.data.nota
        this.data_prova = resp.data.data_prova
        this.$vs.loading.close('#loadResultsOfDETRANTests > .con-vs-loading')
      } catch (error) {
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Desculpe, algo deu errado. Por favor avise o suporte.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },

    async updateStudentNote () {
      this.$vs.loading()
      try {
        await axios.put(`${process.env.VUE_APP_API_URL}/schedule/test/result`, {
          student: this.data_local,
          nota: this.nota,
          data_prova: this.data_prova
        })
        this.popupResultsOfDETRANTests = false
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Dados alterados com sucesso.',
          iconPack: 'feather',
          icon: 'icon-check',
          color: 'success'
        })
      } catch (error) {
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Desculpe, algo deu errado. Por favor avise o suporte.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },

    openLoading () {
      this.$vs.loading({
        container: '#photo-loading',
        scale: 0.6
      })
    },

    closeLoading () {
      this.$vs.loading.close('#photo-loading > .con-vs-loading')
    },

    onValueChanged (newValue) {
      if (this.data_local.photo) this.data_local.photo.url = newValue
      else this.data_local.photo = { url: newValue }
    },

    loadCamera () {
      this.$refs.webcam.loadCamera()
    },

    capitalize (str) {
      return str.slice(0, 1).toUpperCase() + str.slice(1, str.length)
    },

    async searchZipCode ($event) {
      const zip_code = this.data_local.address.zip_code
        .trim()
        .replace(/[^0-9]/g, '')

      if (
        zip_code.length === 8 &&
        ($event.keyCode < 37 || $event.keyCode > 40)
      ) {
        // $event.keyCode evita chamadas de API ao teclar setas

        axios._noHeaders = true //enviando um get sem Autorization no header
        const resp = await axios.get(
          `https://viacep.com.br/ws/${zip_code}/json`
        )
        if (resp.data.erro) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: 'Este CEP não existe ou não foi encontrado.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }

        this.data_local.address.neighborhood = resp.data.bairro
        this.data_local.address.complement = resp.data.complemento

        //abaixo testo a primeira palavra da rua (logradouro), se existir removo da rua e seleciono o logradouro
        const rua = /Rua /i
        const avenida = /Avenida /i
        const rodovia = /Rodovia /i
        const estrada = /Estrada /i
        const lote = /Lote /i
        const servidao = /Servidão /i
        const quadra = /Quadra /i
        const travessa = /Travessa /i
        const linha = /Linha /i

        if (rua.test(resp.data.logradouro)) {
          this.data_local.address.logradouro = 'Rua'
          const regEx = new RegExp('Rua ', 'ig')
          this.data_local.address.street = resp.data.logradouro.replace(
            regEx,
            ''
          )
        } else if (avenida.test(resp.data.logradouro)) {
          this.data_local.address.logradouro = 'Avenida'
          const regEx = new RegExp('Avenida ', 'ig')
          this.data_local.address.street = resp.data.logradouro.replace(
            regEx,
            ''
          )
        } else if (rodovia.test(resp.data.logradouro)) {
          this.data_local.address.logradouro = 'Rodovia'
          const regEx = new RegExp('Rodovia ', 'ig')
          this.data_local.address.street = resp.data.logradouro.replace(
            regEx,
            ''
          )
        } else if (estrada.test(resp.data.logradouro)) {
          this.data_local.address.logradouro = 'Estrada'
          const regEx = new RegExp('Estrada ', 'ig')
          this.data_local.address.street = resp.data.logradouro.replace(
            regEx,
            ''
          )
        } else if (lote.test(resp.data.logradouro)) {
          this.data_local.address.logradouro = 'Lote'
          const regEx = new RegExp('Lote ', 'ig')
          this.data_local.address.street = resp.data.logradouro.replace(
            regEx,
            ''
          )
        } else if (servidao.test(resp.data.logradouro)) {
          this.data_local.address.logradouro = 'Servidão'
          const regEx = new RegExp('Servidão ', 'ig')
          this.data_local.address.street = resp.data.logradouro.replace(
            regEx,
            ''
          )
        } else if (quadra.test(resp.data.logradouro)) {
          this.data_local.address.logradouro = 'Quadra'
          const regEx = new RegExp('Quadra ', 'ig')
          this.data_local.address.street = resp.data.logradouro.replace(
            regEx,
            ''
          )
        } else if (travessa.test(resp.data.logradouro)) {
          this.data_local.address.logradouro = 'Travessa'
          const regEx = new RegExp('Travessa ', 'ig')
          this.data_local.address.street = resp.data.logradouro.replace(
            regEx,
            ''
          )
        } else if (linha.test(resp.data.logradouro)) {
          this.data_local.address.logradouro = 'Linha'
          const regEx = new RegExp('Linha ', 'ig')
          this.data_local.address.street = resp.data.logradouro.replace(
            regEx,
            ''
          )
        } else {
          this.data_local.address.street = resp.data.logradouro
        }

        const state = this.states.find((o) => o.initials === resp.data.uf)
        this.data_local.address.state_id = state.value
        await this.fillCity(state.value, resp.data.localidade)
      }
    },

    async fillCity (stateId, city = null) {
      this.cityOptions = []
      try {
        if (stateId) {
          const resp = await axios.get(
            `${process.env.VUE_APP_API_URL}/cities/${stateId}`
          )
          this.cityOptions.push({ value: null, label: 'Selecione' })

          for (const k in resp.data) {
            this.cityOptions.push({
              value: resp.data[k].id,
              label: resp.data[k].city
            })
          }
          if (city) {
            //procura pelo id, se não encontrar peocura pelo nome (motivo: da api recebo ID e do CEP recebo nome)
            let ct = this.cityOptions.find((x) => x.value === city)
            if (!ct) {
              ct = this.cityOptions.find((x) => x.label === city)
            }
            this.data_local.address.city_id = ct.value
          }
        }
      } catch (error) {
        console.error(error)
      }
    },

    saveChanges () {
      if (!this.validateForm) return
      // Loading
      this.$vs.loading()
      this.disableSave = true
      setTimeout(() => {
        this.disableSave = false
      }, 2500) //TRAVA O BOTÃO SALVAR POR 2,5 SEGUNDOS

      this.changed = false //salvou por isso não preciso alertar ao trocar de página

      // VENCIMMENTO DO RENACH AUTOMÁTICO PARA 1 ANO // SEGUNDO REGRAS EM 13/04/2021
      if (
        this.data_local.opening_date_renach_form_number &&
        moment(this.data_local.opening_date_renach_form_number).year() > 1000
      ) {
        this.data_local.date_expiration_renach_form_number = moment(
          this.data_local.opening_date_renach_form_number
        )
          .add(1, 'year')
          .format('YYYY-MM-DD')
      } else {
        this.data_local.date_expiration_renach_form_number = ''
      }
      this.data_local.phones[0].phone = this.cellphone

      this.$store
        .dispatch('studentManagement/updateStudent', this.data_local)
        .then(() => {
          this.resetLocalData()
          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Salvos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch((error) => {
          this.$vs.loading.close()
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        })
    },
    resetLocalData (change = false) {
      this.data_local = JSON.parse(
        JSON.stringify(this.$store.getters['studentManagement/getStudent'])
      )
      this.cellphone = `${this.data_local.phones[0].phone}`
      if (this.data_local.intended_services) {
        const index = this.servicePackages.findIndex(
          (e) => e.value === this.data_local.intended_services
        )
        if (index < 0) {
          // foi excluído
          this.servicePackages.push({
            label: 'SERVIÇO EXCLUÍDO',
            value: this.data_local.intended_services
          })
        }
      }
      this.$store
        .dispatch('serviceHistory/fetchAll', this.data_local.id)
        .then(() => {})
        .catch((error) => {
          console.error(error)
        })
      this.changed = change
    },

    cleanStr (str) {
      return str.replace(/\.|-/g, '')
    },

    upper (obj) {
      for (const prop in obj) {
        if (typeof obj[prop] === 'string') {
          //LISTAR OS CAMPOS DO OBJETO QUE DEVEM SER ALTERADOS
          if (
            prop === 'name' ||
            prop === 'street' ||
            prop === 'complement' ||
            prop === 'neighborhood' ||
            prop === 'extra_field'
          ) {
            obj[prop] = obj[prop].toUpperCase()
          }
        }
        if (typeof obj[prop] === 'object' && prop !== 'phones') {
          this.upper(obj[prop])
        }
      }
      return obj
    }
  },
  watch: {
    data_local: {
      deep: true,
      handler (data_local) {
        if (this.user.company.configuration) {
          if (
            this.user.company.configuration.general &&
            this.user.company.configuration.general.uppercase_forms
          ) {
            // SE CONFIGURADO PARA USAR MAIÚSUCAS NOS FORMS
            // MÉTODO VARRE TODO O OBJETO COLOCANDO MAIÚSCULOS OS CAMPOS STRING DO IF DENTRO DO MÉTODO upper
            this.upper(data_local)
          }
        }

        // foi preciso tratar os telefones pois uma informação vinha com mascara e outra sem
        const comp1 = JSON.parse(JSON.stringify(data_local))
        const comp2 = JSON.parse(
          JSON.stringify(this.$store.getters['studentManagement/getStudent'])
        )
        comp1.phones = JSON.stringify(comp1.phones).replace(/[^\d]+/g, '')
        comp2.phones = JSON.stringify(comp2.phones).replace(/[^\d]+/g, '')

        //OBSERVO DATA_LOCAL comparando com os dados do GETTER PARA AVISAR CASO HAJA UMA ALTERAÇÃO E SE TENTE SAIR DA PÁGINA SEM SALVAR
        if (JSON.stringify(comp1) === JSON.stringify(comp2)) {
          this.changed = false
        } else {
          this.changed = true
        }

        // APENAS UM CÓDIGO UTILIZADO PARA AUXILIAR NA COMPARAÇÃO ENTRE OBJETOS (DESCOMENTAR LODASH)

        // function difference (origObj, newObj) {
        //   function changes (newObj, origObj) {
        //     let arrayIndexCounter = 0
        //     return _.transform(newObj, function (result, value, key) {
        //       if (!_.isEqual(value, origObj[key])) {
        //         const resultKey = _.isArray(origObj) ? arrayIndexCounter++ : key
        //         result[resultKey] = (_.isObject(value) && _.isObject(origObj[key])) ? _.isDate(value) ? value : changes(value, origObj[key]) : value
        //       }
        //     })
        //   }
        //   return changes(newObj, origObj)
        // }
        // console.log(difference(data_local, this.$store.getters['studentManagement/getStudent']))

        // console.log(JSON.stringify(data_local.phones).replace(/[^\d]+/g, ''))
        // console.log(JSON.stringify(this.$store.getters['studentManagement/getStudent'].phones).replace(/[^\d]+/g, ''))
      }
    }
    // data: {
    //   efetuei testes e aparentemente o bloco não é necessário. Deixei caso verifique a necessidade. Pode ser apagado em um tempo
    //   deep: true,
    //   handler () {
    //     OBSERVO DATA PARA ALTERAR OS DADOS NO MOMENTO EM QUE A PÁGINA É TROCADA
    //     this.data_local = JSON.parse(JSON.stringify(this.data))
    //     this.resetLocalData()
    //   }
    // }
  },
  mounted () {
    this.openLoading()
    if (this.data_local.address.state_id) {
      if (this.data_local.address.city_id) {
        this.fillCity(
          this.data_local.address.state_id,
          this.data_local.address.city_id
        )
      } else {
        this.fillCity(this.data_local.address.state_id)
      }
    }
  },
  created () {
    // Register Module StudentManagement Module
    if (!moduleStudentManagement.isRegistered) {
      this.$store.registerModule('studentManagement', moduleStudentManagement)
      moduleStudentManagement.isRegistered = true
    }

    if (!moduleServiceHistory.isRegistered) {
      this.$store.registerModule('serviceHistory', moduleServiceHistory)
      moduleServiceHistory.isRegistered = true
    }

    this.resetLocalData()
  }
}
</script>
<style>
hr {
  margin-top: 2px;
  border: none;
  height: 1px;
  /* Set the hr color */
  color: rgb(204, 204, 204); /* old IE */
  background-color: rgb(204, 204, 204); /* Modern Browsers */
}

#popupResultsOfDETRANTests .vs-popup {
  width: 60% !important;
}

.con-vs-popup .vs-popup {
  width: 100%;
}

.noOverflow .vs-popup--content {
  overflow: hidden;
}

.con-vs-checkbox {
  /* position: relative; */
  display: -webkit-box !important;
}
</style>
