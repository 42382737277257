<template>
  <div class="w-full">
    <vs-input
      class="w-full"
      :label="label"
      v-model="localValue"
      :data-vv-as="label"
      :v-validate="validate"
      :disabled="disabled"
      name="single"
    />
    <span class="text-danger text-sm" v-show="errors.has('single')">{{
      errors.first("single")
    }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    label: {},
    disabled: {},
    validate: {}
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  data () {
    return {}
  }
}
</script>
