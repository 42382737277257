var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vx-row" },
    [
      _c("select-bank", {
        staticClass: "vx-col md:w-1/3",
        model: {
          value: _vm.bank,
          callback: function ($$v) {
            _vm.bank = $$v
          },
          expression: "bank",
        },
      }),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/3 w-full mt-0" },
        [
          _c("vs-input", {
            staticClass: "w-full mt-0",
            attrs: { label: "Agência" },
            model: {
              value: _vm.branch,
              callback: function ($$v) {
                _vm.branch = $$v
              },
              expression: "branch",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/3 w-full mt-0" },
        [
          _c("vs-input", {
            staticClass: "w-full mt-0",
            attrs: { label: "Conta" },
            model: {
              value: _vm.account,
              callback: function ($$v) {
                _vm.account = $$v
              },
              expression: "account",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }