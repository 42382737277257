<template>
  <div class="w-full">
    <div class="vx-row w-full">
      <div class="vx-col w-full">
        <!-- <div class="vx-col">
          <select-student v-model="student" disabled :use-id="true"/>
        </div> -->
        <div class="vx-col" v-if="!debit || !debit.id">
          <select-service v-model="service" @input="afterChangeService" />
        </div>
        <div class="vx-col">
          <sig-input-currency v-model="valueService" label="Valor" />
        </div>
        <div class="vx-col">
          <select-situation-debit v-model="situation" />
        </div>
        <div>
           <sig-input v-model="observation" label="Observação" />
        </div>
          <vs-divider />
    <div class="flex flex-row-reverse">
      <vs-button color="primary" type="filled" @click="afterClickToSave">Salvar</vs-button>
    </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectService from '@/components/register/service/SelectService.vue'
import SelectStudent from '@/components/register/student/SelectStudent.vue'
import SelectSituationDebit from './SelectSituationDebit.vue'
import SigInputCurrency from '@/components/sig/SigInputCurrency.vue'
import SigInput from '@/components/sig/SigInput.vue'
import model from '@/store/register/debitStudent/model'

export default {
  components: {
    SelectService,
    SelectSituationDebit,
    SigInputCurrency,
    SigInput,
    SelectStudent
  },
  props: {
    value: {
      default () {
        return {}
      }
    },
    studentId: {
    }
  },
  data () {
    return {
      service: null,
      valueService: this.debit ? this.debit.value : 0,
      student: this.studentId,
      situation: this.debit ? this.debit.situation : null,
      observation: this.debit ? this.debit.observation : '',
      localRecord: {}
    }
  },
  computed: {
    debit: {
      get () {
        return this.value || this.localRecord
      },
      set (value) {
        if (this.value === undefined) this.localRecord = value
        else this.$emit('input', value)
      }
    }
  },
  watch: {
    debit (value) {
      if (value) {
        this.situation = value.situation ? value.situation : ''
        this.observation = value.observation ? value.observation : ''
        this.valueService = value.value ? value.value : 0
      }
    }
  },
  methods: {
    afterClickToSave () {
      // validar primeiro
      if (!this.validate()) return
      const debit = {
        name: this.service ? this.service.service : '',
        student_id: this.studentId,
        situation: this.situation,
        value: this.valueService,
        observation: this.observation
      }

      if (this.value && this.value.id) {
        debit.name = this.value.name
        debit.id = this.value.id
      }
      const me = this
      this.$vs.loading()
      this.$store.dispatch(
        model.$store.dispatch.saveSingle,
        new model.constructor(debit).$serialize()
      ).then(() => {
        me.mixinNotify('Operação executada com sucesso')
        me.$emit('saved')
      })
        .catch(this.mixinCatch)
        .finally(this.$vs.loading.close)
    },
    afterChangeService () {
      this.valueService = this.service.value
    },
    validate () {
      // lista de validações
      const rules = [
        () => {
          if (!this.service && !this.debit.name) this.mixinNotify('Selecione o serviço', 'warning')
          return !!this.service || !!this.debit.name
        },
        () => {
          if (!this.situation) this.mixinNotify('Selecione a situação', 'warning')
          return !!this.situation
        }
      ]

      for (const validate of rules) {
        if (!validate()) return false
      }
      return true
    }
  },
  created () {}
}
</script>

