import axios from '@/axios.js'

export default {
  getByCpfCnpj (context, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_API_URL}/guarantor/getByCpfCnpj/${payload}`)
        .then((resp) => {
          resolve(resp)
        })
        .catch((error) => { reject(error) })
    })
  },
  store (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/guarantor`, payload)
        .then((resp) => {
          resolve(resp)
        })
        .catch((error) => { reject(error) })
    })
  },
  update (context, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/guarantor/${payload.id}`, payload)
        .then((resp) => {
          resolve(resp)
        })
        .catch((error) => { reject(error) })
    })
  },
  delete (context, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_API_URL}/guarantor/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
