var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "email-app" } },
    [
      _c("vx-card", [
        _c(
          "div",
          {
            staticClass: "tabs-container px-6 pt-6",
            attrs: { slot: "no-body" },
            slot: "no-body",
          },
          [
            _c(
              "vs-tabs",
              {
                staticClass: "tab-action-btn-fill-conatiner",
                model: {
                  value: _vm.activeTab,
                  callback: function ($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab",
                },
              },
              [
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "Prática",
                      "icon-pack": "feather",
                      icon: "icon-calendar",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [
                        _c("grid-practical", {
                          ref: "gridPractical",
                          attrs: { student: this.student },
                          on: { openViewSideBar: _vm.openViewSideBar },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      label: "Teórica",
                      "icon-pack": "feather",
                      icon: "icon-file-text",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text" },
                      [
                        _c("grid-theoretical", {
                          ref: "gridTheoretical",
                          attrs: { student: this.student },
                          on: { openViewSideBar: _vm.openViewSideBar },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.activeTab === 0
        ? _c("practical-schedule-view", {
            attrs: { isSidebarActive: _vm.isSidebarActive },
            on: {
              refreshData: function ($event) {
                return _vm.refreshGridPractical(false)
              },
              closeSidebar: _vm.closeViewSidebar,
            },
          })
        : _vm.activeTab === 1
        ? _c("theoretical-schedule-view", {
            attrs: { isSidebarActive: _vm.isSidebarActive },
            on: {
              refreshData: function ($event) {
                return _vm.refreshGridTheoretical(false)
              },
              closeSidebar: _vm.closeViewSidebar,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }