import axios from '@/axios.js'

export default {
  saveTenant ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/tenant`, payload)
        .then((resp) => {
          commit('SET_TENANT', resp.data)
          resolve(resp)
        })
        .catch((error) => { reject(error) })
    })
  },
  delete ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_API_URL}/tenant/${id}`)
        .then((response) => {
          commit('DELETE_TENANT')
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
