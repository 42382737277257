<template>
  <div>
    <label class="vs-input--label">{{ label }}</label>
    <v-select
      v-model="localValue"
      style="background-color: white"
      :reduce="(option) => option.value"
      :options="options"
      :appendToBody="appendToBody"
      :dir="$vs.rtl ? 'rtl' : 'ltr'"
      :multiple="multiple"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    label: {
      default () {
        return 'Ressarcimento'
      }
    },
    multiple: {},
    appendToBody: {}
  },
  data () {
    return {
      options:[
        {label: 'Sem ressarcimento', value: 'no_refund'},
        {label: 'Devolver valor ao aluno', value: 'refund_student'},
        {label: 'Receber valor do aluno', value: 'refund_company'}
      ]
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

