var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "vx-row" },
      [
        _c("sig-input", {
          staticClass: "vx-col md:w-1/1",
          attrs: { label: "Serviço", disabled: "" },
          model: {
            value: _vm.studentDocument.service_package,
            callback: function ($$v) {
              _vm.$set(_vm.studentDocument, "service_package", $$v)
            },
            expression: "studentDocument.service_package",
          },
        }),
        _c("sig-input", {
          staticClass: "vx-col md:w-1/1",
          attrs: { label: "Situação" },
          model: {
            value: _vm.studentDocument.situation,
            callback: function ($$v) {
              _vm.$set(_vm.studentDocument, "situation", $$v)
            },
            expression: "studentDocument.situation",
          },
        }),
        _c("sig-input", {
          staticClass: "vx-col md:w-1/1",
          attrs: { label: "Retorno Detran" },
          model: {
            value: _vm.studentDocument.response,
            callback: function ($$v) {
              _vm.$set(_vm.studentDocument, "response", $$v)
            },
            expression: "studentDocument.response",
          },
        }),
        _c("sig-input", {
          staticClass: "vx-col md:w-1/1",
          attrs: { label: "Observação" },
          model: {
            value: _vm.studentDocument.observation,
            callback: function ($$v) {
              _vm.$set(_vm.studentDocument, "observation", $$v)
            },
            expression: "studentDocument.observation",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }