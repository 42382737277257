var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "border border-solid d-theme-border-grey-light rounded relative overflow-hidden",
    },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup50",
          attrs: {
            fullscreen: false,
            title: "Vincular Instrutor e Veículo",
            active: _vm.popupAddEmployeeVehicle,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupAddEmployeeVehicle = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Instrutor"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "vue_select_drop_size_150",
                  staticStyle: { "background-color": "white" },
                  attrs: {
                    "data-vv-as": "Instrutor",
                    "data-vv-scope": "add_employee_vehicle",
                    name: "employee_id",
                    reduce: (option) => option.value,
                    options: _vm.employees,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v("Desculpe, nenhum resultado encontrado."),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.employee_id,
                    callback: function ($$v) {
                      _vm.employee_id = $$v
                    },
                    expression: "employee_id",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has(
                          "add_employee_vehicle.employee_id"
                        ),
                        expression:
                          "errors.has('add_employee_vehicle.employee_id')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.errors.first("add_employee_vehicle.employee_id")
                      )
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "vx-row" },
            [
              _c(
                "div",
                { staticClass: "vx-col md:w-full w-full mt-0" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v("Veículo"),
                  ]),
                  _c("v-select", {
                    staticClass: "vue_select_drop_size_90",
                    staticStyle: { "background-color": "white" },
                    attrs: {
                      reduce: (option) => option.data,
                      options: _vm.vehicles,
                      placeholder: "Selecione",
                      dir: _vm.$vs.rtl ? "rtl" : "ltr",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "no-options",
                        fn: function ({}) {
                          return [
                            _vm._v(
                              "\n            Desculpe, nenhum resultado encontrado.\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.vehicle,
                      callback: function ($$v) {
                        _vm.vehicle = $$v
                      },
                      expression: "vehicle",
                    },
                  }),
                ],
                1
              ),
              _c("vs-divider"),
              _c("div", { staticClass: "vx-col w-full mt-0" }, [
                _c(
                  "div",
                  { staticClass: "mt-2 flex flex-wrap items-center justify" },
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "w-full",
                        attrs: { disabled: _vm.validateForm },
                        on: { click: _vm.addEmployeeVehicle },
                      },
                      [_vm._v("Acicionar Instrutor e Veículo")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "vs-sidebar",
        {
          staticClass: "items-no-padding",
          attrs: {
            parent: "#schedule-exam",
            "click-not-close": _vm.clickNotClose,
            "hidden-background": _vm.clickNotClose,
          },
          model: {
            value: _vm.isEmailSidebarActive,
            callback: function ($$v) {
              _vm.isEmailSidebarActive = $$v
            },
            expression: "isEmailSidebarActive",
          },
        },
        [_c("schedule-sidebar")],
        1
      ),
      _c(
        "vs-sidebar",
        {
          staticClass: "full-vs-sidebar email-view-sidebar items-no-padding",
          attrs: {
            "click-not-close": "",
            parent: "#schedule-exam",
            "hidden-background": true,
            "position-right": "",
          },
          model: {
            value: _vm.isSidebarActive,
            callback: function ($$v) {
              _vm.isSidebarActive = $$v
            },
            expression: "isSidebarActive",
          },
        },
        [
          _c(
            "div",
            { staticClass: "mail-sidebar-content px-0 sm:pb-6 h-full" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-wrap items-center email-header justify-between md:px-8 px-6 sm:pb-2 sm: pt-6 d-theme-dark-bg",
                },
                [
                  _c("div", { staticClass: "flex mb-4" }, [
                    _c(
                      "div",
                      { staticClass: "flex items-center" },
                      [
                        _c("feather-icon", {
                          staticClass: "cursor-pointer hover:text-primary mr-4",
                          attrs: {
                            icon: _vm.$vs.rtl
                              ? "ArrowRightIcon"
                              : "ArrowLeftIcon",
                            "svg-classes": "w-6 h-6",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("closeSidebar")
                            },
                          },
                        }),
                        _c("h4", [_vm._v("VOLTAR")]),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "ml-10 mb-4 mt-1" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "email__actions--single flex items-baseline",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex border d-theme-dark-bg items-center justify-end",
                          },
                          [
                            _c(
                              "vx-tooltip",
                              {
                                staticClass: "ml-4",
                                attrs: {
                                  text: "Abrir Barra de Calendário",
                                  position: "top",
                                },
                              },
                              [
                                _c("feather-icon", {
                                  staticClass:
                                    "md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer",
                                  attrs: { icon: "MenuIcon" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.toggleEmailSidebar(true)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _c("vue-context", { ref: "menu" }, [
                _vm.contextAdd
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          staticClass:
                            "flex items-center text-sm hover:text-primary",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.contextMenuClicked("ADD_TIME")
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            attrs: {
                              icon: "PlusCircleIcon",
                              svgClasses: "w-5 h-5",
                            },
                          }),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v("Adicionar Horário"),
                          ]),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.contextChange
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          staticClass:
                            "flex items-center text-sm hover:text-primary",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.contextMenuClicked("CHANGE_TIME")
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "EditIcon", svgClasses: "w-5 h-5" },
                          }),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v("Alterar Horário"),
                          ]),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.contextCopy
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          staticClass:
                            "flex items-center text-sm hover:text-primary",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.contextMenuClicked("COPY")
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "CopyIcon", svgClasses: "w-5 h-5" },
                          }),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v("Copiar Grupo"),
                          ]),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.contextPaste
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          staticClass:
                            "flex items-center text-sm hover:text-primary",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.contextMenuClicked("PASTE")
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            attrs: {
                              icon: "FilePlusIcon",
                              svgClasses: "w-5 h-5",
                            },
                          }),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v("Colar Grupo"),
                          ]),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.contextCopy
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          staticClass:
                            "flex items-center text-sm hover:text-primary",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.contextMenuClicked(
                                "ADD_EMPLOYEE_VEHICLE"
                              )
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            attrs: {
                              icon: "UserPlusIcon",
                              svgClasses: "w-5 h-5",
                            },
                          }),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v("Vincular Instrutor e Veículo"),
                          ]),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.contextDelete
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          staticClass:
                            "flex items-center text-sm hover:text-danger",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.contextMenuClicked("DELETE")
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            attrs: {
                              icon: "MinusCircleIcon",
                              svgClasses: "w-5 h-5",
                            },
                          }),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v("Excluir Agendamento"),
                          ]),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "email__actions p-2 border border-r-0 border-l-0 border-solid d-theme-border-grey-light",
                },
                [
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-2/3 w-full mt-0" },
                      [
                        _c("label", { staticClass: "vs-input--label" }, [
                          _vm._v("Aluno(s)"),
                        ]),
                        _c("v-select", {
                          staticStyle: { "background-color": "white" },
                          attrs: {
                            multiple: "",
                            clearable: true,
                            filterable: true,
                            options: _vm.studentOptions,
                            placeholder: "Digite CPF ou nome do aluno...",
                            dir: _vm.$vs.rtl ? "rtl" : "ltr",
                          },
                          on: { search: _vm.debouncedGetSearchStudent },
                          scopedSlots: _vm._u([
                            {
                              key: "no-options",
                              fn: function ({}) {
                                return [
                                  _vm._v(
                                    "\n                Nenhum resultado encontrado.\n              "
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.students,
                            callback: function ($$v) {
                              _vm.students = $$v
                            },
                            expression: "students",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/3 w-full mt-0" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: { label: "Examinador" },
                          model: {
                            value: _vm.examinador,
                            callback: function ($$v) {
                              _vm.examinador = $$v
                            },
                            expression: "examinador",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                      [
                        _c("label", { staticClass: "vs-input--label" }, [
                          _vm._v("Categoria"),
                        ]),
                        _c("v-select", {
                          staticStyle: { "background-color": "white" },
                          attrs: {
                            clearable: false,
                            reduce: (option) => option.value,
                            options: _vm.categoryCNHOptions,
                            placeholder: "Selecione",
                            dir: _vm.$vs.rtl ? "rtl" : "ltr",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "no-options",
                              fn: function ({}) {
                                return [
                                  _vm._v(
                                    "\n                  Nenhum resultado encontrado.\n                "
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.category,
                            callback: function ($$v) {
                              _vm.category = $$v
                            },
                            expression: "category",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "vx-col md:w-4/5 w-full mt-0" }, [
                      _c("div", {
                        staticClass: "mt-4 flex flex-wrap items-center",
                      }),
                    ]),
                  ]),
                ]
              ),
              _c(
                _vm.scrollbarTag,
                {
                  key: _vm.$vs.rtl,
                  ref: "mailListPS",
                  tag: "component",
                  staticClass: "email-content-scroll-area",
                  attrs: { settings: _vm.settings },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "vs-con-loading__container",
                      attrs: { id: "loading" },
                    },
                    _vm._l(_vm.tables, function (table, tIndex) {
                      return _c(
                        "vs-table",
                        {
                          key: tIndex,
                          staticClass: "bordered",
                          staticStyle: { overflow: "-webkit-paged-y" },
                          attrs: { data: _vm.selectedDays, hoverFlat: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ data }) {
                                  return [
                                    _c(
                                      "vs-tr",
                                      {
                                        staticStyle: {
                                          "font-size": "12px",
                                          opacity: "1 !important",
                                          "vertical-align": "top",
                                        },
                                        attrs: { height: "30" },
                                      },
                                      _vm._l(
                                        _vm.handleRange(table),
                                        function (hr, itd) {
                                          return _c(
                                            "vs-td",
                                            {
                                              key: itd,
                                              nativeOn: {
                                                contextmenu: function ($event) {
                                                  $event.preventDefault()
                                                  $event.stopPropagation()
                                                  return _vm.contextMenu(
                                                    $event,
                                                    hr - 1
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._l(
                                                _vm.selectedDays[hr - 1]
                                                  .holidaySchedule,
                                                function (holiday, hd) {
                                                  return _c("feather-icon", {
                                                    key: `${hd}_holiday`,
                                                    staticClass: "mr-1",
                                                    attrs: {
                                                      title:
                                                        holiday.holidayName,
                                                      icon: "StarIcon",
                                                      svgClasses: "w-4 h-4",
                                                    },
                                                  })
                                                }
                                              ),
                                              _vm._l(
                                                _vm._.groupBy(
                                                  _vm.selectedDays[hr - 1]
                                                    .studentSchedule,
                                                  "start_time"
                                                ),
                                                function (sc, hour) {
                                                  return _c(
                                                    "span",
                                                    {
                                                      key: hour,
                                                      on: {
                                                        contextmenu: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          $event.stopPropagation()
                                                          return _vm.contextMenu(
                                                            $event,
                                                            hr - 1,
                                                            hour
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "mb-1 font-semibold flex p-1 bg-secondary text-white cursor-pointer",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.addSchedule(
                                                                _vm
                                                                  .selectedDays[
                                                                  hr - 1
                                                                ],
                                                                hour
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(_vm._s(hour))]
                                                      ),
                                                      _vm._l(
                                                        sc.sort((a, b) => {
                                                          if (
                                                            a.student &&
                                                            b.student
                                                          )
                                                            return a.student.name.localeCompare(
                                                              b.student.name
                                                            )
                                                        }),
                                                        function (
                                                          schedule,
                                                          idx
                                                        ) {
                                                          return _c(
                                                            "span",
                                                            {
                                                              key: idx,
                                                              staticClass:
                                                                "cursor-pointer hover:text-primary hover:semibold",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.viewSchedules(
                                                                      sc
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              schedule.student
                                                                ? _c("div", [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "flex",
                                                                        on: {
                                                                          contextmenu:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              $event.stopPropagation()
                                                                              return _vm.contextMenu(
                                                                                $event,
                                                                                -1
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            schedule.student &&
                                                                              schedule
                                                                                .student
                                                                                .name
                                                                              ? schedule
                                                                                  .student
                                                                                  .name
                                                                              : ""
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c("hr"),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      _c("vs-divider"),
                                                    ],
                                                    2
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                    _c(
                                      "vs-tr",
                                      {
                                        staticStyle: {
                                          opacity: "1 !important",
                                          border: "10px",
                                        },
                                      },
                                      [
                                        _c(
                                          "vs-td",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                            },
                                            attrs: {
                                              colspan:
                                                _vm.handleRange(table).length +
                                                1,
                                            },
                                          },
                                          [_c("vs-divider")],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "template",
                            { slot: "thead" },
                            _vm._l(_vm.handleRange(table), function (hr, i) {
                              return _c(
                                "vs-th",
                                {
                                  key: i,
                                  staticStyle: {
                                    "font-size": "11px",
                                    "min-width": "124px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.weekDays[
                                          _vm.selectedDays[hr - 1]
                                            .weekdayPosition
                                        ]
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm._f("dateTime")(
                                          _vm.selectedDays[hr - 1].id,
                                          "date"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        2
                      )
                    }),
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-6 flex flex-wrap items-center justify-center",
                    },
                    [
                      _c(
                        "vs-alert",
                        {
                          staticClass: "w-5/6",
                          staticStyle: { height: "-webkit-fill-available" },
                          attrs: {
                            color: "warning",
                            title: "Defina o período",
                            active: !_vm.tables,
                          },
                        },
                        [
                          _vm._v(
                            "\n              Selecione a(s) data(s) no calendário ou defina um intervalo de datas para ver\n              os agendamentos conforme o filtro superior.\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }