var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("popup-pdf", { ref: "popupPdf" }),
      _c("sig-data-list", {
        ref: "list",
        attrs: {
          model: _vm.model,
          fetch: _vm.params,
          "allow-search": _vm.allowSearch,
        },
        on: {
          "search-end": function ($event) {
            return _vm.$emit("search-end")
          },
          "grid-ready": function ($event) {
            return _vm.$emit("grid-ready")
          },
          saved: function ($event) {
            return _vm.search(false)
          },
          register: _vm.register,
          editing: _vm.register,
        },
        scopedSlots: _vm._u([
          {
            key: "form",
            fn: function () {
              return [
                _c(
                  "sig-popup",
                  {
                    ref: "popup",
                    model: {
                      value: _vm.popup,
                      callback: function ($$v) {
                        _vm.popup = $$v
                      },
                      expression: "popup",
                    },
                  },
                  [
                    _c("observation-student-form", {
                      ref: "form",
                      attrs: { "student-id": _vm.studentId },
                      on: {
                        saved: function ($event) {
                          _vm.$refs.popup.isOpen = false
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "action",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "ml-2" },
                  [
                    _c(
                      "vx-tooltip",
                      { attrs: { text: "Relatório PDF", position: "top" } },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "svg-hover h-8 w-8 cursor-pointer",
                            staticStyle: {
                              "-ms-transform": "rotate(360deg)",
                              "-webkit-transform": "rotate(360deg)",
                              transform: "rotate(360deg)",
                            },
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              "xmlns:xlink": "http://www.w3.org/1999/xlink",
                              "aria-hidden": "true",
                              focusable: "false",
                              width: "0.86em",
                              height: "1em",
                              preserveAspectRatio: "xMidYMid meet",
                              viewBox: "0 0 1536 1792",
                            },
                            on: { click: _vm.report },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M1468 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28H96q-40 0-68-28t-28-68V96q0-40 28-68T96 0h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528V640H992q-40 0-68-28t-28-68V128H128v1536h1280zm-514-593q33 26 84 56q59-7 117-7q147 0 177 49q16 22 2 52q0 1-1 2l-2 2v1q-6 38-71 38q-48 0-115-20t-130-53q-221 24-392 83q-153 262-242 262q-15 0-28-7l-24-12q-1-1-6-5q-10-10-6-36q9-40 56-91.5t132-96.5q14-9 23 6q2 2 2 4q52-85 107-197q68-136 104-262q-24-82-30.5-159.5T657 552q11-40 42-40h22q23 0 35 15q18 21 9 68q-2 6-4 8q1 3 1 8v30q-2 123-14 192q55 164 146 238zm-576 411q52-24 137-158q-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132q1-7 7-44q0-3 7-43q1-4 4-8q-1-1-1-2q-1-2-1-3q-1-22-13-36q0 1-1 2v2zm-124 661q135-54 284-81q-2-1-13-9.5t-16-13.5q-76-67-127-176q-27 86-83 197q-30 56-45 83zm646-16q-24-24-140-24q76 28 124 28q14 0 18-1q0-1-2-3z",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }