<template>
  <div>
    <vs-popup
      class="popup80 noOverflow"
      :fullscreen="false"
      :title="showPdfTitle"
      :active.sync="popupShowPdf"
    >
      <object :data="srcPdf" width="100%" style="height: 600px" />
    </vs-popup>
    <vs-prompt
      title="Imprimir"
      @accept="doPrint"
      accept-text="Confirmar"
      :active.sync="popupCategoryCnh">
         <vs-row>
        <vs-col>
          <label class="vs-input--label">Categoria</label>
          <v-select
            v-model="categoryCnh"
            :clearable="false"
            style="background-color: white"
            :reduce="(option) => option.value"
            :options="categoryCNHOptions"
            placeholder="Selecione"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          >
            <template #no-options="{}"> Nenhum resultado encontrado. </template>
          </v-select>
        </vs-col>
      </vs-row>
     </vs-prompt>

    <div class="flex flex-wrap items-center mt-8">
      <!-- ITEMS PER PAGE -->
      <div class="flex flex-grow">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="
              p-4
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            "
          >
            <span class="mr-2"
              >{{
                currentPage * paginationPageSize - (paginationPageSize - 1)
              }}
              -
              {{
                allData.total - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : allData.total || 0
              }}
              de {{ allData.total || 0 }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="paginationSetPageSize(20)">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="paginationSetPageSize(30)">
              <span>30</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="paginationSetPageSize(50)">
              <span>50</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <vx-tooltip
          text="Imprimir"
          position="top"
          class="ml-4 flex items-center cursor-pointer hover:text-primary"
        >
          <feather-icon
            icon="PrinterIcon"
            @click="popupCategoryCnh = true"
            svg-classes="h-8 w-8"
          ></feather-icon>
        </vx-tooltip>
      </div>
      <vx-tooltip
        text="Alterar Situação de Agendamentos Selecionados"
        position="top"
        class="mr-2 flex items-center cursor-pointer hover:text-primary"
      >
        <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
          <feather-icon icon="EditIcon" svgClasses="h-8 w-8 mr-1" />
          <vs-dropdown-menu class="w-60">
            <vs-dropdown-item @click="updateSelectedStatus('Concluído')">
              <span class="flex items-center">
                <div
                  class="h-4 w-4 inline-block rounded-full mr-3 bg-success"
                ></div>
                <span>Concluído</span>
              </span>
            </vs-dropdown-item>

            <vs-dropdown-item @click="updateSelectedStatus('Não Concluído')">
              <span class="flex items-center">
                <div
                  class="
                    h-4
                    w-4
                    inline-block
                    rounded-full
                    mr-3
                    border-2 border-solid border-secondary
                  "
                ></div>
                <!-- <div class="h-4 w-4 inline-block rounded-full mr-3 bg-secondary"></div> -->
                <span>Não Concluído</span>
              </span>
            </vs-dropdown-item>

            <vs-dropdown-item @click="updateSelectedStatus('ABSENCE')">
              <span class="flex items-center">
                <feather-icon
                  class="mr-2 text-danger"
                  icon="SlashIcon"
                  svgClasses="w-5 h-5"
                />
                <!-- <div class="h-4 w-4 inline-block rounded-full mr-3 border-2 border-solid border-secondary"></div> -->
                <span class="text-danger hover:text-primary">Ausente</span>
              </span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </vx-tooltip>

      <vx-tooltip
        text="Selecionat Todos"
        position="top"
        class="mr-2 flex items-center cursor-pointer hover:text-primary"
      >
        <feather-icon
          @click="selectAll"
          icon="CheckSquareIcon"
          svgClasses="h-8 w-8 mr-1"
        />
      </vx-tooltip>

      <vx-tooltip
        text="Lipar Seleção"
        position="top"
        class="mr-2 flex items-center cursor-pointer hover:text-primary"
      >
        <feather-icon
          @click="deselectAll"
          icon="DeleteIcon"
          svgClasses="h-8 w-8 mr-1"
        />
      </vx-tooltip>

      <vs-button
        @click="$emit('openViewSideBar')"
        icon-pack="feather"
        icon="icon-calendar"
        class="shadow-md ml-2 mb-4 md:mb-0"
        type="line"
        >Novo Agendamento</vs-button
      >
    </div>

    <!-- AgGrid Table -->
    <ag-grid-vue
      ref="agGridTable"
      :components="components"
      :gridOptions="gridOptions"
      @grid-ready="onGridReady"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :overlayLoadingTemplate="overlayLoadingTemplate"
      :overlayNoRowsTemplate="noRowsTemplate"
      :localeText="localeText"
      rowSelection="multiple"
      colResizeDefault="shift"
      :animateRows="true"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      :cacheBlockSize="payload.limit"
      :suppressPaginationPanel="true"
      :rowMultiSelectWithClick="true"
      :suppressRowClickSelection="false"
      :enableRtl="$vs.rtl"
    >
    </ag-grid-vue>

    <vs-pagination :total="totalPages" :max="10" v-model="currentPage" />
  </div>
</template>

<script>
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { AgGridVue } from 'ag-grid-vue'
import { localePt_Br } from '../../../../../assets/agGridTranslate/locale.pt_Br'
// Cell Renderer
import CellRendererDateTime from '../cell-renderer-practical-class-schedule/CellRendererDateTime.vue'
import CellRendererSituation from '../cell-renderer-practical-class-schedule/CellRendererSituation.vue'
// import CellRendererActions  from './cell-renderer-practical-class-schedule/CellRendererActions.vue'
//STORE
import moduleSchedule from '@/store/schedule/moduleSchedule.js'

import PaginationMixin from '@/mixins/pagination'

export default {
  mixins: [PaginationMixin],
  components: {
    AgGridVue,
    CellRendererDateTime,
    CellRendererSituation
    // CellRendererActions
  },
  props: {
    student: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      popupCategoryCnh: false,
      categoryCnh: 'ALL',
      categoryCNHOptions: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'C', value: 'C' },
        { label: 'D', value: 'D' },
        { label: 'E', value: 'E' },
        { label: 'ACC', value: 'ACC' },
        { label: 'TODAS', value: 'ALL' }
      ],
      showPdfTitle: 'PDF',
      srcPdf: '',
      popupShowPdf: false,

      payload: {
        offset: 0,
        limit: 200, // SE EXISTIREM MAIS DO QUE 200 HAVERÁ PROBLEMAS NA SELEÇÃO MÚLTIPLA
        student_id: null,
        type: 'practical',
        params: null,
        consultApi: true
      },
      // AgGrid
      gridApi: null,
      localeText: null,
      dataSource: {},
      gridOptions: {
        rowModelType: 'infinite'
      },
      overlayLoadingTemplate: 'Consultando dados...',
      noRowsTemplate: 'Nenhum dado foi encontrado',
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      components: {
        CellRendererDateTime,
        CellRendererSituation
        // CellRendererActions
      },

      columnDefs: [
        {
          headerName: 'Data',
          field: 'start_date',
          width: 260,
          cellRendererFramework: 'CellRendererDateTime',
          checkboxSelection: true
        },
        // {
        //   headerName: 'Início',
        //   field: 'start_time',
        //   width: 90,
        //   valueFormatter: params => params.value && params.value.slice(0, 5)
        // },
        // {
        //   headerName: 'Fim',
        //   field: 'end_time',
        //   width: 90,
        //   valueFormatter: params => params.value && params.value.slice(0, 5)
        // },
        {
          headerName: 'Instrutor',
          field: 'employee.name',
          width: 200
        },
        {
          headerName: 'Veículo',
          field: 'vehicle',
          width: 200,
          valueFormatter: (params) => params.value &&
            `${params.value.brand} ${params.value.model} - ${params.value.license_plate}`
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 210,
          onCellClicked: (e) => {
            e.node.setSelected(!e.node.isSelected()) // EVITA ALTERAR A SELEÇÃO AO CLICAR NA CÉLULA
          },
          cellRendererParams: {
            updateStatus: this.updateStatus // usado para passar parametros para dentro o componente renderizado no ag grid
          },
          cellRendererFramework: 'CellRendererSituation'
        }
        // {
        //   headerName: 'Ações',
        //   field: 'transactions',
        //   width: 95,
        //   sortable: false,
        //   cellRendererFramework: 'CellRendererActions'
        // }
      ]
    }
  },
  computed: {
    allData () {
      const data = this.$store.state.schedule.studentListSchedules
      return data
    }
  },
  methods: {
    doPrint () {
      setTimeout(this.printPracticalScheduleReport, 500)
    },
    async printPracticalScheduleReport () {
      this.$vs.loading()
      this.showPdfTitle = 'Relatório de Agendamentos de Aulas Práticas'
      const resp = await this.$http.post(
        `${process.env.VUE_APP_API_URL}/schedule/practicalClass/report`,
        {
          firstPeriod: null,
          lastPeriod: null,
          orderBy: 'date',
          format: 'PDF',
          payload: {
            students: [this.student.id],
            employees: [],
            vehicles: [],
            type_vehicle: null,
            status: null,
            category: this.categoryCnh
          }
        },
        { responseType: this.type === 'EXCEL' ? 'arraybuffer' : null }
      )

      // if (this.type === 'PDF') {
      this.srcPdf = `data:application/pdf;base64,${resp.data}`
      this.popupShowPdf = true
      // } else if (this.type === 'EXCEL') {
      //   const blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      //   this.makeBlobDoc(blob, 'Relatório de Agendamentos de Aulas Práticas')
      // }
      this.$nextTick(() => {
        this.$vs.loading.close()
      })
    },
    refreshGrid (consultApi) {
      this.payload.consultApi = consultApi
      this.gridApi.refreshInfiniteCache()
    },
    async updateStatus (event, status) {
      const schedule = JSON.parse(JSON.stringify(event.data))
      schedule.status = status
      schedule.duration = '00:50'

      try {
        this.$vs.loading()
        await this.$store.dispatch('schedule/updateMany', [schedule])
        this.$store.commit('schedule/UPDATE_STUDENT_LIST_SCHEDULES', schedule)
        this.refreshGrid(false) // ATUALIZA O DATAGRID

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Alterados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.error(error)
      }
    },

    async updateSelectedStatus (status) {
      const selectedNodes = this.gridApi.getSelectedNodes()
      const selectedData = selectedNodes.map((node) => node.data)
      const schedules = JSON.parse(JSON.stringify(selectedData))
      if (!schedules.length) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione ao menos um agendamento.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      schedules.map((s) => {
        s.status = status
        s.duration = '00:50'
      })

      try {
        this.$vs.loading()
        await this.$store.dispatch('schedule/updateMany', schedules)
        schedules.forEach((schedule) => {
          this.$store.commit('schedule/UPDATE_STUDENT_LIST_SCHEDULES', schedule)
        })
        this.refreshGrid(false) // ATUALIZA O DATAGRID

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Alterados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.error(error)
      }
    },

    deselectAll () {
      this.gridApi.deselectAll()
    },

    selectAll () {
      this.gridApi.forEachNode((rowNode) => {
        rowNode.setSelected(true)
      })
    },

    onGridReady (params) {
      this.gridApi = params.api
      const self = this
      this.dataSource = {
        rowCount: null,
        async getRows (params) {
          self.payload.offset = params.startRow
          self.payload.params = params

          if (self.payload.consultApi) await self.getAllData()
          self.payload.consultApi = true //esta linha faz com que a próxima chamada sempre seja feita para a API

          let lastRow = -1
          if (self.allData.total <= params.endRow) {
            lastRow = self.allData.total
          }
          params.successCallback(self.allData.data, lastRow)
        }
      }
      this.gridApi.setDatasource(this.dataSource)
    },
    async getAllData () {
      this.gridApi.showLoadingOverlay()
      const data = await this.$store.dispatch(
        'schedule/fetchStudentSchedules',
        this.payload
      )
      this.gridApi.hideOverlay()
      if (!data.data.total) this.gridApi.showNoRowsOverlay()
      return data
    }
  },
  mounted () {
    this.localeText = localePt_Br
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        '.ag-header-container'
      )
      header.style.left = `-${String(
        Number(header.style.transform.slice(11, -3)) + 9
      )}px`
    }
  },
  created () {
    this.setGrid('allData')

    this.payload.student_id = this.student.id

    if (!moduleSchedule.isRegistered) {
      this.$store.registerModule('schedule', moduleSchedule)
      moduleSchedule.isRegistered = true
    }
  }
}
</script>
<style>
.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  color: #158482;
}
.popup80 .vs-popup {
  width: 80% !important;
}
.popup40 .vs-popup {
  width: 40% !important;
}
</style>
