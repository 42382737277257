var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex items-center" },
    [
      _c(
        "vx-tooltip",
        {
          staticClass: "flex",
          attrs: { text: "Último Contrato", position: "top", delay: ".3s" },
        },
        [
          _vm.params.data && _vm.params.data.isLast
            ? _c("feather-icon", {
                staticClass: "h-5 w-5 mr-1",
                attrs: {
                  icon: "StarIcon",
                  svgClasses: ["stoke-current text-warning"],
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._l(_vm.servicePackages, function (servicePackage, i) {
        return _c("span", { key: i }, [
          _vm._v(
            "\n      " +
              _vm._s(i > 0 && i < _vm.servicePackages.length ? " |  " : "") +
              " " +
              _vm._s(servicePackage) +
              "\n    "
          ),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }