<template>
  <div>
    <vs-popup title="Foto" :active.sync="popupActive" id="webcamPopup">
      <div class="area_webcam vs-con-loading__container" id="webcam-loading">
        <video autoplay="true" id="webCamera"></video>
        <input type="hidden" id="base_img" name="base_img" />
        <button type="button" @click="takeSnapShot(person.id)">
          Tirar foto e salvar</button
        ><br />
      </div>
      <div class="vx-rol flex mb-4">
        <div class="vx-col md:w-1/2 flex">
          <button type="button" @click="changeCamera">
            Trocar Câmera
          </button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
// Store Module
import moduleStudentManagement from '@/store/student-management/moduleStudentManagement.js'

export default {
  name: 'webcamPhotoComponent',
  props: {
    person: { Object, required: true }
  },
  watch: {
    popupActive (popupActive) {
      if (!popupActive) {
        this.stopCamera()
      }
    }
  },
  data () {
    return {
      popupActive: false,
      primary: true
    }
  },
  methods: {
    openLoading () {
      this.$vs.loading({
        container: '#webcam-loading',
        scale: 0.8
      })
    },
    closeLoading () {
      this.$vs.loading.close('#webcam-loading > .con-vs-loading')
    },
    loadCamera () {
      const self = this
      this.popupActive = true
      this.openLoading()
      //Captura elemento de vídeo
      this.$nextTick(function () {
        const video = document.querySelector('#webCamera')
        //As opções abaixo são necessárias para o funcionamento correto no iOS
        video.setAttribute('autoplay', '')
        video.setAttribute('muted', '')
        video.setAttribute('playsinline', '')
        //Verifica se o navegador pode capturar mídia
        if (navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices
            .getUserMedia({ audio: false, video: { facingMode: self.primary ? 'user' : 'environment' } })
            .then(function (stream) {
              //Definir o elemento vídeo a carregar o capturado pela webcam
              video.srcObject = stream
              self.closeLoading()
            })
            .catch(function (error) {
              self.closeLoading()
              self.$vs.notify({
                title: 'Aviso',
                text: 'Desculpe, algo deu errado.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'warning'
              })
              console.log(error.response)
            })
        }
      })
    },
    takeSnapShot (person_id) {
      //Captura elemento de vídeo
      const video = document.querySelector('#webCamera')

      //Criando um canvas que vai guardar a imagem temporariamente
      const canvas = document.createElement('canvas')
      canvas.width = 200 // canvas.width = video.videoWidth;
      canvas.height = 200 // canvas.height = video.videoHeight;

      const ctx = canvas.getContext('2d')

      //Desenhando e convertendo as dimensões
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

      //Criando o JPG
      const dataURI = canvas.toDataURL('image/jpeg') //O resultado é um BASE64 de uma imagem.

      this.sendSnapShot(dataURI, person_id) //Gerar Imagem e Salvar Caminho no Banco
    },

    /**
     * Envia imagem para o servidor
     * @param base64
     * @param person_id
     */
    sendSnapShot (base64, person_id) {
      this.openLoading()
      const payload = {
        base_img: base64,
        person_id
      }

      this.$store
        .dispatch('studentManagement/sendSnapShot', payload)
        .then((res) => {
          this.closeLoading()
          this.photoUrl = res.data.photoUrl
          this.popupActive = false
        })
        .catch((err) => {
          console.error(err)
        })
    },
    changeCamera () {
      this.primary = !this.primary
      this.stopCamera()
      this.loadCamera()
    },

    /**
     * Desliga a webcam
     * @param e
     * @returns {boolean}
     */
    stopCamera () {
      const video = document.querySelector('#webCamera')
      if (video.srcObject) {
        const stream = video.srcObject
        const tracks = stream.getTracks()
        for (let i = 0; i < tracks.length; i++) {
          const track = tracks[i]
          track.stop()
        }
        video.srcObject = null
      } else {
        return false
      }
      return true
    }
  },
  computed: {
    photoUrl: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('photoUrl', newValue)
      }
    }
  },
  created () {
    // Register Module StudentManagement Module
    if (!moduleStudentManagement.isRegistered) {
      this.$store.registerModule('studentManagement', moduleStudentManagement)
      moduleStudentManagement.isRegistered = true
    }
  }
}
</script>

<style>
#webcamPopup .vs-popup {
  width: auto !important;
}

.area_webcam {
  margin: 0px auto;
  box-shadow: 0 10px 100px #ccc;
  padding: 0px;
  box-sizing: border-box;
  max-width: 600px;
}
.area_webcam video {
  width: 100%;
  height: auto;
  background-color: whitesmoke;
}
.area_webcam textarea_webcam {
  width: 100%;
  margin-top: 10px;
  height: 80px;
  box-sizing: border-box;
}
.area_webcam button {
  -webkit-appearance: none;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
  background-color: #068c84;
  color: white;
  text-transform: uppercase;
  border: 1px solid white;
  box-shadow: 0 1px 5px #666;
}
.area_webcam button:focus {
  outline: none;
  background-color: #0989b0;
}
.area_webcam img {
  max-width: 100%;
  height: auto;
}
.area_webcam .caminho-imagem {
  padding: 5px 10px;
  border-radius: 3px;
  background-color: #068c84;
  text-align: center;
}
.area_webcam .caminho-imagem a {
  color: white;
  text-decoration: none;
}
.area_webcam .caminho-imagem a:hover {
  color: yellow;
}
</style>
