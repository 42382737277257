<template>
  <div>
    <div v-if="!transactions.length">
      <div class="vx-row">
      <div class="vx-col md:w-1/5">
        <select-student v-model="studentSelected" disabled />
      </div>
      <div class="vx-col md:w-1/5">
        <select-service-package v-model="servicePackages" :multiple="true" />
      </div>
      <div class="vx-col md:w-1/5">
        <sig-input-currency v-model="values.total.value" label="Total" />
      </div>
      <div class="vx-col md:w-1/5">
        <sig-input-value-percent v-model="values.discount" label="Desconto" />
      </div>
      <div class="vx-col md:w-1/5">
        <label class="vs-input--label">Total</label>
        <h1 class="flex flex-wrap justify-center">
          <span>{{ this.mixinCurrency(finalValue) }}</span>
        </h1>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-1/5">
        <label class="vs-input--label">Nº de Parcelas</label>
        <vs-input-number
          min="1"
          class="mt-2"
          v-model="parcels.quantity"
          data-vv-as="Parcelas"
          data-vv-scope="credit"
          v-validate="'required|min_value:1'"
          name="parcelsNumber"
          @input="transactions = []"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('credit.parcelsNumber')"
          >{{ errors.first("credit.parcelsNumber") }}</span
        >
      </div>
      <div class="vx-col md:w-1/5">
        <sig-input-currency
          v-model="firstParcel.value_parcel"
          label="Valor 1ª parcela"
          :disabled="parcels.quantity < 2"
          @input="transactions = []"
        />
      </div>
      <div class="vx-col md:w-1/5">
        <vs-input
          type="date"
          class="mr-2 w-full"
          label="1º Vencimento"
          v-model="firstParcel.expiration_date"
          data-vv-as="1º Vencimento"
          data-vv-scope="credit"
          v-validate.initial="'required'"
          name="expirationDateFirstParcel"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('credit.expirationDateFirstParcel')"
          >Este campo é obrigatório.</span
        >
      </div>
      <div class="vx-col md:w-1/5">
        <label class="vs-input--label">Periodicidade</label>
        <v-select
          v-model="parcels.frequency"
          :reduce="(option) => option.value"
          :clearable="false"
          :options="[
            { label: 'Mensal', value: 'monthly' },
            { label: 'Quinzenal', value: 'biweekly' },
            { label: 'Semanal', value: 'weekly' },
          ]"
          placeholder="Selecione"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        ></v-select>
      </div>
       <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Aplicar Juros</label>
          <div class="flex flex-wrap items-center justify-center">
          <vs-switch class="w-1/2 mt-2" icon-pack="feather" vs-icon-on="icon-check-circle" vs-icon-off="icon-slash" v-model="applyInterest">
            <span slot="on">SIM</span> <span slot="off">NÃO</span>
          </vs-switch>
          </div>
        </div>
    </div>
    <div class="vx-row">
      <div class="vx-col md:w-2/5">
        <select-type-payment-and-account v-model="typePaymentAndAccount" />
      </div>
      <div class="vx-col md:w-1/5">
        <select-account-plan v-model="accountPlan" />
      </div>
      <div class="vx-col md:w-2/5">
        <vs-input class="w-full" label="Descrição" v-model="observation" />
      </div>
    </div>
      <div class="vx-row">
      <div class="vx-col w-full mt-2">
        <div class="mt-0 flex flex-wrap items-center justify-end">
          <vs-button @click="createTransactions" class="w-1/5" type="border" color="warning" icon-pack="feather" icon="icon-dollar-sign">Gerar Parcelas</vs-button>
        </div>
          <vs-divider></vs-divider>
      </div>
    </div>
    </div>
    <div v-if="transactions.length">
       <div class="flex flex-row-reverse">
        <div class="vx-col">
        <label class="vs-input--label">Valor Final</label>
        <h1 class="">
          <span>{{ this.mixinCurrency(totalValue) }}</span>
        </h1>
      </div>
       <div class="vx-col mr-3">
        <label class="vs-input--label">Valor Original</label>
        <h3 class="">
          <span>{{ this.mixinCurrency(finalValue) }}</span>
        </h3>
      </div>
       </div>
      <transaction-list v-model="transactions" :total-value="finalValue" />
    </div>
  </div>
</template>

<script>
import SelectStudent from '@/components/register/student/SelectStudent.vue'
import {NumberSig} from '@/util/NumberSig'
import SelectTypePaymentAndAccount from '@/components/financial/typePayment/SelectTypePaymentAndAccount.vue'
import SelectAccount from '@/components/financial/account/SelectAccount.vue'
import SelectAccountPlan from '@/components/financial/accountPlan/SelectAccountPlan.vue'
import SelectServicePackage from '@/components/register/service/SelectServicePackage.vue'
import TransactionList from './TransactionList.vue'
import SigInputCurrency from '@/components/sig/SigInputCurrency.vue'
import SigInputValuePercent from '@/components/sig/SigInputValuePercent.vue'
import axios from '@/axios.js'
import moment from 'moment'
import { Transaction } from '@/model/financial/Transaction'
export default {
  components: {
    SelectStudent,
    SelectServicePackage,
    SelectTypePaymentAndAccount,
    SelectAccount,
    SelectAccountPlan,
    TransactionList,
    SigInputCurrency,
    SigInputValuePercent
  },
  props: {
    data: {
      default () {
        return {}
      }
    },
    value: {},
    student: {},
    regenerate: {
      default: false
    },
    cancel: {
      default () {
        return []
      }
    }
  },
  data () {
    window.apt = this
    return {
      studentSelected: this.student,
      servicePackages: [],
      typePaymentAndAccount:  { typePayment: null, account: null },
      accountPlan: null,
      firstParcel: {
        // transacao ref a 1a parcela
        value_parcel: 0,
        expiration_date: this.mixinToday()
      },
      parcels: {
        quantity: 1,
        expiration_date: '',
        frequency: 'monthly'
      },
      values: {
        total: {
          value: 0
        },
        discount: {
          value: 0,
          type: '%'
        }
      },
      applyInterest: true,
      observation: null
    }
  },
  watch: {
    data (transaction) {
      this.setLocalData(transaction)
    }
  },
  computed: {
    transactions:{
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    finalValue () {
      let discount = 0
      if (this.values.discount.value) discount =
          this.values.discount.type === '%'
            ? this.values.total.value * (this.values.discount.value / 100)
            : this.values.discount.value
      return this.values.total.value - discount
    },
    totalValue () {
      return this.transactions.reduce((a, b) => NumberSig.round(a || 0) + NumberSig.round(b.value_parcel || 0), 0)
    },
    valueOfEachParcel () {
      // maior que dois por que a 1a e a ultima parcela calculo de forma diferente
      if (this.parcels.quantity > 2) {
        if (this.firstParcel.value_parcel) return parseFloat(
          (this.finalValue - this.firstParcel.value_parcel) /
              (this.parcels.quantity - 1)
        ).toFixed(2)
        return parseFloat(
          this.finalValue / this.parcels.quantity
        ).toFixed(2)
      }
      return 0
    }
  },
  methods: {
    // definir todos os dados locais
    setLocalData (transaction) {
      if (!transaction) return
      // valor total
      this.values.total.value = transaction.value_total || 0
      // observacao
      this.observation = transaction.observation || null

      // pacote de servicos
      this.servicePackages = transaction.servicePackages || []

      // tipo de pagamento
      this.typePaymentAndAccount = { typePayment: null, account: null }
      if (transaction.typePayment && transaction.typePayment.id) this.typePaymentAndAccount.typePayment = transaction.typePayment
      else if (transaction.type_payment && transaction.type_payment.id) this.typePaymentAndAccount.typePayment = this.data.type_payment
      else if (transaction.type_payment_id) this.typePaymentAndAccount.typePayment = { id: this.data.type_payment_id }

      // conta
      if (transaction.account && transaction.account.id) this.typePaymentAndAccount.account = transaction.account
      else if (transaction.account_id) this.typePaymentAndAccount.account = { id: transaction.account_id }

      // plano de conta
      if (transaction.accountPlan && transaction.accountPlan.id) this.accountPlan = transaction.accountPlan
      else if (transaction.account_plan && transaction.account_plan.id) this.accountPlan = transaction.account_plan
      else if (transaction.account_plan_id) this.accountPlan = { id: transaction.account_plan_id }

      // estudante
      if (transaction.student && transaction.student.id) this.studentSelected = transaction.student
      else if (transaction.student_id) this.studentSelected = {id: transaction.student_id}


    },
    createTransactions () {
      // zerar transacoes
      this.transactions = []
      // preencher primeira transacao
      this.transactions = this.generateFirstTransaction()

      const quantity = this.parcels.quantity - 1
      // termina se nao houver mais parcelas
      if (!quantity) return

      // adiciona parcelas, exceto a ultima

      if (quantity > 1) {
        for (let i = 1; i < quantity; i++) {
          this.transactions.push(this.getTemplateTransaction())
        }
      }
      // a ultima parcela pode ser diferente
      this.transactions = this.generateLastTransaction()
    },
    // a primeira parcela pode ser diferente
    generateFirstTransaction () {
      const transaction = this.getTemplateTransaction()
      transaction.value_parcel =
        this.firstParcel.value_parcel ||
        parseFloat(this.finalValue / this.parcels.quantity).toFixed(2)
      transaction.expiration_date = this.firstParcel.expiration_date
      this.transactions.push(transaction)
      return this.transactions
    },
    // a ultima parcela é diferente
    generateLastTransaction () {
      let valueLastTransaction = 0
      for (const transaction of this.transactions) {
        valueLastTransaction += parseFloat(transaction.value_parcel)
      }
      const transaction = this.getTemplateTransaction()
      valueLastTransaction = parseFloat(
        this.finalValue - valueLastTransaction
      ).toFixed(2)
      transaction.value_parcel = valueLastTransaction

      this.transactions.push(transaction)
      return this.transactions
    },
    getTemplateTransaction () {
      return new Transaction({
        futureDate: false,
        type: 'CREDIT',
        expiration_date: this.getExpirationDate(),
        payday: '',
        value_pay: 0,
        parcel: 1,
        value_parcel: this.valueOfEachParcel,
        situation: false, // false = PENDING | true = PAID
        value_total: this.finalValue,
        observation: this.observation,
        account_plan_id: this.accountPlan.id,
        account_id: this.typePaymentAndAccount.account.id,
        type_payment_id: this.typePaymentAndAccount.typePayment.id,
        // os dados abaixo sao para manter compativel com  o metodo antigo
        account: this.typePaymentAndAccount.account.id,
        account_plan: this.accountPlan.id,
        servicePackages: this.servicePackages
      })
    },
    getExpirationDate () {
      const param = {
        value: 1
      }
      switch (this.parcels.frequency) {
      case 'monthly':
        param.type = 'month'
        break
      case 'biweekly':
        param.value = 2
        param.type = 'week'
        break
      case 'weekly':
        param.type = 'week'
        break
      }

      if (this.firstParcel.expiration_date && this.transactions.length) return moment(this.firstParcel.expiration_date).add(param.value * this.transactions.length, param.type).format('YYYY-MM-DD')
    },
    async saveSendToServer () {
      const payload = {
        account: this.typePaymentAndAccount.account.id,
        account_plan: this.accountPlan.id,
        applyInterest: this.applyInterest,
        discount: this.values.discount.value,
        discount_type: this.values.discount.type,
        expirationDateFirstParcel: this.transactions[0].expiration_date,
        firstParcelValue: this.transactions[0].value_parcel,
        frequency: this.parcels.frequency,
        observation: this.observation,
        parcelNumber: this.parcels.quantity,
        servicePackages: this.servicePackages.filter(e => !!e), // tive que fazer assim porque coisas de 2021 causam erro
        student_id: this.student.id,
        sub_total: this.values.total.value,
        total: this.finalValue,
        type_payment_id: this.typePaymentAndAccount.typePayment.id,
        cancelTransactions: this.cancel,
        transactions: this.transactions
      }

      this.$vs.loading()

      axios.post(`${process.env.VUE_APP_API_URL}/transaction`, payload)
        .then(() => {
          this.mixinNotify('Operação executada com sucesso')
          this.$emit('saved')
        })
        .catch(this.mixinCatch)
        .finally(this.$vs.loading.close)
    }
  },
  created () {
    this.setLocalData(this.data)
  }
}
</script>

