<template>
  <div class="w-full">
    <label class="vs-input--label">{{ label }}</label>
    <v-select
      :clearable="false"
      v-model="situation"
      :reduce="(option) => option.value"
      :options="options"
      placeholder="Selecione"
      :dir="$vs.rtl ? 'rtl' : 'ltr'"
      data-vv-as="Situação"
    >
      <template #no-options="{}"> Nenhum resultado encontrado. </template>
    </v-select>
  </div>
</template>

<script>

import { situationOptions } from '@/store/register/debitStudent/situation'

export default {
  props: {
    value: {},
    label: {
      default: 'Situação'
    },
    field: {
      default: 'situation'
    }
  },
  data () {
    return {
      rawValue: '',
      options: situationOptions
    }
  },
  computed: {
    situation: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

