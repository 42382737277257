<template>
  <div v-if="params.data" class="flex items-start">
    <feather-icon v-if="type === 'CREDIT'" title="Entrada" icon="ArrowUpIcon" svgClasses="h-4 w-4 mt-4" class="ml-1 mr-1 text-success" />
    <feather-icon v-else title="Saída" icon="ArrowDownIcon" svgClasses="h-4 w-4" class="ml-1 mr-1 mt-4 text-danger" />
    {{observation}}
  </div>
</template>

<script>
export default {
  name: 'CellRendererObservation',
  computed: {
    observation () {
      if (this.params.data) {
        const services = this.params.data.servicePackages ? this.params.data.servicePackages.map(e => {
          if (e && e.name) {
            return ` ${e.name || ''} ${e.category_cnh || ''}`
          }
          return ''
        }).flat() : ''
        return (this.params.value || 'Nenhuma descrição.') + services
      }
    },
    type () {
      if (this.params.data) {
        return this.params.data.type
      }
    }
  }
}
</script>
