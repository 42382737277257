import axios from '@/axios.js'

export default {
  storeCnh (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/cnh`, payload)
        .then((resp) => {
          resolve(resp)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateCnh (context, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/cnh/${payload.id}`, payload)
        .then((resp) => {
          resolve(resp)
        })
        .catch((error) => { reject(error) })
    })
  },
  delete (context, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_API_URL}/cnh/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
