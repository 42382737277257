<template>
  <div id="schedule-exam">

    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">

        <grid ref="gridTheoretical"
          @openMedicalExam="openMedicalExamViewSideBar"
          @openPracticalExam="openPracticalExamViewSideBar"
          @openPsychotechnicalExam="openPsychotechnicalExamViewSideBar"
          @openTheoreticalExam="openTheoreticalExamViewSideBar"
          :student="this.student"></grid>

      </div>
    </vx-card>

    <!-- MEDICAL EXAM SCHEDULE VIEW SIDEBAR -->
    <medical-exam-schedule :isSidebarActive="isMedicalExamSidebarActive" @refreshData="refreshGrid(false)" @closeSidebar="closeMedicalExamViewSideBar"></medical-exam-schedule>
    <!-- PRACTICAL EXAM SCHEDULE VIEW SIDEBAR -->
    <practical-exam-schedule :isSidebarActive="isPracticalExamSidebarActive" @refreshData="refreshGrid(false)" @closeSidebar="closePracticalExamViewSideBar"></practical-exam-schedule>
    <!-- PSYCHOTECHNICAL EXAM SCHEDULE VIEW SIDEBAR -->
    <psychotechnical-exam-schedule :isSidebarActive="isPsychotechnicalExamSidebarActive" @refreshData="refreshGrid(false)" @closeSidebar="closePsychotechnicalExamViewSideBar"></psychotechnical-exam-schedule>
    <!-- THEORETICAL EXAM SCHEDULE VIEW SIDEBAR -->
    <theoretical-exam-schedule :isSidebarActive="isTheoreticalExamSidebarActive" @refreshData="refreshGrid(false)" @closeSidebar="closeTheoreticalExamViewSideBar"></theoretical-exam-schedule>

  </div>
</template>

<script>

import MedicalExamSchedule  from './schedule-exam/medical-exam/MedicalSchedule.vue'
import PracticalExamSchedule  from './schedule-exam/practical-exam/PracticalSchedule.vue'
import PsychotechnicalExamSchedule  from './schedule-exam/psychotechnical-exam/PsychotechnicalSchedule.vue'
import TheoreticalExamSchedule  from './schedule-exam/theoretical-exam/TheoreticalSchedule.vue'

import Grid from './schedule-exam/Grid.vue'

//STORE
import moduleStudentManagement from '@/store/student-management/moduleStudentManagement.js'

export default {
  components: {
    MedicalExamSchedule,
    PracticalExamSchedule,
    PsychotechnicalExamSchedule,
    TheoreticalExamSchedule,
    Grid
  },
  data () {
    return {
      student: {},
      // isSidebarActive      : false,
      isMedicalExamSidebarActive          : false,
      isPracticalExamSidebarActive        : false,
      isPsychotechnicalExamSidebarActive  : false,
      isTheoreticalExamSidebarActive      : false,
      clickNotClose                       : true
    }
  },
  methods: {
    openMedicalExamViewSideBar () {
      this.isMedicalExamSidebarActive = true
    },
    closeMedicalExamViewSideBar () {
      this.isMedicalExamSidebarActive = false
    },
    openPracticalExamViewSideBar () {
      this.isPracticalExamSidebarActive = true
    },
    closePracticalExamViewSideBar () {
      this.isPracticalExamSidebarActive = false
    },
    openPsychotechnicalExamViewSideBar () {
      this.isPsychotechnicalExamSidebarActive = true
    },
    closePsychotechnicalExamViewSideBar () {
      this.isPsychotechnicalExamSidebarActive = false
    },
    openTheoreticalExamViewSideBar () {
      this.isTheoreticalExamSidebarActive = true
    },
    closeTheoreticalExamViewSideBar () {
      this.isTheoreticalExamSidebarActive = false
    },
    resetLocalData () {
      this.student = JSON.parse(JSON.stringify(this.$store.getters['studentManagement/getStudent']))
    }
  },
  created () {
    // Register VUEX Modules
    if (!moduleStudentManagement.isRegistered) {
      this.$store.registerModule('studentManagement', moduleStudentManagement)
      moduleStudentManagement.isRegistered = true
    }
    this.resetLocalData()
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
</style>
