var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "border border-solid d-theme-border-grey-light rounded relative overflow-hidden",
    },
    [
      _c(
        "vs-sidebar",
        {
          staticClass: "items-no-padding",
          attrs: {
            parent: "#email-app",
            "click-not-close": _vm.clickNotClose,
            "hidden-background": _vm.clickNotClose,
          },
          model: {
            value: _vm.isEmailSidebarActive,
            callback: function ($$v) {
              _vm.isEmailSidebarActive = $$v
            },
            expression: "isEmailSidebarActive",
          },
        },
        [_c("schedule-sidebar")],
        1
      ),
      _c(
        "vs-sidebar",
        {
          staticClass: "full-vs-sidebar email-view-sidebar items-no-padding",
          attrs: {
            "click-not-close": "",
            parent: "#email-app",
            "hidden-background": true,
            "position-right": "",
          },
          model: {
            value: _vm.isSidebarActive,
            callback: function ($$v) {
              _vm.isSidebarActive = $$v
            },
            expression: "isSidebarActive",
          },
        },
        [
          _c(
            "div",
            { staticClass: "mail-sidebar-content px-0 sm:pb-6 h-full" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-wrap items-center email-header justify-between md:px-8 px-6 sm:pb-2 sm: pt-6 d-theme-dark-bg",
                },
                [
                  _c("div", { staticClass: "flex mb-4" }, [
                    _c(
                      "div",
                      { staticClass: "flex items-center" },
                      [
                        _c("feather-icon", {
                          staticClass: "cursor-pointer hover:text-primary mr-4",
                          attrs: {
                            icon: _vm.$vs.rtl
                              ? "ArrowRightIcon"
                              : "ArrowLeftIcon",
                            "svg-classes": "w-6 h-6",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("closeSidebar")
                            },
                          },
                        }),
                        _c("h4", [_vm._v("VOLTAR")]),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "ml-10 mb-4 mt-1" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "email__actions--single flex items-baseline",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex border d-theme-dark-bg items-center justify-end",
                          },
                          [
                            _c(
                              "vx-tooltip",
                              {
                                staticClass: "ml-4",
                                attrs: {
                                  text: "Abrir Barra de Calendário",
                                  position: "top",
                                },
                              },
                              [
                                _c("feather-icon", {
                                  staticClass:
                                    "md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer",
                                  attrs: { icon: "MenuIcon" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.toggleEmailSidebar(true)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "vx-tooltip",
                          {
                            staticClass: "ml-4",
                            attrs: { text: "Imprimir", position: "top" },
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "cursor-pointer",
                              attrs: {
                                icon: "PrinterIcon",
                                "svg-classes": "h-6 w-6",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("markUnread")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c("vue-context", { ref: "menu" }, [
                _c("li", [
                  _c(
                    "a",
                    {
                      staticClass:
                        "flex items-center text-sm hover:text-danger",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.contextMenuClicked("DELETE")
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        attrs: {
                          icon: "MinusCircleIcon",
                          svgClasses: "w-5 h-5",
                        },
                      }),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v("Excluir Todos os Alunos Deste Encontro"),
                      ]),
                    ],
                    1
                  ),
                ]),
                !_vm.contextDeleteStudentSchedule
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          staticClass:
                            "flex items-center text-sm hover:text-danger",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.contextMenuClicked(
                                "ADD_STUDENT_SCHEDULE"
                              )
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            attrs: {
                              icon: "MinusCircleIcon",
                              svgClasses: "w-5 h-5",
                            },
                          }),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v("Adicionar o Aluno Neste Encontro"),
                          ]),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                !_vm.contextDeleteStudentSchedule
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          staticClass:
                            "flex items-center text-sm hover:text-danger",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.contextMenuClicked(
                                "ADD_STUDENT_SCHEDULE_DAY"
                              )
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            attrs: {
                              icon: "MinusCircleIcon",
                              svgClasses: "w-5 h-5",
                            },
                          }),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v(
                              "Adicionar o Aluno em Todos os Encontros do Dia"
                            ),
                          ]),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.contextDeleteStudentSchedule
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          staticClass:
                            "flex items-center text-sm hover:text-danger",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.contextMenuClicked(
                                "DELETE_STUDENT_SCHEDULE"
                              )
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            attrs: {
                              icon: "MinusCircleIcon",
                              svgClasses: "w-5 h-5",
                            },
                          }),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v("Excluir Somente o Aluno Deste Encontro"),
                          ]),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.contextDeleteStudentScheduleDay
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          staticClass:
                            "flex items-center text-sm hover:text-danger",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.contextMenuClicked(
                                "DELETE_STUDENT_SCHEDULE_DAY"
                              )
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            attrs: {
                              icon: "MinusCircleIcon",
                              svgClasses: "w-5 h-5",
                            },
                          }),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v(
                              "Excluir Somente o Aluno em Todos os Encontros do Dia"
                            ),
                          ]),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "email__actions p-2 border border-r-0 border-l-0 border-solid d-theme-border-grey-light",
                },
                [
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/2 w-full mt-0" },
                      [
                        _c("label", { staticClass: "vs-input--label" }, [
                          _vm._v("Turma"),
                        ]),
                        _c("v-select", {
                          staticStyle: { "background-color": "white" },
                          attrs: {
                            clearable: true,
                            filterable: false,
                            reduce: (option) => option.value,
                            options: _vm.turmaOptions,
                            placeholder: "Digite o nome da turma...",
                            dir: _vm.$vs.rtl ? "rtl" : "ltr",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.changeTurma()
                            },
                            search: _vm.debouncedGetSearchTurma,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "no-options",
                              fn: function ({}) {
                                return [
                                  _vm._v(
                                    "\n                Nenhum resultado encontrado.\n              "
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.turma,
                            callback: function ($$v) {
                              _vm.turma = $$v
                            },
                            expression: "turma",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/2 w-full mt-0" },
                      [
                        _c("label", { staticClass: "vs-input--label" }, [
                          _vm._v("Disciplina"),
                        ]),
                        _c("v-select", {
                          staticStyle: { "background-color": "white" },
                          attrs: {
                            clearable: true,
                            reduce: (option) => option.value,
                            options: _vm.modules,
                            placeholder: "Selecione",
                            dir: _vm.$vs.rtl ? "rtl" : "ltr",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "no-options",
                              fn: function ({}) {
                                return [
                                  _vm._v(
                                    "\n                Nenhum resultado encontrado.\n              "
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.moduleCourse,
                            callback: function ($$v) {
                              _vm.moduleCourse = $$v
                            },
                            expression: "moduleCourse",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/3 w-full mt-0" },
                      [
                        _c("label", { staticClass: "vs-input--label" }, [
                          _vm._v("Instrutor"),
                        ]),
                        _c("v-select", {
                          staticStyle: { "background-color": "white" },
                          attrs: {
                            reduce: (option) => option.value,
                            options: _vm.employees,
                            placeholder: "Selecione",
                            dir: _vm.$vs.rtl ? "rtl" : "ltr",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.changeEmployee()
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "no-options",
                              fn: function ({}) {
                                return [
                                  _vm._v(
                                    "\n                Desculpe, nenhum resultado encontrado.\n              "
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.employee_id,
                            callback: function ($$v) {
                              _vm.employee_id = $$v
                            },
                            expression: "employee_id",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/3 w-full mt-0" },
                      [
                        _c("label", { staticClass: "vs-input--label" }, [
                          _vm._v("Sala"),
                        ]),
                        _c("v-select", {
                          staticStyle: { "background-color": "white" },
                          attrs: {
                            reduce: (option) => option.data,
                            options: _vm.classrooms,
                            placeholder: "Selecione",
                            dir: _vm.$vs.rtl ? "rtl" : "ltr",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.changeClassroom()
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "no-options",
                              fn: function ({}) {
                                return [
                                  _vm._v(
                                    "\n                Desculpe, nenhum resultado encontrado.\n              "
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.classroom,
                            callback: function ($$v) {
                              _vm.classroom = $$v
                            },
                            expression: "classroom",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "vx-col md:w-1/3 w-full mt-0" }, [
                      _c("div", {
                        staticClass: "mt-4 flex flex-wrap items-center",
                      }),
                    ]),
                  ]),
                ]
              ),
              _c(
                _vm.scrollbarTag,
                {
                  key: _vm.$vs.rtl,
                  ref: "mailListPS",
                  tag: "component",
                  staticClass: "email-content-scroll-area",
                  attrs: { settings: _vm.settings },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "vs-con-loading__container",
                      attrs: { id: "loading" },
                    },
                    _vm._l(_vm.tables, function (table, tIndex) {
                      return _c(
                        "vs-table",
                        {
                          key: tIndex,
                          staticClass: "bordered",
                          staticStyle: { overflow: "-webkit-paged-y" },
                          attrs: { data: _vm.selectedDays, hoverFlat: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ data }) {
                                  return [
                                    _vm._l(_vm.hours, function (hour, i) {
                                      return _c(
                                        "vs-tr",
                                        {
                                          key: i,
                                          staticStyle: {
                                            "font-size": "12px",
                                            opacity: "1 !important",
                                          },
                                        },
                                        [
                                          _c(
                                            "vs-td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "cursor-default font-semibold",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(hour.slice(0, 5))
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._l(
                                            _vm.handleRange(table),
                                            function (hr, i) {
                                              return _c(
                                                "vs-td",
                                                {
                                                  key: i,
                                                  staticClass: "cursor-pointer",
                                                  style:
                                                    (_vm.fillSchedule(
                                                      _vm.selectedDays[hr - 1],
                                                      hour
                                                    ).length &&
                                                      _vm.fillSchedule(
                                                        _vm.selectedDays[
                                                          hr - 1
                                                        ],
                                                        hour
                                                      )[0].bg) ||
                                                    "",
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.addSchedule(
                                                        _vm.selectedDays[
                                                          hr - 1
                                                        ],
                                                        hour
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._l(
                                                    _vm.selectedDays[hr - 1]
                                                      .holidaySchedule,
                                                    function (holiday, hd) {
                                                      return _c(
                                                        "feather-icon",
                                                        {
                                                          key: `${hd}_holiday`,
                                                          staticClass: "mr-1",
                                                          attrs: {
                                                            title:
                                                              holiday.holidayName,
                                                            icon: "StarIcon",
                                                            svgClasses:
                                                              "w-4 h-4",
                                                          },
                                                        }
                                                      )
                                                    }
                                                  ),
                                                  _vm._l(
                                                    _vm.fillSchedule(
                                                      _vm.selectedDays[hr - 1],
                                                      hour
                                                    ),
                                                    function (schedule, idx) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: `${idx}_schedule`,
                                                          staticClass:
                                                            "text-black hover:text-primary",
                                                          staticStyle: {
                                                            "margin-top":
                                                              "-7px",
                                                          },
                                                          on: {
                                                            contextmenu:
                                                              function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.contextMenu(
                                                                  $event,
                                                                  hr - 1,
                                                                  hour,
                                                                  schedule
                                                                )
                                                              },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-semibold",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "10px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  schedule.turma
                                                                    .code
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("br"),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex items-center",
                                                            },
                                                            [
                                                              schedule.allStudents.findIndex(
                                                                (a) =>
                                                                  a ===
                                                                  _vm.student.id
                                                              ) >= 0
                                                                ? _c(
                                                                    "feather-icon",
                                                                    {
                                                                      staticClass:
                                                                        "mr-1",
                                                                      attrs: {
                                                                        icon: "UserIcon",
                                                                        svgClasses:
                                                                          "w-4 h-4",
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    schedule
                                                                      .module
                                                                      .abbreviation
                                                                  ) +
                                                                    " (" +
                                                                    _vm._s(
                                                                      schedule
                                                                        .module
                                                                        .class_hour
                                                                    ) +
                                                                    "h)"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    }),
                                    _c(
                                      "vs-tr",
                                      {
                                        staticStyle: {
                                          opacity: "1 !important",
                                          border: "10px",
                                        },
                                      },
                                      [
                                        _c(
                                          "vs-td",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                            },
                                            attrs: {
                                              colspan:
                                                _vm.handleRange(table).length +
                                                1,
                                            },
                                          },
                                          [_c("vs-divider")],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "template",
                            { slot: "thead" },
                            [
                              _c(
                                "vs-th",
                                {
                                  staticStyle: {
                                    "font-size": "11px",
                                    width: "85px",
                                  },
                                },
                                [_c("span", [_vm._v("HORÁRIO")])]
                              ),
                              _vm._l(_vm.handleRange(table), function (hr, i) {
                                return _c(
                                  "vs-th",
                                  {
                                    key: i,
                                    staticStyle: {
                                      "font-size": "11px",
                                      "min-width": "126px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.weekDays[
                                            _vm.selectedDays[hr - 1]
                                              .weekdayPosition
                                          ]
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm._f("dateTime")(
                                            _vm.selectedDays[hr - 1].id
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        2
                      )
                    }),
                    1
                  ),
                  !_vm.tables
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mt-6 flex flex-wrap items-center justify-center",
                        },
                        [
                          _c(
                            "vs-alert",
                            {
                              staticClass: "w-5/6",
                              staticStyle: { height: "-webkit-fill-available" },
                              attrs: {
                                color: "warning",
                                title: "Defina o período",
                                active: !_vm.tables,
                              },
                            },
                            [
                              _vm._v(
                                "\n            Selecione a(s) data(s) no calendário ou defina um intervalo de datas para ver\n            os agendamentos conforme o filtro superior.\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }