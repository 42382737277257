export default {
  // SET_CNH (state, payload) {
  //   state.cnh = Object.assign({}, state.cnh, payload) //precisa ser assim para manter a reatividade
  // },
  // SET_CNHS (state, cnhs) {
  //   state.cnhs = cnhs
  // },
  // DELETE_CNH (state, itemId) {
  //   const userIndex = state.students.findIndex((u) => u.id === itemId)
  //   state.students.splice(userIndex, 1)
  // }
}
