var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "user-edit-tab-contract" } },
    [
      _c("cancel-contract-form-popup", {
        attrs: {
          "contract-id": _vm.contractId,
          active: _vm.popupCancelContract,
        },
        on: {
          "update:active": function ($event) {
            _vm.popupCancelContract = $event
          },
          saved: _vm.refreshData,
        },
      }),
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: {
            fullscreen: false,
            title: "Boleto(s)",
            active: _vm.popupShowBoleto,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowBoleto = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "vs-con-loading__container",
              attrs: { id: "boleto-loading" },
            },
            [
              !Array.isArray(_vm.boletoUrl)
                ? _c("embed", {
                    attrs: {
                      src: _vm.boletoUrl,
                      alt: "Boleto",
                      width: "100%",
                      height: "600",
                      type: "application/pdf",
                      pluginspage:
                        "http://www.adobe.com/products/acrobat/readstep2.html",
                    },
                  })
                : _vm._e(),
              Array.isArray(_vm.boletoUrl)
                ? _c(
                    "div",
                    _vm._l(_vm.boletoUrl, function (bUrl, index) {
                      return _c("embed", {
                        key: index,
                        attrs: {
                          src: bUrl,
                          alt: "Boleto",
                          width: "100%",
                          height: "600",
                          type: "application/pdf",
                          pluginspage:
                            "http://www.adobe.com/products/acrobat/readstep2.html",
                        },
                      })
                    }),
                    0
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "vx-row" },
                [
                  _c("vs-divider"),
                  _c("div", { staticClass: "vx-col w-full mt-0" }, [
                    _c(
                      "div",
                      {
                        staticClass: "mt-2 flex flex-wrap items-center justify",
                      },
                      [
                        !Array.isArray(_vm.remessaUrl)
                          ? _c(
                              "vs-button",
                              {
                                staticClass: "w-full",
                                attrs: { href: _vm.remessaUrl },
                              },
                              [
                                _vm._v(
                                  "!! Clique aqui para baixar o arquivo de remessa !!"
                                ),
                              ]
                            )
                          : _vm._e(),
                        Array.isArray(_vm.remessaUrl)
                          ? _c(
                              "div",
                              _vm._l(_vm.remessaUrl, function (rUrl, index) {
                                return _c(
                                  "vs-button",
                                  {
                                    key: index,
                                    staticClass: "w-full",
                                    attrs: { href: rUrl },
                                  },
                                  [
                                    _vm._v(
                                      "!! Clique aqui para baixar o arquivo " +
                                        _vm._s(index + 1) +
                                        " de remessa !!"
                                    ),
                                  ]
                                )
                              }),
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: {
            fullscreen: false,
            title: _vm.contractPopupTitle,
            active: _vm.popupContract,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupContract = $event
            },
          },
        },
        [
          _c(
            "dialog-sig",
            {
              staticStyle: { "z-index": "9999999999" },
              attrs: {
                show: _vm.popupServiceQty,
                title: "QUANTIDADE",
                icon: "warning",
                actionButtonText: "Continuar",
                actionButtonColor: "warning",
              },
              on: {
                action: _vm.changeServiceQty,
                cancel: _vm.closeChangeServiceQty,
              },
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full mt-0" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { type: "number", min: "1", label: "Quantidade" },
                      model: {
                        value: _vm.serviceQty,
                        callback: function ($$v) {
                          _vm.serviceQty = $$v
                        },
                        expression: "serviceQty",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c("permission-password", {
            staticStyle: { "z-index": "9999999999" },
            attrs: {
              show: _vm.popupPermissionPassword,
              title: "ALERTA DE BAIXA RETROATIVA",
              icon: "warning",
              actionButtonText: "Prosseguir",
            },
            on: {
              action: function ($event) {
                return _vm.storeContract(true)
              },
              cancel: function ($event) {
                _vm.popupPermissionPassword = false
              },
            },
          }),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-2/5 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Serviços"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  attrs: {
                    "data-vv-as": "Serviços",
                    "data-vv-scope": "contract",
                    name: "services",
                    options: _vm.servicePackages,
                    multiple: "",
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  on: {
                    input: _vm.changeContractSubTotal,
                    "option:deselected": _vm.serviceDeselected,
                    "option:selected": _vm.newServiceSelected,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.contract.servicePackages,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "servicePackages", $$v)
                    },
                    expression: "contract.servicePackages",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("contract.services"),
                        expression: "errors.has('contract.services')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("contract.services")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "currency",
                      rawName: "v-currency",
                      value: _vm.currencyConfig,
                      expression: "currencyConfig",
                    },
                  ],
                  ref: "subTotal",
                  staticClass: "w-full",
                  attrs: { label: "Valor" },
                  model: {
                    value: _vm.contract.sub_total,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "sub_total", $$v)
                    },
                    expression: "contract.sub_total",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Desconto " + _vm._s(_vm.contract.discount_type)),
                ]),
                _c(
                  "vx-input-group",
                  {
                    attrs: {
                      prependClasses:
                        "border border-solid border-grey border-r-0",
                    },
                  },
                  [
                    _c("template", { slot: "append" }, [
                      _c(
                        "div",
                        { staticClass: "append-text btn-addon" },
                        [
                          _c(
                            "vs-button",
                            { on: { click: _vm.toggleDiscountType } },
                            [_vm._v(_vm._s(_vm.contract.discount_type))]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.contract.discount_type === "%"
                      ? _c("vs-input", {
                          key: "1__",
                          staticClass: "w-full",
                          attrs: { type: "number" },
                          model: {
                            value: _vm.contract.discount,
                            callback: function ($$v) {
                              _vm.$set(_vm.contract, "discount", $$v)
                            },
                            expression: "contract.discount",
                          },
                        })
                      : _c("vs-input", {
                          directives: [
                            {
                              name: "currency",
                              rawName: "v-currency",
                              value: _vm.currencyConfig,
                              expression: "currencyConfig",
                            },
                          ],
                          key: "2___",
                          ref: "discount",
                          staticClass: "w-full",
                          model: {
                            value: _vm.contract.discount,
                            callback: function ($$v) {
                              _vm.$set(_vm.contract, "discount", $$v)
                            },
                            expression: "contract.discount",
                          },
                        }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-0" }, [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v("Total"),
              ]),
              _c("h1", { staticClass: "flex flex-wrap justify-center" }, [
                _c("sup", { staticClass: "text-lg" }, [_vm._v("R$")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.contract.total
                        .toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                        .replace("R$", "")
                    )
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Nº de Parcelas"),
                ]),
                _c("vs-input-number", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min_value:1",
                      expression: "'required|min_value:1'",
                    },
                  ],
                  staticClass: "mt-2",
                  attrs: {
                    min: "1",
                    "data-vv-as": "Parcelas",
                    "data-vv-scope": "contract",
                    name: "parcelsNumber",
                  },
                  on: { input: _vm.changeParcelsNumber },
                  model: {
                    value: _vm.contract.parcelsNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "parcelsNumber", $$v)
                    },
                    expression: "contract.parcelsNumber",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("contract.parcelsNumber"),
                        expression: "errors.has('contract.parcelsNumber')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("contract.parcelsNumber")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "currency",
                      rawName: "v-currency",
                      value: _vm.currencyConfig,
                      expression: "currencyConfig",
                    },
                  ],
                  ref: "firstParcelValue",
                  staticClass: "w-full mt-0",
                  attrs: {
                    label: "Valor 1ª Parcela",
                    disabled: _vm.contract.parcelsNumber < 2,
                  },
                  model: {
                    value: _vm.contract.firstParcelValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "firstParcelValue", $$v)
                    },
                    expression: "contract.firstParcelValue",
                  },
                }),
                _vm.errorfirstParcelValue
                  ? _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v("Este valor é maior que o total."),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    type: "date",
                    label: "1º Vencimento",
                    "data-vv-as": "1º Vencimento",
                    "data-vv-scope": "contract",
                    name: "expirationDateFirstParcel",
                  },
                  model: {
                    value: _vm.contract.expirationDateFirstParcel,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "expirationDateFirstParcel", $$v)
                    },
                    expression: "contract.expirationDateFirstParcel",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has(
                          "contract.expirationDateFirstParcel"
                        ),
                        expression:
                          "errors.has('contract.expirationDateFirstParcel')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Este campo é obrigatório.")]
                ),
                !_vm.isSameOrAfterToday(_vm.contract.expirationDateFirstParcel)
                  ? _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v("A primeira parcela já está vencida."),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Periodicidade"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: [
                      { label: "Mensal", value: "monthly" },
                      { label: "Quinzenal", value: "biweekly" },
                      { label: "Semanal", value: "weekly" },
                    ],
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  model: {
                    value: _vm.contract.frequency,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "frequency", $$v)
                    },
                    expression: "contract.frequency",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-2" }, [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v("Aplicar Juros"),
              ]),
              _c(
                "div",
                { staticClass: "flex flex-wrap items-center justify-center" },
                [
                  _c(
                    "vs-switch",
                    {
                      staticClass: "w-1/2 mt-2",
                      attrs: {
                        "icon-pack": "feather",
                        "vs-icon-on": "icon-check-circle",
                        "vs-icon-off": "icon-slash",
                      },
                      model: {
                        value: _vm.contract.applyInterest,
                        callback: function ($$v) {
                          _vm.$set(_vm.contract, "applyInterest", $$v)
                        },
                        expression: "contract.applyInterest",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                        _vm._v("SIM"),
                      ]),
                      _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                        _vm._v("NÃO"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Tipo de Pagamento"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "vue_select_drop_size_180",
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    "data-vv-as": "Tipo de Pagamento",
                    "data-vv-scope": "contract",
                    name: "type_payment",
                    options: _vm.typePayments,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  on: { input: _vm.filterAccounts },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.contract.type_payment,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "type_payment", $$v)
                    },
                    expression: "contract.type_payment",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("contract.type_payment"),
                        expression: "errors.has('contract.type_payment')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Este campo é obrigatório.")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Conta"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "vue_select_drop_size_180",
                  attrs: {
                    disabled: !_vm.contract.type_payment,
                    reduce: (option) => option.value,
                    clearable: false,
                    "data-vv-as": "Conta",
                    "data-vv-scope": "contract",
                    name: "account",
                    options: _vm.accountsFilteredOptions,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.contract.account,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "account", $$v)
                    },
                    expression: "contract.account",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("contract.account"),
                        expression: "errors.has('contract.account')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Este campo é obrigatório.")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Plano de contas"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_180",
                  attrs: {
                    reduce: (option) => option.value,
                    options: _vm.accountPlans,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.contract.account_plan,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "account_plan", $$v)
                    },
                    expression: "contract.account_plan",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Vendedor"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_180",
                  attrs: {
                    reduce: (option) => option.value,
                    options: _vm.employees,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  on: { input: _vm.changeSeller },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.contract.employee_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "employee_id", $$v)
                    },
                    expression: "contract.employee_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Comissão " + _vm._s(_vm.contract.commission_type)),
                ]),
                _c(
                  "vx-input-group",
                  {
                    attrs: {
                      prependClasses:
                        "border border-solid border-grey border-r-0",
                    },
                  },
                  [
                    _c("template", { slot: "append" }, [
                      _c(
                        "div",
                        { staticClass: "append-text btn-addon" },
                        [
                          _c(
                            "vs-button",
                            { on: { click: _vm.toggleCommissionType } },
                            [_vm._v(_vm._s(_vm.contract.commission_type))]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.contract.commission_type === "%"
                      ? _c("vs-input", {
                          key: "1__",
                          staticClass: "w-full",
                          attrs: { type: "number" },
                          model: {
                            value: _vm.contract.commission,
                            callback: function ($$v) {
                              _vm.$set(_vm.contract, "commission", $$v)
                            },
                            expression: "contract.commission",
                          },
                        })
                      : _c("vs-input", {
                          directives: [
                            {
                              name: "currency",
                              rawName: "v-currency",
                              value: _vm.currencyConfig,
                              expression: "currencyConfig",
                            },
                          ],
                          key: "2___",
                          ref: "commission",
                          staticClass: "w-full",
                          model: {
                            value: _vm.contract.commission,
                            callback: function ($$v) {
                              _vm.$set(_vm.contract, "commission", $$v)
                            },
                            expression: "contract.commission",
                          },
                        }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Categoria Atual"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_115",
                  attrs: {
                    reduce: (option) => option.value,
                    options: _vm.cnhOptions,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.contract.current_category_cnh,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "current_category_cnh", $$v)
                    },
                    expression: "contract.current_category_cnh",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Categoria Pretendida"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_115",
                  attrs: {
                    reduce: (option) => option.value,
                    options: _vm.cnhOptions,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.contract.intended_category_cnh,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "intended_category_cnh", $$v)
                    },
                    expression: "contract.intended_category_cnh",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-2" }, [
              _c("label", { staticClass: "vs-input--label" }, [_vm._v("EAR")]),
              _c(
                "div",
                { staticClass: "flex flex-wrap items-center justify-center" },
                [
                  _c(
                    "vs-switch",
                    {
                      staticClass: "w-1/2 mt-2",
                      attrs: {
                        "icon-pack": "feather",
                        "vs-icon-on": "icon-check-circle",
                        "vs-icon-off": "icon-slash",
                      },
                      model: {
                        value: _vm.contract.gainful_activity,
                        callback: function ($$v) {
                          _vm.$set(_vm.contract, "gainful_activity", $$v)
                        },
                        expression: "contract.gainful_activity",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                        _vm._v("SIM"),
                      ]),
                      _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                        _vm._v("NÃO"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "vx-col md:w-2/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Cursos"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_115",
                  attrs: {
                    reduce: (option) => option.value,
                    options: _vm.specializedCoursesCNHOptions,
                    multiple: "",
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.contract.specialized_courses,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "specialized_courses", $$v)
                    },
                    expression: "contract.specialized_courses",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-4/5 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "Observações" },
                  model: {
                    value: _vm.contract.observation,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "observation", $$v)
                    },
                    expression: "contract.observation",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-2" }, [
              _c(
                "div",
                { staticClass: "mt-5 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "w-full",
                      attrs: {
                        disabled: _vm.disableGenerateParcels,
                        type: "border",
                        color: "warning",
                        "icon-pack": "feather",
                        icon: "icon-dollar-sign",
                      },
                      on: { click: _vm.generateContractParcels },
                    },
                    [_vm._v("Gerar Parcelas")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "vs-divider",
            { attrs: { position: "left" } },
            [
              _c("feather-icon", {
                staticClass: "mr-0",
                attrs: { icon: "DollarSignIcon", svgClasses: "w-4 h-4" },
              }),
              _c("span", { staticClass: "mb-4" }, [_vm._v("Parcelas")]),
            ],
            1
          ),
          _vm.contract.parcels.length
            ? _c(
                "vs-table",
                {
                  staticStyle: { overflow: "-webkit-paged-y" },
                  attrs: { data: _vm.contract.parcels, hoverFlat: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ data }) {
                          return [
                            _vm._l(data, function (parcel, i) {
                              return _c(
                                "vs-tr",
                                {
                                  key: i,
                                  staticStyle: { opacity: "1 !important" },
                                },
                                [
                                  _c("vs-td", [
                                    _vm._v(" " + _vm._s(i + 1) + " "),
                                  ]),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("currency-input", {
                                        staticClass:
                                          "w-32 vs-inputx vs-input--input normal hasValue",
                                        attrs: {
                                          currency: { prefix: "R$ " },
                                          valueAsInteger: false,
                                          distractionFree: false,
                                          precision: 2,
                                          autoDecimalMode: true,
                                          valueRange: { min: 0 },
                                          allowNegative: false,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.changeValueParcel(
                                              parcel,
                                              i
                                            )
                                          },
                                        },
                                        model: {
                                          value: parcel.value_parcel,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              parcel,
                                              "value_parcel",
                                              $$v
                                            )
                                          },
                                          expression: "parcel.value_parcel",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("vs-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate.initial",
                                            value: { required: true },
                                            expression: "{ required: true }",
                                            modifiers: { initial: true },
                                          },
                                        ],
                                        staticClass: "w-48",
                                        attrs: {
                                          type: "date",
                                          "data-vv-as": "Vencimento",
                                          "data-vv-scope": "contract",
                                          name: "expiration_date" + i,
                                        },
                                        model: {
                                          value: parcel.expiration_date,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              parcel,
                                              "expiration_date",
                                              $$v
                                            )
                                          },
                                          expression: "parcel.expiration_date",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.errors.has(
                                                "contract.expiration_date" + i
                                              ),
                                              expression:
                                                "errors.has('contract.expiration_date'+i)",
                                            },
                                          ],
                                          staticClass: "text-danger text-sm",
                                        },
                                        [_vm._v("Campo obrigatório")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("v-select", {
                                        staticClass:
                                          "vue_select_drop_size_100 w-48",
                                        attrs: {
                                          clearable: false,
                                          reduce: (option) => option.value,
                                          options: _vm.typePayments,
                                          placeholder: "Selecione",
                                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.filterAccountsParcels(i)
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "no-options",
                                              fn: function ({}) {
                                                return [
                                                  _vm._v(
                                                    "\n                Nenhum resultado encontrado.\n              "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: parcel.type_payment,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              parcel,
                                              "type_payment",
                                              $$v
                                            )
                                          },
                                          expression: "parcel.type_payment",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("v-select", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate.initial",
                                            value: {
                                              required: !parcel.account,
                                            },
                                            expression:
                                              "{ required: !parcel.account }",
                                            modifiers: { initial: true },
                                          },
                                        ],
                                        staticClass:
                                          "vue_select_drop_size_100 w-48",
                                        attrs: {
                                          clearable: false,
                                          disabled: !parcel.type_payment,
                                          reduce: (option) => option.value,
                                          options:
                                            parcel.accountsFilteredOptions,
                                          placeholder: "Selecione",
                                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                                          "data-vv-as": "Conta",
                                          "data-vv-scope": "contract",
                                          name: "account" + i,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "no-options",
                                              fn: function ({}) {
                                                return [
                                                  _vm._v(
                                                    "\n                Nenhum resultado encontrado.\n              "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: parcel.account,
                                          callback: function ($$v) {
                                            _vm.$set(parcel, "account", $$v)
                                          },
                                          expression: "parcel.account",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.errors.has(
                                                "contract.account" + i
                                              ),
                                              expression:
                                                "errors.has('contract.account'+i)",
                                            },
                                          ],
                                          staticClass: "text-danger text-sm",
                                        },
                                        [_vm._v("Campo obrigatório")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("vs-td", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex flex-wrap items-center justify-center",
                                      },
                                      [
                                        _c(
                                          "vs-switch",
                                          {
                                            staticClass: "w-28 mt-2",
                                            attrs: {
                                              "icon-pack": "feather",
                                              "vs-icon-on": "icon-check-circle",
                                              "vs-icon-off": "icon-slash",
                                            },
                                            on: {
                                              input: function ($event) {
                                                parcel.situation
                                                  ? (parcel.value_pay =
                                                      parcel.value_parcel)
                                                  : (parcel.value_pay = 0)
                                                parcel.situation
                                                  ? (parcel.payday = _vm.today)
                                                  : (parcel.payday = "")
                                                parcel.situation
                                                  ? ""
                                                  : (parcel.futureDate = false)
                                                _vm.checkPayday(parcel)
                                              },
                                            },
                                            model: {
                                              value: parcel.situation,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  parcel,
                                                  "situation",
                                                  $$v
                                                )
                                              },
                                              expression: "parcel.situation",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                attrs: { slot: "off" },
                                                slot: "off",
                                              },
                                              [_vm._v("ABERTA")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                attrs: { slot: "on" },
                                                slot: "on",
                                              },
                                              [_vm._v("QUITADA")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("vs-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate.initial",
                                            value: {
                                              required: parcel.situation,
                                            },
                                            expression:
                                              "{ required: parcel.situation }",
                                            modifiers: { initial: true },
                                          },
                                        ],
                                        staticClass: "w-48",
                                        attrs: {
                                          type: "date",
                                          disabled: !parcel.situation,
                                          "data-vv-as": "Pago em",
                                          "data-vv-scope": "contract",
                                          name: "payday" + i,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.checkPayday(parcel)
                                          },
                                        },
                                        model: {
                                          value: parcel.payday,
                                          callback: function ($$v) {
                                            _vm.$set(parcel, "payday", $$v)
                                          },
                                          expression: "parcel.payday",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.errors.has(
                                                "contract.payday" + i
                                              ),
                                              expression:
                                                "errors.has('contract.payday'+i)",
                                            },
                                          ],
                                          staticClass: "text-danger text-sm",
                                        },
                                        [_vm._v("Campo obrigatório")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: parcel.futureDate,
                                              expression: "parcel.futureDate",
                                            },
                                          ],
                                          staticClass: "text-danger text-sm",
                                        },
                                        [_vm._v("Data futura não permitida")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            _c(
                              "vs-tr",
                              { staticStyle: { opacity: "1 !important" } },
                              [
                                _c(
                                  "vs-td",
                                  { attrs: { colspan: "8" } },
                                  [
                                    _c("vs-divider"),
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mt-2 flex flex-wrap items-center",
                                          },
                                          [
                                            _c(
                                              "vs-button",
                                              {
                                                staticClass: "mr-4",
                                                attrs: { color: "secondary" },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.popupContract = false
                                                  },
                                                },
                                              },
                                              [_vm._v("Cancelar")]
                                            ),
                                            _c(
                                              "vs-button",
                                              {
                                                staticClass: "mr-auto",
                                                attrs: {
                                                  disabled:
                                                    !_vm.validateContract ||
                                                    _vm.disableGenerateParcels ||
                                                    _vm.disableSave,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.storeContract()
                                                  },
                                                },
                                              },
                                              [_vm._v("Salvar Contrato")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2607928697
                  ),
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", [_vm._v("#")]),
                      _c("vs-th", [_vm._v("Valor")]),
                      _c("vs-th", [_vm._v("Vencimento")]),
                      _c("vs-th", [_vm._v("Forma Pagamento")]),
                      _c("vs-th", [_vm._v("Conta")]),
                      _c("vs-th", [_vm._v("Situação")]),
                      _c("vs-th", [_vm._v("Pago Em")]),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          !_vm.contract.parcels.length
            ? _c(
                "vs-alert",
                {
                  staticClass: "mt-4 mb-4",
                  staticStyle: { height: "auto" },
                  attrs: {
                    active: true,
                    color: "warning",
                    "icon-pack": "feather",
                    icon: "icon-info",
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      "Por favor preencha o formulário e gere as parcelas para poder cadastrar o contrato!"
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup50",
          attrs: { fullscreen: false, title: "Cancelar contrato" },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Ressarcimento"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: [
                      { label: "Sem ressarcimento", value: "no_refund" },
                      {
                        label: "Devolver valor ao aluno",
                        value: "refund_student",
                      },
                      {
                        label: "Receber valor do aluno",
                        value: "refund_company",
                      },
                    ],
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  model: {
                    value: _vm.contractRefund,
                    callback: function ($$v) {
                      _vm.contractRefund = $$v
                    },
                    expression: "contractRefund",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm.contractRefund !== "no_refund"
            ? _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Banco"),
                    ]),
                    _c("v-select", {
                      staticClass: "vue_select_drop_size_150",
                      attrs: {
                        reduce: (option) => option.value,
                        options: _vm.banks,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      model: {
                        value: _vm.contract.return_bank_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.contract, "return_bank_id", $$v)
                        },
                        expression: "contract.return_bank_id",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full mt-0",
                      attrs: { label: "Agência" },
                      model: {
                        value: _vm.contract.return_agency,
                        callback: function ($$v) {
                          _vm.$set(_vm.contract, "return_agency", $$v)
                        },
                        expression: "contract.return_agency",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full mt-0",
                      attrs: { label: "Conta" },
                      model: {
                        value: _vm.contract.return_account,
                        callback: function ($$v) {
                          _vm.$set(_vm.contract, "return_account", $$v)
                        },
                        expression: "contract.return_account",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.contractRefund === "refund_company"
            ? _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Valor"),
                    ]),
                    _c("currency-input", {
                      key: "contractRefund1",
                      staticClass:
                        "w-full vs-inputx vs-input--input normal hasValue",
                      attrs: {
                        currency: { prefix: "R$ " },
                        valueAsInteger: false,
                        distractionFree: false,
                        precision: 2,
                        autoDecimalMode: true,
                        valueRange: { min: 0 },
                        allowNegative: false,
                      },
                      model: {
                        value: _vm.contract.amount_receivable,
                        callback: function ($$v) {
                          _vm.$set(_vm.contract, "amount_receivable", $$v)
                        },
                        expression: "contract.amount_receivable",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full mt-0" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full mt-0",
                      attrs: { type: "date", label: "Vencimento" },
                      model: {
                        value: _vm.contract.date_receivable,
                        callback: function ($$v) {
                          _vm.$set(_vm.contract, "date_receivable", $$v)
                        },
                        expression: "contract.date_receivable",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.contractRefund === "refund_student"
            ? _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Valor"),
                    ]),
                    _c("currency-input", {
                      key: "contractRefund1",
                      staticClass:
                        "w-full vs-inputx vs-input--input normal hasValue",
                      attrs: {
                        currency: { prefix: "R$ " },
                        valueAsInteger: false,
                        distractionFree: false,
                        precision: 2,
                        autoDecimalMode: true,
                        valueRange: { min: 0 },
                        allowNegative: false,
                      },
                      model: {
                        value: _vm.contract.amount_to_pay,
                        callback: function ($$v) {
                          _vm.$set(_vm.contract, "amount_to_pay", $$v)
                        },
                        expression: "contract.amount_to_pay",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full mt-0" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full mt-0",
                      attrs: { type: "date", label: "Vencimento" },
                      model: {
                        value: _vm.contract.date_amount_to_pay,
                        callback: function ($$v) {
                          _vm.$set(_vm.contract, "date_amount_to_pay", $$v)
                        },
                        expression: "contract.date_amount_to_pay",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Motivo do cancelamento"),
                ]),
                _c("vs-textarea", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "mb-0",
                  attrs: {
                    "data-vv-as": "Motivo do cancelamento",
                    "data-vv-scope": "contractCancel",
                    name: "reason_cancellation",
                  },
                  model: {
                    value: _vm.contract.reason_cancellation,
                    callback: function ($$v) {
                      _vm.$set(_vm.contract, "reason_cancellation", $$v)
                    },
                    expression: "contract.reason_cancellation",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has(
                          "contractCancel.reason_cancellation"
                        ),
                        expression:
                          "errors.has('contractCancel.reason_cancellation')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.errors.first("contractCancel.reason_cancellation")
                      )
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c(
            "vs-alert",
            {
              staticClass: "mt-4 mb-4",
              staticStyle: { height: "auto" },
              attrs: {
                active: "",
                color: "danger",
                "icon-pack": "feather",
                icon: "icon-info",
              },
            },
            [
              _c("span", [
                _vm._v(
                  "\n        ATENÇÃO. O cancelamento de um contrato é irreversível.\n        As parcelas pagas serão mantidas e as parcelas em aberto serão excluídas.\n        Outros ajustes devem ser feitos manualmente direto no financeiro.\n      "
                ),
              ]),
            ]
          ),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col md:w-full w-full" }, [
              _c(
                "div",
                { staticClass: "mt-2 flex flex-wrap items-center" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupCancelContract = false
                        },
                      },
                    },
                    [_vm._v("Fechar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-2 mr-0",
                      attrs: {
                        disabled: !_vm.contract.reason_cancellation,
                        color: "danger",
                      },
                      on: { click: _vm.cancelContract },
                    },
                    [_vm._v("Cancelar Contrato")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup50",
          attrs: {
            fullscreen: false,
            title: "Alterar situação do contrato",
            active: _vm.popupChangeContractSituation,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupChangeContractSituation = $event
            },
          },
        },
        [
          _c(
            "vs-alert",
            {
              staticClass: "mt-4 mb-4",
              staticStyle: { height: "auto" },
              attrs: {
                active:
                  _vm.constractToChangeSituation.situation_contract ==
                  "CANCELED",
                color: "warning",
                "icon-pack": "feather",
                icon: "icon-info",
              },
            },
            [
              _c("span", [
                _vm._v(
                  "Este contrato está cancelado e por tanto não pode ter sua situação alterada."
                ),
              ]),
            ]
          ),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-1/4" }, [
              _c(
                "div",
                { staticClass: "mt-2 flex flex-wrap items-center" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mr-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupChangeContractSituation = false
                        },
                      },
                    },
                    [_vm._v("Fechar")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-col w-3/4" }, [
              _c(
                "div",
                { staticClass: "mt-2 flex flex-wrap items-center" },
                [
                  _vm.constractToChangeSituation.situation_contract ===
                  "FINISHED"
                    ? _c(
                        "vs-button",
                        {
                          staticClass: "ml-auto",
                          on: {
                            click: function ($event) {
                              return _vm.changeContractSituation("IN_PROGRESS")
                            },
                          },
                        },
                        [_vm._v("Reabrir Contrato")]
                      )
                    : _vm._e(),
                  _vm.constractToChangeSituation.situation_contract ===
                  "IN_PROGRESS"
                    ? _c(
                        "vs-button",
                        {
                          staticClass: "ml-auto",
                          on: {
                            click: function ($event) {
                              return _vm.changeContractSituation("FINISHED")
                            },
                          },
                        },
                        [_vm._v("Finalizar Contrato")]
                      )
                    : _vm._e(),
                  _vm.constractToChangeSituation.situation_contract !==
                  "CANCELED"
                    ? _c(
                        "vs-button",
                        {
                          staticClass: "ml-2 mr-0",
                          attrs: { color: "danger" },
                          on: { click: _vm.handleCancelContract },
                        },
                        [_vm._v("Cancelar Contrato")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup80",
          attrs: {
            fullscreen: false,
            title: "Salvar Avalista",
            active: _vm.popupShowGuarantor,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowGuarantor = $event
            },
          },
        },
        [
          _c(
            "dialog-sig",
            {
              staticStyle: { "z-index": "999999999999999" },
              attrs: { show: _vm.popupGuarantorDeleteConfirm, title: "ALERTA" },
              on: {
                action: _vm.dispatchDeleteGuarantor,
                cancel: function ($event) {
                  _vm.popupGuarantorDeleteConfirm = false
                },
              },
            },
            [
              _vm._v("\n        Tem certeza que deseja excluir o avalista? "),
              _c("br"),
              _vm._v("\n        Esta ação é irreversível\n    "),
            ]
          ),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-4/5 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required|alpha_spaces",
                      expression: "'required|alpha_spaces'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    label: "Nome Avalista",
                    "data-vv-as": "Nome Avalista",
                    "data-vv-scope": "guarantor",
                    name: "name",
                  },
                  model: {
                    value: _vm.data_local.guarantor.name_guarantor,
                    callback: function ($$v) {
                      _vm.$set(_vm.data_local.guarantor, "name_guarantor", $$v)
                    },
                    expression: "data_local.guarantor.name_guarantor",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("guarantor.name"),
                        expression: "errors.has('guarantor.name')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("guarantor.name")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Tipo de Pessoa"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: _vm.typeOptions,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.data_local.guarantor.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.data_local.guarantor, "type", $$v)
                    },
                    expression: "data_local.guarantor.type",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: ["###.###.###-##", "##.###.###/####-##"],
                      expression: "['###.###.###-##','##.###.###/####-##']",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "cpf",
                      expression: "'cpf'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    label: "CPF/CNPJ",
                    "data-vv-as": "CPF",
                    "data-vv-scope": "guarantor",
                    name: "cpf",
                  },
                  on: { keyup: _vm.searchGuarantor },
                  model: {
                    value: _vm.data_local.guarantor.cpf_guarantor,
                    callback: function ($$v) {
                      _vm.$set(_vm.data_local.guarantor, "cpf_guarantor", $$v)
                    },
                    expression: "data_local.guarantor.cpf_guarantor",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("guarantor.cpf"),
                        expression: "errors.has('guarantor.cpf')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("O CPF/CNPJ não é válido")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "RG/IE" },
                  model: {
                    value: _vm.data_local.guarantor.rg_guarantor,
                    callback: function ($$v) {
                      _vm.$set(_vm.data_local.guarantor, "rg_guarantor", $$v)
                    },
                    expression: "data_local.guarantor.rg_guarantor",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "Órgão Emissor RG" },
                  model: {
                    value: _vm.data_local.guarantor.orgao_emissor_rg_guarantor,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.data_local.guarantor,
                        "orgao_emissor_rg_guarantor",
                        $$v
                      )
                    },
                    expression:
                      "data_local.guarantor.orgao_emissor_rg_guarantor",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Estado RG"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: true,
                    options: _vm.states,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value:
                      _vm.data_local.guarantor.orgao_emissor_rg_uf_id_guarantor,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.data_local.guarantor,
                        "orgao_emissor_rg_uf_id_guarantor",
                        $$v
                      )
                    },
                    expression:
                      "data_local.guarantor.orgao_emissor_rg_uf_id_guarantor",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { type: "date", label: "Data de Nascimento" },
                  model: {
                    value: _vm.data_local.guarantor.date_of_birth_guarantor,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.data_local.guarantor,
                        "date_of_birth_guarantor",
                        $$v
                      )
                    },
                    expression: "data_local.guarantor.date_of_birth_guarantor",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "MapPinIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Endereço"),
              ]),
            ],
            1
          ),
          _c("hr"),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: ["#####-###"],
                      expression: "['#####-###']",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { label: "CEP", placeholder: "CEP" },
                  on: { keyup: _vm.searchZipCode },
                  model: {
                    value: _vm.data_local.guarantor.address.zip_code,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.data_local.guarantor.address,
                        "zip_code",
                        $$v
                      )
                    },
                    expression: "data_local.guarantor.address.zip_code",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Logradouro"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: "",
                    options: _vm.logradouroOptions,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.data_local.guarantor.address.logradouro,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.data_local.guarantor.address,
                        "logradouro",
                        $$v
                      )
                    },
                    expression: "data_local.guarantor.address.logradouro",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-2/5 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "Endereço" },
                  model: {
                    value: _vm.data_local.guarantor.address.street,
                    callback: function ($$v) {
                      _vm.$set(_vm.data_local.guarantor.address, "street", $$v)
                    },
                    expression: "data_local.guarantor.address.street",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    label: "Número",
                    type: "number",
                    placeholder: "S/N",
                  },
                  model: {
                    value: _vm.data_local.guarantor.address.number,
                    callback: function ($$v) {
                      _vm.$set(_vm.data_local.guarantor.address, "number", $$v)
                    },
                    expression: "data_local.guarantor.address.number",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: { label: "Complemento" },
                  model: {
                    value: _vm.data_local.guarantor.address.complement,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.data_local.guarantor.address,
                        "complement",
                        $$v
                      )
                    },
                    expression: "data_local.guarantor.address.complement",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: { label: "Bairro" },
                  model: {
                    value: _vm.data_local.guarantor.address.neighborhood,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.data_local.guarantor.address,
                        "neighborhood",
                        $$v
                      )
                    },
                    expression: "data_local.guarantor.address.neighborhood",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4_ w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Cidade"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_240",
                  attrs: {
                    reduce: (option) => option.value,
                    placeholder: _vm.cityGuarantorOptions.length
                      ? "Selecione"
                      : "Selecione um estado",
                    options: _vm.cityGuarantorOptions,
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.data_local.guarantor.address.city_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.data_local.guarantor.address, "city_id", $$v)
                    },
                    expression: "data_local.guarantor.address.city_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Estado"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_240",
                  attrs: {
                    reduce: (option) => option.value,
                    options: _vm.states,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  on: {
                    input: function ($event) {
                      _vm.fillCity(
                        _vm.data_local.guarantor.address.state_id,
                        null,
                        "cityGuarantorOptions",
                        "guarantor"
                      ),
                        (_vm.data_local.guarantor.address.city_id = null)
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.data_local.guarantor.address.state_id,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.data_local.guarantor.address,
                        "state_id",
                        $$v
                      )
                    },
                    expression: "data_local.guarantor.address.state_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("País"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: _vm.countryOptions,
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.data_local.guarantor.address.country,
                    callback: function ($$v) {
                      _vm.$set(_vm.data_local.guarantor.address, "country", $$v)
                    },
                    expression: "data_local.guarantor.address.country",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "PhoneIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Contato"),
              ]),
            ],
            1
          ),
          _c("hr"),
          _c(
            "div",
            { staticClass: "vx-row" },
            [
              _vm._l(_vm.data_local.guarantor.phones, function (phone, i) {
                return _c(
                  "div",
                  { key: i, staticClass: "vx-col md:w-1/5 w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["(##)#####-####", "(##)####-####"],
                          expression: "['(##)#####-####','(##)####-####']",
                        },
                      ],
                      staticClass: "w-full mt-2",
                      attrs: {
                        label:
                          phone.type === "phone"
                            ? "Telefone"
                            : phone.type === "mobile"
                            ? "Celular"
                            : phone.type === "other"
                            ? "Outro"
                            : "",
                      },
                      model: {
                        value: phone.phone,
                        callback: function ($$v) {
                          _vm.$set(phone, "phone", $$v)
                        },
                        expression: "phone.phone",
                      },
                    }),
                  ],
                  1
                )
              }),
              _c(
                "div",
                { staticClass: "vx-col md:w-2/5 w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "email",
                        expression: "'email'",
                      },
                    ],
                    staticClass: "w-full mt-2",
                    attrs: {
                      label: "E-mail",
                      "data-vv-as": "E-mail",
                      "data-vv-scope": "guarantor",
                      type: "email",
                      name: "email",
                    },
                    model: {
                      value: _vm.data_local.guarantor.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.data_local.guarantor, "email", $$v)
                      },
                      expression: "data_local.guarantor.email",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("guarantor.email"),
                          expression: "errors.has('guarantor.email')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("guarantor.email")))]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: { label: "Local de Trabalho" },
                  model: {
                    value: _vm.data_local.guarantor.workplace_guarantor,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.data_local.guarantor,
                        "workplace_guarantor",
                        $$v
                      )
                    },
                    expression: "data_local.guarantor.workplace_guarantor",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: { label: "Cônjuge" },
                  model: {
                    value: _vm.data_local.guarantor.spouse_name_guarantor,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.data_local.guarantor,
                        "spouse_name_guarantor",
                        $$v
                      )
                    },
                    expression: "data_local.guarantor.spouse_name_guarantor",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-3/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: { label: "Observações" },
                  model: {
                    value: _vm.data_local.guarantor.observation_guarantor,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.data_local.guarantor,
                        "observation_guarantor",
                        $$v
                      )
                    },
                    expression: "data_local.guarantor.observation_guarantor",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-0 flex flex-wrap items-center justify-end" },
                [
                  _vm.data_local.guarantor.id
                    ? _c(
                        "vs-button",
                        {
                          staticClass: "ml-auto mt-2",
                          attrs: { color: "danger" },
                          on: { click: _vm.deleteGuarantor },
                        },
                        [_vm._v("Deletar Avalista")]
                      )
                    : _vm._e(),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4 mt-2",
                      attrs: {
                        disabled:
                          !_vm.validateGuarantorForm ||
                          _vm.disableGuarantorSave,
                      },
                      on: { click: _vm.saveGuarantor },
                    },
                    [_vm._v("Salvar Avalista")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
          _c(
            "div",
            { staticClass: "mt-2 mb-4 flex flex-wrap items-center" },
            [
              _c(
                "vs-button",
                { staticClass: "w-1/2", on: { click: _vm.newContract } },
                [_vm._v("Novo Contrato")]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
          _c(
            "div",
            {
              staticClass: "mt-2 mb-4 flex flex-wrap items-center justify-end",
            },
            [
              _c(
                "vs-button",
                { staticClass: "w-1/2", on: { click: _vm.newContractModel } },
                [_vm._v("Novo Modelo de Contrato")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm.accordionDocumentTemplate
        ? _c("div", { staticClass: "vx-row mt-0" }, [
            _c("div", { staticClass: "vx-col md:w-full w-full mt-0" }, [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-3/4 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      staticClass: "w-full mt-0",
                      attrs: {
                        label: "Título",
                        "data-vv-as": "Título",
                        "data-vv-scope": "contractModel",
                        name: "title",
                      },
                      model: {
                        value: _vm.contractModel.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.contractModel, "title", $$v)
                        },
                        expression: "contractModel.title",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("contractModel.title"),
                            expression: "errors.has('contractModel.title')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("contractModel.title")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Cabeçalho"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        reduce: (option) => option.value,
                        clearable: false,
                        options: [
                          { label: "SIM", value: true },
                          { label: "NÃO", value: false },
                        ],
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      model: {
                        value: _vm.contractModel.print_header,
                        callback: function ($$v) {
                          _vm.$set(_vm.contractModel, "print_header", $$v)
                        },
                        expression: "contractModel.print_header",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "vx-row mt-4" }, [
                _c("div", { staticClass: "vx-col md:w-3/4 w-full mt-0" }, [
                  _c(
                    "div",
                    [
                      _c("ckeditor", {
                        attrs: { editor: _vm.editor, config: _vm.editorConfig },
                        on: { ready: _vm.onReady },
                        model: {
                          value: _vm.contractModel.body,
                          callback: function ($$v) {
                            _vm.$set(_vm.contractModel, "body", $$v)
                          },
                          expression: "contractModel.body",
                        },
                      }),
                      _c("div", { staticClass: "vx-row" }, [
                        _c("div", { staticClass: "vx-col w-full" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mt-0 flex flex-wrap items-center justify-end",
                            },
                            [
                              _c(
                                "vs-button",
                                {
                                  staticClass: "ml-auto mt-4",
                                  attrs: { color: "secondary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.accordionDocumentTemplate = false
                                    },
                                  },
                                },
                                [_vm._v("Cancelar")]
                              ),
                              _c(
                                "vs-button",
                                {
                                  staticClass: "ml-4 mt-4",
                                  attrs: {
                                    disabled:
                                      !_vm.validateContractFormModel ||
                                      _vm.disableContractModelSave,
                                  },
                                  on: { click: _vm.addContractModel },
                                },
                                [_vm._v("Salvar Modelo De Contrato")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                  [
                    _c("vs-divider", { attrs: { position: "left" } }, [
                      _c("strong", { staticClass: "text-sm" }, [
                        _vm._v("Variáveis do Aluno"),
                      ]),
                    ]),
                    _vm._l(_vm.studentVariables, function (variable, i) {
                      return _c(
                        "p",
                        {
                          key: `${i}_studentVariables`,
                          staticClass: "text-sm cursor-pointer",
                          on: {
                            click: function ($event) {
                              return _vm.copyVariable(variable.variable)
                            },
                          },
                        },
                        [
                          _c(
                            "vx-tooltip",
                            {
                              attrs: {
                                text: variable.variable,
                                position: "left",
                              },
                            },
                            [
                              _c("span", { staticClass: "under mb-1" }, [
                                _vm._v(_vm._s(variable.label)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    _c("vs-divider", { attrs: { position: "left" } }, [
                      _c("strong", { staticClass: "text-sm" }, [
                        _vm._v("Endereço"),
                      ]),
                    ]),
                    _vm._l(_vm.addressVariables, function (variable, i) {
                      return _c(
                        "p",
                        {
                          key: `${i}_addressVariables`,
                          staticClass: "text-sm cursor-pointer",
                          on: {
                            click: function ($event) {
                              return _vm.copyVariable(variable.variable)
                            },
                          },
                        },
                        [
                          _c(
                            "vx-tooltip",
                            {
                              attrs: {
                                text: variable.variable,
                                position: "left",
                              },
                            },
                            [
                              _c("span", { staticClass: "under mb-1" }, [
                                _vm._v(_vm._s(variable.label)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    _c("vs-divider", { attrs: { position: "left" } }, [
                      _c("strong", { staticClass: "text-sm" }, [
                        _vm._v("Avalista"),
                      ]),
                    ]),
                    _vm._l(_vm.tenantVariables, function (variable, i) {
                      return _c(
                        "p",
                        {
                          key: `${i}_tenantVariables`,
                          staticClass: "text-sm cursor-pointer",
                          on: {
                            click: function ($event) {
                              return _vm.copyVariable(variable.variable)
                            },
                          },
                        },
                        [
                          _c(
                            "vx-tooltip",
                            {
                              attrs: {
                                text: variable.variable,
                                position: "left",
                              },
                            },
                            [
                              _c("span", { staticClass: "under mb-1" }, [
                                _vm._v(_vm._s(variable.label)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    _c("vs-divider", { attrs: { position: "left" } }, [
                      _c("strong", { staticClass: "text-sm" }, [
                        _vm._v("Contrato"),
                      ]),
                    ]),
                    _vm._l(_vm.contractVariables, function (variable, i) {
                      return _c(
                        "p",
                        {
                          key: `${i}_contractVariables`,
                          staticClass: "text-sm cursor-pointer",
                          on: {
                            click: function ($event) {
                              return _vm.copyVariable(variable.variable)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(variable.label) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex items-end mt-2" },
        [
          _c("feather-icon", {
            staticClass: "mr-2",
            attrs: { icon: "UserIcon", svgClasses: "w-5 h-5" },
          }),
          _c("span", { staticClass: "leading-none font-medium" }, [
            _vm._v("Avalista"),
          ]),
        ],
        1
      ),
      _c("hr"),
      _c("div", { staticClass: "vx-row flex flex-wrap items-center" }, [
        _c("div", { staticClass: "vx-col md:w-1/4 w-full mt-0" }, [
          _c("span", [
            _vm._v(
              _vm._s(
                this.$store.state.studentManagement.student.guarantor
                  ? this.$store.state.studentManagement.student.guarantor
                      .name_guarantor
                  : ""
              )
            ),
          ]),
        ]),
        _c("div", { staticClass: "vx-col md:w-1/4 w-full mt-0" }, [
          _c("span", [
            _vm._v(
              _vm._s(
                this.$store.state.studentManagement.student.guarantor &&
                  this.$store.state.studentManagement.student.guarantor
                    .phones &&
                  this.$store.state.studentManagement.student.guarantor.phones.find(
                    (phone) => phone.type === "mobile"
                  )
                  ? this.$store.state.studentManagement.student.guarantor.phones.find(
                      (phone) => phone.type === "mobile"
                    ).phone
                  : ""
              )
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/2 w-full mt-0" },
          [
            _c(
              "vs-button",
              {
                staticClass: "ml-auto sizedIcon",
                attrs: {
                  type: "line",
                  icon: _vm.data_local.guarantor.id
                    ? "create"
                    : "add_circle_outline",
                },
                on: { click: _vm.handleGuarantor },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.data_local.guarantor.id ? "Alterar" : "Cadastrar"
                  ) + " Avalista"
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "flex items-end mt-2" },
        [
          _c("feather-icon", {
            staticClass: "mr-2",
            attrs: { icon: "FileTextIcon", svgClasses: "w-5 h-5" },
          }),
          _c("span", { staticClass: "leading-none font-medium" }, [
            _vm._v("Contratos"),
          ]),
        ],
        1
      ),
      _c("hr", { staticClass: "mb-8" }),
      _c("div", { staticClass: "flex flex-wrap items-center" }, [
        _c(
          "div",
          { staticClass: "flex-grow" },
          [
            _c(
              "vs-dropdown",
              {
                staticClass: "cursor-pointer",
                attrs: { "vs-trigger-click": "" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium",
                  },
                  [
                    _c("span", { staticClass: "mr-2" }, [
                      _vm._v(
                        _vm._s(
                          _vm.currentPage * _vm.paginationPageSize -
                            (_vm.paginationPageSize - 1)
                        ) +
                          " - " +
                          _vm._s(
                            _vm.contractStudentsData.total -
                              _vm.currentPage * _vm.paginationPageSize >
                              0
                              ? _vm.currentPage * _vm.paginationPageSize
                              : _vm.contractStudentsData.total
                          ) +
                          " de " +
                          _vm._s(_vm.contractStudentsData.total)
                      ),
                    ]),
                    _c("feather-icon", {
                      attrs: { icon: "ChevronDownIcon", svgClasses: "h-4 w-4" },
                    }),
                  ],
                  1
                ),
                _c(
                  "vs-dropdown-menu",
                  [
                    _c(
                      "vs-dropdown-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.paginationSetPageSize(10)
                          },
                        },
                      },
                      [_c("span", [_vm._v("10")])]
                    ),
                    _c(
                      "vs-dropdown-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.paginationSetPageSize(15)
                          },
                        },
                      },
                      [_c("span", [_vm._v("15")])]
                    ),
                    _c(
                      "vs-dropdown-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.paginationSetPageSize(20)
                          },
                        },
                      },
                      [_c("span", [_vm._v("20")])]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("ag-grid-vue", {
        ref: "agGridTableContract",
        staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
        attrs: {
          components: _vm.components,
          gridOptions: _vm.gridOptions,
          columnDefs: _vm.columnDefs,
          defaultColDef: _vm.defaultColDef,
          overlayLoadingTemplate: _vm.overlayLoadingTemplate,
          overlayNoRowsTemplate: _vm.noRowsTemplate,
          localeText: _vm.localeText,
          rowSelection: "multiple",
          colResizeDefault: "shift",
          animateRows: true,
          pagination: true,
          paginationPageSize: _vm.paginationPageSize,
          cacheBlockSize: 40,
          suppressPaginationPanel: true,
          enableRtl: _vm.$vs.rtl,
        },
        on: { "grid-ready": _vm.onGridReady },
      }),
      _c("vs-pagination", {
        attrs: { total: _vm.totalPages, max: 10 },
        model: {
          value: _vm.currentPage,
          callback: function ($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }