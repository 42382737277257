var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "student-edit-tab-general-data" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "noOverflow",
          attrs: {
            fullscreen: false,
            title: _vm.showPdfTitle,
            active: _vm.popupShowPdf,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowPdf = $event
            },
          },
        },
        [
          _c("object", {
            staticStyle: { height: "600px" },
            attrs: { data: _vm.srcPdf, width: "100%" },
          }),
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            fullscreen: false,
            title: "Entrega de Documentos",
            active: _vm.popupDeliveryOfDocuments,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupDeliveryOfDocuments = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "FileTextIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Motivos(s)"),
              ]),
            ],
            1
          ),
          _c("hr", { staticClass: "mb-4" }),
          _vm._l(_vm.deliveryOfDocumentReasonList, function (reason, i) {
            return _c("div", { key: `${i}_reason`, staticClass: "vx-row" }, [
              reason.value1
                ? _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": reason.value1 },
                              model: {
                                value: _vm.deliveryOfDocument.motivo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.deliveryOfDocument,
                                    "motivo",
                                    $$v
                                  )
                                },
                                expression: "deliveryOfDocument.motivo",
                              },
                            },
                            [_vm._v(_vm._s(reason.text1))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              reason.value2
                ? _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": reason.value2 },
                              model: {
                                value: _vm.deliveryOfDocument.motivo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.deliveryOfDocument,
                                    "motivo",
                                    $$v
                                  )
                                },
                                expression: "deliveryOfDocument.motivo",
                              },
                            },
                            [_vm._v(_vm._s(reason.text2))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              reason.value3
                ? _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": reason.value3 },
                              model: {
                                value: _vm.deliveryOfDocument.motivo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.deliveryOfDocument,
                                    "motivo",
                                    $$v
                                  )
                                },
                                expression: "deliveryOfDocument.motivo",
                              },
                            },
                            [_vm._v(_vm._s(reason.text3))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ])
          }),
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "FileTextIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Documento(s)"),
              ]),
            ],
            1
          ),
          _c("hr", { staticClass: "mb-4" }),
          _vm._l(_vm.deliveryOfDocumentList, function (document, i) {
            return _c("div", { key: i, staticClass: "vx-row" }, [
              document.value1
                ? _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": document.value1 },
                              model: {
                                value: _vm.deliveryOfDocument.document,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.deliveryOfDocument,
                                    "document",
                                    $$v
                                  )
                                },
                                expression: "deliveryOfDocument.document",
                              },
                            },
                            [_vm._v(_vm._s(document.text1))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              document.value2
                ? _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": document.value2 },
                              model: {
                                value: _vm.deliveryOfDocument.document,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.deliveryOfDocument,
                                    "document",
                                    $$v
                                  )
                                },
                                expression: "deliveryOfDocument.document",
                              },
                            },
                            [_vm._v(_vm._s(document.text2))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              document.value3
                ? _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": document.value3 },
                              model: {
                                value: _vm.deliveryOfDocument.document,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.deliveryOfDocument,
                                    "document",
                                    $$v
                                  )
                                },
                                expression: "deliveryOfDocument.document",
                              },
                            },
                            [_vm._v(_vm._s(document.text3))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ])
          }),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "Outros (Especificar)" },
                  model: {
                    value: _vm.deliveryOfDocument.other,
                    callback: function ($$v) {
                      _vm.$set(_vm.deliveryOfDocument, "other", $$v)
                    },
                    expression: "deliveryOfDocument.other",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-2 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-2",
                      on: { click: _vm.generateDeliveryOfDocument },
                    },
                    [_vm._v("Gerar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4 mt-2",
                      attrs: { type: "border", color: "warning" },
                      on: {
                        click: function ($event) {
                          _vm.popupDeliveryOfDocuments = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            fullscreen: false,
            title: "Documentos Faltantes",
            active: _vm.popupPendingDocuments,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupPendingDocuments = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "FileTextIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Motivos(s)"),
              ]),
            ],
            1
          ),
          _c("hr", { staticClass: "mb-4" }),
          _vm._l(_vm.deliveryOfDocumentReasonList, function (reason, i) {
            return _c("div", { key: `${i}_reason`, staticClass: "vx-row" }, [
              reason.value1
                ? _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": reason.value1 },
                              model: {
                                value: _vm.deliveryOfDocument.motivo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.deliveryOfDocument,
                                    "motivo",
                                    $$v
                                  )
                                },
                                expression: "deliveryOfDocument.motivo",
                              },
                            },
                            [_vm._v(_vm._s(reason.text1))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              reason.value2
                ? _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": reason.value2 },
                              model: {
                                value: _vm.deliveryOfDocument.motivo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.deliveryOfDocument,
                                    "motivo",
                                    $$v
                                  )
                                },
                                expression: "deliveryOfDocument.motivo",
                              },
                            },
                            [_vm._v(_vm._s(reason.text2))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              reason.value3
                ? _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": reason.value3 },
                              model: {
                                value: _vm.deliveryOfDocument.motivo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.deliveryOfDocument,
                                    "motivo",
                                    $$v
                                  )
                                },
                                expression: "deliveryOfDocument.motivo",
                              },
                            },
                            [_vm._v(_vm._s(reason.text3))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ])
          }),
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "FileTextIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Documento(s)"),
              ]),
            ],
            1
          ),
          _c("hr", { staticClass: "mb-4" }),
          _vm._l(_vm.deliveryOfDocumentList, function (document, i) {
            return _c("div", { key: i, staticClass: "vx-row" }, [
              document.value1
                ? _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": document.value1 },
                              model: {
                                value: _vm.deliveryOfDocument.document,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.deliveryOfDocument,
                                    "document",
                                    $$v
                                  )
                                },
                                expression: "deliveryOfDocument.document",
                              },
                            },
                            [_vm._v(_vm._s(document.text1))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              document.value2
                ? _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": document.value2 },
                              model: {
                                value: _vm.deliveryOfDocument.document,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.deliveryOfDocument,
                                    "document",
                                    $$v
                                  )
                                },
                                expression: "deliveryOfDocument.document",
                              },
                            },
                            [_vm._v(_vm._s(document.text2))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              document.value3
                ? _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mt-1" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": document.value3 },
                              model: {
                                value: _vm.deliveryOfDocument.document,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.deliveryOfDocument,
                                    "document",
                                    $$v
                                  )
                                },
                                expression: "deliveryOfDocument.document",
                              },
                            },
                            [_vm._v(_vm._s(document.text3))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ])
          }),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "Outros (Especificar)" },
                  model: {
                    value: _vm.deliveryOfDocument.other,
                    callback: function ($$v) {
                      _vm.$set(_vm.deliveryOfDocument, "other", $$v)
                    },
                    expression: "deliveryOfDocument.other",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-2 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-2",
                      on: { click: _vm.generatePendingDocument },
                    },
                    [_vm._v("Gerar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4 mt-2",
                      attrs: { type: "border", color: "warning" },
                      on: {
                        click: function ($event) {
                          _vm.popuPendingDocuments = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            id: "popupResultsOfDETRANTests",
            fullscreen: false,
            title: "Resultado de Provas DETRAN",
            active: _vm.popupResultsOfDETRANTests,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupResultsOfDETRANTests = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "vs-con-loading__container",
              attrs: { id: "loadResultsOfDETRANTests" },
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col md:w-2/5 w-full mt-2" }, [
                  _vm._v("Aluno"),
                ]),
                _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-2" }, [
                  _vm._v("Data"),
                ]),
                _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-2" }, [
                  _vm._v("Nota"),
                ]),
                _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-2" }, [
                  _vm._v("Situação"),
                ]),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col md:w-2/5 w-full mt-2" }, [
                  _vm._v(_vm._s(_vm.data_local.name)),
                ]),
                _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-2" }, [
                  _vm._v(_vm._s(_vm.data_prova)),
                ]),
                _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-2" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.nota ? _vm.nota : "Sem nota") +
                      "\n        "
                  ),
                ]),
                _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-2" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.nota
                          ? _vm.nota >= 7
                            ? "APTO"
                            : "INAPTO"
                          : "AGUARDANDO TESTE"
                      ) +
                      "\n        "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-2 flex flex-wrap items-center justify-end",
                    },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "ml-auto mt-2",
                          on: { click: _vm.updateStudentNote },
                        },
                        [_vm._v("Atualizar Nota do Aluno")]
                      ),
                      _c(
                        "vs-button",
                        {
                          staticClass: "ml-4 mt-2",
                          attrs: { type: "border", color: "warning" },
                          on: {
                            click: function ($event) {
                              _vm.popupResultsOfDETRANTests = false
                            },
                          },
                        },
                        [_vm._v("Cancelar")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c("webcam-photo", {
        ref: "webcam",
        attrs: { person: _vm.data_local },
        on: { photoUrl: _vm.onValueChanged },
      }),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c("div", { staticClass: "flex items-start flex-col lg:flex-row" }, [
            _c(
              "div",
              {
                staticClass:
                  "cursor-pointer p-2 vs-con-loading__container mt-6",
                staticStyle: {
                  position: "absolute",
                  width: "200px",
                  height: "200px",
                },
                attrs: { id: "photo-loading" },
                on: {
                  mouseover: function ($event) {
                    _vm.mouseOver = true
                  },
                  mouseleave: function ($event) {
                    _vm.mouseOver = false
                  },
                  click: _vm.loadCamera,
                },
              },
              [
                _vm.mouseOver
                  ? _c("feather-icon", {
                      style:
                        _vm.data_local.photo && _vm.data_local.photo.url
                          ? "margin-left: 67px; margin-top: 61px"
                          : "margin-left: 55px; margin-top: 54px",
                      attrs: {
                        svgClasses: ["h-16 w-16 stroke-current", _vm.textColor],
                        icon: "CameraIcon",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c("img", {
              staticClass: "mt-6 mr-8 rounded h-56 w-56",
              attrs: {
                src:
                  _vm.data_local.photo && _vm.data_local.photo.url
                    ? _vm.data_local.photo.url
                    : _vm.noUserPhoto,
              },
              on: { load: _vm.closeLoading },
            }),
            _c("div", { staticClass: "w-full" }, [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full mt-0",
                      attrs: {
                        label: "Código",
                        "data-vv-as": "Código",
                        disabled: "",
                      },
                      model: {
                        value: _vm.data_local.code,
                        callback: function ($$v) {
                          _vm.$set(_vm.data_local, "code", $$v)
                        },
                        expression: "data_local.code",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "vx-col md:w-3/5" }, [
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/2" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|alpha_spaces",
                              expression: "'required|alpha_spaces'",
                            },
                          ],
                          staticClass: "w-full mt-0",
                          attrs: {
                            label: "Nome",
                            "data-vv-as": "Nome",
                            name: "name",
                          },
                          model: {
                            value: _vm.data_local.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.data_local, "name", $$v)
                            },
                            expression: "data_local.name",
                          },
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("name"),
                                expression: "errors.has('name')",
                              },
                            ],
                            staticClass: "text-danger text-sm",
                          },
                          [_vm._v(_vm._s(_vm.errors.first("name")))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/2" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "alpha_spaces",
                              expression: "'alpha_spaces'",
                            },
                          ],
                          staticClass: "w-full mt-0",
                          attrs: {
                            label: "Nome social",
                            "data-vv-as": "Nome social",
                            name: "socialName",
                          },
                          model: {
                            value: _vm.data_local.social_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.data_local, "social_name", $$v)
                            },
                            expression: "data_local.social_name",
                          },
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("socialName"),
                                expression: "errors.has('socialName')",
                              },
                            ],
                            staticClass: "text-danger text-sm",
                          },
                          [_vm._v(_vm._s(_vm.errors.first("socialName")))]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Tipo de Pessoa"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        reduce: (option) => option.value,
                        clearable: false,
                        options: _vm.typeOptions,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n                  Desculpe, nenhum resultado encontrado.\n                "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.data_local.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.data_local, "type", $$v)
                        },
                        expression: "data_local.type",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["###.###.###-##", "##.###.###/####-##"],
                          expression:
                            "['###.###.###-##', '##.###.###/####-##']",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "cpf",
                          expression: "'cpf'",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: { label: "CPF", "data-vv-as": "CPF", name: "cpf" },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "c",
                              undefined,
                              $event.key,
                              undefined
                            )
                          )
                            return null
                          if (!$event.ctrlKey) return null
                          return _vm.copyCpf.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.data_local.cpf,
                        callback: function ($$v) {
                          _vm.$set(_vm.data_local, "cpf", $$v)
                        },
                        expression: "data_local.cpf",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("cpf"),
                            expression: "errors.has('cpf')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("cpf")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "RG/IE" },
                      model: {
                        value: _vm.data_local.rg,
                        callback: function ($$v) {
                          _vm.$set(_vm.data_local, "rg", $$v)
                        },
                        expression: "data_local.rg",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-2" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "date_format:yyyy-MM-dd",
                          expression: "'date_format:yyyy-MM-dd'",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: {
                        type: "date",
                        label: "Data de Nascimento",
                        "data-vv-as": "Data de Nascimento",
                        name: "date_of_birth",
                      },
                      model: {
                        value: _vm.data_local.date_of_birth,
                        callback: function ($$v) {
                          _vm.$set(_vm.data_local, "date_of_birth", $$v)
                        },
                        expression: "data_local.date_of_birth",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("date_of_birth"),
                            expression: "errors.has('date_of_birth')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v("Formato incorreto")]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-2" }, [
                  _c("div", [
                    _c("label", { staticClass: "text-sm" }, [_vm._v("Sexo")]),
                    _c(
                      "div",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "vs-radio",
                          {
                            staticClass: "mr-4",
                            attrs: { "vs-value": "male" },
                            model: {
                              value: _vm.data_local.gender,
                              callback: function ($$v) {
                                _vm.$set(_vm.data_local, "gender", $$v)
                              },
                              expression: "data_local.gender",
                            },
                          },
                          [_vm._v("Masc.")]
                        ),
                        _c(
                          "vs-radio",
                          {
                            staticClass: "mr-4",
                            attrs: { "vs-value": "feminine" },
                            model: {
                              value: _vm.data_local.gender,
                              callback: function ($$v) {
                                _vm.$set(_vm.data_local, "gender", $$v)
                              },
                              expression: "data_local.gender",
                            },
                          },
                          [_vm._v("Fem.")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Situação"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        reduce: (option) => option.value,
                        clearable: false,
                        options: _vm.statusOptions,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n                  Desculpe, nenhum resultado encontrado.\n                "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.data_local.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.data_local, "status", $$v)
                        },
                        expression: "data_local.status",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "flex items-end mt-2" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-2",
                    attrs: { icon: "MapPinIcon", svgClasses: "w-5 h-5" },
                  }),
                  _c("span", { staticClass: "leading-none font-medium" }, [
                    _vm._v("Endereço"),
                  ]),
                ],
                1
              ),
              _c("hr"),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["#####-###"],
                          expression: "['#####-###']",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: { label: "CEP", placeholder: "CEP" },
                      on: { keyup: _vm.searchZipCode },
                      model: {
                        value: _vm.data_local.address.zip_code,
                        callback: function ($$v) {
                          _vm.$set(_vm.data_local.address, "zip_code", $$v)
                        },
                        expression: "data_local.address.zip_code",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Logradouro"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        reduce: (option) => option.value,
                        clearable: "",
                        options: _vm.logradouroOptions,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n                  Desculpe, nenhum resultado encontrado.\n                "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.data_local.address.logradouro,
                        callback: function ($$v) {
                          _vm.$set(_vm.data_local.address, "logradouro", $$v)
                        },
                        expression: "data_local.address.logradouro",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "Endereço" },
                      model: {
                        value: _vm.data_local.address.street,
                        callback: function ($$v) {
                          _vm.$set(_vm.data_local.address, "street", $$v)
                        },
                        expression: "data_local.address.street",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        label: "Número",
                        type: "number",
                        placeholder: "S/N",
                      },
                      model: {
                        value: _vm.data_local.address.number,
                        callback: function ($$v) {
                          _vm.$set(_vm.data_local.address, "number", $$v)
                        },
                        expression: "data_local.address.number",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full" },
          [
            _c("vs-input", {
              staticClass: "w-full mt-2",
              attrs: { label: "Complemento" },
              model: {
                value: _vm.data_local.address.complement,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local.address, "complement", $$v)
                },
                expression: "data_local.address.complement",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full" },
          [
            _c("vs-input", {
              staticClass: "w-full mt-2",
              attrs: { label: "Bairro" },
              model: {
                value: _vm.data_local.address.neighborhood,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local.address, "neighborhood", $$v)
                },
                expression: "data_local.address.neighborhood",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/4_ w-full mt-2" },
          [
            _c("label", { staticClass: "vs-input--label" }, [_vm._v("Cidade")]),
            _c("v-select", {
              attrs: {
                reduce: (option) => option.value,
                placeholder: _vm.cityOptions.length
                  ? "Selecione"
                  : "Selecione um estado",
                options: _vm.cityOptions,
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n          Desculpe, nenhum resultado encontrado.\n        "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.data_local.address.city_id,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local.address, "city_id", $$v)
                },
                expression: "data_local.address.city_id",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/6 w-full mt-2" },
          [
            _c("label", { staticClass: "vs-input--label" }, [_vm._v("Estado")]),
            _c("v-select", {
              attrs: {
                reduce: (option) => option.value,
                options: _vm.states,
                placeholder: "Selecione",
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              on: {
                input: function ($event) {
                  _vm.fillCity(_vm.data_local.address.state_id),
                    (_vm.data_local.address.city_id = null)
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n          Desculpe, nenhum resultado encontrado.\n        "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.data_local.address.state_id,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local.address, "state_id", $$v)
                },
                expression: "data_local.address.state_id",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/6 w-full mt-2" },
          [
            _c("label", { staticClass: "vs-input--label" }, [_vm._v("País")]),
            _c("v-select", {
              attrs: {
                reduce: (option) => option.value,
                clearable: false,
                options: _vm.countryOptions,
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n          Desculpe, nenhum resultado encontrado.\n        "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.data_local.address.country,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local.address, "country", $$v)
                },
                expression: "data_local.address.country",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "flex items-end mt-2" },
        [
          _c("feather-icon", {
            staticClass: "mr-2",
            attrs: { icon: "PhoneIcon", svgClasses: "w-5 h-5" },
          }),
          _c("span", { staticClass: "leading-none font-medium" }, [
            _vm._v("Contato"),
          ]),
        ],
        1
      ),
      _c("hr"),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col md:w-1/5 w-full" }, [
          _c(
            "div",
            { staticClass: "w-full mt-1" },
            [
              _c(
                "label",
                { staticClass: "vs-input--label", attrs: { for: "" } },
                [_vm._v("Celular")]
              ),
              _c(
                "vue-tel-input",
                _vm._b(
                  {
                    model: {
                      value: _vm.cellphone,
                      callback: function ($$v) {
                        _vm.cellphone = $$v
                      },
                      expression: "cellphone",
                    },
                  },
                  "vue-tel-input",
                  _vm.vuetelbind,
                  false
                )
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "mask",
                  rawName: "v-mask",
                  value: ["(##)#####-####", "(##)####-####"],
                  expression: "['(##)#####-####', '(##)####-####']",
                },
              ],
              staticClass: "w-full mt-1",
              attrs: { label: "Telefone" },
              model: {
                value: _vm.data_local.phones[1].phone,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local.phones[1], "phone", $$v)
                },
                expression: "data_local.phones[1].phone",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "mask",
                  rawName: "v-mask",
                  value: ["(##)#####-####", "(##)####-####"],
                  expression: "['(##)#####-####', '(##)####-####']",
                },
              ],
              staticClass: "w-full mt-1",
              attrs: { label: "Outro" },
              model: {
                value: _vm.data_local.phones[2].phone,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local.phones[2], "phone", $$v)
                },
                expression: "data_local.phones[2].phone",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-2/5 w-full" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "email",
                  expression: "'email'",
                },
              ],
              staticClass: "w-full mt-2",
              attrs: {
                label: "E-mail",
                "data-vv-as": "E-mail",
                type: "email",
                name: "email",
              },
              model: {
                value: _vm.data_local.email,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "email", $$v)
                },
                expression: "data_local.email",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("email"),
                    expression: "errors.has('email')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("email")))]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
          _c("div", { staticClass: "mt-2" }, [
            _c("label", { staticClass: "text-sm" }, [
              _vm._v("Aceita Comunicados"),
            ]),
            _c("div", { staticClass: "mt-2" }, [
              _c(
                "div",
                { staticClass: "flex flex-wrap mt-1" },
                [
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "mr-4 mb-2",
                      attrs: { "vs-value": "email" },
                      model: {
                        value: _vm.data_local.type_of_communication,
                        callback: function ($$v) {
                          _vm.$set(_vm.data_local, "type_of_communication", $$v)
                        },
                        expression: "data_local.type_of_communication",
                      },
                    },
                    [_vm._v("E-mail")]
                  ),
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "mr-4 mb-2",
                      attrs: { "vs-value": "sms" },
                      model: {
                        value: _vm.data_local.type_of_communication,
                        callback: function ($$v) {
                          _vm.$set(_vm.data_local, "type_of_communication", $$v)
                        },
                        expression: "data_local.type_of_communication",
                      },
                    },
                    [_vm._v("SMS")]
                  ),
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "mb-2",
                      attrs: { "vs-value": "whatsapp" },
                      model: {
                        value: _vm.data_local.type_of_communication,
                        callback: function ($$v) {
                          _vm.$set(_vm.data_local, "type_of_communication", $$v)
                        },
                        expression: "data_local.type_of_communication",
                      },
                    },
                    [_vm._v("Whatsapp")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/3_ w-full" },
          [
            _c("vs-input", {
              staticClass: "w-full mt-2",
              attrs: {
                label: "Facebook",
                "icon-pack": "feather",
                icon: "icon-facebook",
                "icon-no-border": "",
              },
              model: {
                value: _vm.data_local.facebook,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "facebook", $$v)
                },
                expression: "data_local.facebook",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/3_ w-full" },
          [
            _c("vs-input", {
              staticClass: "w-full mt-2",
              attrs: {
                label: "Instagram",
                "icon-pack": "feather",
                icon: "icon-instagram",
                "icon-no-border": "",
              },
              model: {
                value: _vm.data_local.instagram,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "instagram", $$v)
                },
                expression: "data_local.instagram",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/3_ w-full" },
          [
            _c("vs-input", {
              staticClass: "w-full mt-2",
              attrs: {
                label: "Outra Mídia Social",
                "icon-pack": "feather",
                icon: "icon-share-2",
                "icon-no-border": "",
              },
              model: {
                value: _vm.data_local.other_social_media,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "other_social_media", $$v)
                },
                expression: "data_local.other_social_media",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "flex items-end mt-2" },
        [
          _c("feather-icon", {
            staticClass: "mr-2",
            attrs: { icon: "InfoIcon", svgClasses: "w-5 h-5" },
          }),
          _c("span", { staticClass: "leading-none font-medium" }, [
            _vm._v("Outros"),
          ]),
        ],
        1
      ),
      _c("hr"),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full mt-2" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v("Último Serviço"),
            ]),
            _c("v-select", {
              attrs: {
                appendToBody: "",
                clearable: "",
                reduce: (option) => option.value,
                options: _vm.servicePackages,
                placeholder: "Selecione",
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              on: { input: _vm.changeLastService },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n          Desculpe, nenhum resultado encontrado.\n        "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.data_local.intended_services,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "intended_services", $$v)
                },
                expression: "data_local.intended_services",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full mt-2" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v("Cat. Atual"),
            ]),
            _c("v-select", {
              attrs: {
                appendToBody: "",
                options: _vm.categoryCNHOptions,
                reduce: (option) => option.value,
                placeholder: "Selecione",
                clearable: true,
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n          Desculpe, nenhum resultado encontrado.\n        "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.data_local.current_category_cnh,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "current_category_cnh", $$v)
                },
                expression: "data_local.current_category_cnh",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full mt-2" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v("Cat. Desejada"),
            ]),
            _c("v-select", {
              attrs: {
                appendToBody: "",
                options: _vm.categoryCNHOptions,
                reduce: (option) => option.value,
                placeholder: "Selecione",
                clearable: true,
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n          Desculpe, nenhum resultado encontrado.\n        "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.data_local.intended_category_cnh,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "intended_category_cnh", $$v)
                },
                expression: "data_local.intended_category_cnh",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-2" }, [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v("Histórico de Serviços"),
          ]),
          _c(
            "div",
            {
              staticClass: "w-full flex flex-wrap items-center justify-center",
            },
            [
              _c(
                "vs-dropdown",
                {
                  staticClass: "cursor-pointer",
                  attrs: { "vs-trigger-click": "" },
                },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "shadow-md w-full lg:mt-0 mt-4",
                      attrs: {
                        "icon-pack": "feather",
                        icon: "icon-chevrons-right",
                        "icon-after": "",
                      },
                    },
                    [_vm._v("Ver Histórico")]
                  ),
                  _c(
                    "vs-dropdown-menu",
                    { staticClass: "w-1/3" },
                    [
                      _vm._l(
                        _vm.serviceHistories,
                        function (serviceHistory, i) {
                          return _c("vs-dropdown-item", { key: i }, [
                            serviceHistory.service_package
                              ? _c(
                                  "div",
                                  { staticClass: "flex items-center" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatDateTime(
                                            serviceHistory.created,
                                            "dateTimeNoSecond"
                                          )
                                        ) +
                                          "\n                  - " +
                                          _vm._s(
                                            serviceHistory.service_package.name
                                          ) +
                                          "\n                  " +
                                          _vm._s(
                                            serviceHistory.service_package
                                              .category_cnh
                                          )
                                      ),
                                    ]),
                                    _c("feather-icon", {
                                      staticClass: "inline-block ml-4",
                                      attrs: {
                                        icon: "Trash2Icon",
                                        svgClasses: "w-5 h-5 hover:text-danger",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.confirmDeleteServiceHistory(
                                            serviceHistory
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        }
                      ),
                      !_vm.serviceHistories.length
                        ? _c("vs-dropdown-item", [
                            _c(
                              "div",
                              { staticClass: "flex items-center" },
                              [
                                _c("feather-icon", {
                                  staticClass: "inline-block mr-4",
                                  attrs: {
                                    icon: "AlertCircleIcon",
                                    svgClasses: "w-5 h-5",
                                  },
                                }),
                                _c("span", [
                                  _vm._v("NENHUM SERVIÇO NO HISTÓRICO"),
                                ]),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full mt-2" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "date_format:yyyy-MM-dd",
                  expression: "'date_format:yyyy-MM-dd'",
                },
              ],
              staticClass: "w-full",
              attrs: {
                type: "date",
                label: "Emissão de LADV",
                "data-vv-as": "Emissão de LADV",
                name: "ladv_emission",
              },
              model: {
                value: _vm.data_local.ladv_emission,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "ladv_emission", $$v)
                },
                expression: "data_local.ladv_emission",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("ladv_emission"),
                    expression: "errors.has('ladv_emission')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v("Formato incorreto")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full mt-2" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v("CNH Impressa|Digital"),
            ]),
            _c("v-select", {
              attrs: {
                appendToBody: "",
                clearable: true,
                reduce: (option) => option.value,
                options: _vm.typeCNHOptions,
                placeholder: "Selecione",
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n          Desculpe, nenhum resultado encontrado.\n        "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.data_local.cnh_printed_digital,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "cnh_printed_digital", $$v)
                },
                expression: "data_local.cnh_printed_digital",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full mt-2" },
          [
            _c("vs-input", {
              staticClass: "w-full",
              attrs: { label: "RENACH" },
              model: {
                value: _vm.data_local.renach_form_number,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "renach_form_number", $$v)
                },
                expression: "data_local.renach_form_number",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full mt-2" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "date_format:yyyy-MM-dd",
                  expression: "'date_format:yyyy-MM-dd'",
                },
              ],
              staticClass: "w-full",
              attrs: {
                type: "date",
                label: "Abertura RENACH",
                "data-vv-as": "Data Abertura RENACH",
                name: "opening_date_renach_form_number",
              },
              model: {
                value: _vm.data_local.opening_date_renach_form_number,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.data_local,
                    "opening_date_renach_form_number",
                    $$v
                  )
                },
                expression: "data_local.opening_date_renach_form_number",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("opening_date_renach_form_number"),
                    expression: "errors.has('opening_date_renach_form_number')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v("Formato incorreto")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-2/5 w-full" },
          [
            _c("vs-input", {
              staticClass: "w-full mt-2",
              attrs: { label: "Campo Extra" },
              model: {
                value: _vm.data_local.extra_field,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "extra_field", $$v)
                },
                expression: "data_local.extra_field",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-1/2 w-full mt-2" },
          [
            _c("label", { staticClass: "vs-input--label" }, [_vm._v("Grupos")]),
            _c("v-select", {
              attrs: {
                appendToBody: "",
                reduce: (status) => status.value,
                clearable: "",
                multiple: "",
                options: _vm.groups,
                placeholder: "Selecione",
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n          Desculpe, nenhum resultado encontrado.\n        "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.data_local.groups,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "groups", $$v)
                },
                expression: "data_local.groups",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "vx-col md:w-1/2 w-full mt-6 flex flex-wrap items-center justify-end",
          },
          [
            _c(
              "vs-dropdown",
              {
                staticClass: "ml-auto cursor-pointer",
                attrs: { "vs-trigger-click": "" },
              },
              [
                _c("vs-button", {
                  attrs: {
                    radius: "",
                    icon: "icon-eye",
                    "icon-pack": "feather",
                  },
                }),
                _c(
                  "vs-dropdown-menu",
                  { staticClass: "w-71" },
                  [
                    _c("vs-dropdown-item", [
                      _c(
                        "div",
                        {
                          staticClass: "flex items-center",
                          on: { click: _vm.consultDETRANProcess },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "inline-block mr-2",
                            attrs: {
                              icon: "SearchIcon",
                              svgClasses: "w-4 h-4",
                            },
                          }),
                          _c("span", [_vm._v("Consultar Processo DETRAN")]),
                        ],
                        1
                      ),
                    ]),
                    _c("vs-dropdown-item", [
                      _c(
                        "div",
                        {
                          staticClass: "flex items-center",
                          on: { click: _vm.issueOfDETRANGuides },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "inline-block mr-2",
                            attrs: {
                              icon: "SearchIcon",
                              svgClasses: "w-4 h-4",
                            },
                          }),
                          _c("span", [_vm._v("Emissão de Guias DETRAN")]),
                        ],
                        1
                      ),
                    ]),
                    _c("vs-dropdown-item", [
                      _c(
                        "div",
                        {
                          staticClass: "flex items-center",
                          on: { click: _vm.resultsOfDETRANTests },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "inline-block mr-2",
                            attrs: {
                              icon: "SearchIcon",
                              svgClasses: "w-4 h-4",
                            },
                          }),
                          _c("span", [_vm._v("Resultado de Prova DETRAN")]),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "vs-dropdown",
              {
                staticClass: "ml-auto cursor-pointer",
                attrs: { "vs-trigger-click": "" },
              },
              [
                _c("vs-button", {
                  attrs: {
                    radius: "",
                    icon: "icon-printer",
                    "icon-pack": "feather",
                  },
                }),
                _c(
                  "vs-dropdown-menu",
                  { staticClass: "w-71" },
                  [
                    _c("vs-dropdown-item", [
                      _c(
                        "div",
                        {
                          staticClass: "flex items-center",
                          on: {
                            click: function ($event) {
                              _vm.popupDeliveryOfDocuments = true
                              _vm.deliveryOfDocument = {
                                motivo: [],
                                document: [],
                                other: null,
                                student_id: null,
                              }
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "inline-block mr-2",
                            attrs: {
                              icon: "PrinterIcon",
                              svgClasses: "w-4 h-4",
                            },
                          }),
                          _c("span", [_vm._v("Entrega de Documentos")]),
                        ],
                        1
                      ),
                    ]),
                    _c("vs-dropdown-item", [
                      _c(
                        "div",
                        {
                          staticClass: "flex items-center",
                          on: {
                            click: function ($event) {
                              _vm.popupPendingDocuments = true
                              _vm.deliveryOfDocument = {
                                motivo: [],
                                document: [],
                                other: null,
                                student_id: null,
                              }
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "inline-block mr-2",
                            attrs: {
                              icon: "PrinterIcon",
                              svgClasses: "w-4 h-4",
                            },
                          }),
                          _c("span", [_vm._v("Documentos Faltantes")]),
                        ],
                        1
                      ),
                    ]),
                    _c("vs-dropdown-item", [
                      _c(
                        "div",
                        {
                          staticClass: "flex items-center",
                          on: { click: _vm.generatePrintRegistrationForm },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "inline-block mr-2",
                            attrs: {
                              icon: "PrinterIcon",
                              svgClasses: "w-4 h-4",
                            },
                          }),
                          _c("span", [_vm._v("Imprimir Ficha de Matrícula")]),
                        ],
                        1
                      ),
                    ]),
                    _c("vs-dropdown-item", [
                      _c(
                        "div",
                        {
                          staticClass: "flex items-center",
                          on: { click: _vm.generatePrintMedicalRecord },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "inline-block mr-2",
                            attrs: {
                              icon: "PrinterIcon",
                              svgClasses: "w-4 h-4",
                            },
                          }),
                          _c("span", [_vm._v("Imprimir Ficha Médica")]),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "vs-button",
              {
                staticClass: "ml-auto mt-2",
                attrs: { disabled: !_vm.validateForm || _vm.disableSave },
                on: { click: _vm.saveChanges },
              },
              [_vm._v("Salvar Alterações")]
            ),
            _c(
              "vs-button",
              {
                staticClass: "ml-4 mt-2",
                attrs: { type: "border", color: "warning" },
                on: { click: _vm.resetLocalData },
              },
              [_vm._v("Resetar")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "form",
        {
          attrs: {
            name: "formDETRANProcess",
            id: "DETRANProcess",
            method: "post",
            target: "_blank",
            action:
              "https://consultas.detrannet.sc.gov.br/habilitacao/ConsultaProcesso.asp",
          },
        },
        [
          _c("input", { attrs: { type: "hidden", name: "modo", value: "C" } }),
          _c("input", {
            staticClass: "var_NumeroRENACH",
            attrs: { type: "hidden", name: "NumeroRENACH" },
            domProps: { value: _vm.data_local.renach_form_number },
          }),
          _c("input", {
            staticClass: "var_txtDocPrincipal",
            attrs: { type: "hidden", name: "txtDocPrincipal" },
            domProps: { value: _vm.cleanStr(_vm.data_local.cpf) },
          }),
          _c("input", {
            attrs: { type: "hidden", name: "oculto", value: "AvancarC" },
          }),
        ]
      ),
      _c(
        "form",
        {
          attrs: {
            name: "formDETRANGuias",
            id: "DETRANGuides",
            method: "post",
            target: "_blank",
            action:
              "http://consultas.detrannet.sc.gov.br/habilitacao/ConsultaCpf.asp",
          },
        },
        [
          _c("input", {
            attrs: { type: "hidden", name: "oculto", value: "C" },
          }),
          _c("input", {
            attrs: { type: "hidden", name: "txtDocPrincipal" },
            domProps: { value: _vm.cleanStr(_vm.data_local.cpf) },
          }),
          _c("input", {
            attrs: { type: "hidden", name: "NumeroRenach", id: "NumeroRenach" },
          }),
          _c("input", {
            attrs: { type: "hidden", name: "txtDocCNH", id: "txtDocCNH" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }