import axios from '@/axios.js'

export default {
  store ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/contract`, payload)
        .then((response) => {
          commit('SET', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateContract ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/contract/${payload.id}`, payload)
        .then((resp) => {
          commit('UPDATE', payload)
          resolve(resp)
        })
        .catch((error) => { reject(error) })
    })
  },
  contractCancel ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/contractCancel/${payload.id}`, payload)
        .then((resp) => {
          commit('UPDATE', { id: payload.id, situation_contract: 'CANCELED' })
          resolve(resp)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchContracts ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/contracts`, payload)
        .then((response) => {
          commit('SET_CONTRACTS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
  // fetchContract ({ commit }, studentId) {
  //   return new Promise((resolve, reject) => {
  //     axios.get(`${process.env.VUE_APP_API_URL}/student/${studentId}`)
  //       .then((res) => {
  //         commit('SET_STUDENT', res.data)
  //         resolve(res)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
  // removeRecord ({ commit }, userId) {
  //   return new Promise((resolve, reject) => {
  //     axios.delete(`${process.env.VUE_APP_API_URL}/student/${userId}`)
  //       .then((response) => {
  //         commit('REMOVE_RECORD', userId)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // }
}
