import axios from '@/axios.js'

export default {
  async fetchAll ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_API_URL}/serviceHistories/${payload}`)
        .then((response) => {
          commit('REFRESH', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  async delete ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_API_URL}/serviceHistory/${payload.id}`)
        .then((response) => {
          commit('DELETE', payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
