<template>
  <div class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">
    <vs-sidebar class="items-no-padding" parent="#schedule-exam" :click-not-close="clickNotClose" :hidden-background="clickNotClose" v-model="isEmailSidebarActive">
      <schedule-sidebar />
    </vs-sidebar>

    <vs-sidebar click-not-close parent="#schedule-exam" :hidden-background="true" class="full-vs-sidebar email-view-sidebar items-no-padding" v-model="isSidebarActive" position-right>
      <div class="mail-sidebar-content px-0 sm:pb-6 h-full">
        <div class="flex flex-wrap items-center email-header justify-between md:px-8 px-6 sm:pb-2 sm: pt-6 d-theme-dark-bg">
          <div class="flex mb-4">
            <div class="flex items-center">
              <feather-icon :icon="$vs.rtl ? 'ArrowRightIcon' : 'ArrowLeftIcon'" @click="$emit('closeSidebar')" class="cursor-pointer hover:text-primary mr-4" svg-classes="w-6 h-6"></feather-icon>
              <h4>VOLTAR</h4>
            </div>
          </div>
          <div class="ml-10 mb-4 mt-1">
            <div class="email__actions--single flex items-baseline">
              <div class="flex border d-theme-dark-bg items-center justify-end">
                <vx-tooltip text="Abrir Barra de Calendário" position="top" class="ml-4">
                  <feather-icon class="md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer" icon="MenuIcon" @click.stop="toggleEmailSidebar(true)"/>
                </vx-tooltip>
              </div>
              <!-- <vx-tooltip text="Imprimir" position="top" class="ml-4">
                <feather-icon icon="PrinterIcon" svg-classes="h-6 w-6" class="cursor-pointer" @click="$emit('markUnread')"></feather-icon>
              </vx-tooltip> -->
            </div>
          </div>
        </div>

        <!-- MENU DE CONTEXTO -->
        <vue-context ref="menu">
          <li v-if="contextAdd">
            <a href="#" @click="contextMenuClicked('ADD_TIME')" class="flex items-center text-sm hover:text-primary">
              <feather-icon icon="PlusCircleIcon" svgClasses="w-5 h-5" />
              <span class="ml-2">Adicionar Horário</span>
            </a>
          </li>
          <li v-if="contextCopy">
            <a href="#" @click="contextMenuClicked('COPY')" class="flex items-center text-sm hover:text-primary">
              <feather-icon icon="CopyIcon" svgClasses="w-5 h-5" />
              <span class="ml-2">Copiar Grupo</span>
            </a>
          </li>
          <li v-if="contextPaste">
            <a href="#" @click="contextMenuClicked('PASTE')" class="flex items-center text-sm hover:text-primary">
              <feather-icon icon="FilePlusIcon" svgClasses="w-5 h-5" />
              <span class="ml-2">Colar Grupo</span>
            </a>
          </li>
          <li v-if="contextChange">
            <a href="#" @click="contextMenuClicked('CHANGE_TIME')" class="flex items-center text-sm hover:text-primary">
              <feather-icon icon="EditIcon" svgClasses="w-5 h-5" />
              <span class="ml-2">Alterar Horário</span>
            </a>
          </li>
          <li v-if="contextDelete">
            <a href="#" @click="contextMenuClicked('DELETE')" class="flex items-center text-sm hover:text-danger">
              <feather-icon icon="MinusCircleIcon" svgClasses="w-5 h-5" />
              <span class="ml-2">Excluir Agendamento</span>
            </a>
          </li>
        </vue-context>

        <!-- CORPO -->
        <div class="email__actions p-2 border border-r-0 border-l-0 border-solid d-theme-border-grey-light">
          <div class="vx-row">
            <div class="vx-col md:w-2/3 w-full mt-0">
              <label class="vs-input--label">Aluno(s)</label>
              <v-select v-model="students" @search="debouncedGetSearchStudent" multiple :clearable="true" :filterable="true" style="background-color: white;"
                :options="studentOptions"
                placeholder="Digite CPF ou nome do aluno..." :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-0">
              <label class="vs-input--label">Funcionário</label>
              <v-select v-model="employee_id" :reduce="option => option.value" :options="employees" placeholder="Selecione" style="background-color: white;" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mt-0">
              <label class="vs-input--label">Clínica</label>
              <v-select v-model="cac_id" :clearable="true" style="background-color: white;"
                :reduce="option => option.value" :options="cacs" @input="changeCac"
                placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-0">
              <label class="vs-input--label">Médico</label>
              <v-select v-model="doctor_id" :clearable="true" style="background-color: white;"
                :reduce="option => option.value" :options="doctorsFiltered"
                placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-0">
              <div class="mt-4 flex flex-wrap items-center">
              </div>
            </div>
          </div>
        </div>

        <!-- SCHEDULE -->
        <component :is="scrollbarTag" class="email-content-scroll-area" :settings="settings" ref="mailListPS" :key="$vs.rtl">
          <div class="vs-con-loading__container" id="loading">
            <vs-table v-for="(table, tIndex) in tables" :key="tIndex" :data="selectedDays" class="bordered" hoverFlat style="overflow: -webkit-paged-y">
              <template slot="thead">
                <vs-th style="font-size: 11px; min-width: 124px" v-for="(hr, i) in handleRange(table)" :key="i">
                    {{ weekDays[selectedDays[hr - 1].weekdayPosition] }} {{ selectedDays[hr - 1].id | dateTime('date') }}
                </vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr height="30" style="font-size: 12px; opacity: 1 !important; vertical-align: top;">
                  <vs-td v-for="(hr, itd) in handleRange(table)" :key="itd" @contextmenu.prevent.native.stop="contextMenu($event, hr - 1)">
                    <feather-icon v-for="(holiday, hd) in selectedDays[hr - 1].holidaySchedule" :key="`${hd}_holiday`" :title="holiday.holidayName" class="mr-1" icon="StarIcon" svgClasses="w-4 h-4" />
                    <span :key="hour" v-for="(sc, hour) in _.groupBy(selectedDays[hr-1].studentSchedule, 'start_time')" @contextmenu.prevent.stop="contextMenu($event, hr - 1, hour)">
                      <span class="mb-1 font-semibold flex p-1 bg-secondary text-white cursor-pointer" @click="addSchedule(selectedDays[hr - 1], hour)">{{ hour }}</span>
                      <span class="cursor-pointer hover:text-primary hover:semibold" :key="idx" v-for="(schedule, idx) in sc.sort((a, b) => { if (a.student && b.student) return a.student.name.localeCompare(b.student.name)})">
                        <div v-if="schedule.student">
                          <span class="flex" @contextmenu.prevent.stop="contextMenu($event, -1)">{{ (schedule.student && schedule.student.name) ? schedule.student.name : '' }}</span> <hr>
                        </div>
                      </span>
                      <vs-divider></vs-divider>
                    </span>
                  </vs-td>
                </vs-tr>

                <vs-tr style="opacity: 1 !important; border: 10px">
                  <vs-td :colspan="handleRange(table).length + 1" style="text-align:right">
                    <vs-divider></vs-divider>
                  </vs-td>
                </vs-tr>

              </template>
            </vs-table>
          </div>
          <div class="mt-6 flex flex-wrap items-center justify-center">
            <vs-alert class="w-5/6" style="height: -webkit-fill-available;" color="warning" title="Defina o período" :active="!tables">
                Selecione a(s) data(s) no calendário ou defina um intervalo de datas para ver
                os agendamentos conforme o filtro superior.
            </vs-alert>
          </div>
        </component>

      </div>
    </vs-sidebar>
  </div>
</template>

<script>
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
import _                    from 'lodash'
import ScheduleSidebar      from './ScheduleSidebar.vue'
import { VueContext }       from 'vue-context'

import VuePerfectScrollbar      from 'vue-perfect-scrollbar'
import moduleStudentManagement  from '@/store/student-management/moduleStudentManagement.js'

import moduleScheduleMedicalExam from '@/store/schedule-medical-exam/moduleScheduleMedicalExam.js'
import moduleEmployee            from '@/store/employee/moduleEmployee.js'
import moduleDoctor              from '@/store/doctor/moduleDoctor.js'
import moduleCac                 from '@/store/cac/moduleCac.js'
import moduleScheduleTime      from '@/store/schedule-time/moduleScheduleTime.js'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      clickNotClose        : true,
      isEmailSidebarActive : false,

      showThread: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.50
      },
      student: {},

      contextMenuIdx       : null,
      contextMenuHour      : null,
      contextAdd           : false,
      contextChange        : false,
      contextDelete        : false,
      contextCopy          : false,
      contextPaste         : false,

      selectedDay          : null,
      selectedHour         : null,

      schedules            : [],
      scheduleGroup        : [],

      weekDays: [0, 'SEG.', 'TER.', 'QUA.', 'QUI.', 'SEX.', 'SÁB.', 'DOM.'],

      students: [],
      studentOptions: [],
      employee_id: null,
      doctor_id: null,
      doctorsFiltered: [],
      cac_id: null,
      type: 'medical_exam',
      status: 'Pendente',     // STATUS DEFAULT

      type_service: null
    }
  },
  watch: {
    selectedDays (value) {
      if (value.length) {
        this.openLoading()
        this.debouncedGetFilterSchedule()
      }
    },
    isSidebarActive (value) {
      if (!value) {
        this.$emit('closeSidebar')
        setTimeout(() => {
          this.showThread = false
        }, 500)
      } else {
        this.filterSchedule() // INICIA FILTRANDO PELO ALUNO
      }
      this.isEmailSidebarActive = value
      this.setSidebarWidth()
    },
    windowWidth () {
      this.setSidebarWidth()
    }
  },
  computed: {
    _ () {
      return _
    },
    scheduleTime () {
      return this.$store.state.scheduleTime.scheduleTime
    },
    selectedDays () {
      return this.$store.state.scheduleMedicalExam ? this.$store.state.scheduleMedicalExam.selectedDays : null
    },
    time () {
      const time = new Date(this.$store.state.scheduleMedicalExam.time)
      return `${time.getHours() < 10 ? 0 : ''}${time.getHours()}:${time.getMinutes() < 10 ? 0 : ''}${time.getMinutes()}:00`
    },
    endTime () {
      const endTime = new Date(this.$store.state.scheduleMedicalExam.endTime)
      return `${endTime.getHours() < 10 ? 0 : ''}${endTime.getHours()}:${endTime.getMinutes() < 10 ? 0 : ''}${endTime.getMinutes()}:00`
    },
    tables () {
      return Math.ceil(this.selectedDays.length / 6) // CONTROLE DE COLUNAS POR TABELA DE DATAS (7 COLUNAS POR TABELA)
    },
    employees () {
      return this.$store.getters['employee/forSelect']
    },
    doctors () {
      return this.$store.getters['doctor/forSelect']
    },
    cacs () {
      return this.$store.getters['cac/forSelect']
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  methods: {
    setSidebarWidth () {
      if (this.windowWidth < 992 && this.isSidebarActive) {
        this.isEmailSidebarActive = this.clickNotClose = false
      } else if (this.isSidebarActive) {
        this.isEmailSidebarActive = this.clickNotClose = true
      }
    },
    toggleEmailSidebar (value = false) {
      if (!value) {
        this.$emit('closeSidebar')
        if (this.clickNotClose) {
          return
        }
      }
      this.isEmailSidebarActive = value
    },
    openLoading () {
      this.$vs.loading({
        container: '#loading',
        scale: 0.8
      })
    },
    closeLoading () {
      this.$vs.loading.close('#loading > .con-vs-loading')
    },
    contextMenu (event, idx, hour = null) {
      if (idx === -1) return
      this.contextAdd = true
      this.contextPaste = !!this.scheduleGroup.length // sem grupo copiado não mostro colar
      this.contextCopy = false
      this.contextChange = false
      this.contextDelete = false
      if (hour) {
        this.contextChange = true
        this.contextDelete = true
        this.contextCopy = true
      }
      this.contextMenuIdx = idx
      this.contextMenuHour = hour
      this.$refs.menu.open(event)
    },
    contextMenuClicked (action) {
      if (action === 'ADD_TIME') {
        this.addTime()
      } else if (action === 'CHANGE_TIME') {
        this.changeTime()
      } else if (action === 'DELETE') {
        this.confirmDelete()
      } else if (action === 'COPY') {
        this.copyGroup()
      } else if (action === 'PASTE') {
        this.pasteGroup()
      }
    },
    async addTime () {
      // SE JÁ EXISTIR O HORÁRIO, NAO CADASTRAR
      if (this.selectedDays[this.contextMenuIdx].studentSchedule.find(a => a.start_time === this.time)) return

      try {
        // SALVA NO BANCO
        const payload = {
          weekdays: this.scheduleTime.weekdays || [], // ARRAY 0 dom ... 6 sáb
          specific_days: [`${this.selectedDays[this.contextMenuIdx].id} ${this.time} ${this.endTime}`],
          schedule_type: this.type
        }
        await this.$store.dispatch('scheduleTime/store', payload)

        // ALTERA O STATE NO VUEX
        const payload2 = {
          contextMenuIdx: this.contextMenuIdx,  // UTILIZADO SOMENTE NO COMMIT
          start_date: this.selectedDays[this.contextMenuIdx].id,
          time: this.time,                      // UTILIZADO SOMENTE NO COMMIT
          endTime: this.endTime                 // UTILIZADO SOMENTE NO COMMIT
        }
        this.$store.commit('scheduleMedicalExam/ADD_SCHEDULE_TIME', payload2)
      } catch (error) { console.error(error) }

      // ZERA AS VARIÁVEIS SOMENTE POR SEGURANÇA
      this.contextMenuIdx = null
      this.contextMenuHour = null
    },
    async changeTime () {
      try {
        const payload = {
          start_time: this.time,
          end_time: this.endTime,
          duration: '00:50' // PRECISO COLOCAR ASSIM O TEMPO PQ NO BANCO É TIME
        }

        const scheduleGroup = JSON.parse(JSON.stringify(this.selectedDays[this.contextMenuIdx].studentSchedule.filter(a => a.start_time === this.contextMenuHour)))
        const oldscheduleGroup = JSON.parse(JSON.stringify(scheduleGroup[0]))
        this.$store.commit('scheduleMedicalExam/UPDATE_MASSIVE_SCHEDULES', { schedules: scheduleGroup, changes: payload })
        scheduleGroup.map(a => { // PRECISO ALTERAR 2 VEZES PQ O COMMIT DEMORA
          a.start_time = this.time
          a.end_time = this.endTime
          a.duration = '00:50'
        })
        if (scheduleGroup[0].id) {
          this.$vs.loading()
          await this.$store.dispatch('scheduleMedicalExam/updateMany', scheduleGroup.filter(s => s.id)) //somente com id
          this.$vs.loading.close()
        }
        await this.$store.dispatch('scheduleTime/update', {
          schedule_type: this.type,

          date: this.selectedDays[this.contextMenuIdx].id,
          start_time: scheduleGroup[0].start_time,
          end_time: scheduleGroup[0].end_time,

          oldDate: oldscheduleGroup.start_date,
          oldStart_time: oldscheduleGroup.start_time,
          oldEnd_time: oldscheduleGroup.end_time
        })

        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Alterados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) { console.error(error) }
    },
    copyGroup () {
      this.scheduleGroup = this.selectedDays[this.contextMenuIdx].studentSchedule.filter(a => a.start_time === this.contextMenuHour).filter(a => a.id)
      this.$vs.notify({
        time: 5000,
        title: 'SUCESSO',
        text: 'Dados copiados para a memória.',
        color: 'success',
        iconPack: 'feather',
        icon: 'icon-check'
      })
    },
    async pasteGroup () {
      try {
        const selectedDay = this.selectedDays[this.contextMenuIdx]
        const hour = this.scheduleGroup[0].start_time
        const students = this.scheduleGroup.reduce((a, b) => {
          a.push(b.student)
          return a
        }, [])

        // REMOVE O ALUNO DA MARCAÇÃO CASO JÁ ESTEJA NO GRUPO AGENDADO (VERIFICAR DIA E HORÁRIO)
        selectedDay.studentSchedule.map(schedule => {
          if (schedule.student) { // PODE SER QUE EXISTA O HORÁRIO MAS SEM STUDENT
            const idx = students.findIndex(student =>  student.id === schedule.student.id && schedule.start_time === hour)
            if (idx >= 0) students.splice(idx, 1)
          }
        })

        this.verifyWarnings(students.map(a => a.id), this.scheduleGroup[0])

        const payload = {
          students,
          employee_id: this.scheduleGroup[0].employee_id,
          start_date: selectedDay.id,
          start_time: hour, // NAO TENHO UM HORÁRIO DEGRUPO,PEGO O HORÁRIOAGRUPADO POIS TODAS AS AG DO GRUPO TEM OS MESMOS HORÁRIOS
          end_time: this.scheduleGroup[0].end_time // NAO TENHO UM HORÁRIO DE GRUPO,PEGO O HORÁRIO DA PRIMEIRA POIS TODAS AS AG DO GRUPO TEM OS MESMOS HORÁRIOS
        }
        if (students.length) {
          this.$vs.loading()
          await this.$store.dispatch('scheduleMedicalExam/store', payload)
          this.$vs.loading.close()
        }

        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.log(error)
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    confirmDelete () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: 'Tem certeza que deseja excluir este grupo?',
        accept: this.deleteSchedule,
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    async deleteSchedule () {
      try {
        this.$vs.loading()
        const scheduleGroup = this.selectedDays[this.contextMenuIdx].studentSchedule.filter(a => a.start_time === this.contextMenuHour)
        const scheduleGroupIds = scheduleGroup.map(a => a.id)
        if (scheduleGroupIds[0]) {
          // TEM AGENDAMENTOS
          await this.$store.dispatch('scheduleMedicalExam/delete', scheduleGroupIds)
        }
        await this.$store.dispatch('scheduleTime/delete', {
          selectedDays: [],
          schedule_type: this.type,
          date: this.selectedDays[this.contextMenuIdx].id,
          start_time: scheduleGroup[0].start_time,
          end_time: scheduleGroup[0].end_time
        })

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Excluídos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.error(error)
        this.$vs.loading.close()
      }
    },
    async addSchedule (selectedDay, hour) {
      if (!this.students.length) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione ao menos um aluno.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }

      // REMOVE O ALUNO DA MARCAÇÃO CASO JÁ ESTEJA NO GRUPO AGENDADO (VERIFICAR DIA E HORÁRIO)
      selectedDay.studentSchedule.map(a => {
        if (a.student) { // PODE SER QUE EXISTA O HORÁRIO MAS SEM STUDENT
          const idx = this.students.findIndex(o =>  o.value === a.student.id && a.start_time === hour)
          if (idx >= 0) this.students.splice(idx, 1)
        }
      })
      // pego o grupo exato do dia (podem haver mais de um grupo no mesmo dia)
      const scheduleGroup = selectedDay.studentSchedule.filter(a => a.start_time === hour)

      this.verifyWarnings(this.students.map(a => a.value), scheduleGroup[0])

      try {
        this.$vs.loading()
        const payload = {
          students: this.students.map(a => a.data),
          employee_id: this.employee_id,
          doctor_id: this.doctor_id,
          cac_id: this.cac_id,
          start_date: selectedDay.id,
          start_time: hour, // NAO TENHO UM HORÁRIO DEGRUPO,PEGO O HORÁRIOAGRUPADO POIS TODAS AS AG DO GRUPO TEM OS MESMOS HORÁRIOS
          end_time: scheduleGroup[0].end_time // NAO TENHO UM HORÁRIO DE GRUPO,PEGO O HORÁRIO DA PRIMEIRA POIS TODAS AS AG DO GRUPO TEM OS MESMOS HORÁRIOS
        }
        await this.$store.dispatch('scheduleMedicalExam/store', payload)
        this.students = []

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.log(error)
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    //students deve ser um array de ids
    async verifyWarnings (students, schedule) {
      schedule.type = this.type
      const response = await this.$http.post(`${process.env.VUE_APP_API_URL}/schedule/lastByStatus`, { schedule, students, status: 'Inapto', pastDays: 15})
      if (response.data.length) {
        const students = response.data.map(a => a.student.name)
        this.$vs.notify({
          time: 10000,
          title: 'AVISO',
          text: `O(s) aluno(s) ${students.join(', ')}, foram agendados como INAPTOS há menos de 15 dias.`,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    handleRange (row, colsPerRow = 6) {
      if (row === this.tables && this.selectedDays.length % colsPerRow > 0) {
        return _.range((row * colsPerRow) - (colsPerRow - 1), (row * colsPerRow) - (colsPerRow - 1) + (this.selectedDays.length % colsPerRow))
      }
      return _.range((row * colsPerRow) - (colsPerRow - 1), (row * colsPerRow) + 1)
    },
    changeCac () {
      this.doctor_id = null
      const myArrayFiltered = this.doctors.filter(doctor => {
        return doctor.data.cacs.some(e => e.id === this.cac_id)
      })
      if (myArrayFiltered.length) {
        this.doctorsFiltered = myArrayFiltered
        this.doctor_id = this.doctorsFiltered[0].value
      } else if (this.cac_id) {
        this.doctorsFiltered = []
      } else {
        this.doctorsFiltered = JSON.parse(JSON.stringify(this.doctors))
      }
    },

    async filterSchedule () {
      try {
        this.openLoading()
        const payload = {
          getByDate: true,
          getByPeriod: false,
          type: this.type,
          dates: this.selectedDays.map(day => day.id),
          student_id: this.student_id,
          employee_id: this.employee_id
        }
        await this.$store.dispatch('scheduleMedicalExam/fetchFilter', payload) // BUSCA OS AGENDAMENTOS NA API CONFORME FILTRO
        this.$store.commit('scheduleMedicalExam/FILL_SELECTED_DAYS')           // COLOCA OS DADOS DOS AGENDAMENTOS EM SELECEDDAYS PARA PREENCHER A TABELA DE AGENDAS
        this.closeLoading()
      } catch (error) { console.error(error) }
    },
    fetchStudentOptions (search, loading) {
      if (search.length) {
        loading(true)
        this.$http.post(`${process.env.VUE_APP_API_URL}/student/search`, { kw: search, type: 'STUDENT', with: ['schedules'] })
          .then(function (response) {
            for (const k in response.data) {
              //INCLUIR SOMENTE OS ALUNOS QUE NÃO EXISTEM
              const idx = this.studentOptions.findIndex((e) => e.value === response.data[k].id)
              if (idx === -1) {
                this.studentOptions.push({
                  value: response.data[k].id,
                  label:  `${response.data[k].name} ${response.data[k].cpf || ''}`,
                  data:   response.data[k]
                })
              }
            }
            loading(false)
          }.bind(this))
          .catch(function (error) {
            console.error(error)
          })

      } else {
        loading(false)
      }
    }
  },
  components: {
    VuePerfectScrollbar,
    ScheduleSidebar,
    vSelect,
    VueContext
  },

  created () {
    this.debouncedGetSearchStudent = _.debounce(this.fetchStudentOptions, 600)
    this.debouncedGetFilterSchedule = _.debounce(this.filterSchedule, 1000)

    // Register Module StudentManagement Module
    if (!moduleStudentManagement.isRegistered) {
      this.$store.registerModule('studentManagement', moduleStudentManagement)
      moduleStudentManagement.isRegistered = true
    }

    if (!moduleScheduleMedicalExam.isRegistered) {
      this.$store.registerModule('scheduleMedicalExam', moduleScheduleMedicalExam)
      moduleScheduleMedicalExam.isRegistered = true
    }

    if (!moduleEmployee.isRegistered) {
      this.$store.registerModule('employee', moduleEmployee)
      moduleEmployee.isRegistered = true
    }
    this.$store.dispatch('employee/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule('doctor', moduleDoctor)
      moduleDoctor.isRegistered = true
    }
    this.$store.dispatch('doctor/fetchAll').then(() => {
      this.doctorsFiltered = JSON.parse(JSON.stringify(this.doctors))
    }).catch(err => { console.error(err) })

    if (!moduleCac.isRegistered) {
      this.$store.registerModule('cac', moduleCac)
      moduleCac.isRegistered = true
    }
    this.$store.dispatch('cac/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleScheduleTime.isRegistered) {
      this.$store.registerModule('scheduleTime', moduleScheduleTime)
      moduleScheduleTime.isRegistered = true
    }
    this.$store.dispatch('scheduleTime/fetch', this.type).then(() => { }).catch(err => { console.error(err) })

    this.student = JSON.parse(JSON.stringify(this.$store.getters['studentManagement/getStudent']))

  },
  mounted () {
    this.setSidebarWidth()

    //PRÉ SELECIONA O ALUNO
    this.studentOptions = []
    this.studentOptions.push({
      value: this.student.id,
      label: `${this.student.name} ${this.student.cpf || ''}`,
      data:  this.student
    })
    this.students.push({
      value: this.student.id,
      label: `${this.student.name} ${this.student.cpf || ''}`,
      data:  this.student
    })
  }
}

</script>
<style lang="scss">
// COLOCA O BACKGROUND NA FRENTE DO SIDEBAR PARA QUE POSSA SER CLICADO
#schedule-exam .vs-sidebar--background {
    z-index: 40000 !important;
}
// FAZ COM QUE O ESPAÇO DO CALENDÁRIO (TABELA) APAREÇA POR COMPLETO NO FINAL DA BARRA DE ROLAGEM
#schedule-exam .email-content-scroll-area {
  height: calc(100% - 190px) !important;
}

#schedule-exam th .vs-table-text {
    cursor: default;
}
.svg-hover:hover {
  fill: #158482;
}
.svg-hover {
  fill: #626262;
}
#schedule-exam td {
  border: 1px solid #c0c0c0;
  line-height: 1;
}
#schedule-exam td:hover {
  border-bottom: 1px solid #353434;
}
#schedule-exam td:first-child:hover {
  border-bottom: 1px solid #c0c0c0;
}
#schedule-exam .vs-con-table td:last-child {
  border-right: 1px solid transparent;
}
#schedule-exam .vs-table--thead {
  border: 1px solid transparent;
}
.icon {
  font-size: 16px;
}
#schedule-exam .vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
    padding: 6px;
}
#schedule-exam .ps__scrollbar-y-rail {
  z-index: 99999;
}
</style>
