<template>
  <div>
    <!-- essa popup para para a edicao de um registro -->
     <vs-popup class="popup400" title="Formulário de taxa" :active.sync="popupForm">
        <div style="min-height: 150px">
          <form-debit-student v-model="debit" :student-id="$route.params.studentId" @saved="afterEditRegister"/>
        </div>
      </vs-popup>

     <vs-table
    :data="services"
    hoverFlat
    noDataText="Nenhum dado encontrado"
    stripe
    style="overflow: -webkit-paged-y"
  >
   <template slot="header">
        <h3>
          Taxas
        </h3>
    </template>

    <template slot="thead">
      <vs-th>Ações</vs-th>
      <vs-th>Descrição</vs-th>
      <vs-th>Valor</vs-th>
      <vs-th>Situação</vs-th>
      <vs-th>Observação</vs-th>
    </template>
    <template slot-scope="{ data }">
      <vs-tr
        :key="i"
        v-for="(service, i) in data"
        style="opacity: 1 !important"
      >
        <vs-td>
          <vs-tooltip text="Tooltip Default">
            <vs-button
              type="flat"
              icon="edit"
              @click="editRecord(service)"
            />
          </vs-tooltip>
        </vs-td>
        <vs-td>
          {{ service.name }}
        </vs-td>
        <vs-td>
          {{ mixinCurrency(service.value) }}
        </vs-td>
        <vs-td>
          {{ service.situation_formated }}
        </vs-td>
        <vs-td>
          {{ service.observation }}
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
  </div>
</template>

<script>
import model from '@/store/register/debitStudent/model'
import FormDebitStudent from './FormDebitStudent.vue'

export default {
  components: {
    FormDebitStudent
  },
  props: {
    studentId: {}
  },
  data () {
    return {
      popupForm: false,
      debit: {}
    }
  },
  computed: {
    services () {
      return this.$store.state[model.$store.name].fetchGrid.data
    }
  },
  methods: {
    fetchDebitStudent () {
      this.$vs.loading()
      this.$store.dispatch(model.$store.dispatch.fetchStudent, {
        student_id: this.studentId
      })
        .catch(this.mixinCatch)
        .finally(this.$vs.loading.close)
    },
    editRecord (service) {
      this.debit = service
      this.popupForm = true
    },
    afterEditRegister () {
      this.popupForm = false
      this.fetchDebitStudent()
    }
  },
  mounted () {
    this.fetchDebitStudent()
  }
}
</script>

