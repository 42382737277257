var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.params.data
    ? _c(
        "div",
        { staticClass: "flex items-center" },
        [
          _c(
            "vx-tooltip",
            {
              staticClass: "flex items-center",
              attrs: { text: "Alterar Situação", position: "top" },
            },
            [
              _c("feather-icon", {
                attrs: {
                  icon: "MenuIcon",
                  svgClasses: "h-5 w-5 mr-1 cursor-pointer hover:text-primary",
                },
              }),
              _c("span", { staticClass: "cursor-pointer hover:text-primary" }, [
                _vm._v(_vm._s(_vm.situation)),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }