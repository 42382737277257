<template>
  <div class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">
    <vs-sidebar class="items-no-padding" parent="#email-app" :click-not-close="clickNotClose" :hidden-background="clickNotClose" v-model="isEmailSidebarActive">
      <email-sidebar
        @filterSchedule="filterSchedule" />
    </vs-sidebar>

    <vs-sidebar click-not-close parent="#email-app" :hidden-background="true" class="full-vs-sidebar email-view-sidebar items-no-padding" v-model="isSidebarActive" position-right>
      <div class="mail-sidebar-content px-0 sm:pb-6 h-full">
        <div class="flex flex-wrap items-center email-header justify-between md:px-8 px-6 sm:pb-2 sm: pt-6 d-theme-dark-bg">
          <div class="flex mb-4">
            <div class="flex items-center">
              <feather-icon :icon="$vs.rtl ? 'ArrowRightIcon' : 'ArrowLeftIcon'" @click="$emit('closeSidebar')" class="cursor-pointer hover:text-primary mr-4" svg-classes="w-6 h-6"></feather-icon>
              <h4>VOLTAR</h4>
            </div>
          </div>
          <div class="ml-10 mb-4 mt-1">
            <div class="email__actions--single flex items-baseline">
              <div class="flex border d-theme-dark-bg items-center justify-end">
                <vx-tooltip text="Abrir Barra de Calendário" position="top" class="ml-4">
                  <feather-icon class="md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer" icon="MenuIcon" @click.stop="toggleEmailSidebar(true)"/>
                </vx-tooltip>
              </div>
              <!-- <vx-tooltip text="Imprimir" position="top" class="ml-4">
                <feather-icon icon="PrinterIcon" svg-classes="h-6 w-6" class="cursor-pointer" @click="$emit('markUnread')"></feather-icon>
              </vx-tooltip> -->
            </div>
          </div>
        </div>


        <!-- MENU DE CONTEXTO -->
        <vue-context ref="menu" :closeOnClick="closeOnClick">
          <li v-if="contextAdd">
            <a href="#" @click="contextMenuClicked('ADD')" class="flex items-center text-sm hover:text-primary">
              <feather-icon icon="PlusCircleIcon" svgClasses="w-5 h-5" />
              <span class="ml-2">Novo Agendamento</span>
            </a>
          </li>
          <li v-if="contextAbsence">
            <a href="#" @click="contextMenuClicked('ABSENCE')" class="flex items-center text-sm hover:text-warning">
              <feather-icon icon="SlashIcon" svgClasses="w-5 h-5" />
              <span class="ml-2">Falta sem Justificativa</span>
            </a>
          </li>
          <li v-if="contextDelete">
            <a href="#" @click="contextMenuClicked('DELETE')" class="flex items-center text-sm hover:text-danger">
              <feather-icon icon="MinusCircleIcon" svgClasses="w-5 h-5" />
              <span class="ml-2">Excluir</span>
            </a>
          </li>
          <li v-if="contextBlockEmployee">
            <vs-textarea v-model="blockEmployeeMotivation" placeholder="Motivo do Bloqueio" class="mb-4" counter="120" maxlength="120" rows="5" />

            <a href="#" @click="contextMenuClicked('BLOCK_EMPLOYEE')" class="flex items-center text-sm hover:text-danger">
              <feather-icon icon="SlashIcon" svgClasses="w-5 h-5" />
              <span class="ml-2">Bloquear Instrutor Selecionado</span>
            </a>
          </li>
        </vue-context>

        <!-- CORPO -->
          <div class="email__actions p-2 border border-r-0 border-l-0 border-solid d-theme-border-grey-light">
            <div class="vx-row">
              <div class="vx-col md:w-1/3 w-full mt-0">
                <label class="vs-input--label">Instrutor</label>
                <v-select v-model="employee_id" @input="changeEmployee()" :reduce="option => option.value" :options="employees" placeholder="Selecione" style="background-color: white;" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                  <template #no-options="{}">
                    Desculpe, nenhum resultado encontrado.
                  </template>
                </v-select>
              </div>
              <div class="vx-col md:w-1/3 w-full mt-0">
                <label class="vs-input--label">Veículo</label>
                <v-select v-model="vehicle" @input="changeVehicle()" :reduce="option => option.data" :options="vehicles" placeholder="Selecione" style="background-color: white;" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                  <template #no-options="{}">
                    Desculpe, nenhum resultado encontrado.
                  </template>
                </v-select>
              </div>
              <div class="vx-col md:w-1/6 w-full mt-0">
                <label class="vs-input--label">Cat.</label>
                <v-select v-model="category" @input="changeCategory()" :clearable="false" style="background-color: white;"
                  :reduce="option => option.value" :options="categoryCNHOptions"
                  placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                  <template #no-options="{}">
                    Nenhum resultado encontrado.
                  </template>
                </v-select>
              </div>
              <div class="vx-col md:w-1/6 w-full mt-0">
                  <vx-tooltip v-if="disabledIbio" text="Configure o acesso ao IBio" position="top" class="mt-8">
                    <vs-checkbox class="mt-8" :disabled="disabledIbio" v-model="ibio">IBio</vs-checkbox>
                  </vx-tooltip>
                  <vs-checkbox v-else class="mt-8" :disabled="disabledIbio" v-model="ibio">IBio</vs-checkbox>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col md:w-1/3 w-full mt-0">
                <label class="vs-input--label">Tipo de Serviço</label>
                <v-select v-model="type_service" :clearable="true" style="background-color: white;"
                  :reduce="option => option.value" :options="typeServiceOptions"
                  placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                  <template #no-options="{}">
                    Nenhum resultado encontrado.
                  </template>
                </v-select>
              </div>
              <div class="vx-col md:w-1/2 w-full mt-0">
                <div class="mt-4 flex flex-wrap items-center">

                  <span class="ml-6 font-semibold" style="font-size: 10px">
                    Contratado: {{ contractedSchedules }} <br>
                    Marcado: {{ scheduledClasses }} <br>
                    <!-- // REMOVIDO PELA NOVA LEGISLAÇÃO
                    Noturno: {{ nightSchedules }} / {{ nightSchedulesRequired }} -->
                    Noturno: {{ nightSchedules }}
                  </span>

                  <span class="ml-6 font-semibold" style="font-size: 10px">
                    Disponível: <span :class="{ 'text-danger': availableSchedules < 0 }">{{ availableSchedules }}</span> <br>
                    Faltas: {{ absences }}<br> &nbsp;
                  </span>

                </div>
              </div>
            </div>
          </div>


          <!-- SCHEDULE -->
          <component :is="scrollbarTag" class="email-content-scroll-area" :settings="settings" ref="mailListPS" :key="$vs.rtl">
            <div class="vs-con-loading__container" id="loading">

              <vs-table v-for="(table, tIndex) in tables" :key="tIndex" :data="selectedDays" class="bordered" hoverFlat style="overflow: -webkit-paged-y;">
                <template slot="thead">
                  <vs-th style="font-size: 11px; width: 85px"><span>HORÁRIO</span></vs-th>
                  <vs-th style="font-size: 11px; min-width: 124px" v-for="(hr, i) in handleRange(table)" :key="i">
                      {{weekDays[selectedDays[hr - 1].weekdayPosition]}} {{ selectedDays[hr - 1].id | dateTime }}
                  </vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr v-for="(hour, i) in hours" :key="i" style="font-size: 12px; opacity: 1 !important">
                    <vs-td style="text-align:right"><span class="cursor-default font-semibold">{{hour.slice(0, 5)}}</span></vs-td>
                    <vs-td v-for="(hr, i) in handleRange(table)" :key="i" class="cursor-pointer" :style="fillSchedule(selectedDays[hr - 1], hour).length === 1 && fillSchedule(selectedDays[hr - 1], hour)[0].bg || ''"
                      @click.native="addSchedule(selectedDays[hr - 1], hour)"
                      @contextmenu.native.prevent.self="contextMenu($event, null, selectedDays[hr - 1], hour)">
                      <feather-icon v-for="(holiday, hd) in selectedDays[hr - 1].holidaySchedule" :key="`${hd}_holiday`" :title="holiday.holidayName" class="mr-1" icon="StarIcon" svgClasses="w-4 h-4" />

                        <div v-for="(schedule, idx) in fillSchedule(selectedDays[hr - 1], hour)" :key="`${idx}_schedule`" class="text-black flex items-center hover:text-primary"
                          @contextmenu.prevent="contextMenu($event, schedule, selectedDays[hr - 1], hour)"
                          :style="schedule.fill ? '' : schedule.bg">

                          <feather-icon v-if="schedule.status === 'ABSENCE' || schedule.status === 'Bloqueado'" :class="{'mr-1': schedule.status === 'Bloqueado'}" icon="SlashIcon" svgClasses="w-4 h-4" />
                          <i v-if="schedule.vehicle && schedule.vehicle.type === 'CAR'" class="mr-1 material-icons-outlined flex items-center icon" title="Carro">directions_car</i>
                          <i v-else-if="schedule.vehicle && schedule.vehicle.type === 'MOTORCYCLE'" class="mr-1 material-icons-outlined flex items-center icon" title="Moto">sports_motorsports</i>
                          <i v-else-if="schedule.vehicle && schedule.vehicle.type === 'TRUCK'" class="mr-1 material-icons-outlined flex items-center icon" title="Caminhão">directions_bus_filled</i>
                          <i v-else-if="schedule.status !== 'Bloqueado'" class="mr-1 material-icons-outlined flex items-center icon" title="Ônibus">departure_board</i>
                          {{ schedule.student && schedule.student.name.split(' ').slice(0, 1).join(' ') + ' | ' }} {{ schedule.employee && schedule.employee.name.split(' ').slice(0, 1).join(' ') }}
                        </div>

                    </vs-td>
                  </vs-tr>

                  <vs-tr style="opacity: 1 !important; border: 10px">
                    <vs-td :colspan="handleRange(table).length + 1" style="text-align:right">
                      <vs-divider></vs-divider>
                    </vs-td>
                  </vs-tr>

                </template>
              </vs-table>
            </div>
            <div v-if="!tables" class="mt-6 flex flex-wrap items-center justify-center">
              <vs-alert class="w-5/6" style="height: -webkit-fill-available;" color="warning" title="Defina o período" :active="!tables">
                Selecione a(s) data(s) no calendário ou defina um intervalo de datas para ver
                os agendamentos conforme o filtro superior.
              </vs-alert>
            </div>
          </component>


      </div>
    </vs-sidebar>
  </div>
</template>

<script>
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
import _                    from 'lodash'
import moment               from 'moment'
import EmailSidebar         from './EmailSidebar.vue'
import { VueContext }       from 'vue-context'

import VuePerfectScrollbar      from 'vue-perfect-scrollbar'
import moduleStudentManagement  from '@/store/student-management/moduleStudentManagement.js'
import moduleEmployee           from '@/store/employee/moduleEmployee.js'
import moduleVehicle            from '@/store/vehicle/moduleVehicle.js'
import moduleServicePackage     from '@/store/service-package/moduleServicePackage.js'
// import ScheduleCard             from './PracticalExamCard.vue'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      closeOnClick: true,
      blockEmployeeMotivation: '',

      clickNotClose        : true,
      isEmailSidebarActive : false,

      showThread: false,
      selectedSchedules: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.50
      },

      student: {},

      contextAdd           : false,
      contextAbsence       : false,
      contextDelete        : false,
      contextBlockEmployee : false,
      selectedDay          : null,
      selectedHour         : null,

      weekDays: [0, 'SEG.', 'TER.', 'QUA.', 'QUI.', 'SEX.', 'SÁB.', 'DOM.'],
      category: null,
      categoryCNHOptions: [
        { label: 'A',   value: 'A'   },
        { label: 'B',   value: 'B'   },
        { label: 'C',   value: 'C'   },
        { label: 'D',   value: 'D'   },
        { label: 'E',   value: 'E'   },
        { label: 'ACC', value: 'ACC' }
      ],
      employee_id: null,
      vehicle: null,
      ibio: false,
      disabledIbio: false,
      type: 'practical',
      status: 'Não Concluído',
      type_service: null,
      typeServiceOptions: [
        { label: '1ª Habilitação',        value: '1ª Habilitação'      },
        { label: 'Adição de categoria',   value: 'Adição de categoria' },
        { label: 'Mudança de categoria',  value: 'Mudança de categoria'},
        { label: 'Permissão ACC',         value: 'Permissão ACC'       },
        { label: 'Aula avulsa',           value: 'Aula avulsa'         }
      ]

    }
  },
  watch: {
    selectedDays (value) {
      // if (value.length) {
      //   this.openLoading()
      //   this.debouncedGetFilterSchedule()
      // }
      // AO TROCAR O PERÍODO DE DATAS DO CALENDÁRIO ZERO O PERÍODO MANUAL DO RELATÓRIO
      this.reportFirstPeriod = ''
      this.reportLastPeriod = ''
    },
    isSidebarActive (value) {
      if (!value) {
        this.$emit('closeSidebar')
        setTimeout(() => {
          this.showThread = false
        }, 500)
      } else {
        this.filterSchedule() // INICIA FILTRANDO PELO ALUNO
      }
      this.isEmailSidebarActive = value
      this.setSidebarWidth()
    },
    windowWidth () {
      this.setSidebarWidth()
    }
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    schedule () {
      return this.$store.state.schedule.schedule
    },
    selectAllCheckBox: {
      get () {
        return this.selectedSchedules.length
      },
      set (value) {
        if (value) {
          this.selectedSchedules = this.schedules
        } else {
          this.selectedSchedules = []
        }
      }
    },
    selectAllIcon () {
      return this.selectedSchedules.length === this.schedules.length ? 'icon-check' : 'icon-minus'
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    windowWidth () {
      return this.$store.state.windowWidth
    },

    contractedSchedules () {
      if (this.student) {
        // 1ª habilitação Cat A e B 20hs sendo 1 noturna
        if (this.type_service === '1ª Habilitação') return 20
        //Adiçao cat A e B 15h aula sendo 1 noturna
        else if (this.type_service === 'Adição de categoria') return 15
        // Mudança de categoria de C,D,E  20h no mínimo 4 not
        else if (this.type_service === 'Mudança de categoria') return 20
        // ACC 5h ao menos 4 noturnas
        else if (this.type_service === 'Permissão ACC') return 5
      }
      return 0
    },
    availableSchedules () {
      return (this.contractedSchedules - this.scheduledClasses)
    },
    scheduledClasses () {
      return this.student.schedules.filter(o => o.type === this.type).length
    },
    nightSchedules () {
      const nightTime = moment('18:00:00', 'hh:mm:ss')
      return this.student.schedules.filter(o => moment(o.end_time, 'hh:mm:ss').isAfter(nightTime) && o.type === this.type).length
    },
    // REMOVIDO PELA NOVA LEGISLAÇÃO
    // nightSchedulesRequired () {
    //   if (this.student) {
    //     // 1ª habilitação Cat A e B 20hs sendo 1 noturna
    //     if (this.type_service === '1ª Habilitação') return 1
    //     //Adiçao cat A e B 15h aula sendo 1 noturna
    //     else if (this.type_service === 'Adição de categoria') return 1
    //     // Mudança de categoria de C,D,E  20h no mínimo 4 noturnas
    //     else if (this.type_service === 'Mudança de categoria') return 4
    //     // ACC 5h ao menos 4 noturnas
    //     else if (this.type_service === 'Permissão ACC') return 4
    //   }
    //   return 0
    // },
    absences () {
      if (this.student) return this.student.schedules.filter(o => o.type === this.type && o.status === 'ABSENCE').length
      return 0
    },
    servicePackages () {
      return this.$store.getters['servicePackage/forSelect']
    },
    selectedDays () {
      return this.$store.state.schedule ? this.$store.state.schedule.selectedDays : []
    },
    periods () {
      return this.$store.state.schedule.periods // 1 MATUTINO 2 VESPERTINO 3 NOTURNO
    },
    hours () {
      const periods = this.periods
      const h = this.$store.getters.scheduleClassTimes(null)
      const res = h.filter(function (eachElem, index) {
        return periods.indexOf(index) !== -1
      }).flat()
      return res
    },
    tables () {
      return Math.ceil(this.selectedDays.length / 6) // CONTROLE DE COLUNAS POR TABELA DE DATAS (7 COLUNAS POR TABELA)
    },
    employees () {
      return this.$store.getters['employee/forSelect']
    },
    vehicles () {
      return this.$store.getters['vehicle/forSelect']
    }
  },
  methods: {
    setSidebarWidth () {
      if (this.windowWidth < 992 && this.isSidebarActive) {
        this.isEmailSidebarActive = this.clickNotClose = false
      } else if (this.isSidebarActive) {
        this.isEmailSidebarActive = this.clickNotClose = true
      }
    },
    toggleEmailSidebar (value = false) {
      if (!value) {
        this.$emit('closeSidebar')
        if (this.clickNotClose) {
          return
        }
      }
      this.isEmailSidebarActive = value
    },
    openLoading () {
      this.$vs.loading({
        container: '#loading',
        scale: 0.8
      })
    },
    closeLoading () {
      this.$vs.loading.close('#loading > .con-vs-loading')
    },
    contextMenu (event, schedule, selectedDay, hour) {
      this.closeOnClick = true
      this.contextAdd = false
      this.contextAbsence = false
      this.contextDelete = false
      this.contextBlockEmployee = false
      this.selectedDay = selectedDay
      this.selectedHour = hour

      if (schedule) {
        this.contextDelete = true
        // VERIFICA SE TODOS OS DADOS FORAM PREENCHIDOS E SE AS REGRAS SE CUMPREM PARA DUPLO AGENDAMENTO
        if ((this.student.id && this.employee_id && this.vehicle && this.category) && (schedule.student_id !== this.student.id && this.vehicle.id !== schedule.vehicle_id && this.vehicle.type === 'MOTORCYCLE' && schedule.vehicle.type === 'MOTORCYCLE')) {
          const sch = this.fillSchedule(selectedDay, hour)
          if (sch.length < 2) {
            // this.selectedDay = selectedDay
            // this.selectedHour = hour
            this.contextAdd = true
          }
        }
        // MOSTRA A OPÇÃO DE ALTERAR O STATUS PARA AUSÊNCIA
        if (schedule.status !== 'ABSENCE' && schedule.status !== 'Bloqueado') this.contextAbsence = true

        this.$store.commit('schedule/SET_SCHEDULE', schedule)
        this.$refs.menu.open(event)
      } else if (!this.fillSchedule(selectedDay, hour).length) {
        // CONTEXTO EM DATA SEM AGENDAMENTO - BLOQUEIO DE INSTRUTOR
        this.closeOnClick = false
        this.contextBlockEmployee = true
        this.blockEmployeeMotivation = ''
        this.$refs.menu.open(event)
      }
    },
    contextMenuClicked (action) {
      if (action === 'DELETE') {
        this.confirmDelete()
      } else if (action === 'ADD') {
        this.addSchedule(this.selectedDay, this.selectedHour, true)
      } else if (action === 'ABSENCE') { // AUSÊNCIA SEM JUSTIFICATIVA PARA AULA PRÁTICA
        this.updateStatus('ABSENCE')
      } else if (action === 'BLOCK_EMPLOYEE') {
        this.blockEmployee()
      }
    },
    changeEmployee () {
      this.filterSchedule()
    },
    changeVehicle () {
      this.category = this.vehicle ? this.vehicle.category : null
      this.filterSchedule()
    },
    changeCategory () {
      this.filterSchedule()
    },
    async filterSchedule () {
      // CASO NÃO ENVIE FILTRO ESVAZIA A AGENDA
      if (!this.student.id && !this.employee_id && !this.vehicle) {
        this.$store.commit('schedule/SET_STUDENT_SCHEDULES', [])
        this.$store.commit('schedule/SET_EMPLOYEE_SCHEDULES', [])
        this.$store.commit('schedule/SET_VEHICLE_SCHEDULES', [])
        this.$store.commit('schedule/SET_HOLIDAY_SCHEDULES', [])
        this.$store.commit('schedule/FILL_SELECTED_DAYS')
        this.closeLoading()
        return
      }
      try {
        this.openLoading()
        const payload = {
          getByDate: true,
          getByPeriod: false,
          type: this.type,
          dates: this.selectedDays.map(day => day.id),
          category: this.category,
          student_id: this.student.id,
          employee_id: this.employee_id,
          vehicle_id: this.vehicle ? this.vehicle.id : null
        }
        await this.$store.dispatch('schedule/fetchFilter', payload) // BUSCA OS AGENDAMENTOS NA API CONFORME FILTRO
        this.$store.commit('schedule/FILL_SELECTED_DAYS')           // COLOCA OS DADOS DOS AGENDAMENTOS EM SELECEDDAYS PARA PREENCHER A TABELA DE AGENDAS
        this.closeLoading()
      } catch (error) { console.error(error) }
    },
    handleRange (row, hoursPerRow = 6) {
      if (row === this.tables && this.selectedDays.length % hoursPerRow > 0) {
        return _.range((row * hoursPerRow) - (hoursPerRow - 1), (row * hoursPerRow) - (hoursPerRow - 1) + (this.selectedDays.length % hoursPerRow))
      }
      return _.range((row * hoursPerRow) - (hoursPerRow - 1), (row * hoursPerRow) + 1)
    },
    fillSchedule (schedule, hour) {
      if (schedule.studentSchedule.length || schedule.employeeSchedule.length || schedule.vehicleSchedule.length) {
        const format = 'h:mm:ss'
        const beforeTime = moment(`${hour.slice(0, 5)}:00`, format)
        const afterTime = moment(`${hour.slice(6, 11)}:00`, format)

        const studentSchedule = schedule.studentSchedule.filter(e => {
          if (moment(e.start_time, format).isBetween(beforeTime, afterTime,  undefined, '(]') && moment(e.start_time, format).isBetween(afterTime, beforeTime,  undefined, '(]')) {
            return true
          } else if (moment(e.end_time, format).isBetween(beforeTime, afterTime,  undefined, '(]')) {
            return  true
          } else  return false
        })

        if (studentSchedule.length) {
          studentSchedule.map((e, idx) => {
            if (idx === 0 && studentSchedule.length > 1) e.bg = 'background-color: rgba(21, 132, 130, 0.3); margin: -6px;' // #158482
            else if (idx === 1 && studentSchedule.length > 1)  e.bg = 'background-color: rgba(21, 132, 130, 0.3); margin: 6px -6px -6px -6px;' // #158482
            else {
              e.bg = 'background-color: rgba(21, 132, 130, 0.3);' // #158482
              e.fill = true
            }
          })
          return studentSchedule
        }

        const employeeSchedule = schedule.employeeSchedule.filter(e => moment(e.start_time, format).isBetween(beforeTime, afterTime,  undefined, '[)'))
        if (employeeSchedule.length) {
          employeeSchedule.map((e, idx) => {
            if (e.status === 'Bloqueado') {
              e.bg = 'background-color: rgba(92, 99, 106, 0.3);' // #5c636a
              e.fill = true
            } else if (idx === 0 && employeeSchedule.length > 1) e.bg = 'background-color: rgba(255, 159, 67, 0.3); margin: -6px;' // #FF9F43
            else if (idx === 1 && employeeSchedule.length > 1)  e.bg = 'background-color: rgba(255, 159, 67, 0.3); margin: 6px -6px -6px -6px;' // #FF9F43
            else {
              e.bg = 'background-color: rgba(255, 159, 67, 0.3);' // #FF9F43
              e.fill = true
            }
          })
          return employeeSchedule
        }

        const vehicleSchedule = schedule.vehicleSchedule.filter(e => moment(e.start_time, format).isBetween(beforeTime, afterTime,  undefined, '[)'))
        if (vehicleSchedule.length) {
          vehicleSchedule.map((e, idx) => {
            if (idx === 0 && vehicleSchedule.length > 1) e.bg = 'background-color: rgba(234, 84, 85, 0.3); margin: -6px;' // #EA5455
            else if (idx === 1 && vehicleSchedule.length > 1)  e.bg = 'background-color: rgba(234, 84, 85, 0.3); margin: 6px -6px -6px -6px;' // #EA5455
            else {
              e.bg = 'background-color: rgba(234, 84, 85, 0.3);' // #EA5455
              e.fill = true
            }
          })
          return vehicleSchedule
        }
      }
      return []
    },
    async addSchedule (selectedDay, hour, context = false) {
      const schedule = this.fillSchedule(selectedDay, hour)
      if (schedule.length && !context) {
        return                         // (PARA CLIQUES NAS BORDAS DA CÉLULA. OCORRE SOMENTE EM AGENDAMENTOS ÚNICOS ONDE A DIV NÃO COBRE TODO O TD)
      }

      if (!this.student.id || !this.employee_id || !this.vehicle || !this.category) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione <br> <strong>INSTRUTOR, VEÍCULO e CATEGORIA</strong> <br> para o agendamento.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }

      //  CHECA AVISOS EXCETO PARA AULAS AVULSAS DESDE QUE SELECIONADO O TIPO DE SERVIÇO
      if (this.type_service && this.type_service !== 'Aula avulsa') {
        if (this.verifyWarnings(selectedDay) === 'STOP') return
      }
      //  CHECA AVISOS EXCETO PARA AULAS AVULSAS DESDE QUE SELECIONADO O TIPO DE SERVIÇO

      try {
        this.$vs.loading()
        const payload = {
          type: this.type,
          student_id: this.student.id,
          employee_id: this.employee_id,
          category: this.category,
          schedules: [
            {
              status: this.status,
              type: this.type,
              duration: '00:50',
              start_date: selectedDay.id,
              start_time: `${hour.slice(0, 5)}:00`,
              end_time: `${hour.slice(6, 11)}:00`,
              student_id: this.student.id,
              employee_id: this.employee_id,
              vehicle_id: this.vehicle.id,
              category: this.category,
              type_service: this.type_service,
              ibio: this.ibio
            }
          ]
        }

        const response = await this.$store.dispatch('schedule/store', payload)
        this.student.schedules.push(response.data[0])
        this.$store.commit('schedule/ADD_STUDENT_LIST_SCHEDULES', response.data[0])
        this.$emit('refreshData') // ATUALIZA O DATAGRID

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    verifyWarnings (selectedDay) {
      //  BLOCO QUE CHECA SE JÁ EXISTEM 3 MARCAÇÕES DA MESMA CATEGORIA NO DIA SELECIONADO
      //  EVITA UMA 4ª MARCAÇÃO SE RETORNAR STOP
      const counter = {}
      for (let index = 0; index < selectedDay.studentSchedule.length; index++) {
        const obj = selectedDay.studentSchedule[index]
        counter[obj.category] = (counter[obj.category] || 0) + 1
        if (counter[obj.category] >= 3 && obj.category === this.category) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: `<strong>Este aluno já possui 3 agendamentos na categoria ${obj.category} para o dia selecionado.</strong>`,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return 'GO' // STOP PARA PARAR NÃO PERMITINDO O AGENDAMENTO
        }
      }
      //  BLOCO QUE CHECA SE JÁ EXISTEM 3 MARCAÇÕES DA MESMA CATEGORIA NO DIA SELECIONADO
      //  EVITA UMA 4ª MARCAÇÃO SE RETORNAR STOP

      let message = null
      if (this.availableSchedules === 1) {
        message = 'Esta é a última aula necessária.'
      }

      if (this.availableSchedules === 2) {
        message = 'Falta somente um agendamento.'
      }

      // REMOVIDO PELA NOVA LEGISLAÇÃO
      // if (this.availableSchedules <= 4 && (this.nightSchedules < this.nightSchedulesRequired)) {
      //   message = 'Não esqueça dos agendamentos noturnos.'
      // }

      if (message) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    confirmDelete () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: 'Tem certeza que deseja excluir este agendamento?',
        accept: this.deleteSchedule,
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    async deleteSchedule () {
      try {
        this.$vs.loading()

        const idx = this.student.schedules.findIndex(o => o.id === this.schedule.id)
        if (idx >= 0) this.student.schedules.splice(idx, 1)

        this.$store.commit('schedule/DELETE_STUDENT_LIST_SCHEDULES', this.schedule.id)
        this.$emit('refreshData') // ATUALIZA O DATAGRID

        await this.$store.dispatch('schedule/delete', this.schedule.id)

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Excluídos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

    async updateStatus (status) {
      try {
        this.openLoading()
        this.$store.commit('schedule/SET_SCHEDULE', { status, duration: '00:50' })  // PRECISO COLOCAR ASSIM O TEMPO PQ NO BANCO É TIME
        await this.$store.dispatch('schedule/update', this.schedule)

        // O BLOCO ABAIXO SERVE SOMENTE PARA MUDAR O NÚMERO DE AGENDAMENTOS CONFORME O STATUS
        const idx = this.student.schedules.findIndex(o => o.id === this.schedule.id)
        if (idx >= 0) {
          this.$set(this.student.schedules, idx, this.schedule)
        }
        this.$store.commit('schedule/UPDATE_STUDENT_LIST_SCHEDULES', this.schedule)
        this.$emit('refreshData') // ATUALIZA O DATAGRID


        this.request = true // FORÇO CONSULTA NA API PARA LSTAGEM MOSTRAR A ALTERAÇÃO
        this.closeLoading()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Alterados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) { console.error(error) }
    },
    async blockEmployee () {
      if (!this.employee_id) {
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Selecione o Instrutor Bloqueado.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }

      try {
        this.$vs.loading()
        const payload = {
          type: this.type,
          start_date: this.selectedDay.id,
          start_time: `${this.selectedHour.slice(0, 5)}:00`,
          end_time: `${this.selectedHour.slice(6, 11)}:00`,
          employee_id: this.employee_id,
          student_id: null,
          vehicle_id: null,
          category: null,
          doctor_id: null,
          cac_id: null,
          extra: this.blockEmployeeMotivation
        }
        await this.$store.dispatch('schedule/block', payload)
        this.$refs.menu.close()

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.log(error)
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    }
  },
  components: {
    VuePerfectScrollbar,
    EmailSidebar,
    vSelect,
    VueContext
    // ScheduleCard
  },
  // updated () {
  //   if (!this.currentMail) return
  //   if (this.currentMail.unread && this.isSidebarActive) this.$store.dispatch('email/setUnread', { emailIds: [this.openMailId], unread: false })
  // },

  created () {

    this.debouncedGetFilterSchedule = _.debounce(this.filterSchedule, 1000)

    // Register Module StudentManagement Module
    if (!moduleStudentManagement.isRegistered) {
      this.$store.registerModule('studentManagement', moduleStudentManagement)
      moduleStudentManagement.isRegistered = true
    }

    if (!moduleEmployee.isRegistered) {
      this.$store.registerModule('employee', moduleEmployee)
      moduleEmployee.isRegistered = true
    }
    this.$store.dispatch('employee/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleVehicle.isRegistered) {
      this.$store.registerModule('vehicle', moduleVehicle)
      moduleVehicle.isRegistered = true
    }
    this.$store.dispatch('vehicle/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleServicePackage.isRegistered) {
      // this.$store.registerModule('servicePackage', moduleServicePackage)
      moduleServicePackage.isRegistered = true
    }
    this.$store.dispatch('servicePackage/fetchAll').then(() => { }).catch(err => { console.error(err) })

    this.student = JSON.parse(JSON.stringify(this.$store.getters['studentManagement/getStudent']))

  },
  mounted () {
    this.setSidebarWidth()

    if (this.user.company.ibio_config) {
      this.ibio = true
      this.disabledIbio = false
    } else {
      this.disabledIbio = true
    }

  }
}

</script>
<style lang="scss">
// COLOCA O BACKGROUND NA FRENTE DO SIDEBAR PARA QUE POSSA SER CLICADO
#email-app .vs-sidebar--background {
    z-index: 40000 !important;
}
// FAZ COM QUE O ESPAÇO DO CALENDÁRIO (TABELA) APAREÇA POR COMPLETO NO FINAL DA BARRA DE ROLAGEM
#email-app .email-content-scroll-area {
  height: calc(100% - 190px) !important;
}

#email-app th .vs-table-text {
    cursor: default;
}
.svg-hover:hover {
  fill: #158482;
}
.svg-hover {
  fill: #626262;
}
#email-app td {
  border: 1px solid #c0c0c0;
  line-height: 1;
}
#email-app td:hover {
  border-bottom: 1px solid #353434;
}
#email-app td:first-child:hover {
  border-bottom: 1px solid #c0c0c0;
}
#email-app .vs-con-table td:last-child {
  border-right: 1px solid transparent;
}
#email-app .vs-table--thead {
  border: 1px solid transparent;
}
.icon {
  font-size: 16px;
}
#email-app .vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
    padding: 6px;
}
#email-app .ps__scrollbar-y-rail {
  z-index: 99999;
}


</style>
