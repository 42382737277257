<template>
  <div>
    <div class="vx-row w-full  items-end">
      <div class="vx-col md:w-4/5">
        <sig-input v-model="observation" label="Observação" />
      </div>
      <div class="vx-col md:w-1/5">
        <vs-button color="primary" type="filled" @click="save">Salvar</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import SigInput from '@/components/sig/SigInput'
import model from '@/store/register/observationStudent/model'

export default {
  components: {
    SigInput
  },
  props: ['studentId', 'obs'],
  data () {
    return {
      id: null,
      observation: null
    }
  },
  computed: {},
  methods: {
    save () {
      this.$vs.loading()
      this.$store.dispatch(model.$store.dispatch.saveSingle, {
        observation: this.observation,
        student_id: this.studentId,
        id: this.id
      }).then(() => {
        this.mixinNotify('Operação executada com sucesso')
        this.$emit('saved')
      }).catch(this.mixinCatch)
        .finally(() => {
          this.$vs.loading.close()
        })
    }
  },
  created () {}
}
</script>

