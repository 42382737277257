
export default {
  //MANIPULA O ÚNICO OBJETO FORA DO ARRAY NO ESTADO
  SET (state, payload) {
    state.contract = Object.assign({}, state.contract, payload) //precisa ser assim para manter a reatividade
  },

  //ADICIONA UMA NOVA CONTA NO ARRAY DE CONTAS
  ADD (state, payload) {
    state.students.push(payload) //precisa ser assim para manter a reatividade
  },

  //SUBSTITUI O ARRAY DE CONTRACTS POR UM NOVO (REFRESH)
  SET_CONTRACTS (state, contracts) {
    state.contracts = contracts
  },

  //ALTERA UM OBJETO DO ARRAY NO ESTADO pelo ID
  UPDATE (state, payload) {
    const index = state.contracts.data.findIndex(o => o.id === payload.id)
    state.contracts.data[index] = Object.assign({}, state.contracts.data[index], payload) //precisa ser assim para manter a reatividade
  },

  //EXCLUI UMA CONTA DO ARRAY DE CONTAS
  DELETE (state, payload) {
    const index = state.students.data.findIndex(o => o.id === payload)
    state.students.data.splice(index, 1) //precisa ser assim para manter a reatividade
    state.students.total = state.accounts.total - 1 //precisa ser assim para manter a reatividade
    if (state.student && state.student.id === payload) {
      state.student = Object.assign({}, {}) //precisa ser assim para manter a reatividade
    }
  }
}
