import axios from '@/axios.js'

export default {
  //NO FILTER SEMPRE DEVO FAZER A CONSULTA A API PARA RENOVAR O ESTADO POR COMPLETO no GRID
  fetchFilterByStudent ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/transactions/get/allByStudent`, payload)
        .then((response) => {
          response.data.data.forEach(e => {
            if (e.receipts && e.receipts.length) {
              e.receipt = e.receipts[e.receipts.length - 1].number
            }
          })
          commit('REFRESH', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  //NO FILTER SEMPRE DEVO FAZER A CONSULTA A API PARA RENOVAR O ESTADO POR COMPLETO no GRID
  fetchBatchPayment ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/transactions/getBatchPayment`, payload)
        .then((response) => {
          commit('REFRESH', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  //BUSCA TRANSAÇÕES CONFORME PAYLOAD FILTER PARA UM GRID
  fetchGrid ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/transaction/getAllGrid`, payload)
        .then((response) => {
          commit('REFRESH_GRID', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  batchPayment (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/transaction/batchPayments`, payload)
        .then((response) => {
          // commit('REFRESH', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  store (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/transaction`, payload)
        .then((response) => {
          // commit('REFRESH', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  update ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/transaction/${payload.id}`, payload)
        .then((response) => {
          commit('UPDATE', response.data)
          commit('SET', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  chargeback ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/transaction/chargeback/${payload.id}`, payload)
        .then((response) => {
          commit('UPDATE', response.data)
          commit('SET', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  delete ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_API_URL}/transaction/${id}`)
        .then((response) => {
          commit('DELETE', id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  batchDelete ({ commit }, ids) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/transactions/batchDelete`, ids)
        .then((response) => {
          commit('BATCH_DELETE', ids)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  batchUpdate ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/transactions/batchUpdate`, payload)
        .then((response) => {
          // commit('BATCH_UPDATE', payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  experssPayment ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/transaction/experssPayment/${payload.id}`)
        .then((response) => {
          commit('UPDATE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
