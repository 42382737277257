<template>
  <div>
    <sig-prompt :active.sync="formStudentDocument" @accept="save" title="Formulário de protocolo" >
      <student-document-form v-model="studentDocument" ref="form" />
    </sig-prompt>
    <sig-data-list
      :model="model"
      ref="list"
      @search-end="$emit('search-end')"
      @grid-ready="$emit('grid-ready')"
      @saved="search(false)"
      @register="register"
      @editing="register"
      :fetch="params"
      :allow-search="allowSearch"
    >
      <template v-slot:form>
        <sig-popup v-model="popup" ref="popup">
           <div>
        TESTE
      </div>
        </sig-popup>
      </template>
    </sig-data-list>
  </div>
</template>

<script>
import SigDataList from '@/components/sig/SigDataList'
import SigPopup from '@/components/sig/SigPopup'
import StudentDocumentForm from './StudentDocumentForm.vue'
import model from '@/store/register/studentDocument/model'

export default {
  components: {
    SigDataList,
    SigPopup,
    StudentDocumentForm
  },
  // indexSelect indice do state.selected
  props: ['indexSelect', 'nameSelect', 'studentId'], // allowSearch - Permitir busca automatica no grid
  data () {
    return {
      model,
      studentDocument: {},
      formStudentDocument: false,
      popup: {
        title: 'Observação'
      },
      allowSearch: true,
      params: {
        dispatch: model.$store.dispatch.fetchStudent,
        payload: {
          student_id: this.studentId
        }
      }
    }
  },
  methods: {
    register (data) {
      this.formStudentDocument = true
      if (data && data.data) {
        this.studentDocument = data.data.$clone()
      }
    },
    save () {
      this.$refs.form.save()
    }
  }
}
</script>
