<template>
  <div id="user-edit-tab-class-schedule">

    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Prática" icon-pack="feather" icon="icon-calendar">
            <div class="tab-text">

            </div>
          </vs-tab>
          <vs-tab label="Teórica" icon-pack="feather" icon="icon-file-text">
            <div class="tab-text">

            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import dialogSig from '@/components/dialog/dialogSig.vue'

//STORE
import moduleStudentManagement from '@/store/student-management/moduleStudentManagement.js'

export default {
  components: { vSelect, dialogSig },
  data () {
    return {
      activeTab: 0,
      data_local: {},
      disableSave: false
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any('main')
    }
  },
  methods: {
    resetLocalData (change = false) {
      this.data_local = JSON.parse(JSON.stringify(this.$store.getters['studentManagement/getStudent']))
      this.changed = change
    }
  },
  created () {
    // Register VUEX Modules
    if (!moduleStudentManagement.isRegistered) {
      this.$store.registerModule('studentManagement', moduleStudentManagement)
      moduleStudentManagement.isRegistered = true
    }
    this.resetLocalData()
  }
}
</script>
