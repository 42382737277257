<template>

  <div id="page-general-cash">
 <!-- REPARCELAMENTO -->
    <debt-negociation-popup  :transactions="transactionsNotPaid" :active.sync="popupDebtNegociation" ref="debtNegociation" :student="data_local"  @saved="refreshGrid" />
    <dialog-sig :show="popupGenerateReceiptConfirm" title="ALERTA" icon="warning"
      @action="generateReceipt(printReceiptData, printReceiptNode)"
      @cancel="popupGenerateReceiptConfirm = false"
      actionButtonText="Gerar Recibo" actionButtonColor="warning">
        <span v-html="'Um novo recibo será criado! <br> Tem certeza que deseja emitir este recibo?'"></span>
    </dialog-sig>

    <vs-popup class="popup80 noOverflow" :fullscreen="false" :title="showPdfTitle" :active.sync="popupShowPdf">
      <object :data="srcPdf" width="100%" style="height: 600px" />
    </vs-popup>

    <!-- BAIXA RÁPIDA CONFIRMAÇÃO DE RECIBO -->
    <vs-popup class="popup50" :fullscreen="false" title="Confirmação de Baixa" :active.sync="popupChangeTransactionSituation">
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <div class="mt-2 flex flex-wrap items-center justify-center">
            <vs-button color="secondary" @click="popupChangeTransactionSituation = false">Cancelar</vs-button>
          </div>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <div class="mt-2 flex flex-wrap items-center justify-center">
            <vs-button @click="changeTransactionSituation(true)">Com Recibo</vs-button>
          </div>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <div class="mt-2 flex flex-wrap items-center justify-center">
            <vs-button @click="changeTransactionSituation(false)">Sem Recibo</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>

    <batch-payment
      :show="popupBatchPayment"
      :firstPeriod="payload.searchQuery.firstPeriod"
      :lastPeriod="payload.searchQuery.lastPeriod"
      :student="data_local"
      @saved="refreshGrid"
      @cancel="popupBatchPayment = false" />

    <batch-edit
      :show="popupBatchEdit"
      :firstPeriod="payload.searchQuery.firstPeriod"
      :lastPeriod="payload.searchQuery.lastPeriod"
      :student="data_local"
      @saved="refreshGrid"
      @cancel="popupBatchEdit = false" />

    <credit-modal
      :show="popupCredit"
      :student="data_local"
      :credit="credit"
      @saved="refreshGrid"
      @cancel="popupCredit = false" />

    <transaction-edit-modal
      :show="popupTransactionEdit"
      :transaction-prop="transaction"
      @saved="refreshGrid"
      @cancel="popupTransactionEdit = false" />

    <boleto-modal
      :show="popupBoleto"
      :transaction-prop="transaction"
      @saved="refreshGrid"
      @cancel="popupBoleto = false" />

    <debit-modal
      :show="popupDebit"
      :student="data_local"
      @saved="refreshGrid"
      @cancel="popupDebit = false" />

    <chargeback-modal
      :show="popupChargeback"
      @saved="refreshGrid"
      @cancel="popupChargeback = false" />

    <!-- ROW 2 -->
    <div class="vx-row" style="margin-bottom: -15px">
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
          <statistics-card-line
            hideChart
            class="mb-base"
            icon="ArrowUpIcon"
            icon-right
            :statistic="totalIn.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
            statisticTitle="Entrada Aluno"
            color="success" />
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
          <statistics-card-line
            hideChart
            class="mb-base"
            icon="ArrowDownIcon"
            icon-right
            :statistic="totalOut.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
            statisticTitle="Saída Aluno"
            color="danger" />
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
          <statistics-card-line
            hideChart
            class="mb-base"
            icon="DollarSignIcon"
            icon-right
            :statistic="(totalIn - totalOut).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
            statisticTitle="Saldo"
            :color="(totalIn - totalOut) < 0 ? 'danger' : 'success'"
            :text-color="(totalIn - totalOut) < 0 ? 'danger' : 'success'" />
      </div>

      <div class="vx-col cursor-pointer w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
          <statistics-card-line style="border: solid; border-color: #158482;"
            hideChart
            class="mb-base"
            icon="DollarSignIcon"
            icon-right
            :statistic="totalReceivable.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
            statisticTitle="Total A Receber"
            :color="totalReceivable < 0 ? 'danger' : 'success'"
            :text-color="totalReceivable < 0 ? 'danger' : 'success'" />
      </div>
    </div>

    <vx-card ref="filterCard" class="general-cash-filters mb-4">
      <div class="vx-row flex-wrap items-center justify-end">
        <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
          <div class="flex flex-wrap">
            <vx-tooltip text="Período" position="top" class="h-5 w-5 ml-0 mb-6">
              <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer hover:text-primary">
                <a href.prevent class="flex cursor-pointer">
                  <feather-icon icon="CalendarIcon" svgClasses="h-6 w-6" class="p-2 inline-flex rounded-full" :class="[`text-primary`, 'mb-0']"
                    :style="{background: `rgba(var(--vs-success),.15)`}"></feather-icon>
                </a>
                <vs-dropdown-menu class="w-60" style="z-index: 99999999999999;">
                      <vs-input @change="debouncedFilterDate" type="date" v-model="payload.searchQuery.firstPeriod" class="w-full" label="Data Inicial" />
                      <vs-input @change="debouncedFilterDate" type="date" v-model="payload.searchQuery.lastPeriod" class="w-full" label="Data Final" />
                      <span class="text-danger text-sm" v-show="periodError">O período inicial deve ser anterior ao final.</span>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vx-tooltip>

            <vx-tooltip text="Imprimir" position="top" class="h-5 w-5 ml-6 mb-6">
              <vs-dropdown vs-custom-content class="cursor-pointer hover:text-primary">
                <a href.prevent class="flex cursor-pointer">
                  <feather-icon icon="PrinterIcon" svgClasses="h-6 w-6" class="p-2 inline-flex rounded-full" :class="[`text-primary`, 'mb-0']"
                    :style="{background: `rgba(var(--vs-success),.15)`}"></feather-icon>
                </a>
                <vs-dropdown-menu class="w-48" style="z-index: 55555;">
                  <feather-icon @click="printReport('PDF')" icon="AlignCenterIcon" class="p-3 inline-flex rounded-full cursor-pointer hover:text-primary" svgClasses="h-5 w-5 stroke-current" />
                  <span @click="printReport('PDF')" class="cursor-pointer hover:text-primary">Paisagem</span> <br>

                  <feather-icon @click="printReport('PDF', 'portrait')" icon="BarChart2Icon" class="p-3 inline-flex rounded-full cursor-pointer hover:text-primary" svgClasses="h-5 w-5 stroke-current" />
                  <span @click="printReport('PDF', 'portrait')" class="cursor-pointer hover:text-primary">Retrato</span>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vx-tooltip>

            <vx-tooltip text="Editar em Lote" position="top" class="h-5 w-5 ml-6">
              <feather-icon icon="Edit3Icon" svgClasses="h-6 w-6" class="ml-0 p-2 inline-flex rounded-full cursor-pointer" :class="[`text-primary`, 'mb-0']"
                @click="batchEdit" :style="{background: `rgba(var(--vs-success),.15)`}"></feather-icon>
            </vx-tooltip>
             <vx-tooltip text="Reparcelamento" position="top" class="h-5 w-5 ml-6">
              <feather-icon icon="PercentIcon" svgClasses="h-6 w-6" class="ml-0 p-2 inline-flex rounded-full cursor-pointer" :class="[`text-primary`, 'mb-0']"
                @click="doRegenerateTransaction" :style="{background: `rgba(var(--vs-success),.15)`}"></feather-icon>
            </vx-tooltip>
          </div>
        </div>
        <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Total de Taxas</label> <br>
            <span class="text-success">
              <strong>{{ (tax || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</strong>
            </span>
        </div>
        <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Total a Pagar Aluno</label> <br>
            <span class="text-warning">
              <strong>{{totalPayable.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</strong>
            </span>
        </div>
        <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
          <vs-button @click="batchPayment" class="w-full" type="line" color="primary" icon-pack="feather" icon="icon-refresh-ccw">Dar Baixa</vs-button>
        </div>
        <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
        <vs-row class="flex w-full">
                <vs-col vs-w="10">
                 <vs-button @click="credit" class="w-full" color="primary" icon-pack="feather" icon="icon-plus-circle">Receita</vs-button>
                </vs-col>
              </vs-row>

        </div>
        <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
          <vs-button @click="debit" class="w-full" color="danger" icon-pack="feather" icon="icon-minus-circle">Despesa</vs-button>
        </div>
      </div>
    </vx-card>

    <div class="vx-card p-6">

      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ allData.total - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : allData.total }} de {{ allData.total }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <h3 class="inline-flex sm:ml-6 ml-2 sm:w-auto w-full sm:order-normal order-2 sm:mt-0 mt-4">Período: {{ period }}</h3>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <vs-input class="sm:mr-4 mr-0 sm:w-1/3 w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Busca rápida por responsável..." />
          <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->

          <!-- ACTION - DROPDOWN -->
          <!-- <vs-dropdown vs-trigger-click class="cursor-pointer">

            <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
              <span class="mr-2 leading-none">Ações</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Deletar</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Arquivo</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Imprimir</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>CSV</span>
                </span>
              </vs-dropdown-item>

            </vs-dropdown-menu>
          </vs-dropdown> -->
      </div>
      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :overlayLoadingTemplate="overlayLoadingTemplate"
        :overlayNoRowsTemplate="noRowsTemplate"
        :localeText="localeText"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :cacheBlockSize="40"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        rowClass="rowClass"
        :rowClassRules="rowClassRules">
      </ag-grid-vue>

      <vs-pagination :total="totalPages" :max="10" v-model="currentPage" />

    </div>
    <!-- <credit-form ref="creditForm" :student="data_local"/> -->
  </div>

</template>

<script>
import model from '@/store/register/student/model'
import { AgGridVue } from 'ag-grid-vue'
import CreditForm from '@/components/cashier/credit/CreditForm'
import DebtNegociationPopup from '@/components/financial/debt-negociation/DebtNegociationPopup.vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import _ from 'lodash'
import { localePt_Br } from '../../../../assets/agGridTranslate/locale.pt_Br'

// Cell Renderer
import CellRendererDateTime from './financialTabComponents/cell-renderer/CellRendererDateTime.vue'
import CellRendererActions from './financialTabComponents/cell-renderer/CellRendererActions.vue'
import CellRendererObservation from './financialTabComponents/cell-renderer/CellRendererObservation.vue'
import CellRendererSituation from './financialTabComponents/cell-renderer/CellRendererSituation.vue'

// import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
const StatisticsCardLine = () => import(/* webpackChunkName: "StatisticsCardLine" */ '@/components/statistics-cards/StatisticsCardLine.vue')
const BatchPayment = () => import(/* webpackChunkName: "BatchPayment" */ './financialTabComponents/BatchPayment.vue')
const BatchEdit = () => import(/* webpackChunkName: "BatchEdit" */ './financialTabComponents/BatchEdit.vue')
const CreditModal = () => import(/* webpackChunkName: "CreditModal" */ './financialTabComponents/CreditModal.vue')
const TransactionEditModal = () => import(/* webpackChunkName: "TransactionEditModal" */ './financialTabComponents/TransactionEditModal.vue')
const BoletoModal = () => import(/* webpackChunkName: "BoletoModal" */ './financialTabComponents/BoletoModal.vue')
const DebitModal = () => import(/* webpackChunkName: "DebitModal" */ './financialTabComponents/DebitModal.vue')
const ChargebackModal = () => import(/* webpackChunkName: "ChargebackModal" */ './financialTabComponents/ChargebackModal.vue')
const dialogSig = () => import('@/components/dialog/dialogSig.vue')
import moment from 'moment'
import axios from '@/axios.js'

// Store Module
import moduleTransaction from '@/store/transaction/moduleTransaction.js'
import moduleStudentManagement from '@/store/student-management/moduleStudentManagement.js'

import PaginationMixin from '@/mixins/pagination'

export default {
  mixins: [PaginationMixin],
  components: {
    AgGridVue,

    // Cell Renderer
    CellRendererDateTime,
    CellRendererActions,
    CellRendererObservation,
    CellRendererSituation,

    StatisticsCardLine,
    BatchPayment,
    BatchEdit,
    CreditModal,
    DebtNegociationPopup,
    TransactionEditModal,
    BoletoModal, // operações com boletos
    DebitModal,
    ChargebackModal,
    dialogSig
  },
  data () {
    return {
      data_local: {},
      popupDebtNegociation: false,
      popupGenerateReceiptConfirm: false,
      printReceiptData: null,
      printReceiptNode: null,
      deleteTransactionNode: null,

      showPdfTitle: 'PDF',
      srcPdf: '',
      popupShowPdf: false,

      popupBatchPayment: false,
      popupBatchEdit: false,
      popupCredit: false,

      popupTransactionEdit: false,
      transaction: {
        discount: 0,
        discount_type: '%'
      },

      popupBoleto: false,

      popupChangeTransactionSituation: false,
      transactionToChangeSituation: {},

      popupDebit: false,
      popupChargeback: false,
      totalIn: 0,
      totalOut: 0,
      totalReceivable: 0,
      totalPayable: 0,
      periodError: false,
      rowClassRules: {
        'row-exired': (params) => {
          return params.data && params.data.situation === 'EXPIRED'
        },
        'row-pending': (params) => {
          return params.data && params.data.situation === 'PENDING'
        },
        'row-paid': (params) => {
          return params.data && params.data.situation === 'PAID'
        }
      },
      localeText: null,
      // Filter Options
      // roleFilter: { label: 'Todos', value: 'all' },
      // roleOptions: [
      //   { label: 'Todos', value: 'all' },
      //   { label: 'Admin', value: 'admin' },
      //   { label: 'User', value: 'user' },
      //   { label: 'Staff', value: 'staff' }
      // ],

      // statusFilter: { label: 'Todos', value: 'all' },
      // statusOptions: [
      //   { label: 'Todos', value: 'all' },
      //   { label: 'Ativo', value: 'active' },
      //   { label: 'Inativo', value: 'deactivated' },
      //   { label: 'Bloqueado', value: 'blocked' }
      // ],

      // isVerifiedFilter: { label: 'Todos', value: 'all' },
      // isVerifiedOptions: [
      //   { label: 'Todos', value: 'all' },
      //   { label: 'Sim', value: 'yes' },
      //   { label: 'Não', value: 'no' }
      // ],

      // departmentFilter: { label: 'Todos', value: 'all' },
      // departmentOptions: [
      //   { label: 'Todos', value: 'all' },
      //   { label: 'Vendas', value: 'sales' },
      //   { label: 'Desenvolvimento', value: 'development' },
      //   { label: 'Gerência', value: 'management' }
      // ],

      searchQuery: '',

      // AgGrid
      gridApi: null,
      dataSource: {},
      gridOptions: {
        rowModelType: 'infinite'
      },
      overlayLoadingTemplate: 'Consultando dados...',
      noRowsTemplate: 'Nenhum dado foi encontrado neste período.',
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        autoHeight: true,
        wrapText: true
      },
      columnDefs: [
        {
          headerName: 'Ações',
          field: 'actions',
          width: 110,
          sortable: false,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            printReceipt: this.printReceipt, // usado para passar parametros para dentro o componente renderizado no ag grid
            chargeback: this.chargeback, // usado para passar parametros para dentro o componente renderizado no ag grid
            confirmDeleteTransaction: this.confirmDeleteTransaction, // usado para passar parametros para dentro o componente renderizado no ag grid
            editTransaction: this.editTransaction, // usado para passar parametros para dentro o componente renderizado no ag grid
            boleto: this.boleto, // usado para passar parametros para dentro o componente renderizado no ag grid
            printCarnet: this.printCarnet, // usado para passar parametros para dentro o componente renderizado no ag grid
            printPromissoria: this.printPromissoria // usado para passar parametros para dentro o componente renderizado no ag grid
          }
        },
        {
          headerName: 'Código',
          field: 'id',
          width: 140
          // floatingFilter:true,
          // filter: 'agNumberColumnFilter',
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   debounceMs: 800
          // }
          // checkboxSelection: true,
          // headerCheckboxSelectionFilteredOnly: true,
          // headerCheckboxSelection: true
        },
        {
          headerName: 'Vencimento',
          field: 'expiration_date',
          width: 115,
          // floatingFilter:true,
          // filter: 'agDateColumnFilter',
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['equals', 'inRange'],
          //   defaultOption: 'equals',
          //   debounceMs: 800,
          // comparator: (filterLocalDateAtMidnight, cellValue) => {
          //   const dateAsString = cellValue
          //   if (dateAsString === null) return -1
          //   const dateParts = dateAsString.split('/')
          //   const cellDate = new Date(
          //     Number(dateParts[2]),
          //     Number(dateParts[1]) - 1,
          //     Number(dateParts[0])
          //   )

          //   if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          //     return 0
          //   }

          //   if (cellDate < filterLocalDateAtMidnight) {
          //     return -1
          //   }

          //   if (cellDate > filterLocalDateAtMidnight) {
          //     return 1
          //   }
          // },
          //   browserDatePicker: true,
          //   minValidYear: 2000
          // },
          cellRendererFramework: 'CellRendererDateTime'
        },
        {
          headerName: 'Descrição',
          field: 'observation',
          minWidth: 350,
          cellClass: 'my-class',
          cellRendererFramework: 'CellRendererObservation',
          sortable: false

        // data.transaction && data.type !== 'CHARGEBACK' ?
        //     (data.student ? data.student.name +' | ' : '') + (data.transaction.observation ? data.transaction.observation : 'NENHUMA DESCRIÇÃO.') :
        // data.type === 'CHARGEBACK' ?
        //     'ESTORNO ' + (data.transaction.type == 'DEBIT' ? ' DE DÉBITO | ': ' DE CRÉDITO | ' ) + (data.student ? data.student.name +' | ' : '') + (data.transaction.observation ? data.transaction.observation : 'Nenhuma descrição.') :
        //     ''


          // floatingFilter:true,
          // filter: 'agNumberColumnFilter',
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   numberParser: (text) => {
          //     return text === null ? null : text.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,
          //       (regex, a1, a2, a3, a4) => `${a1}.${a2}.${a3}-${a4}`)
          //   },
          //   debounceMs: 800
          // }
        }, {
          headerName: 'Pagamento',
          field: 'type_payment',
          width: 200,
          valueFormatter: params => {
            return params.value ? params.value.type_payment : '--'
          },
          sortable: false
          // floatingFilter:true,
          // filter: true,
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   debounceMs: 800
          // }
        },
        {
          headerName: 'Recibo',
          field: 'receipt',
          width: 200,
          sortable: false
        },
        {
          headerName: 'Valor Parcela',
          field: 'original_value',
          width: 125,
          valueFormatter: params => {
            return this.mixinCurrency(params.value)
          },
          sortable: false
        },
        {
          headerName: 'Valor Editado',
          field: 'value_parcel',
          width: 125,
          valueFormatter: params => {
            return params.value ? params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '--'
          },
          sortable: false

        },
        {
          headerName: 'Desconto',
          field: 'value_discount',
          width: 125,
          valueFormatter: params => {
            return params.value ? params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '--'
          },
          sortable: false
          // floatingFilter:true,
          // filter: true,
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   debounceMs: 800
          // }
        },
        {
          headerName: 'Juros',
          field: 'value_interest',
          width: 125,
          valueFormatter: params => {
            return params.value ? params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '--'
          },
          sortable: false
          // floatingFilter:true,
          // filter: true,
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   debounceMs: 800
          // }
        },
        {
          headerName: 'Valor Pagar',
          field: 'value_updated',
          width: 125,
          valueFormatter: params => {
            return params.value ? params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '--'
          },
          sortable: false
          // floatingFilter:true,
          // filter: true,
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   debounceMs: 800
          // }
        },
        {
          headerName: 'Dt. Pag.',
          field: 'payday',
          width: 115,
          // floatingFilter:true,
          // filter: 'agDateColumnFilter',
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['equals', 'inRange'],
          //   defaultOption: 'equals',
          //   debounceMs: 800,
          // comparator: (filterLocalDateAtMidnight, cellValue) => {
          //   const dateAsString = cellValue
          //   if (dateAsString === null) return -1
          //   const dateParts = dateAsString.split('/')
          //   const cellDate = new Date(
          //     Number(dateParts[2]),
          //     Number(dateParts[1]) - 1,
          //     Number(dateParts[0])
          //   )

          //   if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          //     return 0
          //   }

          //   if (cellDate < filterLocalDateAtMidnight) {
          //     return -1
          //   }

          //   if (cellDate > filterLocalDateAtMidnight) {
          //     return 1
          //   }
          // },
          //   browserDatePicker: true,
          //   minValidYear: 2000
          // },
          cellRendererFramework: 'CellRendererDateTime'
        },
        {
          headerName: 'Valor Pago',
          field: 'value_pay',
          width: 125,
          valueFormatter: params => {
            return params.value ? params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '--'
          },
          sortable: false
          // floatingFilter:true,
          // filter: true,
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   debounceMs: 800
          // }
        },
        {
          headerName: 'Responsável',
          field: 'responsible',
          width: 155,
          valueFormatter: params => {
            return params.value ? params.value.name : ''
          },
          sortable: false
        },
        {
          headerName: 'Situação',
          field: 'situation',
          width: 100,
          cellRendererFramework: 'CellRendererSituation',
          sortable: false,
          onCellClicked: (event) => { this.handleChangeTransactionSituation(event.node) }

          // floatingFilter:true,
          // filter: 'agNumberColumnFilter',
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   debounceMs: 800
          // },
        }
      ],

      components: {
        CellRendererDateTime,
        CellRendererSituation,
        CellRendererActions,
        CellRendererObservation
      },

      payload: {
        offset: 0,
        limit: 40,
        searchQuery: {
          firstPeriod: '',
          lastPeriod: '',
          responsible: ''
        },
        params: null,
        consultApi: true
      }
    }
  },
  watch: {
    // roleFilter (obj) {
    //   this.setColumnFilter('id', obj.value)
    // },
    // statusFilter (obj) {
    //   this.setColumnFilter('name', obj.value)
    // },
    // isVerifiedFilter (obj) {
    //   const val = obj.value === 'all' ? 'all' : obj.value === 'yes' ? 'true' : 'false'
    //   this.setColumnFilter('name', val)
    // },
    // departmentFilter (obj) {
    //   this.setColumnFilter('cpf', obj.value)
    // }

  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    period () {
      if (this.payload.searchQuery.firstPeriod && this.payload.searchQuery.lastPeriod) {
        return `${moment(this.payload.searchQuery.firstPeriod).format('DD/MM/YYYY')} até ${moment(this.payload.searchQuery.lastPeriod).format('DD/MM/YYYY')}`
      }
      return 'Desde o seu cadastro.'
    },
    allData () {
      const data = this.$store.state.transaction ? this.$store.state.transaction.transactions : null
      return JSON.parse(JSON.stringify(data))
    },
    transactionsNotPaid () {
      if (this.allData && this.allData.data && Array.isArray(this.allData.data)) return this.allData.data.filter(e => e.situation !== 'PAID')
      return []
    },
    tax () {

      /**
       * OBTENSÃO DE TAXAS:
       * O VALOR É BUSCADO DOS SERVIÇOS CADASTRADOS NAS TRANSAÇÕES | IMPORTANTE QUE AS TRANSAÇÕES SEMPRE TENHAM O NÚMERO CORRESPONDENTE DA PARCELA |
       * 1º SEPARADO SOMENTE TRANSAÇÕES QUE POSSUAM PACOTE DE SERVIÇO E FEITO O FLAT PEGANDO SOMENTE A 1ª PARCELA PARA NÃO DULICAR AS TAXAS (ISSO É IMPORTANTE)
       * 2º FILTRADO SOMENTE SERVIÇOS TIPO TAX DESTES PACOTES
       * 3º SOMADO TODOS OS VALORES MULTIPLICADO PELA QUANTIDADE DE TAXA
       */

      const transactions = this.$store.state.transaction ? JSON.parse(JSON.stringify(this.$store.state.transaction.transactions)) : null
      let tax = 0
      if (transactions && transactions.data) {
        const sp = transactions.data.filter(e => e.servicePackages && e.parcel === 1).map(e => e.servicePackages).flat()
        const sf = sp.map(e => {
          return e && e.services ? e.services : null
        }).flat()
        if (sf[0]) { // se existir itens ou taxas
          const s = sf.filter(e => e && e.type && e.type === 'TAX')
          tax = s.reduce((a, e) => {
            return a + ((e.quantity || 1) * e.value)
          }, 0)
        } else {
          tax = 0
        }
      }
      return tax
    }
  },
  methods: {
    doRegenerateTransaction () {
      this.popupDebtNegociation = true
      // zera selecao
      this.$refs.debtNegociation.reset()
    },
    // utilizado depois de um reparcelamento
    afterRegenerateTransaction () {
      this.popupDebtNegociation = false
      // atualizar dados
      this.refreshGrid()
    },
    openCreditForm () {
      this.$store.commit(model.$store.commit.nameSelect, 'credit')
      this.$store.commit(model.$store.commit.selected, {
        data: this.student
      })
      this.$refs.creditForm.popup = true
    },
    resetLocalData (change = false) {
      this.data_local = JSON.parse(JSON.stringify(this.$store.getters['studentManagement/getStudent']))
      Object.assign(this.payload, {student_id: this.data_local.id})
      this.changed = change
    },
    handleChangeTransactionSituation (node) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_student_parcels') // baixar parcelas
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      if (node.data.situation !== 'PAID') {
        this.popupChangeTransactionSituation = true
        this.transactionToChangeSituation = node
      }
    },
    async changeTransactionSituation (receipt) {
      try {
        await this.$store.dispatch('transaction/experssPayment', this.transactionToChangeSituation.data)
        this.payload.consultApi = false

        this.popupChangeTransactionSituation = false

        if (receipt) {
          this.generateReceipt(this.transactionToChangeSituation.data, this.transactionToChangeSituation)
        }
        this.gridApi.refreshInfiniteCache()

        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Situação Alterada.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

    // APENAS PARA TESTES E USO FUTURO. EXEMPLO DE COMO REDIMENSIONAR LINHAS
    // i () {
    //   this.gridApi.forEachNode((rowNode) => {
    //     console.log(rowNode)
    //     rowNode.setRowHeight(100)
    //   })
    // },
    getSearchResult (searchVal) {
      this.payload.searchQuery.responsible = searchVal
      this.gridApi.setDatasource(this.dataSource)
    },
    // setColumnFilter (column, val) {
    //   const filter = this.gridApi.getFilterInstance(column)
    //   let modelObj = null

    //   if (val !== 'all') {
    //     modelObj = { type: 'equals', filter: val }
    //   }

    //   filter.setModel(modelObj)
    //   this.gridApi.onFilterChanged()
    // },
    // resetColFilters () {
    // Reset Grid Filter
    // this.gridApi.setFilterModel(null)
    // this.gridApi.onFilterChanged()

    // Reset Filter Options
    // this.roleFilter = this.statusFilter = this.isVerifiedFilter = this.departmentFilter = { label: 'Todos', value: 'all' }

    // this.$refs.filterCard.removeRefreshAnimation()
    // },
    updateSearchQuery (val) {
      this.debouncedGetSearchResult(val)
      // this.gridApi.setQuickFilter(val)
    },
    batchPayment () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_student_parcels') // baixar
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      this.popupBatchPayment = true
    },
    batchEdit () {
      this.popupBatchEdit = true
    },
    credit () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_student_income') // lançar receitas
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      this.popupCredit = true
    },
    debit () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_student_expense') // lançar despesas
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }
      this.popupDebit = true
    },
    refreshGrid () {
      this.gridApi.setDatasource(this.dataSource)
    },
    filterDate () {
      if (moment(this.payload.searchQuery.firstPeriod).year() > 1000 && moment(this.payload.searchQuery.lastPeriod).year() > 1000) {
        const difDays = moment(this.payload.searchQuery.lastPeriod).diff(moment(this.payload.searchQuery.firstPeriod), 'days')
        if (difDays < 0) {
          this.periodError = true
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'O período inicial deve ser anterior ao final.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        } else if (difDays > ((365 * 5) + 1)) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'O período não pode ser maior que 5 anos.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }
        this.periodError = false
        this.gridApi.setDatasource(this.dataSource)
      } else if (!this.payload.searchQuery.firstPeriod && !this.payload.searchQuery.lastPeriod) {
        this.periodError = false
        this.gridApi.setDatasource(this.dataSource)
      }
    },

    onGridReady () {
      const self = this
      this.dataSource = {
        rowCount: null,
        async getRows (params) {
          self.payload.offset = params.startRow
          self.payload.params = params

          if (self.payload.consultApi) await self.getAllData()
          self.payload.consultApi = true //esta linha faz com que a próxima chamada sempre seja feita para a API

          let lastRow = -1
          if (self.allData.total <= params.endRow) {
            lastRow = self.allData.total
          }
          self.totalIn = self.allData.totalIn
          self.totalOut = self.allData.totalOut
          self.totalReceivable = self.allData.totalReceivable
          self.totalPayable = self.allData.totalPayable
          params.successCallback(self.allData.data, lastRow)
        }
      }
      this.gridApi.setDatasource(this.dataSource)
    },

    async getAllData () {
      this.gridApi.showLoadingOverlay()
      const data =  await this.$store.dispatch('transaction/fetchFilterByStudent', this.payload).catch(err => { console.error(err) })
      this.gridApi.hideOverlay()
      if (!data.data.total) {
        this.gridApi.showNoRowsOverlay()
      }
      return JSON.parse(JSON.stringify(data))
    },

    // BAIXA O ARQUIVO EXCEL OU WORD
    makeBlobDoc (blob, filename) {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${filename || 'Relatório'}.xlsx`
      link.click()
    },
    async printReport (type, orientation = 'landscape') {
      this.$vs.loading()
      let phone = ''
      if (this.data_local.phones[0].phone) phone = `- Contato ${  this.data_local.phones[0].phone}`
      this.showPdfTitle = 'Relatório Financeiro do Aluno'
      const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/student/report/financial`, {
        firstPeriod: this.payload.searchQuery.firstPeriod,
        lastPeriod: this.payload.searchQuery.lastPeriod,
        orderBy: 'expiration_date',
        format: type,
        student_id: this.data_local.id,
        payload: {
          title: `Relatório Financeiro do Aluno ${this.data_local.name} ${phone}`,
          orientation
        }
      }, { responseType: type === 'EXCEL' ? 'arraybuffer' : null })

      if (type === 'PDF') {
        this.srcPdf = `data:application/pdf;base64,${resp.data}`
        this.popupShowPdf = true
      } else if (type === 'EXCEL') {
        const blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        this.makeBlobDoc(blob, this.showPdfTitle)
      }
      this.$nextTick(() => { this.$vs.loading.close() })
    },
    printReceipt (data, node) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_recibo')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      if (data.receipts.length) {
        //possui recibo, imprime 2ª via
        this.generateReceipt(data, node)
      } else {
        //não possui recibo, devo perguntar e se for o caso cria recibo
        this.popupGenerateReceiptConfirm = true
        this.printReceiptData = data
        this.printReceiptNode = node
      }
    },
    generateReceipt (data, node) {
      this.$vs.loading()
      this.popupGenerateReceiptConfirm = false
      axios.get(`${process.env.VUE_APP_API_URL}/document/pdf/receipt/${data.id}`)
        .then(function (response) {
          this.showPdfTitle = 'Recibo'
          this.srcPdf = `data:application/pdf;base64,${response.data.pdf}`
          this.popupShowPdf = true

          /**
           * ALTERANDO GRID SEM REFRESH NAS LINHAS ABAIXO
           */
          Object.assign(data, response.data.transaction) // Precisa ser assim em função da reatividade
          const rowNode = this.gridApi.getRowNode(node.id)
          rowNode.setData(data)

          // rowNode.setDataValue('type', 'CHARGEBACK')
          if (!data.receipts.length) this.$store.commit('transaction/UPDATE', data)

          this.$vs.loading.close()
        }.bind(this))
        .catch(function () {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: 'Desculpe, algo deu errado. Por favor avise o suporte.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        }.bind(this))
    },
    printCarnet (node) {
      this.$vs.loading()
      axios.get(`${process.env.VUE_APP_API_URL}/document/pdf/carnet/${node.data.id}`)
        .then(function (response) {
          this.showPdfTitle = 'Carnê'
          this.srcPdf = `data:application/pdf;base64,${response.data}`
          this.popupShowPdf = true
          this.$vs.loading.close()
        }.bind(this))
        .catch(function (error) {
          this.$vs.loading.close()
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning',
              position: 'top-center'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        }.bind(this))
    },
    printPromissoria (node) {
      this.$vs.loading()
      axios.get(`${process.env.VUE_APP_API_URL}/document/pdf/promissoria/${node.data.id}`)
        .then(function (response) {
          this.showPdfTitle = 'Promissória'
          this.srcPdf = `data:application/pdf;base64,${response.data}`
          this.popupShowPdf = true
          this.$vs.loading.close()
        }.bind(this))
        .catch(function (error) {
          this.$vs.loading.close()

          if (error.response.status === 412) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning',
              position: 'top-center'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        }.bind(this))
    },
    editTransaction (node) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_student_batch_edit') // editar parcelas
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }
      const transaction = JSON.parse(JSON.stringify(node.data))
      transaction.discount = 0
      transaction.discount_type = '%'
      transaction.applyInterest = true
      transaction.situation = transaction.situation === 'PAID'
      transaction.futureDate = false
      this.transaction = transaction
      this.popupTransactionEdit = true
    },
    boleto (node) {
      const transaction = JSON.parse(JSON.stringify(node.data))
      this.transaction = transaction
      this.popupBoleto = true
    },
    confirmDeleteTransaction (node) {

      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_student_batch_edit') // editar parcelas
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('delete'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      this.deleteTransactionNode = node
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: 'Tem certeza que deseja DELETAR esta movimentação?!',
        accept: this.deleteTransaction,
        acceptText: 'Delete'
      })
    },
    deleteTransaction () {
      this.$vs.loading()
      this.$store.dispatch('transaction/delete', this.deleteTransactionNode.data.id)
        .then(() => {
          this.$vs.loading.close()
          this.payload.consultApi = false //uma consulta desnecessária a menos à API
          this.gridApi.refreshInfiniteCache()
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados excluídos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
          this.deleteTransactionNode = {}
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.deleteTransactionNode = {}
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        })
    },
    chargeback (data) {
      this.$store.commit('transaction/SET', data)
      this.popupChargeback = true
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    this.localeText = localePt_Br
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created () {
    this.setGrid('allData')
    if (!moduleTransaction.isRegistered) {
      this.$store.registerModule('transaction', moduleTransaction)
      moduleTransaction.isRegistered = true
    }
    if (!moduleStudentManagement.isRegistered) {
      this.$store.registerModule('studentManagement', moduleStudentManagement)
      moduleStudentManagement.isRegistered = true
    }
    this.resetLocalData()
    this.debouncedGetSearchResult = _.debounce(this.getSearchResult, 600)
    this.debouncedFilterDate = _.debounce(this.filterDate, 600)
  }
}
</script>

<style lang="scss">
#page-general-cash {
  .general-cash-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
  .vx-card .vx-card__collapsible-content .vx-card__body {
    padding: 1rem !important;
  }
  .ag-floating-filter-button {
    margin-left: 0px !important;
  }
  .my-class {
    line-height: 25px;
  }
  .cell-wrap-text {
    white-space: normal !important;
  }
  .ag-cell-wrap-text {
    word-break: break-word !important;
  }
  .ag-theme-material .ag-cell {
    padding-left: 10px; // 23
    padding-right: 10px; // 23
  }
  .ag-theme-material .ag-header-cell, .ag-theme-material .ag-header-group-cell {
    padding-left: 12px;
    padding-right: 12px;
  }
  // .ag-row-position-absolute {
  //   position: inherit;
  // }
  // .ag-row {
  //   margin-bottom: -40px;
  // }
  .row-exired {
    color: red;
  }
  // .row-pending {
  //   color: red;
  // }
  .row-paid {
    color: #158482;
  }
  .rowClass {
    font-size: 12px;
  }

  .popup80 .vs-popup {
    width: 80% !important;
  }

  .noOverflow .vs-popup--content {
    overflow: hidden;
  }
}
</style>
