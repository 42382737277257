<template>
    <vs-popup :active.sync="localActive" title="Reparcelamento - Renegociação de dívida">
      <debt-negociation :transactions="transactions" ref="debtNegociation" :student="student" @saved="saved" />
    </vs-popup>
</template>

<script>
import DebtNegociation from './DebtNegociation.vue'
export default {
  components: {
    DebtNegociation
  },
  props: {
    transactions: {},
    active: {},
    student: {}
  },
  data () {
    return {
    }
  },
  computed: {
    localActive: {
      get () {
        return this.active
      },
      set (value) {
        this.$emit('update:active', value)
      }
    }
  },
  methods: {
    doNewTransaction () {
      this.$refs.debtNegociation.doNewTransaction()
    },
    reset () {
      this.$refs.debtNegociation.reset()
    },
    saved () {
      this.localActive = false
      this.$emit('saved')
    }
  }
}
</script>

<style>

</style>
