<template>
  <div id="email-app">
    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Prática" icon-pack="feather" icon="icon-calendar">
            <div class="tab-text">

              <grid-practical ref="gridPractical" @openViewSideBar="openViewSideBar" :student="this.student"></grid-practical>

            </div>
          </vs-tab>
          <vs-tab label="Teórica" icon-pack="feather" icon="icon-file-text">
            <div class="tab-text">

              <grid-theoretical ref="gridTheoretical" @openViewSideBar="openViewSideBar" :student="this.student"></grid-theoretical>

            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>

    <!-- PRACTICAL SCHEDULE VIEW SIDEBAR -->
    <practical-schedule-view v-if="activeTab === 0" :isSidebarActive="isSidebarActive" @refreshData="refreshGridPractical(false)" @closeSidebar="closeViewSidebar"></practical-schedule-view>
    <!-- THEORETICAL SCHEDULE VIEW SIDEBAR -->
    <theoretical-schedule-view v-else-if="activeTab === 1" :isSidebarActive="isSidebarActive" @refreshData="refreshGridTheoretical(false)" @closeSidebar="closeViewSidebar"></theoretical-schedule-view>

  </div>
</template>

<script>
import PracticalScheduleView  from './view-practical-schedule/ScheduleView.vue'
import GridPractical          from './view-practical-schedule/Grid.vue'
import TheoreticalScheduleView  from './view-theoretical-schedule/ScheduleView.vue'
import GridTheoretical          from './view-theoretical-schedule/Grid.vue'


export default {
  components: {
    PracticalScheduleView,
    GridPractical,
    TheoreticalScheduleView,
    GridTheoretical
  },
  data () {
    return {
      activeTab: 0,
      student: {},
      isSidebarActive      : false,
      clickNotClose        : true
    }
  },

  methods: {
    refreshGridPractical (val) {
      this.$refs.gridPractical.refreshGrid(val)
    },
    refreshGridTheoretical (val) {
      this.$refs.gridTheoretical.refreshGrid(val)
    },
    openViewSideBar () {
      this.isSidebarActive = true
    },
    closeViewSidebar () {
      this.isSidebarActive = false
    },
    resetLocalData (change = false) {
      this.student = JSON.parse(JSON.stringify(this.$store.getters['studentManagement/getStudent']))
      this.changed = change
    }
  },
  created () {
    this.resetLocalData()
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
</style>
