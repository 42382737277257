var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-tabs",
    {
      attrs: { alignment: "right" },
      model: {
        value: _vm.activeTab,
        callback: function ($$v) {
          _vm.activeTab = $$v
        },
        expression: "activeTab",
      },
    },
    [
      _c(
        "vs-tab",
        { attrs: { label: "Débitos Abertos" } },
        [
          _c("div", { staticClass: "vx-row flex items-end" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5" },
              [
                _c("sig-switch", {
                  attrs: { label: "Aplicar juros" },
                  model: {
                    value: _vm.applyInterest,
                    callback: function ($$v) {
                      _vm.applyInterest = $$v
                    },
                    expression: "applyInterest",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5" },
              [
                _c("sig-input-currency", {
                  attrs: { label: "Valor original", disabled: "" },
                  model: {
                    value: _vm.totalValue,
                    callback: function ($$v) {
                      _vm.totalValue = $$v
                    },
                    expression: "totalValue",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5" },
              [
                _c("sig-input-currency", {
                  attrs: {
                    label: _vm.applyInterest
                      ? "Valor a pagar (com juros)"
                      : "Valor a pagar",
                    disabled: "",
                  },
                  model: {
                    value: _vm.adjustedValue,
                    callback: function ($$v) {
                      _vm.adjustedValue = $$v
                    },
                    expression: "adjustedValue",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", [_vm._v("Selecione uma ou mais parcelas")]),
          _c(
            "vs-table",
            {
              attrs: { multiple: "", data: _vm.transactions },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ data }) {
                    return _vm._l(data, function (transaction, idx) {
                      return _c(
                        "vs-tr",
                        { key: idx, attrs: { data: transaction } },
                        [
                          _c("vs-td", { attrs: { data: transaction.id } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(transaction.id) +
                                "\n          "
                            ),
                          ]),
                          _c(
                            "vs-td",
                            { attrs: { data: transaction.expiration_date } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.mixinConvertDateStringUsToBr(
                                      transaction.expiration_date
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _c(
                            "vs-td",
                            { attrs: { data: transaction.observation } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(transaction.observation) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _c(
                            "vs-td",
                            { attrs: { data: transaction.value_updated } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.mixinCurrency(transaction.value_updated)
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    })
                  },
                },
              ]),
              model: {
                value: _vm.oldTransactions,
                callback: function ($$v) {
                  _vm.oldTransactions = $$v
                },
                expression: "oldTransactions",
              },
            },
            [
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", [_vm._v(" Código ")]),
                  _c("vs-th", [_vm._v(" Vencimento ")]),
                  _c("vs-th", [_vm._v(" Descrição ")]),
                  _c("vs-th", [_vm._v(" Valor Pagar ")]),
                ],
                1
              ),
            ],
            2
          ),
          _c("vs-divider"),
          _c(
            "div",
            { staticClass: "flex flex-row-reverse" },
            [
              _c(
                "vs-button",
                {
                  attrs: {
                    color: "primary",
                    type: "filled",
                    disabled: !_vm.oldTransactions.length,
                  },
                  on: { click: _vm.doNewTransaction },
                },
                [_vm._v("CONTINUAR")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "vs-tab",
        { attrs: { label: "Nova Parcela", disabled: !_vm.activeTab } },
        [
          _c(
            "div",
            [
              _c("transaction-credit", {
                attrs: { student: _vm.student, data: _vm.tplTransaction },
                on: {
                  saved: function ($event) {
                    return _vm.$emit("saved")
                  },
                },
                model: {
                  value: _vm.newTransactions,
                  callback: function ($$v) {
                    _vm.newTransactions = $$v
                  },
                  expression: "newTransactions",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.newTransactions.length
        ? _c(
            "div",
            [
              _c("vs-divider"),
              _c(
                "div",
                { staticClass: "flex flex-row-reverse" },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: { color: "primary", type: "filled" },
                      on: { click: _vm.save },
                    },
                    [_vm._v("Salvar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "mr-2",
                      attrs: { color: "warning", type: "filled" },
                      on: {
                        click: function ($event) {
                          _vm.newTransactions = []
                        },
                      },
                    },
                    [_vm._v("Limpar")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }