var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-full" }, [
    _c(
      "div",
      { staticClass: "vx-col w-full" },
      [
        _c("observation-student-list", {
          ref: "observation",
          attrs: { "student-id": _vm.$route.params.studentId },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }