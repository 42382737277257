var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "user-edit-tab-info" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup80 noOverflow",
          attrs: {
            fullscreen: false,
            title: _vm.showPdfTitle,
            active: _vm.popupShowPdf,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowPdf = $event
            },
          },
        },
        [
          _c("object", {
            staticStyle: { height: "600px" },
            attrs: { data: _vm.srcPdf, width: "100%" },
          }),
        ]
      ),
      _c(
        "dialog-sig",
        {
          attrs: {
            show: _vm.popupCnhDeleteConfirm,
            title: "ALERTA",
            icon: "warning",
          },
          on: {
            action: function ($event) {
              return _vm.dispatchDeleteCnh(_vm.cnhDeleteId)
            },
            cancel: function ($event) {
              _vm.popupCnhDeleteConfirm = false
            },
          },
        },
        [
          _vm._v("\n      Tem certeza que deseja excluir esta CNH? "),
          _c("br"),
          _vm._v("\n      Esta ação é irreversível\n  "),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup80",
          attrs: {
            fullscreen: false,
            title: "Cadastrar nova CNH",
            active: _vm.popupStoreCnh,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupStoreCnh = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "Registro" },
                  model: {
                    value: _vm.cnh.registro_cnh,
                    callback: function ($$v) {
                      _vm.$set(_vm.cnh, "registro_cnh", $$v)
                    },
                    expression: "cnh.registro_cnh",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "Cód. Segurança" },
                  model: {
                    value: _vm.cnh.security_code,
                    callback: function ($$v) {
                      _vm.$set(_vm.cnh, "security_code", $$v)
                    },
                    expression: "cnh.security_code",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 mt-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "date_format:yyyy-MM-dd",
                      expression: "'date_format:yyyy-MM-dd'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    type: "date",
                    label: "Emissão",
                    "data-vv-scope": "newCNH",
                    "data-vv-as": "Emissão",
                    name: "date_last_cnh",
                  },
                  on: { change: _vm.autoSetValidityCnh },
                  model: {
                    value: _vm.cnh.date_last_cnh,
                    callback: function ($$v) {
                      _vm.$set(_vm.cnh, "date_last_cnh", $$v)
                    },
                    expression: "cnh.date_last_cnh",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("newCNH.date_last_cnh"),
                        expression: "errors.has('newCNH.date_last_cnh')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Formato incorreto")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 mt-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "date_format:yyyy-MM-dd",
                      expression: "'date_format:yyyy-MM-dd'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    type: "date",
                    label: "Vencimento",
                    "data-vv-scope": "newCNH",
                    "data-vv-as": "Emissão",
                    name: "validity_cnh",
                  },
                  model: {
                    value: _vm.cnh.validity_cnh,
                    callback: function ($$v) {
                      _vm.$set(_vm.cnh, "validity_cnh", $$v)
                    },
                    expression: "cnh.validity_cnh",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("newCNH.validity_cnh"),
                        expression: "errors.has('newCNH.validity_cnh')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Formato incorreto")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Categoria"),
                ]),
                _c("v-select", {
                  attrs: {
                    options: _vm.categoryCNHOptions,
                    reduce: (option) => option.value,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n              Desculpe, nenhum resultado encontrado.\n            "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.cnh.current_category_cnh,
                    callback: function ($$v) {
                      _vm.$set(_vm.cnh, "current_category_cnh", $$v)
                    },
                    expression: "cnh.current_category_cnh",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Tipo"),
                ]),
                _c("v-select", {
                  attrs: {
                    options: _vm.typeCNHOptions,
                    reduce: (option) => option.value,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n              Desculpe, nenhum resultado encontrado.\n            "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.cnh.type_cnh,
                    callback: function ($$v) {
                      _vm.$set(_vm.cnh, "type_cnh", $$v)
                    },
                    expression: "cnh.type_cnh",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Cursos"),
                ]),
                _c("v-select", {
                  attrs: {
                    options: _vm.specializedCoursesCNHOptions,
                    reduce: (option) => option.value,
                    placeholder: "Selecione",
                    multiple: "",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n              Desculpe, nenhum resultado encontrado.\n            "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.cnh.specialized_courses,
                    callback: function ($$v) {
                      _vm.$set(_vm.cnh, "specialized_courses", $$v)
                    },
                    expression: "cnh.specialized_courses",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 mt-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "date_format:yyyy-MM-dd",
                      expression: "'date_format:yyyy-MM-dd'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    type: "date",
                    label: "Toxicológico",
                    "data-vv-scope": "newCNH",
                    "data-vv-as": "Toxicológico",
                    name: "toxicological",
                  },
                  on: { change: _vm.autoSetValidityToxicological },
                  model: {
                    value: _vm.cnh.toxicological,
                    callback: function ($$v) {
                      _vm.$set(_vm.cnh, "toxicological", $$v)
                    },
                    expression: "cnh.toxicological",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("newCNH.toxicological"),
                        expression: "errors.has('newCNH.toxicological')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Formato incorreto")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 mt-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "date_format:yyyy-MM-dd",
                      expression: "'date_format:yyyy-MM-dd'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    type: "date",
                    label: "Venc. Toxicológico",
                    "data-vv-scope": "newCNH",
                    "data-vv-as": "Toxicológico",
                    name: "validity_toxicological",
                  },
                  model: {
                    value: _vm.cnh.validity_toxicological,
                    callback: function ($$v) {
                      _vm.$set(_vm.cnh, "validity_toxicological", $$v)
                    },
                    expression: "cnh.validity_toxicological",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("newCNH.validity_toxicological"),
                        expression:
                          "errors.has('newCNH.validity_toxicological')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Formato incorreto")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-2" }, [
              _c(
                "div",
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v("EAR"),
                  ]),
                  _c("v-select", {
                    attrs: {
                      clearable: false,
                      reduce: (option) => option.value,
                      options: [
                        { value: 0, label: "NÃO" },
                        { value: 1, label: "SIM" },
                      ],
                      placeholder: "Selecione",
                      dir: _vm.$vs.rtl ? "rtl" : "ltr",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "no-options",
                        fn: function ({}) {
                          return [
                            _vm._v(
                              "\n                Desculpe, nenhum resultado encontrado.\n              "
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.cnh.gainful_activity,
                      callback: function ($$v) {
                        _vm.$set(_vm.cnh, "gainful_activity", $$v)
                      },
                      expression: "cnh.gainful_activity",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "Observação" },
                  model: {
                    value: _vm.cnh.observation,
                    callback: function ($$v) {
                      _vm.$set(_vm.cnh, "observation", $$v)
                    },
                    expression: "cnh.observation",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-2/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Cidade"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_city_cnh_id",
                  attrs: {
                    reduce: (option) => option.value,
                    placeholder: _vm.cityCnhOptions.length
                      ? "Selecione"
                      : "Selecione um estado",
                    options: _vm.cityCnhOptions,
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n              Desculpe, nenhum resultado encontrado.\n            "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.cnh.city_cnh_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.cnh, "city_cnh_id", $$v)
                    },
                    expression: "cnh.city_cnh_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Estado"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_city_cnh_id",
                  attrs: {
                    reduce: (option) => option.value,
                    options: _vm.states,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  on: {
                    input: function ($event) {
                      _vm.fillCity(
                        _vm.cnh.state_cnh_id,
                        null,
                        "cityCnhOptions",
                        "cnh"
                      ),
                        delete _vm.cnh.city_cnh_id
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n              Desculpe, nenhum resultado encontrado.\n            "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.cnh.state_cnh_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.cnh, "state_cnh_id", $$v)
                    },
                    expression: "cnh.state_cnh_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Situação"),
                ]),
                _c("v-select", {
                  attrs: {
                    options: _vm.typeSituationOptions,
                    reduce: (option) => option.value,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n              Desculpe, nenhum resultado encontrado.\n            "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.cnh.situation,
                    callback: function ($$v) {
                      _vm.$set(_vm.cnh, "situation", $$v)
                    },
                    expression: "cnh.situation",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "date_format:yyyy-MM-dd",
                      expression: "'date_format:yyyy-MM-dd'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    type: "date",
                    label: "Data Entrega",
                    "data-vv-scope": "newCNH",
                    "data-vv-as": "Data Entrega",
                    name: "delivery_date",
                  },
                  model: {
                    value: _vm.cnh.delivery_date,
                    callback: function ($$v) {
                      _vm.$set(_vm.cnh, "delivery_date", $$v)
                    },
                    expression: "cnh.delivery_date",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("newCNH.delivery_date"),
                        expression: "errors.has('newCNH.delivery_date')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Formato incorreto")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-3/5 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "Responsável Pela Retirada" },
                  model: {
                    value: _vm.cnh.responsible_withdrawal,
                    callback: function ($$v) {
                      _vm.$set(_vm.cnh, "responsible_withdrawal", $$v)
                    },
                    expression: "cnh.responsible_withdrawal",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: ["(##)#####-####", "(##)####-####"],
                      expression: "['(##)#####-####','(##)####-####']",
                    },
                  ],
                  staticClass: "w-full mt-2",
                  attrs: { label: "Telefone" },
                  model: {
                    value: _vm.cnh.responsible_withdrawal_phone,
                    callback: function ($$v) {
                      _vm.$set(_vm.cnh, "responsible_withdrawal_phone", $$v)
                    },
                    expression: "cnh.responsible_withdrawal_phone",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/5 mt-2 mb-16" }, [
              _c(
                "div",
                { staticClass: "mt-5 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mt-0 w-full",
                      attrs: {
                        disabled: !_vm.validateFormNewCNH || _vm.disableCnhSave,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.storeCnh()
                        },
                      },
                    },
                    [_vm._v("Cadastrar CNH")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup80",
          attrs: {
            id: "popupShowTenant",
            fullscreen: false,
            title: _vm.showTenantTitle,
            active: _vm.popupShowTenant,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowTenant = $event
            },
          },
        },
        [
          _c(
            "dialog-sig",
            {
              attrs: {
                show: _vm.popupTenantDeleteConfirm,
                title: "ALERTA",
                icon: "warning",
              },
              on: {
                action: _vm.dispatchDeleteTenant,
                cancel: function ($event) {
                  _vm.popupTenantDeleteConfirm = false
                },
              },
            },
            [
              _vm._v("\n        Tem certeza que deseja excluir o locatário? "),
              _c("br"),
              _vm._v("\n        Esta ação é irreversível\n    "),
            ]
          ),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required|alpha_spaces",
                      expression: "'required|alpha_spaces'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    label: "Nome Locatário",
                    "data-vv-as": "Nome Locatário",
                    "data-vv-scope": "tenant",
                    name: "name",
                  },
                  model: {
                    value: _vm.data_local.tenant.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.data_local.tenant, "name", $$v)
                    },
                    expression: "data_local.tenant.name",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("tenant.name"),
                        expression: "errors.has('tenant.name')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("tenant.name")))]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: ["###.###.###-##", "##.###.###/####-##"],
                      expression: "['###.###.###-##','##.###.###/####-##']",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "cpf",
                      expression: "'cpf'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    label: "CPF/CNPJ",
                    "data-vv-as": "CPF",
                    "data-vv-scope": "tenant",
                    name: "cpf",
                  },
                  on: { keyup: _vm.searchTenant },
                  model: {
                    value: _vm.data_local.tenant.cpf,
                    callback: function ($$v) {
                      _vm.$set(_vm.data_local.tenant, "cpf", $$v)
                    },
                    expression: "data_local.tenant.cpf",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("tenant.cpf"),
                        expression: "errors.has('tenant.cpf')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("tenant.cpf")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "RG/IE" },
                  model: {
                    value: _vm.data_local.tenant.rg,
                    callback: function ($$v) {
                      _vm.$set(_vm.data_local.tenant, "rg", $$v)
                    },
                    expression: "data_local.tenant.rg",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "Órgão Emissor RG" },
                  model: {
                    value: _vm.data_local.tenant.orgao_emissor_rg,
                    callback: function ($$v) {
                      _vm.$set(_vm.data_local.tenant, "orgao_emissor_rg", $$v)
                    },
                    expression: "data_local.tenant.orgao_emissor_rg",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Estado RG"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_orgao_emissor_rg_uf_id",
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: true,
                    options: _vm.states,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.data_local.tenant.orgao_emissor_rg_uf_id,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.data_local.tenant,
                        "orgao_emissor_rg_uf_id",
                        $$v
                      )
                    },
                    expression: "data_local.tenant.orgao_emissor_rg_uf_id",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "MapPinIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Endereço"),
              ]),
            ],
            1
          ),
          _c("hr"),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: ["#####-###"],
                      expression: "['#####-###']",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { label: "CEP", placeholder: "CEP" },
                  on: { keyup: _vm.searchZipCode },
                  model: {
                    value: _vm.data_local.tenant.address.zip_code,
                    callback: function ($$v) {
                      _vm.$set(_vm.data_local.tenant.address, "zip_code", $$v)
                    },
                    expression: "data_local.tenant.address.zip_code",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Logradouro"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: "",
                    options: _vm.logradouroOptions,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.data_local.tenant.address.logradouro,
                    callback: function ($$v) {
                      _vm.$set(_vm.data_local.tenant.address, "logradouro", $$v)
                    },
                    expression: "data_local.tenant.address.logradouro",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "Endereço" },
                  model: {
                    value: _vm.data_local.tenant.address.street,
                    callback: function ($$v) {
                      _vm.$set(_vm.data_local.tenant.address, "street", $$v)
                    },
                    expression: "data_local.tenant.address.street",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    label: "Número",
                    type: "number",
                    placeholder: "S/N",
                  },
                  model: {
                    value: _vm.data_local.tenant.address.number,
                    callback: function ($$v) {
                      _vm.$set(_vm.data_local.tenant.address, "number", $$v)
                    },
                    expression: "data_local.tenant.address.number",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: { label: "Complemento" },
                  model: {
                    value: _vm.data_local.tenant.address.complement,
                    callback: function ($$v) {
                      _vm.$set(_vm.data_local.tenant.address, "complement", $$v)
                    },
                    expression: "data_local.tenant.address.complement",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: { label: "Bairro" },
                  model: {
                    value: _vm.data_local.tenant.address.neighborhood,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.data_local.tenant.address,
                        "neighborhood",
                        $$v
                      )
                    },
                    expression: "data_local.tenant.address.neighborhood",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4_ w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Cidade"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_tenant_city_id",
                  attrs: {
                    reduce: (option) => option.value,
                    placeholder: _vm.cityTenantOptions.length
                      ? "Selecione"
                      : "Selecione um estado",
                    options: _vm.cityTenantOptions,
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.data_local.tenant.address.city_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.data_local.tenant.address, "city_id", $$v)
                    },
                    expression: "data_local.tenant.address.city_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Estado"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_tenant_state_id",
                  attrs: {
                    reduce: (option) => option.value,
                    options: _vm.states,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  on: {
                    input: function ($event) {
                      _vm.fillCity(
                        _vm.data_local.tenant.address.state_id,
                        null,
                        "cityTenantOptions",
                        "tenant"
                      ),
                        (_vm.data_local.tenant.address.city_id = null)
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.data_local.tenant.address.state_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.data_local.tenant.address, "state_id", $$v)
                    },
                    expression: "data_local.tenant.address.state_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("País"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: _vm.countryOptions,
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.data_local.tenant.address.country,
                    callback: function ($$v) {
                      _vm.$set(_vm.data_local.tenant.address, "country", $$v)
                    },
                    expression: "data_local.tenant.address.country",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "PhoneIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Contato"),
              ]),
            ],
            1
          ),
          _c("hr"),
          _c(
            "div",
            { staticClass: "vx-row" },
            [
              _vm._l(_vm.data_local.tenant.phones, function (phone, i) {
                return _c(
                  "div",
                  { key: i, staticClass: "vx-col md:w-1/5 w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["(##)#####-####", "(##)####-####"],
                          expression: "['(##)#####-####','(##)####-####']",
                        },
                      ],
                      staticClass: "w-full mt-2",
                      attrs: {
                        label:
                          phone.type === "phone"
                            ? "Telefone"
                            : phone.type === "mobile"
                            ? "Celular"
                            : phone.type === "other"
                            ? "Outro"
                            : "",
                      },
                      model: {
                        value: phone.phone,
                        callback: function ($$v) {
                          _vm.$set(phone, "phone", $$v)
                        },
                        expression: "phone.phone",
                      },
                    }),
                  ],
                  1
                )
              }),
              _c(
                "div",
                { staticClass: "vx-col md:w-2/5 w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "email",
                        expression: "'email'",
                      },
                    ],
                    staticClass: "w-full mt-2",
                    attrs: {
                      label: "E-mail",
                      "data-vv-as": "E-mail",
                      "data-vv-scope": "tenant",
                      type: "email",
                      name: "email",
                    },
                    model: {
                      value: _vm.data_local.tenant.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.data_local.tenant, "email", $$v)
                      },
                      expression: "data_local.tenant.email",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("tenant.email"),
                          expression: "errors.has('tenant.email')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("tenant.email")))]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-8 flex flex-wrap items-center justify-end" },
                [
                  _vm.data_local.tenant.id
                    ? _c(
                        "vs-button",
                        {
                          staticClass: "ml-auto mt-2",
                          attrs: { color: "danger" },
                          on: { click: _vm.deleteTenant },
                        },
                        [_vm._v("Deletar Locatário")]
                      )
                    : _vm._e(),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4 mt-2",
                      attrs: {
                        disabled:
                          !_vm.validateTenantForm || _vm.disableTenantSave,
                      },
                      on: { click: _vm.saveTenant },
                    },
                    [_vm._v("Salvar Locatário")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full mt-0" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v("Estado de Nascimento"),
            ]),
            _c("v-select", {
              attrs: {
                reduce: (option) => option.value,
                options: _vm.states,
                placeholder: "Selecione",
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              on: {
                input: function ($event) {
                  _vm.fillCity(_vm.data_local.state_nasc_id),
                    (_vm.data_local.city_nasc_id = null)
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n          Desculpe, nenhum resultado encontrado.\n        "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.data_local.state_nasc_id,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "state_nasc_id", $$v)
                },
                expression: "data_local.state_nasc_id",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full mt-0" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v("Cidade de Nascimento"),
            ]),
            _c("v-select", {
              attrs: {
                reduce: (option) => option.value,
                placeholder: _vm.cityOptions.length
                  ? "Selecione"
                  : "Selecione um estado",
                options: _vm.cityOptions,
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n          Desculpe, nenhum resultado encontrado.\n        "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.data_local.city_nasc_id,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "city_nasc_id", $$v)
                },
                expression: "data_local.city_nasc_id",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full mt-0" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v("Nacionalidade"),
            ]),
            _c("v-select", {
              attrs: {
                reduce: (option) => option.value,
                clearable: true,
                options: _vm.nationalityOptions,
                placeholder: "Selecione",
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n          Desculpe, nenhum resultado encontrado.\n        "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.data_local.nationality,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "nationality", $$v)
                },
                expression: "data_local.nationality",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full mt-0" },
          [
            _c("vs-input", {
              staticClass: "w-full",
              attrs: { label: "Órgão Emissor RG" },
              model: {
                value: _vm.data_local.orgao_emissor_rg,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "orgao_emissor_rg", $$v)
                },
                expression: "data_local.orgao_emissor_rg",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/5 w-full mt-0" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v("Estado RG"),
            ]),
            _c("v-select", {
              attrs: {
                reduce: (option) => option.value,
                options: _vm.states,
                placeholder: "Selecione",
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n          Desculpe, nenhum resultado encontrado.\n        "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.data_local.orgao_emissor_rg_uf_id,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "orgao_emissor_rg_uf_id", $$v)
                },
                expression: "data_local.orgao_emissor_rg_uf_id",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-1/4 w-full mt-2" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v("Escolaridade"),
            ]),
            _c("v-select", {
              attrs: {
                reduce: (option) => option.value,
                options: _vm.educationOptions,
                placeholder: "Selecione",
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n          Desculpe, nenhum resultado encontrado.\n        "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.data_local.education,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "education", $$v)
                },
                expression: "data_local.education",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/4 w-full mt-2" },
          [
            _c("vs-input", {
              staticClass: "w-full",
              attrs: { label: "Profissão" },
              model: {
                value: _vm.data_local.occupation,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "occupation", $$v)
                },
                expression: "data_local.occupation",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/4 w-full mt-2" },
          [
            _c("vs-input", {
              staticClass: "w-full",
              attrs: { label: "Local de Trabalho" },
              model: {
                value: _vm.data_local.workplace,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "workplace", $$v)
                },
                expression: "data_local.workplace",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/4 w-full mt-2" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v("Estado Civil"),
            ]),
            _c("v-select", {
              attrs: {
                appendToBody: "",
                reduce: (option) => option.value,
                options: _vm.maritalStatusOptions,
                placeholder: "Selecione",
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n          Desculpe, nenhum resultado encontrado.\n        "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.data_local.marital_status,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "marital_status", $$v)
                },
                expression: "data_local.marital_status",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "flex items-end mt-2" },
        [
          _c("feather-icon", {
            staticClass: "mr-2",
            attrs: { icon: "UserIcon", svgClasses: "w-5 h-5" },
          }),
          _c("span", { staticClass: "leading-none font-medium" }, [
            _vm._v("Filiação"),
          ]),
        ],
        1
      ),
      _c("hr"),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-1/2 w-full mt-2" },
          [
            _c("vs-input", {
              staticClass: "w-full",
              attrs: { label: "Nome Mãe" },
              model: {
                value: _vm.data_local.mothers_name,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "mothers_name", $$v)
                },
                expression: "data_local.mothers_name",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/2 w-full mt-2" },
          [
            _c("vs-input", {
              staticClass: "w-full",
              attrs: { label: "Nome Pai" },
              model: {
                value: _vm.data_local.fathers_name,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "fathers_name", $$v)
                },
                expression: "data_local.fathers_name",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "flex items-end mt-2" },
        [
          _c("feather-icon", {
            staticClass: "mr-2",
            attrs: { icon: "UserIcon", svgClasses: "w-5 h-5" },
          }),
          _c("span", { staticClass: "leading-none font-medium" }, [
            _vm._v("Locatário"),
          ]),
        ],
        1
      ),
      _c("hr"),
      _c("div", { staticClass: "vx-row flex flex-wrap items-center" }, [
        _c("div", { staticClass: "vx-col md:w-1/4 w-full mt-0" }, [
          _c("span", [
            _vm._v(
              _vm._s(
                this.$store.state.studentManagement.student.tenant
                  ? this.$store.state.studentManagement.student.tenant.name
                  : ""
              )
            ),
          ]),
        ]),
        _c("div", { staticClass: "vx-col md:w-1/4 w-full mt-0" }, [
          _c("span", [
            _vm._v(
              _vm._s(
                this.$store.state.studentManagement.student.tenant &&
                  this.$store.state.studentManagement.student.tenant.phones &&
                  this.$store.state.studentManagement.student.tenant.phones.find(
                    (phone) => phone.type === "mobile"
                  )
                  ? this.$store.state.studentManagement.student.tenant.phones.find(
                      (phone) => phone.type === "mobile"
                    ).phone
                  : ""
              )
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/2 w-full mt-0" },
          [
            _c(
              "vs-button",
              {
                staticClass: "ml-auto sizedIcon",
                attrs: {
                  type: "line",
                  icon: _vm.data_local.tenant.id
                    ? "create"
                    : "add_circle_outline",
                },
                on: { click: _vm.handleTenant },
              },
              [
                _vm._v(
                  _vm._s(_vm.data_local.tenant.id ? "Alterar" : "Cadastrar") +
                    " Locatário"
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "flex items-end mt-2" },
        [
          _c("feather-icon", {
            staticClass: "mr-2",
            attrs: { icon: "FolderIcon", svgClasses: "w-5 h-5" },
          }),
          _c("span", { staticClass: "leading-none font-medium" }, [
            _vm._v("Cadastro CNH"),
          ]),
        ],
        1
      ),
      _c("hr"),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full mt-2" },
          [
            _c(
              "vs-button",
              {
                staticClass: "ml-auto sizedIcon",
                attrs: { type: "line", icon: "add_circle_outline" },
                on: { click: _vm.handleCnh },
              },
              [_vm._v("Cadastrar Nova CNH")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "vs-collapse",
        { attrs: { accordion: "", type: "margin" } },
        _vm._l(_vm.data_local.cnhs, function (cnh, i) {
          return _c(
            "vs-collapse-item",
            { key: `${i}_cnh`, attrs: { open: i === 0 } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v(
                  "CNH Registro: " +
                    _vm._s(cnh.registro_cnh) +
                    " " +
                    _vm._s(
                      cnh.date_last_cnh
                        ? `| Emitida em: ${_vm.formatDateTime(
                            cnh.date_last_cnh,
                            "date"
                          )}`
                        : ""
                    )
                ),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "Registro" },
                      model: {
                        value: cnh.registro_cnh,
                        callback: function ($$v) {
                          _vm.$set(cnh, "registro_cnh", $$v)
                        },
                        expression: "cnh.registro_cnh",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "Cód. Segurança" },
                      model: {
                        value: cnh.security_code,
                        callback: function ($$v) {
                          _vm.$set(cnh, "security_code", $$v)
                        },
                        expression: "cnh.security_code",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { type: "date", label: "Emissão" },
                      on: {
                        change: function ($event) {
                          return _vm.autoSetValidityCnhArray(i)
                        },
                      },
                      model: {
                        value: cnh.date_last_cnh,
                        callback: function ($$v) {
                          _vm.$set(cnh, "date_last_cnh", $$v)
                        },
                        expression: "cnh.date_last_cnh",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { type: "date", label: "Vencimento" },
                      model: {
                        value: cnh.validity_cnh,
                        callback: function ($$v) {
                          _vm.$set(cnh, "validity_cnh", $$v)
                        },
                        expression: "cnh.validity_cnh",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Categoria"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        appendToBody: "",
                        options: _vm.categoryCNHOptions,
                        reduce: (option) => option.value,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "no-options",
                            fn: function ({}) {
                              return [
                                _vm._v(
                                  "\n                Desculpe, nenhum resultado encontrado.\n              "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: cnh.current_category_cnh,
                        callback: function ($$v) {
                          _vm.$set(cnh, "current_category_cnh", $$v)
                        },
                        expression: "cnh.current_category_cnh",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Tipo"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        appendToBody: "",
                        options: _vm.typeCNHOptions,
                        reduce: (option) => option.value,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "no-options",
                            fn: function ({}) {
                              return [
                                _vm._v(
                                  "\n                Desculpe, nenhum resultado encontrado.\n              "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: cnh.type_cnh,
                        callback: function ($$v) {
                          _vm.$set(cnh, "type_cnh", $$v)
                        },
                        expression: "cnh.type_cnh",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Cursos"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        appendToBody: "",
                        options: _vm.specializedCoursesCNHOptions,
                        reduce: (option) => option.value,
                        placeholder: "Selecione",
                        multiple: "",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "no-options",
                            fn: function ({}) {
                              return [
                                _vm._v(
                                  "\n                Desculpe, nenhum resultado encontrado.\n              "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: cnh.specialized_courses,
                        callback: function ($$v) {
                          _vm.$set(cnh, "specialized_courses", $$v)
                        },
                        expression: "cnh.specialized_courses",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { type: "date", label: "Toxicológico" },
                      on: {
                        change: function ($event) {
                          return _vm.autoSetValidityToxicologicalArray(i)
                        },
                      },
                      model: {
                        value: cnh.toxicological,
                        callback: function ($$v) {
                          _vm.$set(cnh, "toxicological", $$v)
                        },
                        expression: "cnh.toxicological",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { type: "date", label: "Venc. Toxicológico" },
                      model: {
                        value: cnh.validity_toxicological,
                        callback: function ($$v) {
                          _vm.$set(cnh, "validity_toxicological", $$v)
                        },
                        expression: "cnh.validity_toxicological",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-2" }, [
                  _c(
                    "div",
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v("EAR"),
                      ]),
                      _c("v-select", {
                        attrs: {
                          appendToBody: "",
                          clearable: false,
                          reduce: (option) => option.value,
                          options: [
                            { value: 0, label: "NÃO" },
                            { value: 1, label: "SIM" },
                          ],
                          placeholder: "Selecione",
                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                        },
                        on: {
                          input: function ($event) {
                            _vm.fillCityCnh(cnh.state_cnh_id, null, i),
                              (cnh.city_cnh_id = null)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "no-options",
                              fn: function ({}) {
                                return [
                                  _vm._v(
                                    "\n                  Desculpe, nenhum resultado encontrado.\n                "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: cnh.gainful_activity,
                          callback: function ($$v) {
                            _vm.$set(cnh, "gainful_activity", $$v)
                          },
                          expression: "cnh.gainful_activity",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "Observação" },
                      model: {
                        value: cnh.observation,
                        callback: function ($$v) {
                          _vm.$set(cnh, "observation", $$v)
                        },
                        expression: "cnh.observation",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-2/5 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Cidade"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        appendToBody: "",
                        reduce: (option) => option.value,
                        placeholder: cnh.cityCnhOptions
                          ? "Selecione"
                          : "Selecione um estado",
                        options: cnh.cityCnhOptions,
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "no-options",
                            fn: function ({}) {
                              return [
                                _vm._v(
                                  "\n                Desculpe, nenhum resultado encontrado.\n              "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: cnh.city_cnh_id,
                        callback: function ($$v) {
                          _vm.$set(cnh, "city_cnh_id", $$v)
                        },
                        expression: "cnh.city_cnh_id",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Estado"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        appendToBody: "",
                        reduce: (option) => option.value,
                        options: _vm.states,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      on: {
                        input: function ($event) {
                          _vm.fillCityCnh(cnh.state_cnh_id, null, i),
                            (cnh.city_cnh_id = null)
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "no-options",
                            fn: function ({}) {
                              return [
                                _vm._v(
                                  "\n                Desculpe, nenhum resultado encontrado.\n              "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: cnh.state_cnh_id,
                        callback: function ($$v) {
                          _vm.$set(cnh, "state_cnh_id", $$v)
                        },
                        expression: "cnh.state_cnh_id",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Situação"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        appendToBody: "",
                        options: _vm.typeSituationOptions,
                        reduce: (option) => option.value,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "no-options",
                            fn: function ({}) {
                              return [
                                _vm._v(
                                  "\n                Desculpe, nenhum resultado encontrado.\n              "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: cnh.situation,
                        callback: function ($$v) {
                          _vm.$set(cnh, "situation", $$v)
                        },
                        expression: "cnh.situation",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { type: "date", label: "Data Entrega" },
                      model: {
                        value: cnh.delivery_date,
                        callback: function ($$v) {
                          _vm.$set(cnh, "delivery_date", $$v)
                        },
                        expression: "cnh.delivery_date",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-2/5 w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "Responsável Pela Retirada" },
                      model: {
                        value: cnh.responsible_withdrawal,
                        callback: function ($$v) {
                          _vm.$set(cnh, "responsible_withdrawal", $$v)
                        },
                        expression: "cnh.responsible_withdrawal",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["(##)#####-####", "(##)####-####"],
                          expression: "['(##)#####-####','(##)####-####']",
                        },
                      ],
                      staticClass: "w-full mt-2",
                      attrs: { label: "Telefone" },
                      model: {
                        value: cnh.responsible_withdrawal_phone,
                        callback: function ($$v) {
                          _vm.$set(cnh, "responsible_withdrawal_phone", $$v)
                        },
                        expression: "cnh.responsible_withdrawal_phone",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "vx-col md:w-1/5 mt-2 mb-16" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-5 flex flex-wrap items-center justify-end",
                    },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "mt-0",
                          attrs: { disabled: _vm.disableCnhSave },
                          on: {
                            click: function ($event) {
                              return _vm.updateCnh(cnh)
                            },
                          },
                        },
                        [_vm._v("Alterar Esta CNH")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "vx-col md:w-1/5 mt-2 mb-16" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-5 flex flex-wrap items-center justify-end",
                    },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "mt-0",
                          attrs: {
                            color: "danger",
                            disabled: !_vm.validateForm,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteCnh(cnh)
                            },
                          },
                        },
                        [_vm._v("Excluir Esta CNH")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          )
        }),
        1
      ),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "div",
            { staticClass: "mt-8 flex flex-wrap items-center justify-end" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "ml-auto mt-2",
                  attrs: { disabled: !_vm.validateForm || _vm.disableSave },
                  on: { click: _vm.saveChanges },
                },
                [_vm._v("Salvar Alterações Gerais")]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "ml-4 mt-2",
                  attrs: { type: "border", color: "warning" },
                  on: { click: _vm.resetLocalData },
                },
                [_vm._v("Resetar")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }