import state from './moduleGuarantorState.js'
import mutations from './moduleGuarantorMutations.js'
import actions from './moduleGuarantorActions.js'
import getters from './moduleGuarantorGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

