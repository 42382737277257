<template>
  <vs-tabs alignment="right" v-model="activeTab">
    <vs-tab label="Débitos Abertos">
      <div class="vx-row flex items-end">
        <div class="vx-col md:w-1/5">
          <sig-switch v-model="applyInterest" label="Aplicar juros" />
        </div>
        <div class="vx-col md:w-1/5">
          <sig-input-currency
            v-model="totalValue"
            label="Valor original"
            disabled
          />
        </div>
        <div class="vx-col md:w-1/5">
          <sig-input-currency
            v-model="adjustedValue"
            :label="
              applyInterest ? 'Valor a pagar (com juros)' : 'Valor a pagar'
            "
            disabled
          />
        </div>
      </div>
      <div>Selecione uma ou mais parcelas</div>
      <vs-table multiple v-model="oldTransactions" :data="transactions">
        <template slot="thead">
          <vs-th> Código </vs-th>
          <vs-th> Vencimento </vs-th>
          <vs-th> Descrição </vs-th>
          <vs-th> Valor Pagar </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            :data="transaction"
            :key="idx"
            v-for="(transaction, idx) in data"
          >
            <vs-td :data="transaction.id">
              {{ transaction.id }}
            </vs-td>

            <vs-td :data="transaction.expiration_date">
              {{ mixinConvertDateStringUsToBr(transaction.expiration_date) }}
            </vs-td>

            <vs-td :data="transaction.observation">
              {{ transaction.observation }}
            </vs-td>

            <vs-td :data="transaction.value_updated">
              {{ mixinCurrency(transaction.value_updated) }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-divider />
      <div class="flex flex-row-reverse">
        <vs-button
          color="primary"
          type="filled"
          :disabled="!oldTransactions.length"
          @click="doNewTransaction"
          >CONTINUAR</vs-button
        >
      </div>
    </vs-tab>
    <vs-tab label="Nova Parcela" :disabled="!activeTab">
      <div>
        <transaction-credit
          v-model="newTransactions"
          :student="student"
          :data="tplTransaction"
          @saved="$emit('saved')"
        />
      </div>
    </vs-tab>
    <div v-if="newTransactions.length">
      <vs-divider />
      <div class="flex flex-row-reverse">
        <vs-button color="primary" type="filled" @click="save"
          >Salvar</vs-button
        >
         <vs-button color="warning" type="filled" @click="newTransactions = []" class="mr-2"
          >Limpar</vs-button
        >
      </div>
    </div>
  </vs-tabs>
</template>

<script>
import TransactionCredit from '@/components/cashier/transaction/TransactionCredit'
import axios from '@/axios'
export default {
  components: {
    TransactionCredit
  },
  props: {
    transactions: {
      default: []
    },
    student: {}
  },
  data () {
    return {
      oldTransactions: [], // lista de parcelas selecionadas
      newTransactions: [], // novas parcelas geradas
      tplTransaction: {},
      activeTab: 1,
      applyInterest: true
    }
  },
  computed: {
    transactionsToCancel () {
      return this.oldTransactions.map((e) => e.id)
    },
    totalValue: {
      get () {
        let value = 0
        if (this.oldTransactions.length) value = this.oldTransactions.reduce((value, transaction) => {
          return value + transaction.value_parcel
        }, value)
        return value
      },
      set () {}
    },
    adjustedValue () {
      let value = 0
      if (this.oldTransactions.length) {
        if (this.applyInterest) {
          value = this.oldTransactions.reduce((value, transaction) => {
            return value + transaction.value_updated
          }, value)
        } else {
          value = this.oldTransactions.reduce((value, transaction) => {
            return value + transaction.value_parcel
          }, value)
        }
      }
      return value
    }
  },
  methods: {
    // vou montar os dados para a nova transacao
    doNewTransaction () {
      this.tplTransaction = {
        ...this.tplTransaction,
        ...this.oldTransactions[0]
      }
      this.tplTransaction.value_total = 0
      this.tplTransaction.servicePackages = []
      this.tplTransaction.observation = 'Reparcelamento ref '

      for (const transaction of this.oldTransactions) {
        this.tplTransaction.observation += `${transaction.id}, `
        // o valor passado pode ser com ou sem juros
        if (this.applyInterest) this.tplTransaction.value_total += transaction.value_updated
        else this.tplTransaction.value_total += transaction.value_parcel
        this.tplTransaction.servicePackages =
          this.tplTransaction.servicePackages.concat(
            transaction.servicePackages
          )
      }
      //
      this.activeTab = 2
    },
    save () {
      const payload = {
        student_id: this.student.id,
        observation: this.newTransactions[0].observation,
        oldTransactions: this.oldTransactions.map(e => e.id),
        newTransactions: this.newTransactions
      }

      this.$vs.loading()

      axios.post(`${process.env.VUE_APP_API_URL}/financial/debtNegociation`, payload)
        .then(() => {
          this.mixinNotify('Operação executada com sucesso')
          this.$emit('saved')
        })
        .catch(this.mixinCatch)
        .finally(this.$vs.loading.close)
    },
    reset () {
      this.oldTransactions = []
      this.newTransactions = []
      this.activeTab = 0
    }
  }
}
</script>
