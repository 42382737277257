var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "email__email-sidebar h-full" },
    [
      _c(
        _vm.scrollbarTag,
        {
          key: _vm.$vs.rtl,
          tag: "component",
          staticClass: "email-filter-scroll-area",
          attrs: { settings: _vm.settings },
        },
        [
          _c("div", { staticClass: "mt-2" }, [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v("Tipo de calendário"),
            ]),
          ]),
          _c("v-select", {
            staticClass: "mt-0 mb-1 ml-1 mr-1",
            attrs: {
              reduce: (option) => option.value,
              clearable: false,
              options: [
                { label: "Por Período", value: "range" },
                { label: "Por Semana", value: "week" },
                { label: "Por Dia", value: "calendar" },
              ],
              placeholder: "Selecione",
              dir: _vm.$vs.rtl ? "rtl" : "ltr",
            },
            on: { input: _vm.clearCalendar },
            scopedSlots: _vm._u([
              {
                key: "no-options",
                fn: function ({}) {
                  return [
                    _vm._v("\n        Nenhum resultado encontrado.\n      "),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.calendarType,
              callback: function ($$v) {
                _vm.calendarType = $$v
              },
              expression: "calendarType",
            },
          }),
          _c("label", { staticClass: "vs-input--label" }, [_vm._v("Turnos")]),
          _c("v-select", {
            staticClass: "mb-4 ml-1 mr-1",
            attrs: {
              multiple: "",
              reduce: (option) => option.value,
              clearable: false,
              options: [
                { label: "Matutino", value: 0 },
                { label: "Vespertino", value: 1 },
                { label: "Noturno", value: 2 },
              ],
              placeholder: "Selecione",
              dir: _vm.$vs.rtl ? "rtl" : "ltr",
            },
            scopedSlots: _vm._u([
              {
                key: "no-options",
                fn: function ({}) {
                  return [
                    _vm._v("\n        Nenhum resultado encontrado.\n      "),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.periods,
              callback: function ($$v) {
                _vm.periods = $$v
              },
              expression: "periods",
            },
          }),
          _c("vs-divider"),
          _vm.calendarType === "calendar"
            ? _c("vc-calendar", {
                staticClass: "ml-1 mb-0 flex flex-col",
                attrs: {
                  "disabled-dates": { weekdays: [1] },
                  attributes: _vm.attributes,
                },
                on: { dayclick: _vm.onDayClick },
              })
            : _vm.calendarType === "range"
            ? _c("vc-date-picker", {
                staticClass: "ml-1 mb-0 flex flex-col",
                attrs: { "is-range": "" },
                model: {
                  value: _vm.dateRange,
                  callback: function ($$v) {
                    _vm.dateRange = $$v
                  },
                  expression: "dateRange",
                },
              })
            : _vm._e(),
          _vm.calendarType === "week"
            ? _c("vc-calendar", {
                staticClass: "ml-1 mb-0 flex flex-col",
                attrs: {
                  "disabled-dates": { weekdays: [1] },
                  attributes: _vm.attributes,
                },
                on: { dayclick: _vm.onDayClickWeek },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "mt-1 ml-6 mr-6 mb-0 clearfix" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "w-full",
                  attrs: {
                    type: "line",
                    "icon-pack": "feather",
                    icon: "icon-x",
                  },
                  on: { click: _vm.clearCalendar },
                },
                [_vm._v("Limpar Calendário")]
              ),
            ],
            1
          ),
          _c("vs-divider"),
          _c("div", { staticClass: "px-4 py-0 pb-0 flex flex-col" }, [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col flex items-center w-full" },
                [
                  _c(
                    "vs-dropdown",
                    {
                      staticClass: "cursor-pointer hover:text-primary",
                      attrs: {
                        "vs-custom-content": "",
                        "vs-trigger-click": "",
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "flex cursor-pointer",
                          attrs: { "href.prevent": "" },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "p-2 rounded-full",
                            class: [`text-primary`, "mb-0"],
                            style: {
                              background: `rgba(var(--vs-success),.15)`,
                            },
                            attrs: {
                              icon: "CalendarIcon",
                              svgClasses: "h-6 w-6",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-dropdown-menu",
                        {
                          staticClass: "w-60",
                          staticStyle: { "z-index": "200001" },
                        },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: { type: "date", label: "Data Inicial" },
                            on: { change: _vm.debouncedFilterDate },
                            model: {
                              value: _vm.firstPeriod,
                              callback: function ($$v) {
                                _vm.firstPeriod = $$v
                              },
                              expression: "firstPeriod",
                            },
                          }),
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: { type: "date", label: "Data Final" },
                            on: { change: _vm.debouncedFilterDate },
                            model: {
                              value: _vm.lastPeriod,
                              callback: function ($$v) {
                                _vm.lastPeriod = $$v
                              },
                              expression: "lastPeriod",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.periodError,
                                  expression: "periodError",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v("O período inicial é maior que o final.")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("span", { staticClass: "mb-0 ml-2" }, [
                    _vm._v("Intervalo de Datas"),
                  ]),
                ],
                1
              ),
            ]),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "email__labels px-4 py-0" }, [
            _c("h5", { staticClass: "mb-1" }, [_vm._v("Legenda")]),
            _c("div", { staticClass: "email__lables-list" }, [
              _c(
                "span",
                {
                  staticClass:
                    "email__label flex items-center mb-2 cursor-default",
                },
                [
                  _c("div", {
                    staticClass:
                      "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-secondary",
                    staticStyle: { opacity: "0.3" },
                  }),
                  _c("span", { staticClass: "text-lg" }, [_vm._v("Livre")]),
                ]
              ),
              _c(
                "span",
                {
                  staticClass:
                    "email__label flex items-center mb-2 cursor-pointer",
                },
                [
                  _c("div", {
                    staticClass:
                      "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-primary bg-primary",
                    staticStyle: { opacity: "0.3" },
                  }),
                  _c("span", { staticClass: "text-lg" }, [
                    _vm._v("Agenda do Aluno"),
                  ]),
                ]
              ),
              _c(
                "span",
                {
                  staticClass:
                    "email__label flex items-center mb-2 cursor-pointer",
                },
                [
                  _c("div", {
                    staticClass:
                      "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-warning bg-warning",
                    staticStyle: { opacity: "0.3" },
                  }),
                  _c("span", { staticClass: "text-lg" }, [
                    _vm._v("Instrutor Ocupado"),
                  ]),
                ]
              ),
              _c(
                "span",
                {
                  staticClass:
                    "email__label flex items-center mb-2 cursor-pointer",
                },
                [
                  _c("div", {
                    staticClass:
                      "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-danger bg-danger",
                    staticStyle: { opacity: "0.3" },
                  }),
                  _c("span", { staticClass: "text-lg" }, [
                    _vm._v("Veículo Ocupado"),
                  ]),
                ]
              ),
              _c(
                "span",
                {
                  staticClass:
                    "email__label flex items-center mb-2 cursor-pointer",
                },
                [
                  _c("div", {
                    staticClass:
                      "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-secondary bg-secondary",
                    staticStyle: { opacity: "0.3" },
                  }),
                  _c("span", { staticClass: "text-lg" }, [
                    _vm._v("Indisponível"),
                  ]),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }