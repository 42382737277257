export default {
  transactions: {
    data: [],
    total: 0
  },
  transaction: {},
  transactionsGrid: {
    data: [],
    total: 0
  }
}
