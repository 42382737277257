var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.params.data
    ? _c("div", { staticClass: "flex items-center" }, [
        _vm._v(
          "\n  " +
            _vm._s(_vm.date) +
            " " +
            _vm._s(_vm.start_time) +
            " - " +
            _vm._s(_vm.end_time) +
            "\n"
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }