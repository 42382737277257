<template>
  <div class="flex items-center">
      <vx-tooltip class="flex" text="Último Contrato" position="top" delay=".3s">
        <feather-icon v-if="params.data && params.data.isLast" icon="StarIcon" class="h-5 w-5 mr-1" :svgClasses="['stoke-current text-warning']" />
      </vx-tooltip>
      <!-- {{service}} -->
      <span v-for="(servicePackage, i) in servicePackages" :key="i">
        <!-- eslint-disable-next-line vue/no-parsing-error -->
        {{ ((i > 0) && (i < servicePackages.length)) ? '&nbsp;|&nbsp; ' : '' }} {{servicePackage}}
      </span>
  </div>
</template>

<script>
export default {
  name: 'CellRendererServices',

  computed: {
    // service () {
    //   return this.params.value ? this.params.value.service : ''
    // },
    servicePackages () {
      if (this.params.data) {
        return this.params.data.servicePackages ? this.params.data.servicePackages.map((sp) => `${sp.name} ${sp.category_cnh || ''}`) : []
      }
      return []
    }
  }
}
</script>
