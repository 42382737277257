<template>
  <div>
    <popup-pdf ref="popupPdf" />
    <sig-data-list
      :model="model"
      ref="list"
      @search-end="$emit('search-end')"
      @grid-ready="$emit('grid-ready')"
      @saved="search(false)"
      @register="register"
      @editing="register"
      :fetch="params"
      :allow-search="allowSearch"
    >
      <template v-slot:form>
        <sig-popup v-model="popup" ref="popup">
            <observation-student-form  :student-id="studentId" @saved="$refs.popup.isOpen = false" ref="form" />
        </sig-popup>
      </template>
      <template v-slot:action>
        <div class="ml-2">
            <vx-tooltip text="Relatório PDF" position="top">
                <svg
                  @click="report"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  class="svg-hover h-8 w-8 cursor-pointer"
                  aria-hidden="true"
                  focusable="false"
                  width="0.86em"
                  height="1em"
                  style="
                    -ms-transform: rotate(360deg);
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                  "
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 1536 1792"
                >
                  <path
                    d="M1468 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28H96q-40 0-68-28t-28-68V96q0-40 28-68T96 0h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528V640H992q-40 0-68-28t-28-68V128H128v1536h1280zm-514-593q33 26 84 56q59-7 117-7q147 0 177 49q16 22 2 52q0 1-1 2l-2 2v1q-6 38-71 38q-48 0-115-20t-130-53q-221 24-392 83q-153 262-242 262q-15 0-28-7l-24-12q-1-1-6-5q-10-10-6-36q9-40 56-91.5t132-96.5q14-9 23 6q2 2 2 4q52-85 107-197q68-136 104-262q-24-82-30.5-159.5T657 552q11-40 42-40h22q23 0 35 15q18 21 9 68q-2 6-4 8q1 3 1 8v30q-2 123-14 192q55 164 146 238zm-576 411q52-24 137-158q-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132q1-7 7-44q0-3 7-43q1-4 4-8q-1-1-1-2q-1-2-1-3q-1-22-13-36q0 1-1 2v2zm-124 661q135-54 284-81q-2-1-13-9.5t-16-13.5q-76-67-127-176q-27 86-83 197q-30 56-45 83zm646-16q-24-24-140-24q76 28 124 28q14 0 18-1q0-1-2-3z"
                  />
                </svg>
              </vx-tooltip>
        </div>
      </template>
    </sig-data-list>
  </div>
</template>

<script>
import SigDataList from '@/components/sig/SigDataList'
import SigPopup from '@/components/sig/SigPopup'
import ObservationStudentForm from './ObservationStudentForm.vue'
import model from '@/store/register/observationStudent/model'
import PopupPdf from '@/components/report/pdf/PopupPdf'

export default {
  components: {
    SigDataList,
    SigPopup,
    ObservationStudentForm,
    PopupPdf
  },
  // indexSelect indice do state.selected
  props: ['indexSelect', 'nameSelect', 'studentId'], // allowSearch - Permitir busca automatica no grid
  data () {
    return {
      model,
      popup: {
        title: 'Observação'
      },
      allowSearch: true,
      params: {
        dispatch: model.$store.dispatch.fetchStudent,
        payload: {
          student_id: this.studentId
        }
      }
    }
  },
  methods: {
    report () {
      this.$refs.popupPdf.get(`${process.env.VUE_APP_API_URL}/report/student/${this.studentId}/observation`)
    },
    register (data) {
      if (data) {
        this.$refs.form.id = data.data.id
        this.$refs.form.observation = data.data.observation
      } else {
        this.$refs.form.id = null
        this.$refs.form.observation = ''
      }
      this.$refs.popup.isOpen = true
    }
  }
}
</script>
