<template>
  <div>
    <div class="vx-row">
      <sig-input v-model="studentDocument.service_package" label="Serviço" class="vx-col md:w-1/1" disabled />
      <sig-input v-model="studentDocument.situation" label="Situação" class="vx-col md:w-1/1" />
      <sig-input v-model="studentDocument.response" label="Retorno Detran" class="vx-col md:w-1/1" />
      <sig-input v-model="studentDocument.observation" label="Observação" class="vx-col md:w-1/1" />
    </div>
    <!-- <vs-divider />
    <div class="flex flex-row-reverse">
      <vs-button>Salvar</vs-button>
    </div> -->
  </div>
</template>

<script>
import SigInput from '@/components/sig/SigInput'
import model from '@/store/register/studentDocument/model'

export default {
  components: {
    SigInput
  },
  props: {
    value: {
      default () {
        return new model.constructor
      }
    }
  },
  data () {
    return {
      id: null,
      observation: null
    }
  },
  computed: {
    studentDocument: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    save () {
      this.$vs.loading()
      this.$store.dispatch(model.$store.dispatch.saveSingle, {...this.studentDocument.$serialize()}).then(() => {
        this.mixinNotify('Operação executada com sucesso')
        this.$emit('saved')
      }).catch(this.mixinCatch)
        .finally(this.mixinFinally)
    },
    newStudentDocument () {

    }
  },
  created () {}
}
</script>

