var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.params.data
    ? _c(
        "div",
        { staticClass: "flex items-center" },
        [
          _vm.status === "Programada"
            ? _c("div", {
                staticClass:
                  "h-4 w-4 inline-block rounded-full mr-2 border-2 border-solid border-secondary",
              })
            : _vm.status === "Presente"
            ? _c("div", {
                staticClass:
                  "h-4 w-4 inline-block rounded-full mr-2 bg-success",
              })
            : _c("feather-icon", {
                staticClass: "text-danger mr-1",
                attrs: { icon: "SlashIcon", svgClasses: "w-5 h-5" },
              }),
          _c(
            "span",
            {
              staticClass: "cursor-default ml-1 mr-2",
              class: `text-${
                _vm.status === "Presente"
                  ? "success"
                  : _vm.status === "Programada"
                  ? "secondary"
                  : "danger"
              }`,
            },
            [
              _vm._v(
                "\n    " + _vm._s(_vm._f("scheduleStatus")(_vm.status)) + "\n  "
              ),
            ]
          ),
          _c(
            "vx-tooltip",
            {
              staticClass:
                "flex items-center cursor-pointer hover:text-primary",
              attrs: { text: "Alterar Situação", position: "top" },
            },
            [
              _c(
                "vs-dropdown",
                {
                  staticClass: "cursor-pointer",
                  attrs: { "vs-custom-content": "", "vs-trigger-click": "" },
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: "EditIcon", svgClasses: "h-5 w-5 mr-1" },
                  }),
                  _c(
                    "vs-dropdown-menu",
                    { staticClass: "w-52" },
                    [
                      _c(
                        "vs-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.updateStatus("Presente")
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "flex items-center" }, [
                            _c("div", {
                              staticClass:
                                "h-4 w-4 inline-block rounded-full mr-3 bg-success",
                            }),
                            _c("span", [_vm._v("Presente")]),
                          ]),
                        ]
                      ),
                      _c(
                        "vs-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.updateStatus("Ausente")
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "flex items-center" },
                            [
                              _c("feather-icon", {
                                staticClass: "mr-2 text-danger",
                                attrs: {
                                  icon: "SlashIcon",
                                  svgClasses: "w-5 h-5",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "text-danger hover:text-primary",
                                },
                                [_vm._v("Ausente")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "vs-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.updateStatus("Programada")
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "flex items-center" }, [
                            _c("div", {
                              staticClass:
                                "h-4 w-4 inline-block rounded-full mr-3 border-2 border-solid border-secondary",
                            }),
                            _c("span", [_vm._v("Programada")]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }