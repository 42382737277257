<template>
  <div class="w-full">
      <label class="vs-input--label">Banco</label>
      <v-select
        v-model="bank"
        :clearable="false"
        :reduce="(option) => option.value"
        :options="allOptions"
        data-vv-as="Banco"
        v-validate.initial="'required'"
        name="bank"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
        class="fix-vselect"
        :appendToBody="appendToBody"
        :disabled="disabled"
      >
        <template #no-options="{}"> Nenhum resultado encontrado. </template>
      </v-select>
      <span class="text-danger text-sm" v-show="errors.has('bank')">{{
        errors.first('bank')
      }}</span>
  </div>
</template>

<script>
// Store Module
export default {
  props: {
    value: {
      default () {
        return {
          id: null
        }
      }
    },
    appendToBody: {},
    disabled:{},
    multiple: {
      default: false
    },
    useId:{
      default: false // atualizo o v-model com objeto completo
    }
  },
  data () {
    return {
    }
  },
  computed: {
    bank: {
      get () {
        if (Array.isArray(this.value) && this.value.length) {
          return this.value.map(e => e.id)
        }
        if (this.useId) { // no v-model foi passado um ID
          return this.value
        } else {
          return this.value && this.value.id ? this.value.id : null
        }
      },
      set (value) {
        if (this.useId) { // no v-model foi passado um ID
          this.$emit('input', value)
        } else  {  // no v-model foi passado um objeto
          if (this.multiple) {
            this.$emit('input', this.allBanks.filter(e => value.includes(e.id)))
          }
          this.$emit('input', this.allBanks.find(e => e.id === value))
        }
      }
    },
    allBanks () {
      return this.$store.state.bank.banks
    },
    allOptions () {
      return this.$store.state.bank.banks.map(e => ({value: e.id, label: `${e.bank_code} - ${e.name}` }))
    }
  },
  methods: {
    isValid () {
      this.$validator.validateAll()
      return !this.errors.has('bank')
    }
  },
  created () {
    if (!this.allOptions.length) {
      this.$store.dispatch('bank/fetchAll').then(() => { }).catch(err => { console.error(err) })
    }
  }
}
</script>
