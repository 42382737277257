<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">

    <!-- VER CONTRATO -->
    <vs-popup class="popup90" :fullscreen="false" title="Contrato" :active.sync="popupSeeContract">
      <div class="vx-row">
        <!-- Pacotes agora chamamos de serviços e serviços do pacote são ítens -->
        <div class="vx-col md:w-2/5 w-full mt-0">
          <label class="vs-input--label">Serviços</label>
          <v-select :disabled="true" v-model="contract.servicePackages" :options="servicePackages" multiple placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-0">
          <vs-input :disabled="true" class="w-full" label="Valor" ref="subTotal" v-model="contract.sub_total" />
        </div>
        <div class="vx-col md:w-1/5 w-full mt-0">
          <label class="vs-input--label">Desconto {{ contract.discount_type }}</label>
          <vx-input-group prependClasses="border border-solid border-grey border-r-0">
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button :disabled="true">{{ contract.discount_type }}</vs-button>
              </div>
            </template>
            <vs-input :disabled="true" key="1__" v-if="contract.discount_type === '%'" class="w-full" type="number" v-model="contract.discount" />
            <vs-input :disabled="true" key="2___" v-else class="w-full" ref="discount" v-model="contract.discount" />
          </vx-input-group>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-0">
          <label class="vs-input--label">Total</label>
          <h1 class="flex flex-wrap justify-center">
            <sup class="text-lg">R$</sup>
            <span>{{contract.value_total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')}}</span>
          </h1>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Nº de Parcelas</label>
          <vs-input-number :disabled="true" min="1" class="mt-2" v-model="contract.parcelsNumber" />
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <vs-input class="w-full mt-0" label="Valor 1ª Parcela" ref="firstParcelValue" :disabled="true"
            v-model="contract.firstParcelValue"  />
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <vs-input :disabled="true" type="date" class="w-full" label="1º Vencimento" v-model="contract.expirationDateFirstParcel" />
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Periodicidade</label>
          <v-select :disabled="true" v-model="contract.frequency" :reduce="option => option.value" :clearable="false" :options="[{label: 'Mensal', value: 'monthly'}, {label: 'Quinzenal', value: 'biweekly'}, {label: 'Semanal', value: 'weekly'}]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"></v-select>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Aplicar Juros</label>
          <div class="flex flex-wrap items-center justify-center">
          <vs-switch :disabled="true" class="w-1/2 mt-2" icon-pack="feather" vs-icon-on="icon-check-circle" vs-icon-off="icon-slash" v-model="contract.applyInterest">
            <span slot="on">SIM</span> <span slot="off">NÃO</span>
          </vs-switch>
          </div>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Tipo de Pagamento</label>
          <v-select :disabled="true" class="vue_select_drop_size_180" v-model="contract.type_payment" :reduce="option => option.value" :clearable="false"
            :options="typePayments" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Conta</label>
          <v-select :disabled="true" class="vue_select_drop_size_180" v-model="contract.account" :reduce="option => option.value" :clearable="false"
            :options="accounts" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Plano de contas</label>
          <v-select class="vue_select_drop_size_180" :disabled="true" v-model="contract.account_plan" :reduce="option => option.value" :options="accountPlans" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-2/5 w-full mt-2">
          <label class="vs-input--label">Vendedor</label>
          <v-select :disabled="true" class="vue_select_drop_size_180" v-model="contract.employee_id" :reduce="option => option.value" :options="employees" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Categoria Atual</label>
          <v-select :disabled="true" class="vue_select_drop_size_115" v-model="contract.current_category_cnh" :reduce="option => option.value" :options="cnhOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Categoria Pretendida</label>
          <v-select :disabled="true" class="vue_select_drop_size_115" v-model="contract.intended_category_cnh" :reduce="option => option.value" :options="cnhOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">EAR</label>
          <div class="flex flex-wrap items-center justify-center">
            <vs-switch :disabled="true" class="w-1/2 mt-2" icon-pack="feather" vs-icon-on="icon-check-circle" vs-icon-off="icon-slash" v-model="contract.gainful_activity">
              <span slot="on">SIM</span> <span slot="off">NÃO</span>
            </vs-switch>
          </div>
        </div>
        <div class="vx-col md:w-2/5 w-full mt-2">
          <label class="vs-input--label">Cursos</label>
          <v-select :disabled="true" class="vue_select_drop_size_115" v-model="contract.specialized_courses" :reduce="option => option.value" :options="specializedCoursesCNHOptions" multiple placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-4/5 w-full mt-2">
          <vs-input :disabled="true" class="w-full" label="Observações" v-model="contract.observation" />
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <div class="mt-5 flex flex-wrap items-center justify-end">
            <vs-button :disabled="true" class="w-full" type="border" color="warning" icon-pack="feather" icon="icon-dollar-sign">Gerar Parcelas</vs-button>
          </div>
        </div>
      </div>

      <vs-divider position="left">
          <feather-icon icon="DollarSignIcon" class="mr-0" svgClasses="w-4 h-4" /><span class="mb-4">Parcelas</span>
      </vs-divider>

      <!-- PARCELAS -->
      <vs-table v-if="contract.transactions.length" :data="contract.transactions" hoverFlat style="overflow: -webkit-paged-y">
        <template slot="thead">
          <vs-th>#</vs-th>
          <vs-th>Valor</vs-th>
          <vs-th>Vencimento</vs-th>
          <vs-th>Forma Pagamento</vs-th>
          <vs-th>Conta</vs-th>
          <vs-th>Situação</vs-th>
          <vs-th>Pago Em</vs-th>
          <!-- <vs-th>Plano de Contas</vs-th> -->
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="i" v-for="(parcel, i) in data" style="opacity: 1 !important">
            <vs-td> {{ i + 1 }} </vs-td>
            <vs-td>
                <currency-input :disabled="true" class="w-32 vs-inputx vs-input--input normal hasValue"
                  v-model="parcel.value_parcel"
                  :currency="{prefix: 'R$ '}"
                  :valueAsInteger="false"
                  :distractionFree="false"
                  :precision="2"
                  :autoDecimalMode="true"
                  :valueRange="{ min: 0 }"
                  :allowNegative="false" />
            </vs-td>
            <vs-td>
              <vs-input :disabled="true" type="date" class="w-48" v-model="parcel.expiration_date" />
            </vs-td>
            <vs-td>
              <v-select :disabled="true" class="vue_select_drop_size_100 w-48" :clearable="false" v-model="parcel.type_payment_id" :reduce="option => option.value" :options="typePayments" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </vs-td>
            <vs-td>
              <v-select :disabled="true" class="vue_select_drop_size_100 w-48" :clearable="false" v-model="parcel.account_id"
                :reduce="option => option.value" :options="accounts" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </vs-td>
            <vs-td>
              <div class="flex flex-wrap items-center justify-center">
                <vs-switch :disabled="true" class="w-28 mt-2" icon-pack="feather" vs-icon-on="icon-check-circle" vs-icon-off="icon-slash" v-model="parcel.situation">
                  <span slot="off">ABERTA</span> <span slot="on">QUITADA</span>
                </vs-switch>
              </div>
            </vs-td>
            <vs-td>
              <vs-input :disabled="true" type="date" class="w-48" v-model="parcel.payday" />
            </vs-td>
            <!-- <vs-td>
              <v-select class="vue_select_drop_size_100 w-48" :clearable="false" v-model="parcel.account_plan" :reduce="option => option.value" :options="accountPlans" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </vs-td> -->
          </vs-tr>

          <vs-tr style="opacity: 1 !important">
            <vs-td colspan="8">
              <vs-divider></vs-divider>
              <div class="vx-col w-full">
                <div class="mt-2 flex flex-wrap items-center">
                  <vs-button class="mr-4" color="secondary" @click="popupSeeContract = false">Cancelar</vs-button>
                </div>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-popup>

    <vs-popup class="popup90" :fullscreen="false" title="Boleto(s)" :active.sync="popupShowBoleto">
      <div id="boleto-loading" class="vs-con-loading__container">
        <embed v-if="!Array.isArray(boletoUrl)" :src="boletoUrl" alt="Boleto" width="100%" height="600" type="application/pdf" pluginspage="http://www.adobe.com/products/acrobat/readstep2.html">
        <div v-if="Array.isArray(boletoUrl)">
          <embed v-for="(bUrl, index) in boletoUrl" :src="bUrl" :key="index" alt="Boleto" width="100%" height="600" type="application/pdf" pluginspage="http://www.adobe.com/products/acrobat/readstep2.html">
        </div>

        <div class="vx-row">
          <vs-divider></vs-divider>
          <div class="vx-col w-full mt-0">
            <div class="mt-2 flex flex-wrap items-center justify">
              <vs-button v-if="!Array.isArray(remessaUrl)" :href="remessaUrl" class="w-full">!! Clique aqui para baixar o arquivo de remessa !!</vs-button>
              <div v-if="Array.isArray(remessaUrl)">
                <vs-button v-for="(rUrl, index) in remessaUrl" :href="rUrl" :key="index" class="w-full">!! Clique aqui para baixar o arquivo {{ index+1 }} de remessa !!</vs-button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </vs-popup>

    <vs-popup class="noOverflow" :fullscreen="false" :title="showPdfTitle" :active.sync="popupShowPdf">
      <object :data="srcPdf" width="100%" style="height: 600px" />
    </vs-popup>

    <vs-popup class="popup50" :fullscreen="false" title="Impressão de Contrato" :active.sync="popupChooseContractModel">
      <div class="flex items-end mt-2">
        <feather-icon icon="FileTextIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Escolha o modelo de contrato desejado</span>
      </div>
      <hr class="mb-4">

      <div class="vx-row" v-if="params.data">
        <div class="vx-col w-full">
          <span class="mx-2 cursor-pointer hover:text-primary" @click="printTemplateContractSIGCFC(params.data.id)">Contrato Modelo SIGCFC</span>
        </div>
        <div class="vx-col w-full" v-for="(contractModel, i) in contractModels" :key="i">
          <span class="mx-2 cursor-pointer hover:text-primary" @click="printContract(params.data.id, contractModel.id)">{{ contractModel.title }}</span>
        </div>
      </div>
      <vs-alert class="mt-4 mb-4" :active="!contractModels.length" color="warning" icon-pack="feather" icon="icon-info" style="height: auto">
        <span>Nenhum modelo de contrato encontrado. Cadastre um modelo de contrato para poder imprimir aqui.</span>
      </vs-alert>

    </vs-popup>

    <div class="flex justify-between">
      <div class="flex items-center">

        <!-- VER -->
        <vx-tooltip text="Ver" position="top" class="h-5 w-5 mr-2">
          <feather-icon icon="EyeIcon" svgClasses="h-5 w-5 mr-4 cursor-pointer hover:text-primary" @click="seeContract" />
        </vx-tooltip>

        <!-- IMPRIMIR -->
        <vx-tooltip text="Impressões" position="top" class="h-5 w-5">
          <vs-dropdown vs-trigger-click class="cursor-pointer hover:text-primary">
            <feather-icon icon="PrinterIcon" svgClasses="h-5 w-5 mr-4 cursor-pointer hover:text-primary" />
            <vs-dropdown-menu class="w-54">
              <vs-dropdown-item>
                <div class="flex items-center" @click="handlePrintContract">
                  <feather-icon icon="PrinterIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                  <span>Contrato</span>
                </div>
              </vs-dropdown-item>
              <vs-dropdown-item>
                <div class="flex items-center" @click="printPromissoria(params.data.id)">
                  <feather-icon icon="PrinterIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                  <span>Promissória</span>
                </div>
              </vs-dropdown-item>
              <vs-dropdown-item>
                <div class="flex items-center" @click="printCarnet(params.data.id)">
                  <feather-icon icon="PrinterIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                  <span>Carnê</span>
                </div>
              </vs-dropdown-item>
              <vs-dropdown-item v-if="params.data && params.data.situation_contract === 'CANCELED'">
                <div class="flex items-center" @click="printWithdrawal(params.data.id)">
                  <feather-icon icon="PrinterIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                  <span>Desistência</span>
                </div>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </vx-tooltip>

        <!-- BOLETO há um filtro para mostrar ícone somente se houver boleto (no caso verifico se há remessas) -->
        <span v-if="params.data && params.data.remessas" @click="generate2viaBoletos(params.data)">
          <vx-tooltip text="2ª Via de boletos" position="top" class="h-5 w-5 ml-2">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" class="h-6 w-6 mr-4 cursor-pointer svg-hover" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
              <path d="M2 6h2v12H2V6m3 0h1v12H5V6m2 0h3v12H7V6m4 0h1v12h-1V6m3 0h2v12h-2V6m3 0h3v12h-3V6m4 0h1v12h-1V6z" />
            </svg>
          </vx-tooltip>
        </span>
        <vx-tooltip text="Excluir" position="top" class="h-5 w-5 mr-2" v-if="mixinAllow('attendance:student:contract:destroy')">
          <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 ml-4 cursor-pointer hover:text-primary" @click="askToDelete" />
        </vx-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
import { CurrencyInput } from 'vue-currency-input'
import axios from '@/axios.js'

export default {
  name: 'CellRendererActions',
  components: {
    vSelect,
    CurrencyInput
  },

  computed: {
    contractModels () {
      return this.$store.state.contractModel.contractModels
    },
    servicePackages () {
      return this.$store.getters['servicePackage/forSelect']
    },
    typePayments () {
      return this.$store.getters['typePayment/forSelect']
    },
    accounts () {
      return this.$store.getters['account/forSelect']
    },
    accountPlans () {
      return this.$store.getters['accountPlan/forSelectFilterReceipt']
    },
    employees () {
      return this.$store.getters['employee/forSelect']
    }
  },

  methods: {
    askToDelete () {
      if (this.mixinDenies('attendance:student:contract:destroy')) {
        this.mixinNotify('Você não tem permissão para excluir', 'warning')
        return
      }
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar exclusão',
        text: 'Deseja excluir o contrato e suas parcelas não quitadas?',
        accept: this.deleteContract,
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    deleteContract () {
      this.$vs.loading()
      axios.delete(`${process.env.VUE_APP_API_URL}/contract/${this.params.data.id}`)
        .then(() => {
          this.mixinNotify('Operação executada com sucesso')
          this.params.refreshData()
        })
        .catch(this.mixinCatch)
        .finally(this.mixinFinally)
    },
    openLoading () {
      this.$vs.loading({
        container: '#boleto-loading',
        scale: 1
      })
    },
    seeContract () {
      this.contract = JSON.parse(JSON.stringify(this.params.data))
      this.contract.servicePackages.map(a => {
        a.value = a.id
        a.label = a.type_service && a.type_service.toUpperCase() === 'AULA AVULSA' ? `${a.name} ${(a.category_cnh || '')} x ${a.quantity}` : `${a.name} ${(a.category_cnh || '')}`
      })
      this.contract.sub_total = this.contract.sub_total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      if (this.contract.discount_type === '$') {
        this.contract.discount = this.contract.discount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      }
      this.contract.parcelsNumber = this.contract.transactions.length
      this.contract.firstParcelValue = this.contract.transactions[0].value_parcel.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      this.contract.expirationDateFirstParcel = this.contract.transactions[0].expiration_date
      this.contract.frequency = 'Verifique Parcelas' // Não tenho essa informação
      this.contract.applyInterest = !!(this.contract.interest > 0 || this.contract.fee > 0)
      this.contract.type_payment = 'Verifique Parcelas' // Não tenho essa informação
      this.contract.account = 'Verifique Parcelas' // Não tenho essa informação
      this.contract.account_plan = 'Verifique Parcelas' // Não tenho essa informação
      this.contract.specialized_courses = JSON.parse(this.contract.specialized_courses)

      this.contract.transactions.map(a => {
        a.situation = a.situation === 'PAID'
        if (a.payday) {
          a.payday = a.payday.slice(0, 10)
        }
      })
      this.popupSeeContract = true
    },
    handlePrintContract () {
      this.popupChooseContractModel = true
    },
    // IMPRESSÃO DE CONTRATO MODELO OFERECIDO PELO SIGCFC
    async printTemplateContractSIGCFC (contractId) {
      this.$vs.loading()
      try {
        this.showPdfTitle = 'Impressão de Contrato'
        const resp = await axios.get(`${process.env.VUE_APP_API_URL}/contract/model/sigcfc/pdf/${contractId}`)
        this.$vs.loading.close()
        this.srcPdf = `data:application/pdf;base64,${resp.data}`
        this.popupChooseContractModel = false
        this.popupShowPdf = true
      } catch (error) {
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Desculpe, algo deu errado. Por favor avise o suporte.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    async printContract (contractId, contractModelId) {
      this.$vs.loading()
      try {
        this.showPdfTitle = 'Impressão de Contrato'
        const resp = await axios.get(`${process.env.VUE_APP_API_URL}/contract/model/pdf/${contractId}/${contractModelId}`)
        this.$vs.loading.close()
        this.srcPdf = `data:application/pdf;base64,${resp.data}`
        this.popupChooseContractModel = false
        this.popupShowPdf = true
      } catch (error) {
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Desculpe, algo deu errado. Por favor avise o suporte.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    async printPromissoria (contractId) {
      this.$vs.loading()
      try {
        this.showPdfTitle = 'Impressão de Promissória'
        const resp = await axios.get(`${process.env.VUE_APP_API_URL}/document/pdf/promissoria/contract/${contractId}`)
        this.$vs.loading.close()
        this.srcPdf = `data:application/pdf;base64,${resp.data}`
        this.popupChooseContractModel = false
        this.popupShowPdf = true
      } catch (error) {
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Desculpe, algo deu errado. Por favor avise o suporte.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    async printCarnet (contractId) {
      this.$vs.loading()
      try {
        this.showPdfTitle = 'Impressão de Carnê'
        const resp = await axios.get(`${process.env.VUE_APP_API_URL}/document/pdf/carnet/contract/${contractId}`)
        this.$vs.loading.close()
        this.srcPdf = `data:application/pdf;base64,${resp.data}`
        this.popupChooseContractModel = false
        this.popupShowPdf = true
      } catch (error) {
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Desculpe, algo deu errado. Por favor avise o suporte.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    async printWithdrawal (contractId) {
      console.log()
      this.$vs.loading(contractId)
      try {
        this.showPdfTitle = 'Impressão de Carnê'
        const resp = await axios.get(`${process.env.VUE_APP_API_URL}/document/pdf/cancel-contract/${contractId}`)
        this.$vs.loading.close()
        this.srcPdf = `data:application/pdf;base64,${resp.data}`
        this.popupChooseContractModel = false
        this.popupShowPdf = true
      } catch (error) {
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Desculpe, algo deu errado. Por favor avise o suporte.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    async generate2viaBoletos (contract) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/duplicate/lote/contract/${contract.id}`)
        if (!response.data.boletoUrl.length) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: 'Nenhum boleto encontrado para este contrato!',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else if (response.data.boletoUrl.length === 1) {
          this.openLoading()
          this.boletoUrl = response.data.boletoUrl[0]
          this.remessaUrl = response.data.remessaUrl[0]
          this.popupShowBoleto = true
        } else {
          this.openLoading()
          this.boletoUrl = response.data.boletoUrl
          this.remessaUrl = response.data.remessaUrl
          this.popupShowBoleto = true
        }
        const self = this
        setTimeout(() => { self.$vs.loading.close('#boleto-loading > .con-vs-loading') }, 2000)
      } catch (error) {
        console.log(error)
      }
    },
    /**
     * ATENÇÃO, GERA NOVOS BOLETOS. NÃO SE TRATA DE SEGUNDA VIA
     * @param contract
     * @returns {boolean}
     */
    async generateNewBoletos (contract) {

    // COMO AGORA OS BOLETOS SÃO EMITIDOS PELA FORMA DE PAGAMENTO, NÃO FAZ SENTIDO REEMITIR NOVOS BOLETOS
    // CASO QUEIRA REEMITIR BOLETOS DEVE FAZER NO FINANCEIRO

      console.log(contract)
      // VERIFICAR SE ACONTA UTILIZADA EMITE BOLETO


      // let url = ''
      // if (this.optionsConfigBoleto.length === 0) {
      //   this.$toast.warning('Por favor configure sua conta de boletos antes de emitir esse tipo de cobrança!', 'AVISO', this.optionsToast);
      //   return
      // } else if (this.optionsConfigBoleto.length === 1) {
      //   url = `/generate/new/boletos/contract/${this.contract.id}/${this.optionsConfigBoleto[0].value.id}`
      // } else if (!this.configBoleto) {
      //   this.chooseConfigBoletoModalVisible = true
      //   return
      // } else {
      //   url = `/generate/new/boletos/contract/${this.contract.id}/${this.configBoleto.id}`
      // }


      // const self = this
      // this.$toast.question('Tem certeza que deseja gerar novos boletos para este contrato? Não se trata de 2ª via. Novas parcelas NÃO serão geradas<br>Para reimprimir boletos já gerados escolha a opção 2ª via<br>Esta operação é irreversível!','ATENÇÃO',{
      //   timeout: 20000,
      //   close: false,
      //   // overlay: true,
      //   toastOnce: true,
      //   id: self.contract.id,
      //   position: 'center',
      //   buttons: [
      //     [
      //       '<button><b>GERAR NOVOS BOLETOS</b></button>',
      //       async function (instance, toast) {
      //         instance.hide({transitionOut: 'fadeOut'}, toast)
      //         try {
      //           self.chooseConfigBoletoModalVisible = false
      //           self.configBoleto = null
      //           self.overlayAll = true
      //           const response = await axios.get(url)
      //           if (response.data.type === 'success') {
      //             if (response.data.boletoUrl) {
      //               self.boletoUrl = response.data.boletoUrl
      //               self.remessaUrl = response.data.remessaUrl
      //               self.overlayAll = false
      //               self.boletoModalVisible = true
      //               setTimeout(() => { self.overlayBoletoUrl = false }, 2000)
      //             }
      //           } else if (response.data.type === 'warning') {
      //             self.overlayAll = false
      //             self.$toast.warning(response.data.message, response.data.title, self.optionsToast)
      //           } else {
      //             self.overlayAll = false
      //             self.$toast.info(response.data.message, response.data.title, self.optionsToast)
      //           }
      //         } catch (error) {
      //           self.overlay = false
      //           self.overlayAll = false
      //           console.log(error)
      //           if (error.response.status === 401 || error.response.status === 419) {
      //             self.$toast.warning('Sua sessão expirou. Por favor atualize a página.', 'AVISO', self.optionsToast)
      //           } else {
      //             self.$toast.error(error.response.data.message, 'Erro', self.optionsToast)
      //           }
      //         }
      //       },
      //       true
      //     ],
      //     [
      //       '<button>CANCELAR</button>',
      //       (instance, toast) => {
      //         instance.hide({ transitionOut: 'fadeOut' }, toast)
      //       }
      //     ]
      //   ]
      // })
    }
  },

  data () {
    return {
      popupSeeContract: false,

      showPdfTitle: 'PDF',
      srcPdf: '',
      popupShowPdf: false,

      boletoUrl: '',
      bUrl: '',
      remessaUrl: '',
      rUrl: '',
      popupShowBoleto: false,

      popupChooseContractModel: false,

      contract: {
        account: null,
        type_payment: null,
        account_plan: null,
        servicePackages: [],
        situation_contract: 'IN_PROGRESS', //IN_PROGRESS - FINISHED - CANCELED

        reason_cancellation: null,
        return_bank_id: null, //Banco para devolução do valor do contrato em caso de cancelamento
        return_agency: null, //Agência bancária para devolução do valor do contrato em caso de cancelamento
        return_account: null, //Conta bancária para devolução do valor do contrato em caso de cancelamento
        return_operation: null, //Operação bancária para devolução do valor do contrato em caso de cancelamento
        amount_receivable: 0, //valor a receber no cancelamento
        amount_to_pay: 0, //valor a devolver no cancelamento
        date_receivable: '', //data a receber no cancelamento
        date_amount_to_pay: '', //data valor a devolver no cancelamento

        parcelsNumber: 1,
        discount_type: '%',
        discount: 0,
        value_total: 0,
        sub_total: 0,
        firstParcelValue: 0,
        expirationDateFirstParcel: '',
        frequency: 'monthly',
        applyInterest: true,
        employee_id: null,
        current_category_cnh: null,
        intended_category_cnh: null,
        gainful_activity: false,
        specialized_courses: null,
        observation: null,
        transactions: []
      },

      cnhOptions: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'AB', value: 'AB' },
        { label: 'C', value: 'C' },
        { label: 'D', value: 'D' },
        { label: 'E', value: 'E' },
        { label: 'AC', value: 'AC' },
        { label: 'AD', value: 'AD' },
        { label: 'AE', value: 'AE' },
        { label: 'ACC', value: 'ACC' }
      ],

      specializedCoursesCNHOptions: [
        { label: 'Produtos perigosos', value: 11 },
        { label: 'Escolar', value: 12 },
        { label: 'Coletivo', value: 13 },
        { label: 'Emergência', value: 14 }
        // { label: 'EAR', value: 15 }
      ]

    }
  }
}
</script>
<style>
.svg-hover:hover {
  fill: #158482;
}
.popup50 .vs-popup {
  width: 35% !important;
}

</style>
