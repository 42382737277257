<template>
  <div class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">
    <vs-sidebar class="items-no-padding" parent="#email-app" :click-not-close="clickNotClose" :hidden-background="clickNotClose" v-model="isEmailSidebarActive">
      <schedule-sidebar />
    </vs-sidebar>

    <vs-sidebar click-not-close parent="#email-app" :hidden-background="true" class="full-vs-sidebar email-view-sidebar items-no-padding" v-model="isSidebarActive" position-right>
      <div class="mail-sidebar-content px-0 sm:pb-6 h-full">
        <div class="flex flex-wrap items-center email-header justify-between md:px-8 px-6 sm:pb-2 sm: pt-6 d-theme-dark-bg">
          <div class="flex mb-4">
            <div class="flex items-center">
              <feather-icon :icon="$vs.rtl ? 'ArrowRightIcon' : 'ArrowLeftIcon'" @click="$emit('closeSidebar')" class="cursor-pointer hover:text-primary mr-4" svg-classes="w-6 h-6"></feather-icon>
              <h4>VOLTAR</h4>
            </div>
          </div>
          <div class="ml-10 mb-4 mt-1">
            <div class="email__actions--single flex items-baseline">
              <div class="flex border d-theme-dark-bg items-center justify-end">
                <vx-tooltip text="Abrir Barra de Calendário" position="top" class="ml-4">
                  <feather-icon class="md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer" icon="MenuIcon" @click.stop="toggleEmailSidebar(true)"/>
                </vx-tooltip>
              </div>
              <vx-tooltip text="Imprimir" position="top" class="ml-4">
                <feather-icon icon="PrinterIcon" svg-classes="h-6 w-6" class="cursor-pointer" @click="$emit('markUnread')"></feather-icon>
              </vx-tooltip>
            </div>
          </div>
        </div>

        <!-- MENU DE CONTEXTO -->
        <vue-context ref="menu">
          <li>
            <a href="#" @click="contextMenuClicked('DELETE')" class="flex items-center text-sm hover:text-danger">
              <feather-icon icon="MinusCircleIcon" svgClasses="w-5 h-5" />
              <span class="ml-2">Excluir Todos os Alunos Deste Encontro</span>
            </a>
          </li>
          <li v-if="!contextDeleteStudentSchedule">
            <a href="#" @click="contextMenuClicked('ADD_STUDENT_SCHEDULE')" class="flex items-center text-sm hover:text-danger">
              <feather-icon icon="MinusCircleIcon" svgClasses="w-5 h-5" />
              <span class="ml-2">Adicionar o Aluno Neste Encontro</span>
            </a>
          </li>
          <li v-if="!contextDeleteStudentSchedule">
            <a href="#" @click="contextMenuClicked('ADD_STUDENT_SCHEDULE_DAY')" class="flex items-center text-sm hover:text-danger">
              <feather-icon icon="MinusCircleIcon" svgClasses="w-5 h-5" />
              <span class="ml-2">Adicionar o Aluno em Todos os Encontros do Dia</span>
            </a>
          </li>
          <li v-if="contextDeleteStudentSchedule">
            <a href="#" @click="contextMenuClicked('DELETE_STUDENT_SCHEDULE')" class="flex items-center text-sm hover:text-danger">
              <feather-icon icon="MinusCircleIcon" svgClasses="w-5 h-5" />
              <span class="ml-2">Excluir Somente o Aluno Deste Encontro</span>
            </a>
          </li>
          <li v-if="contextDeleteStudentScheduleDay">
            <a href="#" @click="contextMenuClicked('DELETE_STUDENT_SCHEDULE_DAY')" class="flex items-center text-sm hover:text-danger">
              <feather-icon icon="MinusCircleIcon" svgClasses="w-5 h-5" />
              <span class="ml-2">Excluir Somente o Aluno em Todos os Encontros do Dia</span>
            </a>
          </li>
        </vue-context>

        <!-- CORPO -->
        <div class="email__actions p-2 border border-r-0 border-l-0 border-solid d-theme-border-grey-light">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mt-0">
              <label class="vs-input--label">Turma</label>
              <v-select v-model="turma" @input="changeTurma()" :clearable="true" :filterable="false" style="background-color: white;"
                @search="debouncedGetSearchTurma" :reduce="option => option.value" :options="turmaOptions"
                placeholder="Digite o nome da turma..." :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-0">
              <label class="vs-input--label">Disciplina</label>
              <v-select v-model="moduleCourse" :clearable="true" style="background-color: white;"
                :reduce="option => option.value" :options="modules"
                placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mt-0">
              <label class="vs-input--label">Instrutor</label>
              <v-select v-model="employee_id" @input="changeEmployee()" :reduce="option => option.value" :options="employees" placeholder="Selecione" style="background-color: white;" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-0">
              <label class="vs-input--label">Sala</label>
              <v-select v-model="classroom" @input="changeClassroom()" :reduce="option => option.data" :options="classrooms" placeholder="Selecione" style="background-color: white;" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
            </div>

            <div class="vx-col md:w-1/3 w-full mt-0">
              <div class="mt-4 flex flex-wrap items-center">

              </div>
            </div>
          </div>
        </div>

        <!-- SCHEDULE -->
        <component :is="scrollbarTag" class="email-content-scroll-area" :settings="settings" ref="mailListPS" :key="$vs.rtl">
          <div class="vs-con-loading__container" id="loading">

            <vs-table v-for="(table, tIndex) in tables" :key="tIndex" :data="selectedDays" class="bordered" hoverFlat style="overflow: -webkit-paged-y;">
              <template slot="thead">
                <vs-th style="font-size: 11px; width: 85px"><span>HORÁRIO</span></vs-th>
                <vs-th style="font-size: 11px; min-width: 126px" v-for="(hr, i) in handleRange(table)" :key="i">
                    {{weekDays[selectedDays[hr - 1].weekdayPosition]}} {{ selectedDays[hr - 1].id | dateTime }}
                </vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr v-for="(hour, i) in hours" :key="i" style="font-size: 12px; opacity: 1 !important">
                  <vs-td style="text-align:right"><span class="cursor-default font-semibold">{{hour.slice(0, 5)}}</span></vs-td>
                  <vs-td v-for="(hr, i) in handleRange(table)" :key="i" class="cursor-pointer" :style="fillSchedule(selectedDays[hr - 1], hour).length && fillSchedule(selectedDays[hr - 1], hour)[0].bg || ''"
                    @click.native="addSchedule(selectedDays[hr - 1], hour)">
                      <feather-icon v-for="(holiday, hd) in selectedDays[hr - 1].holidaySchedule" :key="`${hd}_holiday`" :title="holiday.holidayName" class="mr-1" icon="StarIcon" svgClasses="w-4 h-4" />
                      <div v-for="(schedule, idx) in fillSchedule(selectedDays[hr - 1], hour)" :key="`${idx}_schedule`" class="text-black hover:text-primary"
                      style="margin-top: -7px;"
                        @contextmenu.prevent="contextMenu($event, hr - 1, hour, schedule)">

                        <span class="font-semibold" style="font-size: 10px;">{{ schedule.turma.code }}</span> <br>
                        <div class="flex items-center">
                          <feather-icon v-if="schedule.allStudents.findIndex(a => a === student.id) >= 0" class="mr-1" icon="UserIcon" svgClasses="w-4 h-4" />
                          <span>{{ schedule.module.abbreviation }} ({{ schedule.module.class_hour }}h)</span>
                        </div>

                      </div>
                  </vs-td>
                </vs-tr>

                <vs-tr style="opacity: 1 !important; border: 10px">
                  <vs-td :colspan="handleRange(table).length + 1" style="text-align:right">
                    <vs-divider></vs-divider>
                  </vs-td>
                </vs-tr>

              </template>
            </vs-table>
          </div>
          <div v-if="!tables" class="mt-6 flex flex-wrap items-center justify-center">
            <vs-alert class="w-5/6" style="height: -webkit-fill-available;" color="warning" title="Defina o período" :active="!tables">
              Selecione a(s) data(s) no calendário ou defina um intervalo de datas para ver
              os agendamentos conforme o filtro superior.
            </vs-alert>
          </div>
        </component>

      </div>
    </vs-sidebar>
  </div>
</template>

<script>
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
import _                    from 'lodash'
import moment               from 'moment'
import ScheduleSidebar      from './ScheduleSidebar.vue'
import { VueContext }       from 'vue-context'

import VuePerfectScrollbar      from 'vue-perfect-scrollbar'
import moduleStudentManagement  from '@/store/student-management/moduleStudentManagement.js'

import moduleScheduleTheoreticalClass from '@/store/schedule-theoretical-class/moduleScheduleTheoreticalClass.js'
import moduleEmployee       from '@/store/employee/moduleEmployee.js'
import moduleClassroom      from '@/store/classroom/moduleClassroom.js'
import moduleTurma          from '@/store/turma/moduleTurma.js'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      clickNotClose        : true,
      isEmailSidebarActive : false,

      showThread: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.50
      },

      student: {},

      contextDeleteStudentSchedule    : false,
      contextDeleteStudentScheduleDay : false,
      contextMenuIdx                  : null,
      contextMenuHour                 : null,
      selectedDay                     : null,
      selectedHour                    : null,

      weekDays: [0, 'SEG.', 'TER.', 'QUA.', 'QUI.', 'SEX.', 'SÁB.', 'DOM.'],
      students: [],

      employee_id: null,
      classroom: null,
      type: 'theoretical',
      status: 'Programada', //Concluído, Não Concluído
      turma: null,
      turmaOptions: [],
      course: null,
      modules: [],
      moduleCourse: null,
      typeServiceOptions: [
        { label: '1ª Habilitação',        value: '1ª Habilitação'      },
        { label: 'Adição de categoria',   value: 'Adição de categoria' },
        { label: 'Mudança de categoria',  value: 'Mudança de categoria'},
        { label: 'Permissão ACC',         value: 'Permissão ACC'       },
        { label: 'Aula avulsa',           value: 'Aula avulsa'         }
      ]
    }
  },
  watch: {
    selectedDays (value) {
      if (value.length) {
        this.openLoading()
        this.debouncedGetFilterSchedule()
      }
      // AO TROCAR O PERÍODO DE DATAS DO CALENDÁRIO ZERO O PERÍODO MANUAL DO RELATÓRIO
      this.reportFirstPeriod = ''
      this.reportLastPeriod = ''
    },
    isSidebarActive (value) {
      if (!value) {
        this.$emit('closeSidebar')
        setTimeout(() => {
          this.showThread = false
        }, 500)
      } else {
        this.filterSchedule() // INICIA FILTRANDO PELO ALUNO
      }
      this.isEmailSidebarActive = value
      this.setSidebarWidth()
    },
    windowWidth () {
      this.setSidebarWidth()
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    windowWidth () {
      return this.$store.state.windowWidth
    },
    selectedDays () {
      return this.$store.state.scheduleTheoreticalClass ? this.$store.state.scheduleTheoreticalClass.selectedDays : []
    },
    periods () {
      return this.$store.state.scheduleTheoreticalClass.periods // 1 MATUTINO 2 VESPERTINO 3 NOTURNO
    },
    hours () {
      const periods = this.periods
      const h = [
        ['06:00 06:50', '06:50 07:40', '07:40 08:30', '08:30 09:20', '09:20 10:10', '10:10 11:00', '11:00 11:50'],
        ['13:00 13:50', '13:50 14:40', '14:40 15:30', '15:30 16:20', '16:20 17:10', '17:10 18:00', '18:00 18:50'],
        ['19:40 20:30', '20:30 21:20', '22:10 23:00', '23:00 23:50']
      ]
      const res = h.filter(function (eachElem, index) {
        return periods.indexOf(index) !== -1
      }).flat()
      return res
    },
    tables () {
      return Math.ceil(this.selectedDays.length / 6) // CONTROLE DE COLUNAS POR TABELA DE DATAS (7 COLUNAS POR TABELA)
    },
    employees () {
      return this.$store.getters['employee/forSelect']
    },
    classrooms () {
      return this.$store.getters['classroom/forSelect']
    }
  },
  methods: {
    setSidebarWidth () {
      if (this.windowWidth < 992 && this.isSidebarActive) {
        this.isEmailSidebarActive = this.clickNotClose = false
      } else if (this.isSidebarActive) {
        this.isEmailSidebarActive = this.clickNotClose = true
      }
    },
    toggleEmailSidebar (value = false) {
      if (!value) {
        this.$emit('closeSidebar')
        if (this.clickNotClose) {
          return
        }
      }
      this.isEmailSidebarActive = value
    },
    openLoading () {
      this.$vs.loading({
        container: '#loading',
        scale: 0.8
      })
    },
    closeLoading () {
      this.$vs.loading.close('#loading > .con-vs-loading')
    },
    contextMenu (event, idx, hour = null, shceduleIdx1 = null) {
      this.contextDeleteStudentSchedule = false
      this.contextDeleteStudentScheduleDay = false

      const idx2 = shceduleIdx1.allStudents.findIndex(a => a === this.student.id) // verifica se o aluno está incluído
      if (idx2 >= 0) {
        this.contextDeleteStudentSchedule = true
        this.contextDeleteStudentScheduleDay = true
      }

      this.contextMenuIdx = idx
      this.contextMenuHour = hour
      this.$refs.menu.open(event)
    },
    contextMenuClicked (action) {
      if (action === 'DELETE') {
        this.confirmDelete()
      } else if (action === 'DELETE_STUDENT_SCHEDULE') {
        this.confirmDelete(action)
      } else if (action === 'DELETE_STUDENT_SCHEDULE_DAY') {
        this.confirmDelete(action)
      } else if (action === 'ADD_STUDENT_SCHEDULE') {
        this.addStudentSchedule()
      } else if (action === 'ADD_STUDENT_SCHEDULE_DAY') {
        this.addStudentScheduleDay()
      }
    },
    changeEmployee () {
      this.filterSchedule()
    },
    confirmDelete (val) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: 'Tem certeza que deseja excluir este agendamento?',
        accept: !val ? this.deleteSchedule : val === 'DELETE_STUDENT_SCHEDULE' ? this.deleteStudentSchedule : this.deleteStudentScheduleDay,
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    async deleteSchedule () {
      try {
        this.$vs.loading()
        let scheduleGroup = []
        const selectedDay = this.selectedDays[this.contextMenuIdx]
        if (selectedDay.turmaSchedule && selectedDay.turmaSchedule.length) scheduleGroup = selectedDay.turmaSchedule.filter(a => a.start_time === `${this.contextMenuHour.slice(0, 5)}:00`)
        else if (selectedDay.employeeSchedule && selectedDay.employeeSchedule.length) scheduleGroup = selectedDay.employeeSchedule.filter(a => a.start_time === `${this.contextMenuHour.slice(0, 5)}:00`)
        else if (selectedDay.classroomSchedule && selectedDay.classroomSchedule.length) scheduleGroup = selectedDay.classroomSchedule.filter(a => a.start_time === `${this.contextMenuHour.slice(0, 5)}:00`)
        const scheduleGroupIds = scheduleGroup.map(a => a.id)

        this.$store.commit('scheduleTheoreticalClass/DELETE_STUDENT_LIST_SCHEDULES', scheduleGroupIds)
        this.$emit('refreshData') // ATUALIZA O DATAGRID

        await this.$store.dispatch('scheduleTheoreticalClass/delete', scheduleGroupIds)

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Excluídos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.error(error)
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

    // REMOVE SOMENTE O AGENDAMENTO DO ESTUDANTE DO ENCONTRO
    async deleteStudentSchedule () {
      try {
        this.$vs.loading()
        let scheduleGroup = []
        const selectedDay = this.selectedDays[this.contextMenuIdx]

        if (selectedDay.turmaSchedule && selectedDay.turmaSchedule.length) scheduleGroup = selectedDay.turmaSchedule.filter(a => a.start_time === `${this.contextMenuHour.slice(0, 5)}:00` && a.student_id === this.student.id)
        else if (selectedDay.employeeSchedule && selectedDay.employeeSchedule.length) scheduleGroup = selectedDay.employeeSchedule.filter(a => a.start_time === `${this.contextMenuHour.slice(0, 5)}:00` && a.student_id === this.student.id)
        else if (selectedDay.classroomSchedule && selectedDay.classroomSchedule.length) scheduleGroup = selectedDay.classroomSchedule.filter(a => a.start_time === `${this.contextMenuHour.slice(0, 5)}:00` && a.student_id === this.student.id)
        const scheduleGroupIds = scheduleGroup.map(a => a.id)

        this.$store.commit('scheduleTheoreticalClass/DELETE_STUDENT_LIST_SCHEDULES', scheduleGroupIds)
        this.$emit('refreshData') // ATUALIZA O DATAGRID

        await this.$store.dispatch('scheduleTheoreticalClass/delete', scheduleGroupIds)

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Excluídos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.error(error)
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

    // REMOVE SOMENTE OS AGENDAMENTOS DO ESTUDANTE DE TODOS OS ENCONTROS DO DIA
    async deleteStudentScheduleDay () {
      try {
        this.$vs.loading()
        let scheduleGroup = []
        const selectedDay = this.selectedDays[this.contextMenuIdx]
        if (selectedDay.turmaSchedule && selectedDay.turmaSchedule.length) scheduleGroup = selectedDay.turmaSchedule.filter(a => a.student_id === this.student.id)
        else if (selectedDay.employeeSchedule && selectedDay.employeeSchedule.length) scheduleGroup = selectedDay.employeeSchedule.filter(a => a.student_id === this.student.id)
        else if (selectedDay.classroomSchedule && selectedDay.classroomSchedule.length) scheduleGroup = selectedDay.classroomSchedule.filter(a => a.student_id === this.student.id)
        const scheduleGroupIds = scheduleGroup.map(a => a.id)

        this.$store.commit('scheduleTheoreticalClass/DELETE_STUDENT_LIST_SCHEDULES', scheduleGroupIds)
        this.$emit('refreshData') // ATUALIZA O DATAGRID

        await this.$store.dispatch('scheduleTheoreticalClass/delete', scheduleGroupIds)

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Excluídos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.error(error)
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

    // ADICIONA UMA TURMA NA DATA/HORA SELECIONADA
    async addSchedule (selectedDay, hour, context = false) {
      const schedule = this.fillSchedule(selectedDay, hour)
      if (schedule.length && !context) {
        return                               // (PARA CLIQUES NAS BORDAS DA CÉLULA. OCORRE SOMENTE EM AGENDAMENTOS ÚNICOS ONDE A DIV NÃO COBRE TODO O TD)
      }

      if (!this.turma || !this.employee_id || !this.moduleCourse || !this.classroom) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione <br> <strong>TURMA, INSTRUTOR, DISCIPLINA e SALA</strong> <br> para o agendamento.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      try {
        this.$vs.loading()
        const payload = {
          type: this.type,
          turma_id: this.turma,
          module_id: this.moduleCourse,
          classroom_id: this.classroom ? this.classroom.id : null,
          employee_id: this.employee_id,
          start_date: selectedDay.id,
          start_time: `${hour.slice(0, 5)}:00`,
          end_time: `${hour.slice(6, 11)}:00`
        }

        const response = await this.$store.dispatch('scheduleTheoreticalClass/store', payload)
        this.$store.commit('schedule/ADD_STUDENT_LIST_SCHEDULES', response.data.filter(a => a.student_id === this.student.id))
        this.$emit('refreshData') // ATUALIZA O DATAGRID

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.log(error)
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

    // ADICIONA O ALUNO NA TURMA CASO ELE NÃO FAÇA PARTE E CRIA O AGENDAMENTO NO ENCONTRO SELECIONADO
    async addStudentSchedule () {
      try {
        this.$vs.loading()

        let scheduleGroup = []
        const selectedDay = this.selectedDays[this.contextMenuIdx]

        if (selectedDay.turmaSchedule && selectedDay.turmaSchedule.length) scheduleGroup = selectedDay.turmaSchedule.filter(a => a.start_time === `${this.contextMenuHour.slice(0, 5)}:00`)
        else if (selectedDay.employeeSchedule && selectedDay.employeeSchedule.length) scheduleGroup = selectedDay.employeeSchedule.filter(a => a.start_time === `${this.contextMenuHour.slice(0, 5)}:00`)
        else if (selectedDay.classroomSchedule && selectedDay.classroomSchedule.length) scheduleGroup = selectedDay.classroomSchedule.filter(a => a.start_time === `${this.contextMenuHour.slice(0, 5)}:00`)
        // const scheduleGroupIds = scheduleGroup.map(a => a.id)

        const localTurma = JSON.parse(JSON.stringify(scheduleGroup[0].turma))

        //ADICIONAR ELE NA TURMA CASO NÃO ESTEJA
        const idx = localTurma.students.findIndex(a => a.id === this.student.id) // VERIFICA SE O ALUNO ESTÁ NA TURMA
        if (idx < 0) {
          //INSIRO ELE NA TURMA
          localTurma.students = scheduleGroup.map(a => a.student_id) //ids
          localTurma.students.push(this.student.id)
          await this.$store.dispatch('turma/update', localTurma)
        }

        //CRIAR SOMENTE O AGENDAMENTO DELE (DEVE SER ENVIADO UM ARRAY DE AGENDAS)
        const payload = [
          {
            type: this.type,
            turma: localTurma,
            student: this.student,
            module_id: scheduleGroup[0].module_id,
            classroom_id: scheduleGroup[0].classroom_id,
            employee_id: scheduleGroup[0].employee_id,
            start_date: scheduleGroup[0].start_date,
            start_time: scheduleGroup[0].start_time,
            end_time: scheduleGroup[0].end_time
          }
        ]

        const response = await this.$store.dispatch('scheduleTheoreticalClass/storeStudentSchedule', payload)
        this.$store.commit('schedule/ADD_STUDENT_LIST_SCHEDULES', response.data.filter(a => a.student_id === this.student.id))
        this.$emit('refreshData') // ATUALIZA O DATAGRID

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.log(error)
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

    // ADICIONA O ALUNO NA TURMA CASO ELE NÃO FAÇA PARTE E CRIA O AGENDAMENTO EM TODOS OS ENCONTROS DO DIA
    async addStudentScheduleDay () {
      try {
        // this.$vs.loading()

        let turmaGroups = []
        const selectedDay = this.selectedDays[this.contextMenuIdx]

        if (selectedDay.turmaSchedule && selectedDay.turmaSchedule.length) turmaGroups = Object.values(_.groupBy(selectedDay.turmaSchedule, 'start_time')).filter(a => !a.some(a => a.student_id === this.student.id))
        else if (selectedDay.employeeSchedule && selectedDay.employeeSchedule.length) turmaGroups = Object.values(_.groupBy(selectedDay.turmaSchedule, 'start_time')).filter(a => !a.some(a => a.student_id === this.student.id))
        else if (selectedDay.classroomSchedule && selectedDay.classroomSchedule.length) turmaGroups = Object.values(_.groupBy(selectedDay.turmaSchedule, 'start_time')).filter(a => !a.some(a => a.student_id === this.student.id))

        //CRIAR SOMENTE O AGENDAMENTO DELE (DEVE SER ENVIADO UM ARRAY DE AGENDAS)
        const payload = []
        for (let index = 0; index < turmaGroups.length; index++) {
          const schedules = turmaGroups[index]
          const localTurma = JSON.parse(JSON.stringify(schedules[0].turma))
          //ADICIONAR ELE NA TURMA CASO NÃO ESTEJA
          const idx = localTurma.students.findIndex(a => a.id === this.student.id) // VERIFICA SE O ALUNO ESTÁ NA TURMA
          if (idx < 0) {
            //INSIRO ELE NA TURMA
            localTurma.students = schedules.map(a => a.student_id) //ids
            localTurma.students.push(this.student.id)
            await this.$store.dispatch('turma/update', localTurma)
          }

          payload.push({
            type: this.type,
            turma: schedules[0].turma,
            student: this.student,
            module_id: schedules[0].module_id,
            classroom_id: schedules[0].classroom_id,
            employee_id: schedules[0].employee_id,
            start_date: schedules[0].start_date,
            start_time: schedules[0].start_time,
            end_time: schedules[0].end_time
          })
        }
        const response = await this.$store.dispatch('scheduleTheoreticalClass/storeStudentSchedule', payload)
        this.$store.commit('schedule/ADD_STUDENT_LIST_SCHEDULES', response.data.filter(a => a.student_id === this.student.id))
        this.$emit('refreshData') // ATUALIZA O DATAGRID

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.log(error)
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

    handleRange (row, hoursPerRow = 6) {
      if (row === this.tables && this.selectedDays.length % hoursPerRow > 0) {
        return _.range((row * hoursPerRow) - (hoursPerRow - 1), (row * hoursPerRow) - (hoursPerRow - 1) + (this.selectedDays.length % hoursPerRow))
      }
      return _.range((row * hoursPerRow) - (hoursPerRow - 1), (row * hoursPerRow) + 1)
    },
    changeClassroom () {
      this.filterSchedule()
      this.checkCalssRommCapacity()
    },
    changeTurma () {
      this.moduleCourse = null
      this.modules.splice(0)
      const turma = this.turmaOptions.find(t => t.value === this.turma)
      if (turma) {
        for (const k in turma.data.course.modules) {
          this.modules.push({
            value: turma.data.course.modules[k].id,
            label: `${turma.data.course.modules[k].title} (${turma.data.course.modules[k].class_hour}h)`,
            data: turma.data.course.modules[k]
          })
        }
        this.checkCalssRommCapacity()
      }
      // REDESENHA A TABELA E PREENCHE COM OS AGENDAMENTOS
      this.filterSchedule()
    },
    checkCalssRommCapacity () {
      const turma = this.turmaOptions.find(t => t.value === this.turma)
      if (this.classroom && turma) {
        if (this.classroom.capacity - turma.data.students.length === 0) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'Limite de capacidade da sala ATINGIDO.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else if (this.classroom.capacity - turma.data.students.length < 0) {
          this.$vs.notify({
            time: 6000,
            title: 'ATENÇÃO',
            text: 'Limite de capacidade da sala SUPERADO.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

    // REDESENHA A TABELA E PREENCHE COM OS AGENDAMENTOS
    async filterSchedule () {
      // CASO NÃO ENVIE FILTRO ESVAZIA A AGENDA
      if (!this.turma && !this.employee_id && !this.moduleCourse && !this.classroom) {
        this.$store.commit('scheduleTheoreticalClass/SET_HOLIDAY_SCHEDULES', [])
        this.$store.commit('scheduleTheoreticalClass/SET_TURMA_SCHEDULES', [])
        this.$store.commit('scheduleTheoreticalClass/SET_EMPLOYEE_SCHEDULES', [])
        this.$store.commit('scheduleTheoreticalClass/SET_CLASSROOM_SCHEDULES', [])
        this.$store.commit('scheduleTheoreticalClass/FILL_SELECTED_DAYS')
        this.closeLoading()
        this.filterScheduleByStudent()
        return
      }
      try {
        this.openLoading()
        const payload = {
          getByDate: true,
          getByPeriod: false,
          type: this.type,
          dates: this.selectedDays.map(day => day.id),
          turma_id: this.turma,
          module_id: this.moduleCourse,
          employee_id: this.employee_id,
          classroom_id: this.classroom ? this.classroom.id : null
        }
        await this.$store.dispatch('scheduleTheoreticalClass/fetchFilter', payload) // BUSCA OS AGENDAMENTOS NA API CONFORME FILTRO
        this.$store.commit('scheduleTheoreticalClass/FILL_SELECTED_DAYS')           // COLOCA OS DADOS DOS AGENDAMENTOS EM SELECEDDAYS PARA PREENCHER A TABELA DE AGENDAS
        this.closeLoading()
      } catch (error) { console.error(error) }
    },

    async filterScheduleByStudent () {
      try {
        this.openLoading()
        const payload = {
          getByDate: true,
          getByPeriod: false,
          type: this.type,
          dates: this.selectedDays.map(day => day.id),
          student_id: this.student.id
        }
        await this.$store.dispatch('scheduleTheoreticalClass/fetchFilterByStudent', payload) // BUSCA OS AGENDAMENTOS NA API CONFORME FILTRO
        this.$store.commit('scheduleTheoreticalClass/FILL_SELECTED_DAYS')           // COLOCA OS DADOS DOS AGENDAMENTOS EM SELECEDDAYS PARA PREENCHER A TABELA DE AGENDAS
        this.closeLoading()
      } catch (error) { console.error(error) }
    },

    fillSchedule (schedule, hour) {
      if (schedule.turmaSchedule.length || schedule.employeeSchedule.length || schedule.classroomSchedule.length) {
        const format = 'h:mm:ss'
        const beforeTime = moment(`${hour.slice(0, 5)}:00`, format)
        const afterTime = moment(`${hour.slice(6, 11)}:00`, format)

        const turmaSchedule = schedule.turmaSchedule.filter(e => moment(e.start_time, format).isBetween(beforeTime, afterTime,  undefined, '[)'))
        if (turmaSchedule.length) {
          /**
           * IMPORTANTE
           * AQUI EU TENHO AS AGENDAS DE TODOS OS PARTICIPANTES DO CURSO NO MEMSO DIA E HORA
           * COMO ESTOU MARCANDO APENAS A TURMA E DISCIPLINA, OU SEJA, SE TEM OU NÃO, NÃO LISTO TODAS AS AGENDAS, APENAS
           * MARCO A DATA/HORA COMO BLOQUEADA POR UMA TURMA, POR ISSO TENTO EVITAD LOOPS DESNECESSÁRIOS
           * RETORNANDO SOMENTE A PRIMEIRA AGENDA.
           * NOTE QUE TAMBÉM PINTO O BG SOMENTE DA 1ª AGENDA
           */
          turmaSchedule[0].bg = 'background-color: rgba(21, 132, 130, 0.3);' // #158482
          turmaSchedule[0].allStudents = turmaSchedule.map(a => a.student_id)
          return [turmaSchedule[0]]
        }

        // O MESMO PARA INSTRUTORES
        const employeeSchedule = schedule.employeeSchedule.filter(e => moment(e.start_time, format).isBetween(beforeTime, afterTime,  undefined, '[)'))
        if (employeeSchedule.length) {
          employeeSchedule[0].bg = 'background-color: rgba(255, 159, 67, 0.3);' // #FF9F43
          employeeSchedule[0].allStudents = employeeSchedule.map(a => a.student_id)
          return [employeeSchedule[0]]
        }

        // O MESMO PARA SALAS
        const classroomSchedule = schedule.classroomSchedule.filter(e => moment(e.start_time, format).isBetween(beforeTime, afterTime,  undefined, '[)'))
        if (classroomSchedule.length) {
          classroomSchedule[0].bg = 'background-color: rgba(234, 84, 85, 0.3);' // #EA5455
          classroomSchedule[0].allStudents = classroomSchedule.map(a => a.student_id)
          return [classroomSchedule[0]]
        }
      }
      return []
    },

    fetchStudentOptions (search, loading) {
      if (search.length) {
        loading(true)
        this.$http.post(`${process.env.VUE_APP_API_URL}/student/search`, { kw: search, type: 'STUDENT', with: ['schedules'] })
          .then(function (response) {
            this.studentOptions = []
            for (const k in response.data) {
              this.studentOptions.push({
                value:  response.data[k].id,
                label:  `${response.data[k].name} ${response.data[k].cpf || ''}`,
                data:   response.data[k]
              })
            }
            loading(false)
          }.bind(this))
          .catch(function (error) {
            console.error(error)
          })

      } else {
        loading(false)
      }
    },

    fetchTurmaOptions (search, loading) {
      if (search.length) {
        loading(true)
        this.$http.post(`${process.env.VUE_APP_API_URL}/turma/search`, { kw: search })
          .then(function (response) {
            this.turmaOptions.length = 0
            for (const k in response.data) {
              this.turmaOptions.push({
                value:  response.data[k].id,
                label:  response.data[k].name,
                data:   response.data[k]
              })
            }
            loading(false)
          }.bind(this))
          .catch(function (error) {
            console.error(error)
          })
      } else {
        loading(false)
      }
    },
    pushTurmaOption () {
      this.turmaOptions.push({
        value: this.turmaVuex.id,
        label: this.turmaVuex.name,
        data:  this.turmaVuex
      })
      this.turma = this.turmaVuex.id
      this.changeTurma()
    },
    editTurma () {
      // ESTE BLOCO SERVE PARA ATUALIZAR O SELECT DAS TURMA Q TBÉM ATUALIZA AS DISCIPLINAS
      const idx = this.turmaOptions.findIndex(a => a.value === this.turmaVuex.id)
      if (idx >= 0) this.turmaOptions.splice(idx, 1)
      this.turma = null
      this.pushTurmaOption()
    },
    deleteTurma (id) {
      // ESTE BLOCO SERVE PARA ATUALIZAR O SELECT DAS TURMA Q TBÉM ATUALIZA AS DISCIPLINAS
      const idx = this.turmaOptions.findIndex(a => a.value === id)
      if (idx >= 0) this.turmaOptions.splice(idx, 1)
      this.turma = null
      this.changeTurma()
    }

  },
  components: {
    VuePerfectScrollbar,
    ScheduleSidebar,
    vSelect,
    VueContext
  },

  created () {
    this.debouncedGetSearchStudent = _.debounce(this.fetchStudentOptions, 600)
    this.debouncedGetSearchTurma = _.debounce(this.fetchTurmaOptions, 600)
    this.debouncedGetFilterSchedule = _.debounce(this.filterSchedule, 1000)

    // Register Module StudentManagement Module
    if (!moduleStudentManagement.isRegistered) {
      this.$store.registerModule('studentManagement', moduleStudentManagement)
      moduleStudentManagement.isRegistered = true
    }

    if (!moduleScheduleTheoreticalClass.isRegistered) {
      this.$store.registerModule('scheduleTheoreticalClass', moduleScheduleTheoreticalClass)
      moduleScheduleTheoreticalClass.isRegistered = true
    }

    if (!moduleEmployee.isRegistered) {
      this.$store.registerModule('employee', moduleEmployee)
      moduleEmployee.isRegistered = true
    }
    this.$store.dispatch('employee/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleClassroom.isRegistered) {
      this.$store.registerModule('classroom', moduleClassroom)
      moduleClassroom.isRegistered = true
    }
    this.$store.dispatch('classroom/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleTurma.isRegistered) {
      this.$store.registerModule('turma', moduleTurma)
      moduleTurma.isRegistered = true
    }

    this.student = JSON.parse(JSON.stringify(this.$store.getters['studentManagement/getStudent']))

  },
  mounted () {
    this.setSidebarWidth()
  }
}

</script>
<style lang="scss">
// COLOCA O BACKGROUND NA FRENTE DO SIDEBAR PARA QUE POSSA SER CLICADO
#email-app .vs-sidebar--background {
    z-index: 40000 !important;
}
// FAZ COM QUE O ESPAÇO DO CALENDÁRIO (TABELA) APAREÇA POR COMPLETO NO FINAL DA BARRA DE ROLAGEM
#email-app .email-content-scroll-area {
  height: calc(100% - 190px) !important;
}

#email-app th .vs-table-text {
    cursor: default;
}
.svg-hover:hover {
  fill: #158482;
}
.svg-hover {
  fill: #626262;
}
#email-app td {
  border: 1px solid #c0c0c0;
  line-height: 1;
}
#email-app td:hover {
  border-bottom: 1px solid #353434;
}
#email-app td:first-child:hover {
  border-bottom: 1px solid #c0c0c0;
}
#email-app .vs-con-table td:last-child {
  border-right: 1px solid transparent;
}
#email-app .vs-table--thead {
  border: 1px solid transparent;
}
.icon {
  font-size: 16px;
}
#email-app .vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
    padding: 6px;
}
#email-app .ps__scrollbar-y-rail {
  z-index: 99999;
}
</style>
