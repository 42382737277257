<template>
  <div class="flex items-center">{{date ? date : '--'}}</div>
</template>

<script>
export default {
  name: 'CellRendererDateTime',
  computed: {
    dateTime () {
      if (this.params.value) {
        const [YYYY, MM, DD] = (this.params.value.slice(0, 10)).split('-')
        const [H, m] = (this.params.value.slice(11, 19)).split(':')
        return `${DD}/${MM}/${YYYY} ${H}:${m}h`
      }
      return null
    },
    date () {
      if (this.params.value) {
        const [YYYY, MM, DD] = (this.params.value.slice(0, 10)).split('-')
        return `${DD}/${MM}/${YYYY}`
      }
      return null
    }
  }
}
</script>
