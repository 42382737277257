<template>
  <div>
    <vs-popup :active.sync="localActive" title="Cancelamento de contrato">
      <cancel-contract-form :contractId="contractId"  @saved="this.saved"/>
    </vs-popup>
  </div>
</template>

<script>
import CancelContractForm from './CancelContractForm'
export default {
  components: {
    CancelContractForm
  },
  props: {
    contractId: {},
    active: {}
  },
  data () {
    return {}
  },
  computed:{
    localActive: {
      get () {
        return this.active
      },
      set (value) {
        this.$emit('update:active', value)
      }
    }
  },
  methods: {
    saved () {
      this.localActive = false
      this.$emit('saved')
    }
  }
}
</script>

