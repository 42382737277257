<template>
  <div id="user-edit-tab-documents">

    <vs-popup class="popup80 noOverflow" :fullscreen="false" :title="showPdfTitle" :active.sync="popupShowPdf">
      <object :data="srcPdf" width="100%" style="height: 600px" />
    </vs-popup>

    <vs-popup class="popup80" :fullscreen="false" title="Declaração de Comparecimento" :active.sync="popupComparecimento">

        <div class="vx-row">
         <div class="vx-col md:w-1/4 w-full mt-0">
            <vs-input type="date" class="w-full mt-0" label="Data Inicial" v-model="comparecimento.startDate" data-vv-scope="comparecimento" data-vv-as="Data inicial" v-validate.initial="'required'" name="startDate" />
            <span class="text-danger text-sm" v-show="errors.has('comparecimento.startDate')">{{ errors.first('comparecimento.startDate') }}</span>
          </div>
          <div class="vx-col md:w-1/4 w-full mt-0">
            <vs-input type="date" class="w-full mt-0" label="Data Final" v-model="comparecimento.endDate" data-vv-scope="comparecimento" data-vv-as="Data final" v-validate.initial="'required'" name="finalDate" />
            <span class="text-danger text-sm" v-show="errors.has('comparecimento.finalDate')">{{ errors.first('comparecimento.finalDate') }}</span>
          </div>
          <div class="vx-col md:w-1/4 w-full mt-0">
            <vs-input type="time" class="w-full mt-0" label="Hora Inicial" v-model="comparecimento.inicial_hour" data-vv-scope="comparecimento" data-vv-as="Hora Inicial" v-validate.initial="'required'" name="inicial_hour" />
            <span class="text-danger text-sm" v-show="errors.has('comparecimento.inicial_hour')">{{ errors.first('comparecimento.inicial_hour') }}</span>
          </div>
          <div class="vx-col md:w-1/4 w-full mt-0">
            <vs-input type="time" class="w-full mt-0" label="Hora Final" v-model="comparecimento.final_hour" data-vv-scope="comparecimento" data-vv-as="Hora Final" v-validate.initial="'required'" name="final_hour" />
            <span class="text-danger text-sm" v-show="errors.has('comparecimento.final_hour')">{{ errors.first('comparecimento.final_hour') }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-full w-full mt-0">
            <vs-input class="w-full mt-0" label="Motivo" v-model="comparecimento.motivo" />
          </div>
        </div>

        <vs-divider></vs-divider>

        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="mt-4 flex flex-wrap items-center justify-end">
              <vs-button color="secondary" class="ml-auto" @click="popupComparecimento = false">Cancelar</vs-button>
              <vs-button class="ml-4" @click="showPdf(2)" :disabled="!validateComparecimento">Gerar Documento</vs-button>
            </div>
          </div>
        </div>

    </vs-popup>

    <vs-popup class="popup50" :fullscreen="false" title="Declaração Rebaixamento de Categ." :active.sync="popupRebaixamento">

        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full mt-0">
            <label class="vs-input--label">Exerce AR</label>
            <v-select class="vue_select_drop_size_90" :clearable="false" v-model="rebaixamento.ar" :reduce="option => option.value"
              :options="[{value: 1, label: 'SIM'}, {value: 0, label: 'NÃO'}]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
          <div class="vx-col md:w-3/4 w-full mt-0">
            <label class="vs-input--label">Categoria Desejada</label>
            <v-select class="vue_select_drop_size_90" :clearable="false" v-model="rebaixamento.catDesejada" :reduce="option => option.value"
              :options="categoryCNHOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
        </div>

        <vs-divider></vs-divider>

        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="mt-4 flex flex-wrap items-center justify-end">
              <vs-button color="secondary" class="ml-auto" @click="popupRebaixamento = false">Cancelar</vs-button>
              <vs-button class="ml-4" @click="showPdf(8)">Gerar Documento</vs-button>
            </div>
          </div>
        </div>

    </vs-popup>

    <vs-popup class="popup80" :fullscreen="false" title="Controle de Frequência" :active.sync="popupFrequencyControl">

        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full mt-0">
            <label class="vs-input--label">Diretor de Ensino</label>
            <v-select class="vue_select_drop_size_90" :clearable="false" v-model="frequencyControl.teaching_director_id" :reduce="option => option.value"
              :options="employees" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
          <div class="vx-col md:w-1/4 w-full mt-0">
            <label class="vs-input--label">Instrutor</label>
            <v-select class="vue_select_drop_size_90" :clearable="false" v-model="frequencyControl.instructor_id" :reduce="option => option.value"
              :options="employees" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
          <div class="vx-col md:w-1/4 w-full mt-0">
            <label class="vs-input--label">Serviço</label>
            <v-select class="vue_select_drop_size_90" :clearable="false" v-model="frequencyControl.service" :reduce="option => option.value"
              :options="[
                { value: 1, label: 'Primeira Habilitação' },
                { value: 2, label: 'Adição' },
                { value: 3, label: 'Mudança de categoria' }
              ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
          <div class="vx-col md:w-1/4 w-full mt-0">
            <label class="vs-input--label">Categoria</label>
            <v-select class="vue_select_drop_size_90" :clearable="false" v-model="frequencyControl.category" :reduce="option => option.value"
              :options="category2CNHOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
        </div>

        <vs-divider></vs-divider>

        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="mt-4 flex flex-wrap items-center justify-end">
              <vs-button color="secondary" class="ml-auto" @click="popupFrequencyControl = false">Cancelar</vs-button>
              <vs-button class="ml-4" @click="showPdf(13)">Gerar Documento</vs-button>
            </div>
          </div>
        </div>

    </vs-popup>

    <!-- MODELO DE DOCUMENTO -->
    <!-- <vs-popup :fullscreen="true" :title="newDocumentTemplatePopupTitleModel" :active.sync="popupDocumentTemplate">

      <div class="vx-row">
        <div class="vx-col md:w-3/4 w-full mt-0">
          <vs-input class="w-full mt-0" label="Título" v-model="documentTemplate.title" data-vv-as="Título" data-vv-scope="documentTemplate" v-validate.initial="'required'" name="title" />
          <span class="text-danger text-sm" v-show="errors.has('documentTemplate.title')">{{ errors.first('documentTemplate.title') }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Cabeçalho</label>
          <v-select v-model="documentTemplate.print_header" :reduce="option => option.value" :clearable="false" :options="[{label: 'SIM', value: true}, {label: 'NÃO', value: false}]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"></v-select>
        </div>
      </div>

      <div class="vx-row mt-4">
        <div class="vx-col md:w-3/4 w-full mt-0">
          <div>
            <ckeditor :editor="editor" v-model="documentTemplate.body" :config="editorConfig" @ready="onReady"></ckeditor>
            <div class="vx-row">
              <div class="vx-col w-full">
                <div class="mt-0 flex flex-wrap items-center justify-end">
                  <vs-button class="ml-auto mt-4" color="secondary" @click="popupdocumentTemplate = false">Cancelar</vs-button>
                  <vs-button class="ml-4 mt-4" @click="saveDocumentTemplate" :disabled="!validateDocumentFormModel">Salvar Modelo De Documento</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <vs-divider position="left"><strong class="text-sm">Variáveis do Aluno</strong></vs-divider>
          <p v-for="(variable, i) in studentVariables" :key="`${i}_studentVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
              <vx-tooltip :text="variable.variable" position="left">
              <span class="under mb-1">{{variable.label}}</span>
              </vx-tooltip>
          </p>
          <vs-divider position="left"><strong class="text-sm">Endereço</strong></vs-divider>
          <p v-for="(variable, i) in addressVariables" :key="`${i}_addressVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
              <vx-tooltip :text="variable.variable" position="left">
              <span class="under mb-1">{{variable.label}}</span>
              </vx-tooltip>
          </p>
          <vs-divider position="left"><strong class="text-sm">Avalista</strong></vs-divider>
          <p v-for="(variable, i) in guarantorVariables" :key="`${i}_guarantorVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
              <vx-tooltip :text="variable.variable" position="left">
              <span class="under mb-1">{{variable.label}}</span>
              </vx-tooltip>
          </p>
          <vs-divider position="left"><strong class="text-sm">Locatário</strong></vs-divider>
          <p v-for="(variable, i) in tenantVariables" :key="`${i}_tenantVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
              <vx-tooltip :text="variable.variable" position="left">
              <span class="under mb-1">{{variable.label}}</span>
              </vx-tooltip>
          </p>
          <vs-divider position="left"><strong class="text-sm">Contrato</strong></vs-divider>
          <p v-for="(variable, i) in contractVariables" :key="`${i}_contractVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
            {{variable.label}}
          </p>
        </div>
      </div>
    </vs-popup> -->
    <div class="vx-card p-2">
      <div class="flex flex-wrap items-center">
        <div class="flex-grow"> </div>
        <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-3 mt-4 ml-6" style="margin-top: 19px !important" v-model="searchQuery" @input="updateSearchQuery" placeholder="Busca rápida por documento..." />
        <vx-tooltip :text="accordionDocumentTemplate ? 'Fechar Cadastro de Documento' : 'Cadastrar Documento'" position="top" class="ml-1 mb-4 sm:mt-8 md:mb-0" style="margin-top: 19px !important">
          <feather-icon v-if="!accordionDocumentTemplate" @click="addDocumentTemplate" icon="FilePlusIcon" svgClasses="h-8 w-8 cursor-pointer hover:text-primary"></feather-icon>
          <feather-icon v-else @click="accordionDocumentTemplate = false" icon="FileMinusIcon" svgClasses="h-8 w-8 cursor-pointer hover:text-primary"></feather-icon>
        </vx-tooltip>
      </div>

      <div v-if="accordionDocumentTemplate" class="vx-row mt-0">
        <div class="vx-col md:w-full w-full mt-0">
          <div class="vx-row">
            <div class="vx-col md:w-3/4 w-full mt-0">
              <vs-input class="w-full mt-0" label="Título" v-model="documentTemplate.title" data-vv-as="Título" data-vv-scope="documentTemplate" v-validate.initial="'required'" name="title" />
              <span class="text-danger text-sm" v-show="errors.has('documentTemplate.title')">{{ errors.first('documentTemplate.title') }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <label class="vs-input--label">Cabeçalho</label>
              <v-select v-model="documentTemplate.print_header" :reduce="option => option.value" :clearable="false" :options="[{label: 'SIM', value: true}, {label: 'NÃO', value: false}]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"></v-select>
            </div>
          </div>

          <div class="vx-row mt-4">
            <div class="vx-col md:w-3/4 w-full mt-0">
              <div>
                <ckeditor :editor="editor" v-model="documentTemplate.body" :config="editorConfig" @ready="onReady"></ckeditor>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <div class="mt-0 flex flex-wrap items-center justify-end">
                      <vs-button class="ml-auto mt-4" color="secondary" @click="accordionDocumentTemplate = false">Cancelar</vs-button>
                      <vs-button class="ml-4 mt-4" @click="saveDocumentTemplate" :disabled="!validateDocumentFormModel">Salvar Modelo De Documento</vs-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-col md:w-1/4 w-full mt-0">
              <vs-divider position="left"><strong class="text-sm">Variáveis do Aluno</strong></vs-divider>
              <p v-for="(variable, i) in studentVariables" :key="`${i}_studentVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
                  <vx-tooltip :text="variable.variable" position="left">
                  <span class="under mb-1">{{variable.label}}</span>
                  </vx-tooltip>
              </p>
              <vs-divider position="left"><strong class="text-sm">Endereço</strong></vs-divider>
              <p v-for="(variable, i) in addressVariables" :key="`${i}_addressVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
                  <vx-tooltip :text="variable.variable" position="left">
                  <span class="under mb-1">{{variable.label}}</span>
                  </vx-tooltip>
              </p>
              <vs-divider position="left"><strong class="text-sm">Avalista</strong></vs-divider>
              <p v-for="(variable, i) in guarantorVariables" :key="`${i}_guarantorVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
                  <vx-tooltip :text="variable.variable" position="left">
                  <span class="under mb-1">{{variable.label}}</span>
                  </vx-tooltip>
              </p>
              <vs-divider position="left"><strong class="text-sm">Locatário</strong></vs-divider>
              <p v-for="(variable, i) in tenantVariables" :key="`${i}_tenantVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
                  <vx-tooltip :text="variable.variable" position="left">
                  <span class="under mb-1">{{variable.label}}</span>
                  </vx-tooltip>
              </p>
              <vs-divider position="left"><strong class="text-sm">Contrato</strong></vs-divider>
              <p v-for="(variable, i) in contractVariables" :key="`${i}_contractVariables`" class="text-sm cursor-pointer" @click="copyVariable(variable.variable)">
                {{variable.label}}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="vx-row mt-4">
        <div class="vx-col md:w-1/2 w-full mt-0">
          <h3>Modelos</h3>
          <vs-table class="bordered" :data="preDocumentsFiltered" hoverFlat style="overflow: -webkit-paged-y;">
            <template slot="thead">
              <vs-th style="font-size: 11px; width: 85px"><span>Título</span></vs-th>
              <vs-th style="font-size: 11px; width: 85px"><span>Ação</span></vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr v-for="(doc, i) in data" :key="i" style="font-size: 12px; opacity: 1 !important">
                <vs-td style="text-align:left"><span class="cursor-default font-semibold">{{ doc.title }}</span></vs-td>
                <vs-td class="flex flex-wrap items-center">
                    <feather-icon @click="print(doc, 'PDF')" title="Imprimir" icon="PrinterIcon" svgClasses="h-5 w-5 mr-2 hover:text-primary cursor-pointer" />
                    <!-- <svg @click="print(doc, 'WORD')" class="svg-hover h-6 w-6 cursor-pointer" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M282.208,19.67c-3.648-3.008-8.48-4.256-13.152-3.392l-256,48C5.472,65.686,0,72.278,0,79.99v352
                          c0,7.68,5.472,14.304,13.056,15.712l256,48c0.96,0.192,1.984,0.288,2.944,0.288c3.68,0,7.328-1.28,10.208-3.68
                          c3.68-3.04,5.792-7.584,5.792-12.32v-448C288,27.222,285.888,22.71,282.208,19.67z M256,460.694L32,418.71V93.27l224-41.984
                          V460.694z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M496,79.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h208v288H272c-8.832,0-16,7.168-16,16
                          c0,8.832,7.168,16,16,16h224c8.832,0,16-7.168,16-16v-320C512,87.158,504.832,79.99,496,79.99z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M432,143.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16
                          C448,151.158,440.832,143.99,432,143.99z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M432,207.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16
                          C448,215.158,440.832,207.99,432,207.99z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M432,271.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16
                          C448,279.158,440.832,271.99,432,271.99z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M432,335.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16
                          C448,343.158,440.832,335.99,432,335.99z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M209.76,176.086c-8.48-0.864-16.704,5.344-17.664,14.112l-8.608,77.504l-24.512-65.344
                          c-4.704-12.48-25.312-12.48-29.984,0l-26.016,69.408l-7.136-50.048c-1.248-8.768-9.44-14.976-18.112-13.568
                          c-8.736,1.248-14.816,9.344-13.568,18.08l16,112c1.024,7.264,6.848,12.896,14.112,13.664c7.424,0.736,14.144-3.424,16.704-10.272
                          L144,253.558l33.024,88.064c2.368,6.272,8.384,10.368,14.976,10.368c0.672,0,1.312-0.032,1.984-0.16
                          c7.328-0.896,13.088-6.752,13.92-14.08l16-144C224.864,184.982,218.56,177.078,209.76,176.086z"></path>
                      </g>
                    </g>
                    </svg> -->
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
        <div class="vx-col md:w-1/2 w-full mt-0">
          <h2>Documentos Criados</h2>
          <vs-table noDataText="Nenhum dado a ser mostrado." class="bordered" :data="documentsFiltered" hoverFlat style="overflow: -webkit-paged-y;">
            <template slot="thead">
              <vs-th style="font-size: 11px; width: 85px"><span>Título</span></vs-th>
              <vs-th style="font-size: 11px; width: 85px"><span>Ação</span></vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr v-for="(doc, i) in data" :key="i" style="font-size: 12px; opacity: 1 !important">
                <vs-td style="text-align:left"><span class="cursor-default font-semibold">{{ doc.title }}</span></vs-td>
                <vs-td class="flex flex-wrap items-center">
                    <feather-icon @click="printTemplate(doc, 'PDF')" title="Imprimir" icon="PrinterIcon" svgClasses="h-5 w-5 mr-2 hover:text-primary cursor-pointer" />
                    <feather-icon @click="update(doc)" title="Alterar" icon="EditIcon" svgClasses="h-5 w-5 mr-2 hover:text-danger cursor-pointer" />
                    <feather-icon @click="confirmDelete(doc)" title="Excluir" icon="Trash2Icon" svgClasses="h-5 w-5 mr-2 hover:text-danger cursor-pointer" />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import _ from 'lodash'
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
import moduleEmployee from '@/store/employee/moduleEmployee.js'
import moduleDocuments from '@/store/documents/moduleDocuments.js'

import CKEditor from '@ckeditor/ckeditor5-vue2'

// ⚠️ NOTE: We don't use @ckeditor/ckeditor5-build-classic any more!
// Since we're building CKEditor from source, we use the source version of ClassicEditor.
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import ClassicEditor from '@/util/ckeditor5/src/ckeditor'
import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br'

import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat.js'
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote.js'
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js'
import Code from '@ckeditor/ckeditor5-basic-styles/src/code.js'
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock.js'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js'
import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport.js'
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js'
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed.js'
import Image from '@ckeditor/ckeditor5-image/src/image.js'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption.js'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle.js'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar.js'
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload.js'
import Indent from '@ckeditor/ckeditor5-indent/src/indent.js'
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js'
import Link from '@ckeditor/ckeditor5-link/src/link.js'
import List from '@ckeditor/ckeditor5-list/src/list.js'
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed.js'
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js'
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice.js'
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting.js'
import Table from '@ckeditor/ckeditor5-table/src/table.js'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar.js'
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation.js'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment.js'
import AutoImage from '@ckeditor/ckeditor5-image/src/autoimage.js'
import CKFinderUploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter.js'
import CloudServices from '@ckeditor/ckeditor5-cloud-services/src/cloudservices.js'
import FindAndReplace from '@ckeditor/ckeditor5-find-and-replace/src/findandreplace.js'
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js'
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor.js'
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily.js'
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize.js'
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight.js'
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize.js'
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock.js'
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat.js'
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters.js'
import SpecialCharactersArrows from '@ckeditor/ckeditor5-special-characters/src/specialcharactersarrows.js'
import SpecialCharactersCurrency from '@ckeditor/ckeditor5-special-characters/src/specialcharacterscurrency.js'
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials.js'
import SpecialCharactersLatin from '@ckeditor/ckeditor5-special-characters/src/specialcharacterslatin.js'
import SpecialCharactersMathematical from '@ckeditor/ckeditor5-special-characters/src/specialcharactersmathematical.js'
import SpecialCharactersText from '@ckeditor/ckeditor5-special-characters/src/specialcharacterstext.js'
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption.js'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline.js'
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount.js'


//STORE
import moduleStudentManagement from '@/store/student-management/moduleStudentManagement.js'

export default {
  components: {
    vSelect,
    ckeditor: CKEditor.component
  },

  data () {
    return {
      activeTab: 0,
      data_local: {},
      disableSave: false,

      accordionDocumentTemplate: false,
      newDocumentTemplatePopupTitleModel: null,
      documentTemplate: {
        id: null,
        title: null,
        print_header: false,
        body: ''
      },
      // popupDocumentTemplate: false,

      ckEd: null,
      editor: ClassicEditor,
      editorConfig: {
        language: 'pt-br',
        plugins: [
          Autoformat,
          BlockQuote,
          Bold,
          Code,
          CodeBlock,
          Essentials,
          GeneralHtmlSupport,
          Heading,
          HtmlEmbed,
          Image,
          ImageCaption,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          Indent,
          Italic,
          Link,
          List,
          MediaEmbed,
          Paragraph,
          PasteFromOffice,
          SourceEditing,
          Table,
          TableToolbar,
          TextTransformation,
          Alignment,
          AutoImage,
          CKFinderUploadAdapter,
          CloudServices,
          FindAndReplace,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          FontSize,
          Highlight,
          ImageResize,
          IndentBlock,
          RemoveFormat,
          SpecialCharacters,
          SpecialCharactersArrows,
          SpecialCharactersCurrency,
          SpecialCharactersEssentials,
          SpecialCharactersLatin,
          SpecialCharactersMathematical,
          SpecialCharactersText,
          TableCaption,
          TableCellProperties,
          TableProperties,
          Underline,
          WordCount
        ],
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            // 'link',
            // 'bulletedList',
            // 'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            // 'imageUpload',
            'blockQuote',
            'insertTable',
            // 'mediaEmbed',
            'undo',
            'redo',
            'alignment',
            'code',
            'findAndReplace',
            // 'fontBackgroundColor',
            // 'fontColor',
            'fontFamily',
            'fontSize',
            'highlight',
            'htmlEmbed',
            'removeFormat',
            'sourceEditing'
            // 'specialCharacters'
          ]
        },
        image: {
          toolbar: [
            'imageTextAlternative',
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side'
          ]
        },
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells'
            // 'tableCellProperties',
            // 'tableProperties'
          ]
        },
        htmlEmbed: {
          showPreviews: true
          // sanitizeHtml: (inputHtml) => {
          //   // Strip unsafe elements and attributes, e.g.:
          //   // the `<script>` elements and `on*` attributes.
          //   const outputHtml = sanitize(inputHtml)

          //   return {
          //     html: outputHtml,
          //     // true or false depending on whether the sanitizer stripped anything.
          //     hasChanged: true
          //   }
          // }
        },
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Paragraph',
              class: 'ck-heading_paragraph'
            },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Heading 1',
              class: 'ck-heading_heading1'
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Heading 2',
              class: 'ck-heading_heading2'
            }
          ]
        }
      },

      // Variáveis do aluno
      studentVariables: [
        {variable: '[logo_empresa]', label: 'Logo da empresa'},
        {variable: '[dia]', label: 'Dia'},
        {variable: '[mes]', label: 'Mês'},
        {variable: '[ano]', label: 'Ano'},
        {variable: '[nome_aluno]', label: 'Nome'},
        {variable: '[nome_social]', label: 'Nome social'},
        {variable: '[data_cadastro]', label: 'Data de cadastro'},
        {variable: '[renach]', label: 'Nº do formulário RENACH'},
        {variable: '[cat_atual]', label: 'Categoria atual'},
        {variable: '[cat_pret]', label: 'Categoria pretendida'},
        {variable: '[data_nascimento]', label: 'Data de nascimento'},
        {variable: '[idade]', label: 'Idade'},
        {variable: '[nome_mae]', label: 'Nome da mãe'},
        {variable: '[nome_pai]', label: 'Nome do pai'},
        {variable: '[uf_nascimento]', label: 'Estado de nascimento'},
        {variable: '[cidade_nascimento]', label: 'Cidade de nascimento'},
        {variable: '[cpf_aluno]', label: 'CPF do aluno'},
        {variable: '[rg_aluno]', label: 'RG do aluno'},
        {variable: '[orgao_emissor]', label: 'Órgão emissor RG'},
        {variable: '[uf_rg]', label: 'Estado do RG'},
        {variable: '[nacionalidade_aluno]', label: 'Nacionalidade'},
        {variable: '[genero_aluno]', label: 'Gênero do aluno'},
        {variable: '[grau_instrucao]', label: 'Grau de instrução'},
        {variable: '[telefone_fixo]', label: 'Telefone fixo'},
        {variable: '[celular]', label: 'Celular'},
        {variable: '[email_aluno]', label: 'E-mail'},
        {variable: '[profissao_aluno]', label: 'Profissão'},
        {variable: '[estado_civil_aluno]', label: 'Estado civil'},
        {variable: '[extra_field]', label: 'Campo extra'},
        {variable: '[servicos_desejados]', label: 'Serviços Desejados'}
      ],

      // ENDEREÇO:
      addressVariables: [
        {variable: '[logo_empresa]', label: 'Logo da empresa'},
        {variable: '[estado_aluno]', label: 'Estado'},
        {variable: '[cidade_aluno]', label: 'Cidade'},
        {variable: '[rua_aluno]', label: 'Rua'},
        {variable: '[numero_endereco]', label: 'Número'},
        {variable: '[bairro_aluno]', label: 'Bairro'},
        {variable: '[cep_aluno]', label: 'CEP'},
        {variable: '[complemento_endereco]', label: 'Complemento'}
      ],

      // AVALISTA:
      guarantorVariables: [
        {variable: '[nome_avalista]', label: 'Nome avalista'},
        {variable: '[cpf_avalista]', label: 'CPF avalista'},
        {variable: '[rg_avalista]', label: 'RG avalista'},
        {variable: '[orgao_emissor_rg_avalista]', label: 'Órg. emissor RG avalista'},
        {variable: '[uf_rg_avalista]', label: 'UF RG avalista'},
        {variable: '[local_trabalho_avalista]', label: 'Local trabalho avalista'},
        {variable: '[conjuge_avalista]', label: 'Cônjuge avalista'},
        {variable: '[estado_avalista]', label: 'Estado'},
        {variable: '[cidade_avalista]', label: 'Cidade'},
        {variable: '[rua_avalista]', label: 'Rua'},
        {variable: '[numero_endereco_avalista]', label: 'Número end.'},
        {variable: '[bairro_avalista]', label: 'Bairro'},
        {variable: '[cep_avalista]', label: 'CEP'},
        {variable: '[complemento_endereco_avalista]', label: 'Complemento end.'}
      ],

      // LOCATÁRIO
      tenantVariables: [
        {variable: '[nome_locatario]', label: 'Nome Locatário'},
        {variable: '[cpf_locatario]', label: 'CPF Locatário'},
        {variable: '[rg_locatario]', label: 'RG Locatário'},
        {variable: '[orgao_emissor_rg_locatario]', label: 'Órg. emissor RG Locatário'},
        {variable: '[uf_rg_locatario]', label: 'UF RG Locatário'},
        {variable: '[estado_locatario]', label: 'Estado'},
        {variable: '[cidade_locatario]', label: 'Cidade'},
        {variable: '[rua_locatario]', label: 'Rua'},
        {variable: '[numero_endereco_locatario]', label: 'Número end.'},
        {variable: '[bairro_locatario]', label: 'Bairro'},
        {variable: '[cep_locatario]', label: 'CEP'}
      ],

      // CONTRATO:
      contractVariables: [
        {variable: '[valor_total]', label: 'Valor total do contrato'},
        {variable: '[valor_das_parcelas]', label: 'Valor de cada parcela'},
        {variable: '[valor_da_primeira_parcela]', label: 'Valor da primeira parcela/entrada'},
        {variable: '[numero_total_de_parcelas]', label: 'Número de parcelas'},
        {variable: '[vencimento_de_parcelas]', label: 'Vencimento de parcelas'},
        {variable: '[nome_do_servico]', label: 'Nome do serviço'},
        {variable: '[pacote_detalhado]', label: 'Pacote detalhado'},
        {variable: '[multa_do_contrato]', label: 'Multa do contrato'},
        {variable: '[juros_do_contrato]', label: 'Juros do contrato'}
      ],

      showPdfTitle: 'PDF',
      srcPdf: '',
      popupShowPdf: false,
      popupComparecimento: false,
      popupRebaixamento: false,
      popupFrequencyControl: false,

      comparecimento: {
        date: this.today,
        inicial_hour: null,
        final_hour:null,
        motivo: null
      },

      rebaixamento: {
        ar: 0,
        catDesejada: 'A'
      },

      frequencyControl: {
        teaching_director_id: null,
        instructor_id: null,
        service: 1,
        category: 'A'
      },

      categoryCNHOptions: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'AB', value: 'AB' },
        { label: 'C', value: 'C' },
        { label: 'D', value: 'D' },
        { label: 'E', value: 'E' },
        { label: 'AC', value: 'AC' },
        { label: 'AD', value: 'AD' },
        { label: 'AE', value: 'AE' },
        { label: 'ACC', value: 'ACC' }
      ],

      category2CNHOptions: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'C', value: 'C' },
        { label: 'D', value: 'D' },
        { label: 'E', value: 'E' }
      ],

      students: [],
      searchQuery: '',
      preDocumentsFiltered: [],
      preDocuments: [
        { value: 13, title: 'Controle de Frequência'                 },
        { value: 2,  title: 'Declaração de Comparecimento'           },
        { value: 5,  title: 'Declaração de Comp. Junta Médica'       },
        { value: 8,  title: 'Declaração Rebaixamento Categ.'         },
        { value: 6,  title: 'Declaração de Residência'               },
        { value: 16,  title: 'Declaração de Residência Locatário M1' },
        { value: 17,  title: 'Declaração de Residência Locatário M2' },
        { value: 15, title: 'Entrega Documentos'                     },
        { value: 12, title: 'Escala de Sonolência'                   },
        { value: 1,  title: 'Exame Médico'                           },
        { value: 14, title: 'Exame Médico 2'                         },
        { value: 4,  title: 'Ficha de Matrícula'                     },
        { value: 7,  title: 'Requerimento 2ª Via/Extravio CNH'       },
        { value: 9,  title: 'Termo de Entrega CNH'                   },
        { value: 10, title: 'Teste Prático Categoria A'              },
        { value: 18, title: 'Teste Prático Categoria A ACC'          },
        { value: 11, title: 'Teste Prático Categoria B/C/D/E'        },
        { value: 19, title: 'Teste Prático Categoria B/C/D/E M2'     }
      ]
    }
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    validateDocumentFormModel () {
      return !this.errors.any('documentTemplate')
    },
    today () {
      const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
      return `${YYYY}-${MM}-${DD}`
    },
    validateComparecimento () {
      return !this.errors.any('comparecimento')
    },
    employees () {
      return this.$store.getters['employee/forSelect']
    },
    document () {
      return this.$store.state.documents.document
    },
    documentsFiltered () {
      return this.$store.state.documents.documentsFiltered
    },
    documents () {
      return this.$store.state.documents.documents
    }
  },
  methods: {
    resetLocalData (change = false) {
      this.data_local = JSON.parse(JSON.stringify(this.$store.getters['studentManagement/getStudent']))
      this.students = [this.data_local.id]
      this.changed = change
    },
    addDocumentTemplate () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_student_documents')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }
      this.accordionDocumentTemplate = true

      // this.newDocumentTemplatePopupTitleModel = 'Novo Modelo de Documento'
      this.documentTemplate = { id: null, title: null, print_header: false, body: '' }
      // this.popupDocumentTemplate = true
    },
    async saveDocumentTemplate () {
      this.$vs.loading()
      try {
        if (this.documentTemplate.id) await this.$store.dispatch('documents/update', this.documentTemplate)
        else await this.$store.dispatch('documents/store', this.documentTemplate)
        // this.popupDocumentTemplate = false
        this.accordionDocumentTemplate = false
        this.$vs.loading.close()
        this.searchQuery = ''
        this.updateSearchQuery('')
        this.$vs.notify({
          time: 5000,
          title: 'Sucesso',
          text: 'Documento Salvo.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$nextTick(() => {
          this.$vs.loading.close()
        })
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    onReady (editor)  {
      this.ckEd = editor
      // console.log(editor.ui.componentFactory.names())
    },
    copyVariable (variable) {
      //INSERE A VARIÁVEL ONDE O CURSOR ESTIVER
      this.ckEd.model.change(writer => {
        const insertPosition = this.ckEd.model.document.selection.getFirstPosition()
        writer.insertText(variable, {}, insertPosition)
      })

      //COPIA A VARIÁVEL PARA A MEMÓRIA (CTRL C + CTRL V)
      const thisIns = this
      this.$copyText(variable).then(function () {
        thisIns.$vs.notify({
          time: 700,
          title: 'Success',
          text: 'Variável copiada.',
          color: 'success',
          iconPack: 'feather',
          position: 'top-center',
          icon: 'icon-check-circle'
        })
      }, function () {
        thisIns.$vs.notify({
          title: 'Failed',
          text: 'Oops. Algo inesperado aconteceu. Por favor avise o suporte',
          color: 'danger',
          iconPack: 'feather',
          position: 'top-center',
          icon: 'icon-alert-circle'
        })
      })
    },
    update (doc) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_student_documents')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      this.$store.commit('documents/SET', doc)
      this.newDocumentTemplatePopupTitleModel = 'Alterar Modelo de Documento'
      this.documentTemplate = JSON.parse(JSON.stringify(this.document))
      // this.popupDocumentTemplate = true
      this.accordionDocumentTemplate = true

    },
    confirmDelete (doc) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_student_documents')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('delete'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      this.$store.commit('documents/SET', doc)
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: 'Tem certeza que deseja excluir este modelo de documento?',
        accept: this.delete,
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    delete () {
      this.$store.dispatch('documents/delete', this.document.id)
        .then(()   => {
          this.showDeleteSuccess()
        })
        .catch(err => { console.error(err) })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'SUCESSO',
        text: 'Dados Excluídos',
        iconPack: 'feather',
        icon: 'icon-check'
      })
    },
    async printTemplate (doc, type) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_student_documents')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('read'))) { // ver ou emitir
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      if (!this.students.length) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione ao menos um aluno!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      try {
        this.$vs.loading()
        if (type === 'PDF') {
          this.showPdfTitle = doc.title
          const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/document/template/student/pdf`, {doc, students: this.students})
          this.srcPdf = `data:application/pdf;base64,${resp.data}`
          this.popupShowPdf = true
        }
        this.$nextTick(() => {
          this.$vs.loading.close()
        })
      } catch (error) {
        this.$vs.loading.close()
        //
      }
    },
    async print (doc, type) {

      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_student_documents')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('read'))) { // ver ou emitir
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      if (!this.students.length) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione ao menos um aluno!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }

      try {
        this.$vs.loading()
        if (type === 'PDF') {
          if (doc.value === 1) {
            this.showPdfTitle = 'Ficha Médica'
            const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/document/pdf/printMedicalRecord`, {student_id: this.students })
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 2) {
            this.comparecimento.startDate = this.today
            this.comparecimento.endDate = this.today
            this.showPdfTitle = 'Declaração de Comparecimento'
            this.popupComparecimento = true
          } else if (doc.value === 4) {
            this.showPdfTitle = 'Ficha de Matrícula'
            const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/document/pdf/printRegistrationForm`, { student_id: this.students })
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 5) {
            this.showPdfTitle = 'Declaração de Comp. Junta Médica'
            const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/document/pdf/juntamedica`, { student_id: this.students })
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 6) {
            this.showPdfTitle = 'Declaração de Residência'
            const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/document/pdf/residencia`, { student_id: this.students })
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 16) {
            this.showPdfTitle = 'Declaração de Residência Locatário M1'
            const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/document/pdf/residenciaLocatarioM1`, { student_id: this.students })
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 17) {
            this.showPdfTitle = 'Declaração de Residência Locatário M2'
            const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/document/pdf/residenciaLocatarioM2`, { student_id: this.students })
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 7) {
            this.showPdfTitle = 'Requerimento 2ª Via/Extravio CNH'
            const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/document/pdf/cnh2via`, { student_id: this.students })
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 8) {
            this.showPdfTitle = 'Declaração Rebaixamento Categ.'
            this.popupRebaixamento = true
          } else if (doc.value === 9) {
            this.showPdfTitle = 'Termo de Entrega CNH'
            const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/document/pdf/entregacnh`, { student_id: this.students })
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 10) {
            this.showPdfTitle = 'Teste Prático Categoria A'
            const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/document/pdf/praticadirecao`, { student_id: this.students })
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 18) {
            this.showPdfTitle = 'Teste Prático Categoria A ACC'
            const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/document/pdf/praticadirecao_a_acc`, { student_id: this.students })
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 19) {
            this.showPdfTitle = 'Teste Prático Categoria B/C/D/E M2'
            const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/document/pdf/praticadirecao_bcde_m2`, { student_id: this.students })
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 11) {
            this.showPdfTitle = 'Teste Prático Categoria B/C/D/E'
            const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/document/pdf/praticadirecao_bcde`, { student_id: this.students })
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 12) {
            this.showPdfTitle = 'Escala de Sonolência'
            const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/document/pdf/drowsiness_scale`, { student_id: this.students })
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 13) {
            this.showPdfTitle = 'Controle de Frequência'
            this.popupFrequencyControl = true
          } else if (doc.value === 14) {
            this.showPdfTitle = 'Exame Médico 2'
            const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/document/pdf/examemedico2`, { student_id: this.students })
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          } else if (doc.value === 15) {
            this.showPdfTitle = 'Entrega Documentos'
            const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/document/pdf/entregadocs`, { student_id: this.students })
            this.srcPdf = `data:application/pdf;base64,${resp.data}`
            this.popupShowPdf = true
          }

        } else if (type === 'WORD') {
          const response = await this.$http.post(`${process.env.VUE_APP_API_URL}/document/docx`, {student_id: this.students }, {responseType:'arraybuffer'})
          const blob = new Blob([response.data], { type: 'application/octet-stream' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'documento.docx'
          link.click()
        }
        this.$nextTick(() => { this.$vs.loading.close() })
      } catch (error) {
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Desculpe, algo deu errado. Por favor avise o suporte.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    async showPdf (id) {
      this.$vs.loading()
      if (id === 2) {
        const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/document/pdf/comparecimento`, {
          student_id: this.students,
          data: this.comparecimento
        })
        this.srcPdf = `data:application/pdf;base64,${resp.data}`
        this.popupShowPdf = true
        this.popupComparecimento = false
      } else if (id === 8) {
        const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/document/pdf/rebaixamento`, {
          student_id: this.students,
          data: this.rebaixamento
        })
        this.srcPdf = `data:application/pdf;base64,${resp.data}`
        this.popupShowPdf = true
        this.popupRebaixamento = false
      } else if (id === 13) {
        try {
          const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/document/pdf/frequencyControl`, {
            student_id: this.students,
            data: this.frequencyControl
          })
          this.srcPdf = `data:application/pdf;base64,${resp.data}`
          this.popupShowPdf = true
          this.popupFrequencyControl = false
          this.$nextTick(() => {
            this.$vs.loading.close()
          })
        } catch (error) {
          this.$vs.loading.close()
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        }
      }
      this.$nextTick(() => { this.$vs.loading.close() })
    },
    clearSelectedStudents () {
      this.students.splice(0)
    },
    updateSearchQuery (val) {
      this.debouncedGetSearchResult(val)
    },
    getSearchResult (searchVal) {
      this.preDocumentsFiltered = JSON.parse(JSON.stringify(this.preDocuments.filter(a => a.title.toUpperCase().indexOf(searchVal.toUpperCase()) > -1)))
      this.$store.commit('documents/FILTER_BY_TITLE', searchVal)
    }
  },
  created () {
    this.debouncedGetSearchResult = _.debounce(this.getSearchResult, 600)

    // Register VUEX Modules
    if (!moduleStudentManagement.isRegistered) {
      this.$store.registerModule('studentManagement', moduleStudentManagement)
      moduleStudentManagement.isRegistered = true
    }
    this.resetLocalData()

    if (!moduleEmployee.isRegistered) {
      this.$store.registerModule('employee', moduleEmployee)
      moduleEmployee.isRegistered = true
    }
    this.$store.dispatch('employee/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleDocuments.isRegistered) {
      this.$store.registerModule('documents', moduleDocuments)
      moduleDocuments.isRegistered = true
    }
    this.$store.dispatch('documents/fetchAll').then(() => { }).catch(err => { console.error(err) })
    this.preDocumentsFiltered = JSON.parse(JSON.stringify(this.preDocuments))

  }
}
</script>

<style lang="scss">
  .ck-editor__editable {
      min-height: 650px;
  }
  .con-vs-popup .vs-popup {
    width: 100%;
  }
  .noOverflow .vs-popup--content {
    overflow: hidden;
  }
  .popup80 .vs-popup {
    width: 80% !important;
  }
  .popup50 .vs-popup {
    width: 50% !important;
  }
  .vue_select_drop_size_90 .vs__dropdown-menu {
    max-height: 90px;
  }
  #user-edit-tab-documents th .vs-table-text {
      cursor: default;
  }
  #user-edit-tab-documents .vs-con-table .vs-con-tbody {
      border: 2px solid black;
  }
  .svg-hover:hover {
    fill: #158482;
  }
  .svg-hover {
    fill: #626262;
  }
  #user-edit-tab-documents td {
    border: 1px solid #c0c0c0;
    line-height: 1;
  }
  #user-edit-tab-documents td:hover {
    border-bottom: 1px solid #353434;
  }
  #user-edit-tab-documents td:first-child:hover {
    border-bottom: 1px solid #c0c0c0;
  }
  #user-edit-tab-documents .vs-con-table td:last-child {
    border-right: 1px solid transparent;
  }
  #user-edit-tab-documents .vs-table--thead {
    border: 1px solid transparent;
  }
  #user-edit-tab-documents .vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
    padding: 6px;
  }
  #user-edit-tab-documents .ps__scrollbar-y-rail {
    z-index: 99999;
  }
  .vs-tooltip {
    z-index: 99999;
  }
  .under:hover {
    text-decoration: underline;
  }
</style>

