var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _c("label", { staticClass: "vs-input--label" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _c("v-select", {
        attrs: {
          clearable: false,
          reduce: (option) => option.value,
          options: _vm.options,
          placeholder: "Selecione",
          dir: _vm.$vs.rtl ? "rtl" : "ltr",
          "data-vv-as": "Situação",
        },
        scopedSlots: _vm._u([
          {
            key: "no-options",
            fn: function ({}) {
              return [_vm._v(" Nenhum resultado encontrado. ")]
            },
          },
        ]),
        model: {
          value: _vm.situation,
          callback: function ($$v) {
            _vm.situation = $$v
          },
          expression: "situation",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }