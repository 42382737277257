var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-popup",
    {
      attrs: {
        active: _vm.localActive,
        title: "Reparcelamento - Renegociação de dívida",
      },
      on: {
        "update:active": function ($event) {
          _vm.localActive = $event
        },
      },
    },
    [
      _c("debt-negociation", {
        ref: "debtNegociation",
        attrs: { transactions: _vm.transactions, student: _vm.student },
        on: { saved: _vm.saved },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }