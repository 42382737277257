<template>
  <div v-if="params.data" class="flex items-center">
      <vx-tooltip text="Alterar Situação" position="top" class="flex items-center">
        <feather-icon icon="MenuIcon" svgClasses="h-5 w-5 mr-1 cursor-pointer hover:text-primary" />
        <span class="cursor-pointer hover:text-primary">{{ situation }}</span>
      </vx-tooltip>
  </div>
</template>

<script>
export default {
  name: 'CellRendererContractSituation',
  computed: {
    situation () {
      return this.params.value === 'IN_PROGRESS' ? 'Em Andamento' : (this.params.value === 'FINISHED' ? 'Finalizado' : (this.params.value === 'CANCELED' ? 'Cancelado' : ''))
    }
  }

}
</script>
