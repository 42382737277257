<template>
  <div v-if="params.data" class="flex items-center">

    <div v-if="status === 'Programada'" class="h-4 w-4 inline-block rounded-full mr-2 border-2 border-solid border-secondary"></div>
    <div v-else-if="status === 'Presente'" class="h-4 w-4 inline-block rounded-full mr-2 bg-success"></div>
    <feather-icon v-else class="text-danger mr-1" icon="SlashIcon" svgClasses="w-5 h-5" />
    <span class="cursor-default ml-1 mr-2" :class="`text-${status === 'Presente' ? 'success' : (status === 'Programada' ? 'secondary' : 'danger')}`">
      {{ status | scheduleStatus }}
    </span>

    <vx-tooltip text="Alterar Situação" position="top" class="flex items-center cursor-pointer hover:text-primary">

      <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
        <feather-icon icon="EditIcon" svgClasses="h-5 w-5 mr-1" />
        <vs-dropdown-menu class="w-52">
          <vs-dropdown-item @click="updateStatus('Presente')">
            <span class="flex items-center">
              <div class="h-4 w-4 inline-block rounded-full mr-3 bg-success"></div>
              <span>Presente</span>
            </span>
          </vs-dropdown-item>

          <vs-dropdown-item @click="updateStatus('Ausente')">
            <span class="flex items-center">
              <feather-icon class="mr-2 text-danger" icon="SlashIcon" svgClasses="w-5 h-5" />
              <span class="text-danger hover:text-primary">Ausente</span>
            </span>
          </vs-dropdown-item>

          <vs-dropdown-item @click="updateStatus('Programada')">
            <span class="flex items-center">
              <div class="h-4 w-4 inline-block rounded-full mr-3 border-2 border-solid border-secondary"></div>
              <span>Programada</span>
            </span>
          </vs-dropdown-item>

        </vs-dropdown-menu>
      </vs-dropdown>
    </vx-tooltip>
  </div>
</template>

<script>
export default {
  name: 'CellRendererSituation',
  computed: {
    status () {
      return this.params.value
    }
  },
  methods: {
    updateStatus (status) {
      this.params.updateStatus(this.params, status)// assim chamo a função de fora
    }
  }
}
</script>
