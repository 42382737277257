import state from './moduleContractStudentState.js'
import mutations from './moduleContractStudentMutations.js'
import actions from './moduleContractStudentActions.js'
import getters from './moduleContractStudentGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

