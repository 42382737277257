import Vue from 'vue'

export default {

  //MANIPULA O OBJETO DO ESTADO
  SET (state, payload) {
    state.transaction = Object.assign({}, state.transaction, payload) //precisa ser assim para manter a reatividade
  },

  //ADICIONA UM NOVO ÍTEM NO ARRAY
  ADD (state, payload) {
    state.transactions.push(payload) //precisa ser assim para manter a reatividade
  },

  //SUBSTITUI O ARRAY POR UM NOVO
  REFRESH (state, payload) {
    state.transactions = payload
  },

  REFRESH_GRID (state, payload) {
    state.transactionsGrid = payload
  },

  UPDATE (state, payload) {
    const idx = state.transactions.data.findIndex((o) => o.id === payload.id)
    if (idx >= 0) Vue.set(state.transactions.data, idx, payload)
    const idx2 = state.transactionsGrid.data.findIndex((o) => o.id === payload.id)
    if (idx2 >= 0) Vue.set(state.transactionsGrid.data, idx2, payload)
  },

  //EXCLUI DO ARRAY
  DELETE (state, payload) {
    const idx = state.transactions.data.findIndex(o => o.id === payload)
    if (idx >= 0) {
      state.transactions.data.splice(idx, 1) //precisa ser assim para manter a reatividade
      state.transactions.total = state.transactions.total - 1 //precisa ser assim para manter a reatividade
    }

    const idx2 = state.transactionsGrid.data.findIndex(o => o.id === payload)
    if (idx2 >= 0) {
      state.transactionsGrid.data.splice(idx2, 1) //precisa ser assim para manter a reatividade
      state.transactionsGrid.total = state.transactionsGrid.total - 1 //precisa ser assim para manter a reatividade
    }

    if (state.transaction && state.transaction.id === payload) {
      state.transaction = Object.assign({}, {}) //precisa ser assim para manter a reatividade
    }
  },

  BATCH_DELETE (state, payload) {
    if (state.transactions && state.transactions.data) {
      payload.forEach(id => {
        const index = state.transactions.data.findIndex(o => o.id === id)
        state.transactions.data.splice(index, 1) //precisa ser assim para manter a reatividade
        state.transactions.total = state.transactions.total - 1 //precisa ser assim para manter a reatividade
        if (state.transaction && state.transaction.id === id) {
          state.transaction = Object.assign({}, {}) //precisa ser assim para manter a reatividade
        }
      })
    }
  }
}
