var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex flex-wrap items-center" },
        [
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "vs-dropdown",
                {
                  staticClass: "cursor-pointer",
                  attrs: { "vs-trigger-click": "" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium",
                    },
                    [
                      _c("span", { staticClass: "mr-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.currentPage * _vm.paginationPageSize -
                              (_vm.paginationPageSize - 1)
                          ) +
                            "\n            -\n            " +
                            _vm._s(
                              _vm.fetchGrid.total -
                                _vm.currentPage * _vm.paginationPageSize >
                                0
                                ? _vm.currentPage * _vm.paginationPageSize
                                : _vm.fetchGrid.total
                            ) +
                            "\n            de " +
                            _vm._s(_vm.fetchGrid.total)
                        ),
                      ]),
                      _c("feather-icon", {
                        attrs: {
                          icon: "ChevronDownIcon",
                          svgClasses: "h-4 w-4",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vs-dropdown-menu",
                    [
                      _c(
                        "vs-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.paginationSetPageSize(10)
                            },
                          },
                        },
                        [_c("span", [_vm._v("10")])]
                      ),
                      _c(
                        "vs-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.paginationSetPageSize(20)
                            },
                          },
                        },
                        [_c("span", [_vm._v("20")])]
                      ),
                      _c(
                        "vs-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.paginationSetPageSize(30)
                            },
                          },
                        },
                        [_c("span", [_vm._v("30")])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex-2" },
            [
              _c("vs-pagination", {
                attrs: { total: _vm.totalPages, max: 1 },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex-2" },
            [
              _c("vs-input", {
                staticClass:
                  "sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4",
                attrs: { placeholder: "Busca rápida por nome..." },
                on: { input: _vm.updateSearchQuery },
                model: {
                  value: _vm.searchQuery,
                  callback: function ($$v) {
                    _vm.searchQuery = $$v
                  },
                  expression: "searchQuery",
                },
              }),
            ],
            1
          ),
          !_vm.model.$form.disabled
            ? _c(
                "div",
                { staticClass: "flex-2" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "shadow-md mb-4 md:mb-0",
                      attrs: { "icon-pack": "feather" },
                      on: {
                        click: function ($event) {
                          return _vm.register(false)
                        },
                      },
                    },
                    [_vm._v("Cadastrar")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._t("action"),
        ],
        2
      ),
      _c("ag-grid-vue", {
        ref: "agGridTable",
        staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
        attrs: {
          components: _vm.components,
          gridOptions: _vm.gridOptions,
          columnDefs: _vm.columnDefs,
          overlayLoadingTemplate: _vm.overlayLoadingTemplate,
          overlayNoRowsTemplate: _vm.noRowsTemplate,
          localeText: _vm.localeText,
          rowSelection: "multiple",
          animateRows: true,
          pagination: true,
          paginationPageSize: _vm.paginationPageSize,
          cacheBlockSize: _vm.payload.limit,
          suppressPaginationPanel: true,
          enableRtl: _vm.$vs.rtl,
        },
        on: { "grid-ready": _vm.onGridReady },
      }),
      _vm._t("form"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }