
export default {
  SET_STUDENT (state, payload) {
    state.student = Object.assign({}, state.student, payload) //precisa ser assim para manter a reatividade
  },
  SET_STUDENT_CNH (state, payload) {
    const cnhIndex = state.student.cnhs.findIndex((cnh) => cnh.id === payload.id)
    state.student.cnhs.splice(cnhIndex, 1, payload) //precisa ser assim para manter a reatividade
  },
  ADD_STUDENT_CNH (state, payload) {
    state.student.cnhs.push(payload) //precisa ser assim para manter a reatividade
  },
  DELETE_STUDENT_CNH (state, payload) {
    const cnhIndex = state.student.cnhs.findIndex((cnh) => cnh.id === payload)
    state.student.cnhs.splice(cnhIndex, 1) //precisa ser assim para manter a reatividade
  },
  SET_STUDENTS (state, students) {
    state.students = students
  },
  REMOVE_RECORD (state, itemId) {
    const userIndex = state.students.findIndex((u) => u.id === itemId)
    state.students.splice(userIndex, 1)
  }
}
